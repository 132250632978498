import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

import moment from 'moment';

import File from './forms/fields/multipleFiles';
import Image from './forms/fields/image';
import striptags from 'striptags';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



/**
* Modal for creating referral
* @author   Milan Stanojevic
*/
class CreateReferralModal extends Component {
    constructor(props) {
        super(props);

        this.searchResultsWraps = {};


        this.state = {
            ...props.initialData,
            articles: [],
            formTags: [],
            tags: [],
            sections: [],
            answers: {},
            patientData: {},
            recipientData: {},
            referralSections: { header: '', footer: '', body: '' },
            selectClinicModal: false,
            version: 0,
            referrals: [],
            referralType: 1,
            questionIndex: 0,
            regions: [],
            disabledForm: false,
            totalSubtotal: [],
        };
    }



    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        document.addEventListener('mousedown', this.handleClickOutside);

        // console.log(this.props.referralContentRef);


        if (this.props.sections) {
            this.setState({
                sections: this.props.sections
            }, this.checkTotalItems)
        }
        if (this.props.answers) {
            if (!(Object.keys(this.props.answers).length === 0 && this.props.answers.constructor === Object)) {

                this.setState({
                    answers: this.props.answers,
                    disabledForm: true
                })
            }

        }
        if (this.props.disabledForm) {
            this.setState({ disabledForm: true })
        }
        if (this.props.referral) {
            this.setState({
                referral: this.props.referral
            })
        }


        fetch(API_ENDPOINT + '/data/regions', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                this.setState({ regions: result })
            }
        })

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.answers != this.state.answers) {
            this.setState({
                answers: this.props.answers,
            })
        }

        if (!prevState.questionsRef && this.state.questionsRef && this.props.referralContentRef && this.props.forPrint) {
            if (this.props.referralContentRef) {
                // console.log(this.props.referralContentRef.offsetHeight , this.titleRef.offsetHeight)
                let children = this.state.questionsRef.children;
                let pageHeight = this.props.referralContentRef.offsetHeight + this.titleRef.offsetHeight;
                // let pageHeight = this.props.referralContentRef.offsetHeight;
                const requiredPageHeight = 1550;
                for (let i = 0; i < children.length; i++) {
                    pageHeight += children[i].offsetHeight + 16;
                    if (pageHeight > requiredPageHeight) {
                        children[i].style.marginTop = ((children[i].offsetHeight + 16) - (pageHeight - requiredPageHeight)) + 'px';
                        pageHeight = children[i].offsetHeight;
                    }

                }
            }

        }

    }

    handleClickOutside = (event) => {
        for (var key in this.searchResultsWraps) {
            if (this.searchResultsWraps[key]) {
                if (this.searchResultsWraps[key] && this.searchResultsWraps[key] && !this.searchResultsWraps[key].contains(event.target)) {
                    let stateObj = {};
                    stateObj[`showIcd10Suggestions-${key}`] = false;
                    this.setState(stateObj)
                }

            }
        }

    }



    getQuestion = (id) => {
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].id == id) {
                        return this.props.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.props.sections[i].questions[j].answers.length; k++) {
                            if (this.props.sections[i].questions[j].answers[k].id == id) {
                                return this.props.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }




    answerQuestion(questionId, answer) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        let answers = this.state.answers;

        if (question.type == 1) {
            if (!answers[question.id]) {
                answers[question.id] = null;
            }

            answers[question.id] = answer;
        } else if (question.type == 2) {
            if (!answers[question.id]) {
                answers[question.id] = [];
            }

            if (answers[question.id].indexOf(answer) !== -1) {
                answers[question.id].splice(answers[question.id].indexOf(answer), 1);
            } else {
                answers[question.id].push(answer);
            }

        } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 7 || question.type == 8 || question.type == 9 || question.type == 12 || question.type == 13) {
            answers[question.id] = answer;
        }

        // clear not vissible fields

        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (!this.checkQuestionCondition(this.props.sections[i].questions[j].id)) {
                        delete answers[this.props.sections[i].questions[j].id];
                        /*if (this.props.sections[i].questions[j].type == 5){
                            this.setState({
                                icd10Search: ''
                            })
                        }*/
                    }
                }
            } else {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    delete answers[this.props.sections[i].questions[j].id];
                    /*if (this.props.sections[i].questions[j].type == 5){
                            this.setState({
                                icd10Search: ''
                            })
                        }*/
                }
            }
        }

        //console.log(answers)
        this.setState({
            answers
        }, () => {
            this.props.answer(this.state.answers)
        })
    }




    checkQuestionCondition(questionId) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }

            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (conditionQuestion.type == 2) {
                if (this.state.answers[conditionQuestion.id] && (this.state.answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1 || this.state.answers[conditionQuestion.id].filter(ans => ans.match(question.conditions[i].conditionAnswer)).length)) {
                    return true;
                }

            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 7 || conditionQuestion.type == 8 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13) {
                if (this.state.answers[conditionQuestion.id]) {
                    return true;
                }

            }
        }
        return false;
    }


    getPreviousVisibleChatQuestion = (sectionIdx, questionIdx) => {
        for (let i = questionIdx; i >= 0; i--) {
            if (this.checkQuestionCondition(this.props.sections[sectionIdx].questions[i].id)) {
                return this.props.sections[sectionIdx].questions[i];
            }
        }

        return null;
    }

    checkChatQuestionCondition = (questionId) => {
        if (!this.checkQuestionCondition(questionId)) {
            return false;
        }

        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i] && this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].id == questionId) {
                        if (j == 0) {
                            return true;
                        } else {
                            let previousQuestion = this.getPreviousVisibleChatQuestion(i, j - 1);
                            if (!previousQuestion) {
                                return false;
                            }
                            if (previousQuestion.type == 4) {
                                return true;
                            } else if (previousQuestion.type == 1 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 2 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 3 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 5 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 6 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 7 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 8 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 9 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 12 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 13 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else {
                                return false;
                            }

                        }
                    }
                }
            }
        }

        return true;
    }


    getCurrentChatQuestion = () => {
        for (let i = 0; i < this.props.sections.length; i++) {

            if (this.props.sections[i] && this.props.sections[i].questions && this.checkSectionCondition(i)) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].type == 4) {
                        continue;
                    }

                    if (this.checkChatQuestionCondition(this.props.sections[i].questions[j].id)) {
                        if (this.props.sections[i].questions[j].type == 1 && !this.state.answers[this.props.sections[i].questions[j].id]) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 2 && !(this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length)) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 3 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 5 && !(this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length)) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 6 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 7 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 8 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 9 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 12 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        } else if (this.props.sections[i].questions[j].type == 13 && !(this.state.answers[this.props.sections[i].questions[j].id])) {
                            return this.props.sections[i].questions[j];
                        }
                    }
                }
            }
        }

        return null;
    }

    checkQuestionAnswer = (questionId) => {
        let question = this.getQuestion(questionId);
        if (!question) {
            return false;
        }

        if (question.type == 1) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 2) {
            if (this.state.answers[question.id] && this.state.answers[question.id].length) {
                return true;
            }

        } else if (question.type == 3) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 5) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 6) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 7) {
            if (this.state.answers[question.id] && this.state.answers[question.id][0] && this.state.answers[question.id][1] && this.state.answers[question.id][2] && this.state.answers[question.id][3]) {
                return true;
            }
        } else if (question.type == 8) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 9) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 10) {
            return true;
            // } else if (question.type == 4) {
            //     return true;

        } else if (question.type == 12) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 13) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 14) {
            if (this.state.answers[question.id]) {
                return true;
            }

        }
        return false;
    }

    checkSectionCondition(sectionIdx) {
        let section = this.props.sections[sectionIdx];
        if (!section) {
            return;
        }
        if (sectionIdx > 0) {
            let prevSection = this.props.sections[sectionIdx - 1]
            let questions = prevSection.questions;
            if (questions && questions.length) {
                let notAllAnswered = false;
                for (let i = 0; i < questions.length; i++) {
                    let check = false;
                    if (questions[i] && questions[i].conditions.length == 0) {
                        check = true;
                    } else if (questions[i] && questions[i].conditions.length > 0 && this.checkQuestionCondition(questions[i].id)) {
                        let check2 = true;
                        for (let j = 0; j < questions[i].conditions.length; j++) {
                            if (this.checkQuestionAnswer(questions[i].conditions[j].conditionQuestion) /*&& this.getAnswer(questions[i].id) == questions[i].conditions[j].conditionAnswer*/ && this.checkQuestionCondition(questions[i].id)) {
                                check2 = false;
                            }
                        }
                        if (check2) {
                            check = true;
                        }
                    }


                    if (!this.checkQuestionAnswer(questions[i].id) && check) {
                        notAllAnswered = true;
                    }

                }
                if (notAllAnswered) {
                    return false;
                }
            }
        }




        if (!section.conditions) {
            return true;
        }
        if (!section.conditions.length) {
            return true;
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.answers[question.id] && this.state.answers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 7) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 8) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 12) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            }
        }

        return false;
    }

    checkReferralAnswers = () => {
        let lastQuestionType = -1;
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && (this.props.sections[i].questions[j].type == 1 || this.props.sections[i].questions[j].type == 2 || this.props.sections[i].questions[j].type == 3 || this.props.sections[i].questions[j].type == 5 || this.props.sections[i].questions[j].type == 6 || this.props.sections[i].questions[j].type == 7 || this.props.sections[i].questions[j].type == 8 || this.props.sections[i].questions[j].type == 9 || this.props.sections[i].questions[j].type == 12 || this.props.sections[i].questions[j].type == 13)) {
                        lastQuestionType = this.props.sections[i].questions[j].type;
                        if (!this.state.answers[this.props.sections[i].questions[j].id]) {
                            // console.log(this.getQuestion(this.props.sections[i].questions[j].id))
                            return false;
                        }
                    }

                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && this.props.sections[i].questions[j].type == 4) {
                        lastQuestionType = this.props.sections[i].questions[j].type;
                    }
                }
            }
        }

        return lastQuestionType != 4 ? true : false;
    }


    generateReferral = () => {

        let selectedClinic = this.state.referral ? this.state.referral.clinic : {};
        let selectedGroup = this.state.clinicGroup ? this.state.clinicGroup : {};



        let clinicData = {
            ...selectedClinic,
            logo: selectedGroup ? selectedGroup.logo : null
        }


        let referralData = '';
        referralData += `<p><strong>Patient svar</strong></p>\n\n`
        // console.log(this.state.answers);

        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                if (this.props.sections[i].displayOnReferral) {
                    if (i > 0) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${this.props.sections[i].name}</strong></p>\n`;
                }
                referralData += '<p>\n';
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && this.state.answers[this.props.sections[i].questions[j].id]) {
                        if (this.props.sections[i].questions[j].displayOnReferral) {
                            if (this.props.sections[i].questions[j].type == 1) {
                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id]).text)
                                    referralData += this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id]).text + ' ';
                            } else if (this.props.sections[i].questions[j].type == 2) {
                                if (this.state.answers[this.props.sections[i].questions[j].id]) {

                                    let arr = [];

                                    //for (let k = 0; k < this.state.answers[this.props.sections[i].questions[j].id].length; k++) {
                                    for (let k = 0; k < this.props.sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < this.state.answers[this.props.sections[i].questions[j].id].length; l++) {
                                            // console.log(this.state.answers[this.props.sections[i].questions[j].id][l], this.props.sections[i].questions[j].answers[k].id)
                                            if (this.props.sections[i].questions[j].answers[k] && this.state.answers[this.props.sections[i].questions[j].id][l] == this.props.sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        // console.log(check)
                                        if (check != -1) {
                                            if (this.props.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.answers[this.props.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.answers[this.props.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text)
                                                    arr.push(this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text);
                                                // }
                                            } else {
                                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text)
                                                    referralData += this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text + ' ';
                                            }
                                        }
                                    }

                                    if (this.props.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.answers[this.props.sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(this.state.answers[this.props.sections[i].questions[j].id][this.indexOfIndexOf(this.state.answers[this.props.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    // console.log(arr);

                                    if (arr.length && this.props.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.props.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                        } else {
                                            referralData += this.props.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                    }
                                }
                            } else if (this.props.sections[i].questions[j].type == 3) {
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] + '. ';
                            } else if (this.props.sections[i].questions[j].type == 5) {
                                //referralData += this.state.answers[this.props.sections[i].questions[j].id] + '. ';
                                let newArr = JSON.parse(JSON.stringify(this.state.answers[this.props.sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }
                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }

                            } else if (this.props.sections[i].questions[j].type == 6) {
                                if (this.state.answers[this.props.sections[i].questions[j].id])
                                    referralData += 'Patientens längd: ' + this.state.answers[this.props.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.answers[this.props.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length ? (this.state.answers[this.props.sections[i].questions[j].id][0] / ((this.state.answers[this.props.sections[i].questions[j].id][1] / 100) * (this.state.answers[this.props.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                            } else if (this.props.sections[i].questions[j].type == 7) {
                                referralData += `<br/><br/>
                                Free kort number: ${this.state.answers[this.props.sections[i].questions[j].id] ? this.state.answers[this.props.sections[i].questions[j].id][0] : ''}<br/>
                                Valid from: ${this.state.answers[this.props.sections[i].questions[j].id] ? this.state.answers[this.props.sections[i].questions[j].id][1] : ''}<br/>
                                Valid to: ${this.state.answers[this.props.sections[i].questions[j].id] ? this.state.answers[this.props.sections[i].questions[j].id][2] : ''}<br/>
                                Issued at: ${this.state.answers[this.props.sections[i].questions[j].id] ? this.state.answers[this.props.sections[i].questions[j].id][3] : ''}<br/>
                                `
                            } else if (this.props.sections[i].questions[j].type == 8) {
                                referralData += `<a href='${API_ENDPOINT + this.state.answers[this.props.sections[i].questions[j].id]}' target="_blank"> ${this.props.sections[i].questions[j].name}</a>` + ' ';
                            } else if (this.props.sections[i].questions[j].type == 9) {
                                if (this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length) {
                                    for (let f = 0; f < this.state.answers[this.props.sections[i].questions[j].id].length; f++) {
                                        if (this.state.answers[this.props.sections[i].questions[j].id][f] && this.state.answers[this.props.sections[i].questions[j].id][f].file)
                                            referralData += `<a href='${API_ENDPOINT + this.state.answers[this.props.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                    }
                                }
                                // referralData += `<a href='${API_ENDPOINT + this.state.answers[this.props.sections[i].questions[j].id].file}' target="_blank"> File/photo</a>` + ' ';
                            } else if (this.props.sections[i].questions[j].type == 12) {
                                referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][0] ? this.state.answers[this.props.sections[i].questions[j].id][0] + '/' : '';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][1] ? this.state.answers[this.props.sections[i].questions[j].id][1] + '<br/>' : '';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.answers[this.props.sections[i].questions[j].id][2] + '<br/>' : '';
                            } else if (this.props.sections[i].questions[j].type == 13) {
                                referralData += 'Patientens VAS-skalvärde är:';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][0] ? this.state.answers[this.props.sections[i].questions[j].id][0] + '<br/>' : '';
                            }
                        }
                    }
                }

                referralData += '</p>\n';
            }
        }


        let html = `
        <div style="display: flex; justify-content: space-between; align-items: center;">
        ${clinicData.logo ? `<p><img style="float: left; max-height: 80px; object-fit: contain; max-width: 220px" src="${API_ENDPOINT + clinicData.logo}" /></p>` : ''}
<p style="text-align: right; float: right;"><strong>Remissdatum:</strong> ${moment().format(this.props.dateFormat)}</p>
</div>
<div style="clear: both; margin-top: 10px;">
    <div>${clinicData.name ? clinicData.name : ''}</div>
    <div>${clinicData.street ? clinicData.street : ''}, ${clinicData.zip ? clinicData.zip : ''} ${clinicData.city ? clinicData.city : ''}</div>
    <div>${clinicData.phone ? clinicData.phone : ''} ${clinicData.email ? clinicData.email : ''}</div>
</div>
<hr />

${referralData}

<p><strong>Samtycke</strong></p>
${true ? '<p>Patienten har l&auml;mnat samtycke till att ni som remissmottagare f&aring;r ta del av tidigare journalhandlingar/anteckningar.</p>' : '<p>Patienten har inte l&auml;mnat samtycke till att ni som remissmottagare f&aring;r ta del av tidigare journalhandlingar/anteckningar.</p>'}
        `

        return html;

    }

    changePatientData = (name, value) => {
        let patientData = this.state.patientData;
        patientData[name] = value;
        this.setState({
            patientData
        })
    }


    changeRecipientData = (name, value) => {
        let recipientData = this.state.recipientData;
        recipientData[name] = value;
        this.setState({
            recipientData
        })
    }

    changeReferralSections = (name, value) => {

        let referralSections = this.state.referralSections;
        referralSections[name] = value;
        this.setState({
            referralSections
        })

    }


    suggest = () => {
        let suggestions = [];
        if (this.state.search) {
            for (let i = 0; i < this.state.articles.length; i++) {
                if (this.state.articles[i].title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    if (suggestions.length < 10)
                        suggestions.push(this.state.articles[i]);
                }
            }
        }


        this.setState({
            suggestions
        })
    }

    splitAddress = (address) => {
        //let address = 'Hudmottagning, Skaraborgs Sjukhus Falköping, Danska vägen 62, 521 85, Falköping';

        let addressLines = ['', '', ''];

        let numbersCount = 0;
        for (let i = address.length - 1; i >= 0; i--) {
            if (address[i] == '0' || address[i] == '1' || address[i] == '2' || address[i] == '3' || address[i] == '4' || address[i] == '5' || address[i] == '6' || address[i] == '7' || address[i] == '8' || address[i] == '9') {
                numbersCount++;
            }

            if (numbersCount == 5) {
                addressLines[2] = address.substring(i - 1, address.length);
                address = address.replace(addressLines[2], '').trim();
                break;
            }
        }

        if (address[address.length - 1] == ',') {
            address = address.substring(0, address.length - 1);
            address = address.trim();
        }


        let splitted = address.split(',');
        addressLines[0] = splitted[0];


        let arr = [];
        for (let i = 1; i < splitted.length; i++) {
            arr.push(splitted[i]);
        }

        addressLines[1] = arr.join(', ');

        if (addressLines[1] == '' && addressLines[2]) {
            addressLines[1] = addressLines[2];
            addressLines[2] = '';
        }

        return addressLines;
    }


    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }





    render() {
        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];
        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.props.sections && this.props.sections.length ?
                            <Col lg="12">
                                <div className={this.props.forPrint ? "panel referral-section for-print-style-form" : "panel referral-section"} style={{ padding: 15 }}>
                                    <Row>
                                        <Col lg="12" >
                                            <div ref={(node) => this.titleRef = node}>
                                                {
                                                    !this.props.hideTitle && this.state.referral ?
                                                        <>
                                                            <h4>{this.state.referral.name}</h4>
                                                            {/* {
                                                            this.state.referral.description
                                                        } */}
                                                            <br />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>






                                            <div ref={(node) => { if (!this.state.questionsRef) this.setState({ questionsRef: node }) }}>
                                                {

                                                    this.props.sections[0].questions.map((question, qidx) => {
                                                        let name = question.name;
                                                        if (question.wikiLinks && question.wikiLinks) {
                                                            for (let i = 0; i < question.wikiLinks.length; i++) {
                                                                name = name.replace(question.wikiLinks[i].term, `<a href="#" widx="${i}">${question.wikiLinks[i].term}</a>`)
                                                            }
                                                        }

                                                        let questionCssStyle = {};
                                                        if (question.fontSize) {
                                                            questionCssStyle.fontSize = question.fontSize;
                                                        }
                                                        if (question.fontWeight) {
                                                            questionCssStyle.fontWeight = question.fontWeight;
                                                        }
                                                        if (question.fontStyle) {
                                                            questionCssStyle.fontStyle = question.fontStyle;
                                                        }


                                                        if (question.type != 10)
                                                            return (

                                                                <div>
                                                                    <FormGroup tag="fieldset">
                                                                        {question.wikiLinks && question.wikiLinks.length ?
                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3 || question.type == 6 || question.type == 7 || question.type == 12 || question.type == 13) ? 'required-question' : ''}>{<div onClick={(e) => {
                                                                                if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                    this.setState({
                                                                                        wikiModal: question.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                    })
                                                                                }
                                                                            }} dangerouslySetInnerHTML={{ __html: name }}></div>}</Label>

                                                                            :
                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3 || question.type == 6 || question.type == 7 || question.type == 12 || question.type == 13) ? 'required-question' : ''}>{question.name}                                                                                            </Label>

                                                                        }

                                                                        {question.type == 1 ?
                                                                            question.answers.map((answer) => {
                                                                                let answerName = answer.name;
                                                                                if (answer.wikiLinks) {
                                                                                    for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                        answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                                    }
                                                                                }

                                                                                return (
                                                                                    <FormGroup className={this.state.answers[question.id] == answer.id ? 'referal-checkbox checked-answer' : 'referal-checkbox'} check>
                                                                                        <Label size="sm" check>
                                                                                            <Input size="sm" name={question.id} disabled={this.state.disabledForm} type={'radio'} checked={this.state.answers[question.id] == answer.id ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                            {
                                                                                                this.state.disabledForm ?

                                                                                                    <>
                                                                                                        <p>{striptags(answerName)}</p>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {' '}{<div onClick={(e) => {
                                                                                                            if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                this.setState({
                                                                                                                    wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                })
                                                                                                            }
                                                                                                        }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                                    </>
                                                                                            }
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    // <div>
                                                                                    //     <FormGroup check style={{ paddingLeft: 0 }}>
                                                                                    //         <div className={this.state.answers[question.id] == answer.id ? 'referal-checkbox checked-answer' : 'referal-checkbox'}>
                                                                                    //             <Label size="sm" check>
                                                                                    //                 <Input size="sm" name={question.id} disabled={this.state.disabledForm} type={'radio'} checked={this.state.answers[question.id] == answer.id ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                    //                 {
                                                                                    //                     this.state.disabledForm ?

                                                                                    //                         <>
                                                                                    //                             <p>{striptags(answerName)}</p>
                                                                                    //                         </>
                                                                                    //                         :
                                                                                    //                         <>
                                                                                    //                             {' '}{<div onClick={(e) => {
                                                                                    //                                 if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                    //                                     this.setState({
                                                                                    //                                         wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                    //                                     })
                                                                                    //                                 }
                                                                                    //                             }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                    //                         </>
                                                                                    //                 }

                                                                                    //             </Label>
                                                                                    //         </div>

                                                                                    //     </FormGroup>
                                                                                    // </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                        }
                                                                        {question.type == 2 ?
                                                                            <>
                                                                                {
                                                                                    question.answers.map((answer) => {
                                                                                        let answerName = answer.name;
                                                                                        if (answer.wikiLinks) {
                                                                                            for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                                answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                                            }
                                                                                        }


                                                                                        return (
                                                                                            <FormGroup className={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? 'referal-checkbox checked-answer' : 'referal-checkbox'} check>
                                                                                                <Label size="sm" check>
                                                                                                    <Input size="sm" name={question.id} disabled={this.state.disabledForm} type={'checkbox'} checked={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                                    {
                                                                                                        this.state.disabledForm ?

                                                                                                            <>
                                                                                                                <p>{striptags(answerName)}</p>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {' '}{<div onClick={(e) => {
                                                                                                                    if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                        this.setState({
                                                                                                                            wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                        })
                                                                                                                    }
                                                                                                                }}
                                                                                                                    dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                                            </>
                                                                                                    }
                                                                                                </Label>
                                                                                            </FormGroup>
                                                                                            // <FormGroup check style={{ paddingLeft: 0 }}>
                                                                                            //     <div className={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? 'referal-checkbox checked-answer' : 'referal-checkbox'}>
                                                                                            //         <Label size="sm" check>
                                                                                            //             <Input size="sm" name={question.id} disabled={this.state.disabledForm} type={'checkbox'} checked={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                            //             {
                                                                                            //                 this.state.disabledForm ?

                                                                                            //                     <>
                                                                                            //                         <p>{striptags(answerName)}</p>
                                                                                            //                     </>
                                                                                            //                     :
                                                                                            //                     <>
                                                                                            //                         {' '}{<div onClick={(e) => {
                                                                                            //                             if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                            //                                 this.setState({
                                                                                            //                                     wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                            //                                 })
                                                                                            //                             }
                                                                                            //                         }}
                                                                                            //                             dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                            //                     </>
                                                                                            //             }


                                                                                            //         </Label>
                                                                                            //     </div>
                                                                                            // </FormGroup>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                {
                                                                                    question.answerPrefix && question.userInput ?
                                                                                        <FormGroup check>
                                                                                            <Label size="sm" check>
                                                                                                <Input size="sm" name={question.id} type={'checkbox'} disabled={this.state.disabledForm} checked={this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') !== -1 ? true : false} onChange={() => {
                                                                                                    let answers = this.state.answers;
                                                                                                    if (!answers[question.id]) {
                                                                                                        answers[question.id] = [];
                                                                                                    }

                                                                                                    let index = this.indexOfIndexOf(answers[question.id], '__user-input:');
                                                                                                    // console.log(index, 'index');
                                                                                                    if (index != -1) {
                                                                                                        answers[question.id].splice(index, 1)
                                                                                                    } else {
                                                                                                        answers[question.id].push('__user-input:');
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        answers
                                                                                                    }, this.forceUpdate)
                                                                                                }} />{' '}{'Ytterligare'}
                                                                                            </Label>
                                                                                        </FormGroup>

                                                                                        :

                                                                                        null
                                                                                }
                                                                                {
                                                                                    this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') != -1 ?
                                                                                        <>
                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '') ? 'required-question' : ''}>{question.answerPrefix}</Label>

                                                                                            <Input size="sm" type="text" disabled={this.state.disabledForm} value={this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '')} onChange={(e) => {
                                                                                                let index = this.indexOfIndexOf(this.state.answers[question.id], '__user-input:');
                                                                                                let answers = this.state.answers;
                                                                                                answers[question.id][index] = '__user-input:' + e.target.value;
                                                                                                this.setState({
                                                                                                    answers
                                                                                                })
                                                                                            }}></Input>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </>
                                                                            :
                                                                            null

                                                                        }


                                                                        {
                                                                            question.type == 3 ?
                                                                                <Input style={questionCssStyle} disabled={this.state.disabledForm} size="sm" type="text" value={this.state.answers[question.id]} onChange={(e) => {
                                                                                    this.answerQuestion(question.id, e.target.value)
                                                                                }}></Input>
                                                                                :

                                                                                null
                                                                        }
                                                                        {/* {
                                                                            question.type == 8 ?
                                                                                <File onChange={(img) => {
                                                                                    this.answerQuestion(question.id, img)

                                                                                }} value={this.state.answers[question.id]} />
                                                                                :

                                                                                null
                                                                        } */}
                                                                        {
                                                                            question.type == 9 ?

                                                                                <File onChange={(img) => {
                                                                                    console.log("ddddddddddddddddddd")
                                                                                    this.answerQuestion(question.id, img)

                                                                                }} value={this.state.answers[question.id]} />
                                                                                :

                                                                                null
                                                                        }
                                                                        {
                                                                            question.type == 7 ?
                                                                                <Row>
                                                                                    <Col lg="3">
                                                                                        <FormGroup>
                                                                                            <Label>{'Free kort number'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', '', ''];
                                                                                                val[0] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="3">
                                                                                        <FormGroup>
                                                                                            <Label>{'Valid from'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="date" value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', '', ''];
                                                                                                val[1] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="3">
                                                                                        <FormGroup>
                                                                                            <Label>{'Valid to'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="date" value={this.state.answers[question.id] ? this.state.answers[question.id][2] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', '', ''];
                                                                                                val[2] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="3">
                                                                                        <FormGroup>
                                                                                            <Label>{'Issued at'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="select" value={this.state.answers[question.id] ? this.state.answers[question.id][3] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', '', ''];
                                                                                                val[3] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}>
                                                                                                {
                                                                                                    this.state.regions ? [{ shortName: 'Select region'.translate(this.props.lang) }, ...this.state.regions].map((item, idx) => {
                                                                                                        return (
                                                                                                            <option value={idx == 0 ? null : item.shortName}>{item.shortName}</option>
                                                                                                        )
                                                                                                    })
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </Input>

                                                                                        </FormGroup>
                                                                                    </Col>



                                                                                </Row>
                                                                                :

                                                                                null
                                                                        }

                                                                        {
                                                                            question.type == 6 ?
                                                                                <Row>
                                                                                    <Col lg="2">
                                                                                        <FormGroup>
                                                                                            <Label>{'Weight [kg]'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                val[0] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="2">
                                                                                        <FormGroup>
                                                                                            <Label>{'Height [cm]'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                val[1] = e.target.value;
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="2" style={{ paddingTop: 35 }}>
                                                                                        <div style={{
                                                                                            width: 24,
                                                                                            height: 24,
                                                                                            backgroundColor: parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                                                                :
                                                                                                parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                                                                    :
                                                                                                    parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                                                                        :
                                                                                                        parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                                                                            :
                                                                                                            parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                                                                :
                                                                                                                bmiIndex[5]

                                                                                        }}></div>
                                                                                    </Col>

                                                                                </Row>
                                                                                :

                                                                                null
                                                                        }
                                                                        {
                                                                            question.type == 12 ?
                                                                                <Row>

                                                                                    <Col lg="4">
                                                                                        <FormGroup>
                                                                                            <Label>{'Systolic'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                if (Number(e.target.value) > 300) {
                                                                                                    val[0] = 300;
                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) < 300) {
                                                                                                    val[0] = e.target.value;
                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                    val[0] = 0;
                                                                                                }
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="4">
                                                                                        <FormGroup>
                                                                                            <Label>{'Diastolic'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                if (Number(e.target.value) > 300) {
                                                                                                    val[1] = 300;
                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) < 300) {
                                                                                                    val[1] = e.target.value;
                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                    val[1] = 0;
                                                                                                }

                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="4">
                                                                                        <FormGroup>
                                                                                            <Label>{'Pulse'.translate(this.props.lang)}</Label>
                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][2] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                if (Number(e.target.value) > 300) {
                                                                                                    val[2] = 300;
                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) < 300) {
                                                                                                    val[2] = e.target.value;
                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                    val[2] = 0;
                                                                                                }
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                </Row>
                                                                                :

                                                                                null
                                                                        }
                                                                        {
                                                                            question.type == 13 ?
                                                                                <Row>
                                                                                    <Col lg="4">
                                                                                        <FormGroup>
                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='10' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : [''];
                                                                                                if (Number(e.target.value) > 10) {
                                                                                                    val[0] = 10;
                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) < 10) {
                                                                                                    val[0] = e.target.value;
                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                    val[0] = 0;
                                                                                                }
                                                                                                this.answerQuestion(question.id, val)
                                                                                            }}></Input>

                                                                                        </FormGroup>
                                                                                    </Col>

                                                                                </Row>
                                                                                :

                                                                                null
                                                                        }


                                                                        {
                                                                            question.type == 4 ?
                                                                                <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>
                                                                                :

                                                                                null
                                                                        }
                                                                        {
                                                                            question.type == 5 ?
                                                                                <>
                                                                                    {
                                                                                        // console.log(this.state.answers[question.id])
                                                                                    }
                                                                                    {

                                                                                        (this.state.answers[question.id] || [""]).map((diagnose, didx) => {
                                                                                            return (
                                                                                                <FormGroup>
                                                                                                    <Input onKeyUp={(e) => {
                                                                                                        if (e.keyCode == 13) {
                                                                                                            this.setState({
                                                                                                                suggestionIndex: null,
                                                                                                            })

                                                                                                            if (this.state.suggestionIndex != null) {
                                                                                                                let suggestion = JSON.parse(JSON.stringify(this.state[`icd10Suggestions-${question.id}-${didx}`][this.state.suggestionIndex]));

                                                                                                                let arr = this.state.answers;
                                                                                                                if (!arr[question.id]) {
                                                                                                                    arr[question.id] = [];
                                                                                                                }

                                                                                                                arr[question.id][didx] = suggestion.code + ' - ' + suggestion.name;


                                                                                                                this.answerQuestion(question.id, arr[question.id])

                                                                                                                //this.answerQuestion(question.id, suggestion.code + ' - ' + suggestion.name);
                                                                                                                let stateObj = {};
                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                this.setState(stateObj);
                                                                                                                return;
                                                                                                            }

                                                                                                        }
                                                                                                        if (e.keyCode == 38) {
                                                                                                            this.setState({
                                                                                                                suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                                                            })
                                                                                                        }
                                                                                                        if (e.keyCode == 40) {
                                                                                                            this.setState({
                                                                                                                suggestionIndex: this.state.suggestionIndex + 1 >= this.state[`icd10Suggestions-${question.id}-${didx}`].length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                                                            })

                                                                                                        }
                                                                                                    }} onFocus={() => {
                                                                                                        let stateObj = { suggestionIndex: null };
                                                                                                        stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                        this.setState(stateObj);
                                                                                                    }} style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] && this.state.answers[question.id][didx] ? this.state.answers[question.id][didx] : ''} onChange={(e) => {
                                                                                                        //this.answerQuestion(question.id, e.target.value)
                                                                                                        let arr = this.state.answers;
                                                                                                        if (!arr[question.id]) {
                                                                                                            arr[question.id] = [];
                                                                                                        }

                                                                                                        arr[question.id][didx] = e.target.value;


                                                                                                        this.answerQuestion(question.id, arr[question.id])
                                                                                                        fetch(API_ENDPOINT + '/data/icd10/suggest', {
                                                                                                            method: 'POST',
                                                                                                            headers: {
                                                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                                'content-type': 'application/json'
                                                                                                            },
                                                                                                            body: JSON.stringify({ search: e.target.value })
                                                                                                        }).then(parseJSON).then(({ result, status }) => {
                                                                                                            if (status >= 200 && status < 300) {
                                                                                                                let stateObj = {};
                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = result;
                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                this.setState(stateObj);
                                                                                                            }
                                                                                                        })


                                                                                                    }}></Input>
                                                                                                    {this.state[`showIcd10Suggestions-${question.id}-${didx}`] ?
                                                                                                        <div className="search-results" ref={(node) => { this.searchResultsWraps[question.id + '-' + didx] = node; }}>
                                                                                                            <ul >
                                                                                                                {
                                                                                                                    this.state[`icd10Suggestions-${question.id}-${didx}`] && this.state[`icd10Suggestions-${question.id}-${didx}`].map((icd10Item, idx) => {
                                                                                                                        return (
                                                                                                                            <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {

                                                                                                                                let arr = this.state.answers;
                                                                                                                                if (!arr[question.id]) {
                                                                                                                                    arr[question.id] = [];
                                                                                                                                }

                                                                                                                                arr[question.id][didx] = icd10Item.code + ' - ' + icd10Item.name;


                                                                                                                                this.answerQuestion(question.id, arr[question.id])


                                                                                                                                let stateObj = {};
                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                this.setState(stateObj);
                                                                                                                            }}>{icd10Item.code} - {icd10Item.name}</li>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </FormGroup>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                    <button className="add-diagnose-button" onClick={() => {
                                                                                        let answers = this.state.answers;
                                                                                        if (!answers[question.id]) {
                                                                                            answers[question.id] = [""];
                                                                                        }

                                                                                        answers[question.id].push("");
                                                                                        this.setState({
                                                                                            answers
                                                                                        })

                                                                                    }}>{'Add diagnos'.translate(this.props.lang)}</button>

                                                                                </>
                                                                                :

                                                                                null
                                                                        }

                                                                        {/*
                                                                        this.state.questionIndex == this.props.sections[0].questions.length - 1 ?
                                                                            <Button disabled={this.state._sendingReferral} className="confirm-button" color="primary" onClick={() => {
                                                                                this.sendReferral();
                                                                            }
                                                                            }>{this.state._sendingReferral ? 'Processing...'.translate(this.props.lang) : 'Finish'.translate(this.props.lang)}</Button>

                                                                            :
                                                                            <Button className="confirm-button" color="primary" onClick={() => {
                                                                                this.setState({
                                                                                    questionIndex: this.state.questionIndex + 1 <= this.props.sections[0].questions.length - 1 ? this.state.questionIndex + 1 : this.state.questionIndex
                                                                                })
                                                                            }
                                                                            }>{'Confirm'.translate(this.props.lang)}</Button>*/
                                                                        }

                                                                    </FormGroup>

                                                                </div>
                                                            )
                                                    }
                                                    )

                                                }
                                            </div>

                                            {/*
                                            <div className="ctrl-buttons">
                                                {this.state.questionIndex !== 0 ?
                                                    <Button outline color="primary" onClick={() => {
                                                        this.setState({
                                                            questionIndex: this.state.questionIndex > 0 ? this.state.questionIndex - 1 : 0
                                                        })
                                                    }}>{'Back'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                                }
                                                {
                                                    this.state.questionIndex != this.props.sections[0].questions.length - 1 ?
                                                        <Button outline color="primary" onClick={() => {
                                                            this.setState({
                                                                questionIndex: this.state.questionIndex + 1 <= this.props.sections[0].questions.length - 1 ? this.state.questionIndex + 1 : this.state.questionIndex
                                                            })
                                                        }}>{'Skip'.translate(this.props.lang)}</Button>
                                                        :
                                                        null
                                                }
                                            </div>
                                            */}
                                        </Col>
                                        {/*<Col lg="6">
                                            <HTML value={this.generateReferral()} onChange={() => { }}></HTML>

                                        </Col>*/}
                                    </Row>
                                </div>


                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default CreateReferralModal;