import React, { Component, Fragment } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, Label, FormGroup, Row, Col } from 'reactstrap';
import InfoWithTooltip from '../InfoWithTooltip';

import SSNField from '../forms/fields/socialSecurityNumber';
import PhoneField from '../forms/fields/phone';
import Checkbox from '../forms/fields/checkbox';
import { validateSocialSecurityNumber } from '../../views/patients/searchEnterprise';
import { API_ENDPOINT } from '../../constants';
import { defaultError, isStringAValidNumber } from '../TwoFactorAuthModal';
import InlineSVG from 'react-inlinesvg';
// import QRCode from "react-qr-code";
import Isvg from "react-inlinesvg";

import infoIcon from '../../assets/svg/link-new-tab.svg';
import trashIcon from '../../assets/svg/garbage.svg';
import uploadIcon from '../../assets/svg/upload.svg';
import NetId from '../../assets/images/netid.png';
import BankId from '../../assets/images/bankid.png';
import ArrowBack from '../../assets/svg/arrow-back.svg'

import axios from 'axios';
import { v4 as uuid } from 'uuid';
import DateOfBirth from '../forms/fields/dateOfBirth';
// import bankIdIcon from '../../assets/images/icon-bankid2.png';

const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'application/rtf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'video/mp4', 'application/json', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

const allowedFileTypeExtensions = ['jpg', 'jpeg', 'png', 'webp', 'txt', 'xls', 'xlsx', 'xml', 'rtf', 'ppt', 'pptx', 'mp4', 'json', 'csv', 'doc', 'docx', 'pdf'];

/**
 * @typedef {Object} Props
 * @property {Boolean} isOpen - asdf
 * @property {Boolean} hasBankId - asdf
 * @property {String} clinicGroupId - id from clinic group
 * @property {Function} closeModal - function to close the modal
 * @property {String} lang - language
 */

/**
 * @class
 * @augments {Component<Props, State>}
 */
export class EconnectExternalCasesModal extends Component {
    /**
     * 
     * @param {Props} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            num: 0,
            loading: false,
            error: null,

            step: 1,

            bankId: '',
            bankIdError: null,
            sign: false,
            submitting: false,

            patient: {
                type: ['denmark', 'sweden'].includes(props?.country) ? null : 'external',
                id: null,
                ssn: '',
                name: '',
                gender: '',
                phone: '',
                email: '',
                dateOfBirth: '',
            },
            sender: {
                type: 'external',
                id: null,
                departmentId: null,
                name: '',
                city: '',
                street: '',
                zip: '',
                phone: '',
                email: '',
                user: '',
            },
            subject: '',
            anamnesis: '',
            status: '',
            attachments: [],
            patientConsent: false,

            pdfGenerated: false,
        }
    }

    onMessage = (e) => {
        if (e?.data?.includes?.('signed')) {
            let id;
            if (e?.data?.includes?.('=')) {
                id = e.data.split('=')?.[1] ?? null;
                this.submitCase(id, true, false);
            }
        }

        if (e?.data?.includes?.('sign_netid')) {
            let id;
            if (e?.data?.includes?.('=')) {
                id = e.data.split('=')?.[1] ?? null;
                this.submitCase(id, false, true);
            }
        }
    }

    componentDidMount() {
        const _uuid = uuid();
        const uploadHeader = 'curoflow-econnect';
        const uploadToken = this.generateRandomToken();
        this.setState({ uuid: _uuid, uploadHeader, uploadToken });

        window?.addEventListener?.('message', this.onMessage);

        if (this.props.hasBankId)
            this.interval = setInterval(() => {
                this.checkBankIdSign()

            }, 2000);
    }

    checkBankIdSign = () => {
        fetch(API_ENDPOINT + '/users/bankid/v5/check/sign/e-connect', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key: this.state.key, group: this.props.clinicGroupId ? this.props.clinicGroupId : '' })
        }).then(res => res.json()).then((result) => {
            if (result && result.status && result.status === 'Finished') {
                // this.props.toggle(this.props.isOpen && this.props.isOpen.key)
                this.submitCase(this.state.key, true, false)
                clearInterval(this.interval)
                clearInterval(this.qrCodeInterval)
            }
        })
    }

    componentWillUnmount() {
        window?.removeEventListener?.('message', this.onMessage);
        clearInterval(this.timer1);

        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.qrCodeInterval) {
            clearInterval(this.qrCodeInterval)
        }
    }

    generateRandomToken = () => {
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        const generateRandomNumber = (max, min = 0) => Math.floor(Math.random() * (max - min) + min);
        const possibilities = [...numbers, ...letters, ...LETTERS];

        let token = '';

        for (let i = 0; i < 5; i++) {
            let code = '';
            for (let j = 0; j < 5; j++) {
                if (i === 0 && j === 0) code += 'r';
                else if (i === 1 && j === 1) code += 'a';
                else if (i === 2 && j === 2) code += 'd';
                else if (i === 3 && j === 3) code += 'a';
                else if (i === 4 && j === 4) code += 'n';
                else code += possibilities[generateRandomNumber(possibilities.length)];
            }

            if (i === 0) token = code;
            else token += `-${code}`
        }

        return token;
    }

    submitCase = (id, signedWithBankId = false, signedWithNetId = false) => {
        this.setState({ loading: true, submitting: true, sign: false }, async () => {
            const form = {
                id,
                signedWithBankId,
                signedWithNetId,
                patient: this.state.patient,
                sender: this.state.sender,
                receiverId: this.props.clinicGroupId,
                subject: this.state.subject,
                anamnesis: this.state.anamnesis,
                status: this.state.status,
                attachments: this.state.attachments,
                uuid: this.state.uuid,
                patientConsent: this.state.patientConsent
            };

            const response = await fetch(API_ENDPOINT + '/e-connect/ext/cases', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'x-cf-upload-token': this.state.uploadToken,
                    'x-cf-upload-header': this.state.uploadHeader
                },
                body: JSON.stringify({ form })
            });
            const result = await response?.json?.();

            if (!result || result.error || !result.uuid) return this.setState({
                loading: false,
                submitting: false,
                error: result.error || defaultError,
            });

            this.numOfTries = 0;
            this.checkProgress(result.uuid);
        });
    }

    getPdf = (id) => {
        if (this.state.pdfGenerated || !id) return;
        this.setState({ loading: true }, async () => {
            const newState = {
                loading: false,
            }
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/pdf/${id}?lang=${this.props.lang || 'en'}&groupId=${this.props.clinicGroupId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                    'x-cf-upload-token': this.state.uploadToken,
                    'x-cf-upload-header': this.state.uploadHeader
                }
            });
            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }
            const result = await response.json();
            if (!result || !result.url || result.error) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            }

            this.setState(newState, () => {
                window?.open?.(API_ENDPOINT + result.url, '_blank');
                this.setState({ pdfGenerated: true });
            });
        })
    }

    checkProgress = (uuid) => {
        if (!uuid) return;
        const fetcher = async (uuid) => {
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/check/${uuid}`, {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response || !response.ok) return null;
            const result = await response.json();
            if (!result || !result.id) return null;
            return result.id;
        }
        this.timer1 = setInterval(async () => {
            if (this.numOfTries >= 5) {
                clearInterval(this.timer1);
                this.timer1 = null;
                this.setState({ loading: false, submitting: false, error: defaultError });
                return;
            }
            let newNum = (this.numOfTries || 0) + 1;
            this.numOfTries = newNum;
            const id = await fetcher(uuid);
            if (id) {
                clearInterval(this.timer1);
                this.timer1 = null;
                this.setState({ loading: false, submitting: false, step: 5 })
            }
        }, 1500);
    }

    uploadFiles = (files) => {
        if (!files) return;
        try {
            files = Array.from(files);
            if (files && Array.isArray(files) && files.length > 0) {
                this.setState({ _uploading: true, _dragging: false }, async () => {
                    let attachments = [];
                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        if (!file || !file.type || !file.name) continue;
                        if (!allowedFileTypes.includes(file.type)) continue;
                        const obj = {
                            type: (file.type?.startsWith?.('video') ? (
                                'video'
                            ) : file.type?.startsWith?.('image') ? (
                                'image'
                            ) : 'file'),
                            name: file.name
                        };

                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('group', this.props.clinicGroupId);
                        formData.append('fileType', 'eConnect');

                        try {
                            const response = await axios.post(API_ENDPOINT + '/data/upload-document', formData, {
                                headers: {
                                    Accept: 'application/json',
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'x-cf-upload-token': this.state.uploadToken,
                                    'x-cf-upload-header': this.state.uploadHeader
                                }
                            });
                            if (response && response.status && response.status == 200 && response.data) {
                                obj.url = response.data;
                                attachments.push(obj);
                            }
                        } catch (err) {
                            console.log('upload err: ', err);
                            continue;
                        }
                    };

                    this.setState(prev => ({
                        ...prev,
                        _uploading: false,
                        attachments: [...(prev?.attachments || []), ...attachments]
                    }));

                    if (attachments && attachments.length > 0) {
                        fetch(API_ENDPOINT + '/e-connect/uploads', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'x-cf-upload-token': this.state.uploadToken,
                                'x-cf-upload-header': this.state.uploadHeader
                            },
                            body: JSON.stringify({
                                attachments,
                                uuid: this.state.uuid
                            })
                        });
                    }
                });
            }
        } catch (err) {
            console.log('upload files err: ', err);
        }
    }

    handler = (action) => {
        if (!action || action === 'no-action') return;
        if (action === 'next-step') return this.setState(prev => ({
            ...prev,
            step: +(prev?.step || 1) + 1
        }));

        // if (action === 'check-ssn') {
        //     if (this.props.uData?.socialSecurityNumber) this.setState({ bankId: this.props.uData?.socialSecurityNumber }, () => {
        //         this.setState(prev => ({
        //             ...prev,
        //             step: +(prev?.step || 1) + 1
        //         }));
        //     });

        //     else {
        //         this.setState(prev => ({
        //             ...prev,
        //             step: +(prev?.step || 1) + 1
        //         }));
        //     }

        //     return;
        // }

        if (action === 'submit') {
            this.setState({ loading: true, step: 4 }, () => {
                this.submitCase(null);
            })
        }

        if (action === 'sign') {
            // if (!this.state.bankId || this.state.bankIdError || this.state.bankId.length !== 12) return;
            this.setState({ loading: true, step: 4 }, async () => {
                if (this.props.hasNetId && !this.props.skipNetId && this.props.skipBankId) {
                    fetch(API_ENDPOINT + `/users/netid/sign/start/${this.props.alias}`, {
                        method: 'GET',
                        headers: {
                            'content-type': 'application/json'
                        },
                    }).then(res => res.json()).then((result) => {
                        if (result.redirectUrl) {
                            this.setState({
                                openSiths: result.redirectUrl,
                                sign: true
                            }, () => {
                                if (this.props.alias)
                                    localStorage.setItem('groupAlias', this.props.alias)
                            })
                        }
                    })
                    this.setState({
                        loading: false
                    })
                } else if (this.props.hasBankId && !this.props.skipBankId && this.props.skipNetId) {
                    const response = await fetch(API_ENDPOINT + '/users/bankid/v5/sign/e-connect', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'x-cf-upload-token': this.state.uploadToken,
                            'x-cf-upload-header': this.state.uploadHeader
                        },
                        body: JSON.stringify({
                            // ssn: this.state.bankId,
                            clinicGroupId: this.props.clinicGroupId
                        })
                    });

                    if (!response || !response.ok) return this.setState({
                        loading: false,
                        bankIdError: true,
                    });

                    const result = await response.json();
                    if (!result || !result.redirect_url) return this.setState({
                        loading: false,
                        bankIdError: true,
                    });

                    this.setState({ redirect_url: result.redirect_url, qrCodeLink: result.qrCodeLink, key: result.key, loading: false }, () => {
                        this.setState({ sign: true, submitting: false }, () => {
                            const qrCodeLink = this.state.qrCodeLink;
                            const imageElement = document.getElementById('qr-image');
                            let t = 0;
                            // console.log(imageElement);
                            this.qrCodeInterval = setInterval(() => {
                                // QR code doesn't work after repeating 14 times so that is the limit for bankid v5 for now
                                if (t === 14) {
                                    clearInterval(this.qrCodeInterval);
                                    this.handler('sign')
                                    t = 0;
                                }

                                imageElement.setAttribute('src', qrCodeLink + '&t=' + t);
                                t++;
                            }, 2000)
                        })


                    });
                } else if (this.state.pickedLoginMethod) {
                    if (this.state.pickedLoginMethod === 'bankid') {
                        const response = await fetch(API_ENDPOINT + '/users/bankid/v5/sign/e-connect', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'x-cf-upload-token': this.state.uploadToken,
                                'x-cf-upload-header': this.state.uploadHeader
                            },
                            body: JSON.stringify({
                                // ssn: this.state.bankId,
                                clinicGroupId: this.props.clinicGroupId
                            })
                        });

                        if (!response || !response.ok) return this.setState({
                            loading: false,
                            bankIdError: true,
                        });

                        const result = await response.json();
                        if (!result || !result.redirect_url) return this.setState({
                            loading: false,
                            bankIdError: true,
                        });

                        this.setState({ redirect_url: result.redirect_url, qrCodeLink: result.qrCodeLink, key: result.key, loading: false }, () => {
                            this.setState({ sign: true, submitting: false }, () => {
                                const qrCodeLink = this.state.qrCodeLink;
                                const imageElement = document.getElementById('qr-image');
                                let t = 0;
                                // console.log(imageElement);
                                this.qrCodeInterval = setInterval(() => {
                                    // QR code doesn't work after repeating 14 times so that is the limit for bankid v5 for now
                                    if (t === 14) {
                                        clearInterval(this.qrCodeInterval);
                                        this.handler('sign')
                                        t = 0;
                                    }

                                    imageElement.setAttribute('src', qrCodeLink + '&t=' + t);
                                    t++;
                                }, 2000)
                            })


                        });
                    } else if (this.state.pickedLoginMethod === 'netid') {
                        fetch(API_ENDPOINT + `/users/netid/sign/start/${this.props.alias}`, {
                            method: 'GET',
                            headers: {
                                'content-type': 'application/json'
                            },
                        }).then(res => res.json()).then((result) => {
                            if (result.redirectUrl) {
                                this.setState({
                                    openSiths: result.redirectUrl,
                                    sign: true
                                }, () => {
                                    if (this.props.alias)
                                        localStorage.setItem('groupAlias', this.props.alias)
                                })
                            }
                        })
                        this.setState({
                            loading: false
                        })
                    }
                } else {
                    this.setState({
                        sign: true,
                        loading: false
                    })
                }

            })
        }
    }

    checkSSN = (ssn) => {
        if (!ssn || !ssn.length) return;
        const check = validateSocialSecurityNumber(ssn, null, this.props.country);
        if (check?.error || !isStringAValidNumber(ssn)) return this.setState(prev => ({
            ...prev,
            ssnError: check?.error || 'Invalid SSN',
            patient: {
                type: null,
                id: null,
                ssn: '',
                name: '',
                gender: '',
                phone: '',
                email: '',
                dateOfBirth: '',
            },
        }));


        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + `/e-connect/patients/${ssn}`, {
                headers: {
                    'Content-Type': 'application/json', 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'x-cf-upload-token': this.state.uploadToken,
                    'x-cf-upload-header': this.state.uploadHeader
                }
            });
            if (!response || !response.ok) return this.setState(prev => ({
                ...prev,
                loading: false,
                patient: {
                    ssn,
                    type: 'external',
                    id: null,
                    name: '',
                    gender: '',
                    phone: '',
                    email: '',
                    dateOfBirth: '',
                }
            }));

            const result = await response.json();
            if (!result || !result.id) return this.setState(prev => ({
                ...prev,
                loading: false,
                patient: {
                    ssn,
                    type: 'external',
                    id: null,
                    name: '',
                    gender: '',
                    phone: '',
                    email: '',
                    dateOfBirth: '',
                }
            }));

            return this.setState(prev => ({
                ...prev,
                loading: false,
                patient: {
                    ssn,
                    type: 'internal',
                    id: result.id
                }
            }))
        });
    }

    render() {

        const { state } = this;
        const { hasBankId, hasNetId, country, skipBankId, skipNetId } = this.props;
        function renderButtonTextAndAction() {
            let obj = {
                title: 'Create Referral',
                tooltip: 'You can create a new case here',

                button: 'Next',
                action: 'next-step',
                disabled: false
            };

            switch (state.step) {
                case 1: { // Patient info
                    obj.title = 'Patient Information';
                    obj.tooltip = ['denmark', 'sweden'].includes(country) ? 'Add patient information here. If a patient with entered SSN does not exist in our system, you will be prompted to enter other needed information' : 'Add patient information here. Make sure that you have patient consent to share their information';

                    if (['denmark', 'sweden'].includes(country)) {
                        if (!state.patient.ssn?.trim?.() || (country === 'denmark' ? state.patient.ssn?.length < 10 : state.patient.ssn?.length < 12)) {
                            obj.action = 'no-action';
                            obj.disabled = true;
                        }
                    } else {
                        if (state.patient.type !== 'external' || !state.patient.dateOfBirth) {
                            obj.action = 'no-action';
                            obj.disabled = true;
                        }
                    }

                    if (!state.patient || !state.patientConsent || !state.patient.type || (state.patient.type === 'internal' && !state.patient.id) || (state.patient.type === 'external' && (!state.patient.name?.trim?.() || !state.patient.gender?.trim?.() || !state.patient.phone?.trim?.()))) {
                        obj.action = 'no-action';
                        obj.disabled = true;
                    };

                    break;
                };
                case 2: { // Sending clinic info (sender)
                    obj.title = 'Sending clinic';
                    obj.tooltip = 'Add information about your clinic here. Please enter either phone or email (or both). If your clinic is part of the Curoflow system and is connected to Curoflow e-Connect, please add this case in your dashboard, and not here!';

                    if (!state.sender || !state.sender.name?.trim?.() || !state.sender.city?.trim?.() || !state.sender.street?.trim?.() || !state.sender.zip?.trim?.() || !state.sender.user?.trim?.() || (!state.sender.phone?.trim?.() && !state.sender.email?.trim?.())) {
                        obj.action = 'no-action';
                        obj.disabled = true;
                    };

                    break;
                };
                case 3: { // Case details
                    obj.title = 'Case Details';
                    obj.tooltip = 'Add detailed information for receiving clinic, about the case, here. You must enter case subject, and at least one of the following: anamnesis, status or attachments.';

                    if (!state.subject?.trim?.() || (!state.anamnesis?.trim?.() && !state.status?.trim?.()) && !state.attachments?.length) {
                        obj.action = 'no-action';
                        obj.disabled = true;
                    } else {
                        // obj.action = 'check-ssn'
                        if (['sweden'].includes(country) && ((hasBankId && !skipBankId) || (hasNetId && !skipNetId))) obj.action = 'sign';
                        else {
                            obj.action = 'submit';
                            obj.button = 'Submit';
                        };
                    }

                    break;
                };

                case 4: { // signing with bank id
                    obj.title = hasBankId && !skipBankId && skipNetId ? 'Sign with BankID'
                        : hasNetId && !skipNetId && skipBankId ? 'Sign with SITHS'
                            : 'Sign with BankID or SITHS';
                    obj.tooltip = hasBankId && !skipBankId && skipNetId ? 'In order to submit this case, you must confirm your identity first, and sign the submission of this case with your BankID'
                        : hasNetId && !skipNetId && skipBankId ? 'In order to submit this case, you must confirm your identity first, and sign the submission of this case with your SITHS card'
                            : 'In order to submit this case, you must confirm your identity first, and sign the submission of this case with your BankID or SITHS card';
                    obj.button = 'Sign and Submit';

                    if (state.submitting) {
                        obj.title = 'Submitting Case';
                        obj.tooltip = 'Case submittion is in progress. Please do not close this page until the submitting process is done';
                    }

                    if (!state.bankId?.trim?.() || (country === 'denmark' ? state.bankId?.length !== 10 : state.bankId?.length !== 12)) {
                        obj.action = 'no-action';
                        obj.disabled = true;
                    } else {
                        obj.action = 'sign';
                    };

                    break;
                };
            }

            return obj;
        }

        return (<>
            <Modal
                isOpen={this.props.isOpen}
                size='md'
                centered
            >
                <ModalHeader>
                    {this.state.step === 5 && !this.state.sign && !this.state.submitting && !this.state.error ? 'Referral submitted'.translate(this.props.lang) : renderButtonTextAndAction()?.title?.translate(this.props.lang)}
                    {this.state.step === 5 && !this.state.sign && !this.state.submitting && !this.state.error ? null : <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text={renderButtonTextAndAction()?.tooltip || ''} />}
                </ModalHeader>

                <ModalBody>
                    {this.state.error ? <div style={{ padding: 10 }} key='paosdufpoasduf'>
                        <p style={{ color: 'red', textAlign: 'center', fontSize: 16 }}>{this.state.error?.translate?.(this.props.lang)}</p>
                    </div> : this.state.submitting ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        />
                    </div> : this.state.step === 1 ? <Fragment key='e-connect-modal-patient'>
                        {['denmark', 'sweden'].includes(this.props.country) ? <SSNField
                            country={this.props.country}
                            value={this.state.patient.ssn || ''}
                            label={'Social security number'.translate(this.props.lang)}
                            error={this.state.ssnError}
                            haveValidation={true}
                            onChange={value => {
                                if ((this.props.country === 'denmark' && value?.length > 10) || value?.length > 12) return;
                                if ((this.props.country === 'denmark' && value?.length === 10) || value?.length === 12) this.checkSSN(value);
                                else this.setState(prev => ({ ...prev, ssnError: null, patient: { ...prev?.patient, ssn: value, type: null, id: null } }))
                            }}
                        /> : null}
                        {this.state.patient?.type === 'external' ? <Fragment key='e-connect-modal-patinet-data'>
                            <FormGroup>
                                <Label htmlFor='patient-name' className='col-form-label-sm'>{'First and last name'.translate(this.props.lang)}:*</Label>
                                <Input type='text' id='patient-name' required value={this.state.patient.name || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, patient: { ...prev?.patient, name: value } })) }} name='patient-name' />
                            </FormGroup>
                            {!['denmark', 'sweden'].includes(this.props.country) ? <FormGroup>
                                <DateOfBirth
                                    label={'Date of birth'.translate(this.props.lang)}
                                    value={this.state.patient.dateOfBirth}
                                    dateFormat={this.props.dateFormat}
                                    // enableManualInput={true}
                                    onChange={value => {
                                        // const value = e?.target?.value ?? '';
                                        this.setState(prev => ({ ...prev, patient: { ...(prev?.patient || {}), dateOfBirth: value } }));
                                    }}
                                />
                            </FormGroup> : null}
                            <FormGroup>
                                <Label htmlFor='patient-gender' size='sm' className='form-group-label-wrap' style={{ position: 'relative' }}>
                                    {'Sex'.translate(this.props.lang)}:*
                                    <div className='info' >
                                        <InlineSVG src={infoIcon} />
                                        <div className="info-text">{"The patient's biological sex is needed for proper treatment".translate(this.props.lang)}</div>
                                    </div>
                                </Label>
                                <Input id='patient-gender' autoComplete='sex' name='patient-gender' value={this.state.patient.gender} type='select' onChange={e => {
                                    let value = e?.target?.value; this.setState(prev => ({
                                        ...prev,
                                        patient: {
                                            ...prev.patient,
                                            gender: value
                                        }
                                    }))
                                }} >
                                    <option key={'e'} value=''>{''}</option>
                                    <option key={'m'} value='male'>{'Male'.translate(this.props.lang)}</option>
                                    <option key={'f'} value='female'>{'Female'.translate(this.props.lang)}</option>
                                </Input>
                            </FormGroup>
                            <PhoneField
                                countryTwoLetter={this.props.countryTwoLetter}
                                country={this.props.country}
                                label={'Phone number'.translate(this.props.lang) + ':'}
                                value={this.state.patient.phone}
                                haveValidation={true}
                                onChange={e => {
                                    let value = e?.replace(/ /g, '')?.replace('+', '');
                                    this.setState(prev => ({
                                        ...prev,
                                        patient: {
                                            ...prev?.patient,
                                            phone: value
                                        }
                                    }))
                                }}
                            />
                            <FormGroup>
                                <Label htmlFor='patient-email' className='col-form-label-sm'>{'Email address'.translate(this.props.lang)}:</Label>
                                <Input type='email' id='patient-email' name='patient-email' value={this.state.patient.email} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, patient: { ...prev?.patient, email: value } })) }} />
                            </FormGroup>
                        </Fragment> : null}
                        <Checkbox small={true} checked={this.state.patientConsent} onChange={e => {
                            const patientConsent = e?.target?.checked;
                            this.setState({ patientConsent });
                        }
                        } label={'Patient has given consent to be referred and their information to be shared'.translate(this.props.lang)} />
                    </Fragment> : this.state.step === 2 ? <Fragment key='e-connect-modal-sender'>
                        <FormGroup>
                            <Label htmlFor='name' className='col-form-label-sm'>{'Your name and title'.translate(this.props.lang)}:*</Label>
                            <Input type='text' id='name' autoComplete='name' name='name' required value={this.state.sender.user || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, user: value } })) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='clinic-name' className='col-form-label-sm'>{'Clinic name'.translate(this.props.lang)}:*</Label>
                            <Input type='text' id='clinic-name' name='clinic-name' required value={this.state.sender.name || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, name: value } })) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='city' className='col-form-label-sm'>{'City'.translate(this.props.lang)}:*</Label>
                            <Input type='text' id='city' autoComplete='address-level2' name='city' required value={this.state.sender.city || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, city: value } })) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='zip' className='col-form-label-sm'>{'Zip'.translate(this.props.lang)}:*</Label>
                            <Input type='text' id='zip' autoComplete='postal-code' name='zip' required value={this.state.sender.zip || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, zip: value } })) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='street' className='col-form-label-sm'>{'Street'.translate(this.props.lang)}:*</Label>
                            <Input type='text' id='street' autoComplete='street-address' name='street' required value={this.state.sender.street || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, street: value } })) }} />
                        </FormGroup>
                        <PhoneField
                            countryTwoLetter={this.props.countryTwoLetter}
                            country={this.props.country}
                            label={'Phone number'.translate(this.props.lang) + ':'}
                            value={this.state.sender.phone}
                            haveValidation={true}
                            onChange={e => {
                                let value = e?.replace(/ /g, '')?.replace('+', '');
                                this.setState(prev => ({
                                    ...prev,
                                    sender: {
                                        ...prev?.sender,
                                        phone: value
                                    }
                                }))
                            }}
                        />
                        <FormGroup>
                            <Label htmlFor='email' className='col-form-label-sm'>{'Email address'.translate(this.props.lang)}:</Label>
                            <Input type='email' id='email' autoComplete='email' name='email' value={this.state.sender.email || ''} onChange={e => { let value = e?.target?.value; this.setState(prev => ({ ...prev, sender: { ...prev?.sender, email: value } })) }} />
                        </FormGroup>
                    </Fragment> : this.state.step === 3 ? <Fragment key='e-connect-modal-data'>
                        <FormGroup>
                            <Label htmlFor='subject' className='col-form-label-sm'>{'Subject'.translate(this.props.lang)}:*</Label>
                            <Input type='textarea' id='subject' name='subject' rows='2' placeholder={'What is this case in regard to?'.translate(this.props.lang)} required value={this.state.subject || ''} onChange={e => { let value = e?.target?.value; this.setState({ subject: value }) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='anamnesis' className='col-form-label-sm'>{'Anamnesis'.translate(this.props.lang)}:</Label>
                            <Input type='textarea' id='anamnesis' name='anamnesis' rows='5' required value={this.state.anamnesis || ''} onChange={e => { let value = e?.target?.value; this.setState({ anamnesis: value }) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor='status' className='col-form-label-sm'>{'Status'.translate(this.props.lang)}:</Label>
                            <Input type='textarea' id='status' name='status' rows='5' required value={this.state.status || ''} onChange={e => { let value = e?.target?.value; this.setState({ status: value }) }} />
                        </FormGroup>
                        <Row>
                            <Col>
                                <h6 className='e-connect-title ext-ec-attch-title'>
                                    {'Attachments'.translate(this.props.lang)}:
                                    {' '}
                                    <InfoWithTooltip style={{}} text={`You can upload as many files and/or images as you need. Just click on the upload button, or drag and drop your files. Allowed file types are: ${allowedFileTypeExtensions.map(s => `.${s}`).join(', ')}`} lang={this.props.lang} />
                                </h6>
                                <div className='e-connect-modal-attachments'>
                                    <div
                                        onDragEnter={e => { e.preventDefault(); e.stopPropagation(); this.setState({ _dragging: true }) }}
                                        onDragOver={e => { e.preventDefault(); e.stopPropagation(); }}
                                        onDragLeave={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.setState({ _dragging: false })
                                        }}
                                        onDrop={e => {
                                            e.preventDefault();
                                            const files = e?.dataTransfer?.files;
                                            if (files) this.uploadFiles(files);
                                        }}
                                        className={`${this.state._uploading || this.state._dragging ? 'ec-uploading-pl' : ''}`}
                                    >
                                        {this.state._uploading || this.state._dragging ? <div key='_uploading'>
                                            {this.state._uploading ? 'Uploading...'.translate(this.props.lang) : this.state._dragging ? 'Drop files here'.translate(this.props.lang) : ''}
                                        </div>
                                            :
                                            this.state.attachments?.map?.((item, i) => {
                                                if (!item?.url) return null;
                                                return <div
                                                    key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                    className='econnect-attachment-item'
                                                >
                                                    <div>
                                                        <button
                                                            type='button'
                                                            title={'Opens in new browser tab'.translate(this.props.lang)}
                                                            onClick={() => {
                                                                if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                            }}
                                                        >
                                                            &#91;
                                                            {'Open'.translate(this.props.lang)}
                                                            &#93;
                                                        </button>
                                                        <div>{item?.name}</div>
                                                    </div>
                                                    <button type='button' onClick={() => {
                                                        const url = item.url;
                                                        const attachments = this.state.attachments?.filter?.(item => item.url !== url);
                                                        this.setState({ attachments })
                                                    }}>
                                                        <InlineSVG src={trashIcon} title={'Delete'.translate(this.props.lang)} />
                                                    </button>
                                                </div>
                                            })}
                                    </div>
                                    <div className={`${this.state._uploading ? 'ec2-disabled-indom' : ''}`}>
                                        <Label className='ext-ec-upload-btn' title={'Upload'.translate(this.props.lang)}>
                                            <InlineSVG src={uploadIcon} />
                                            <Input disabled={this.state._uploading} multiple accept={`.png,.jpg,.jpeg,.webp,.pdf,.doc,.docx,.txt,.xls,.xlsx,.mp4,.ppt,.pptx,.json,.csv,${allowedFileTypes.join(',')}`} type='file' onChange={(e) => {
                                                const files = e?.target?.files;
                                                if (files) this.uploadFiles(files);
                                            }} />
                                        </Label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                        :
                        this.state.step === 4 ?
                            <Fragment key='e-connect-modal-sign'>
                                {
                                    this.state.sign && ((hasBankId && !skipBankId && skipNetId) || this.state.pickedLoginMethod === 'bankid') ?
                                        <Fragment key='e-connect-modal-sign-1'>
                                            {
                                                hasBankId && hasNetId && !skipBankId && !skipNetId ?
                                                    <button onClick={() => {
                                                        this.setState({
                                                            redirect_url: false,
                                                            qrCodeLink: false,
                                                            key: false,
                                                            openSiths: false,
                                                            pickedLoginMethod: false
                                                        })
                                                    }} className="login-back-e-connect"> <Isvg src={ArrowBack} /> {'go back'.translate(this.props.lang)}</button>
                                                    :
                                                    null
                                            }

                                            <div className='bank-id-checkout'>
                                                <div  >


                                                    <img id='qr-image' src={this.state.qrCodeLink} style={{ height: 'auto', maxWidth: 200, width: 200 }} />

                                                </div>
                                                <p>{"Scan QR code if bankid app doesn't shows up".translate(this.props.lang)}</p>
                                                <a href={this.state.redirect_url} className='btn btn-primary'>{"Open BankID on this device".translate(this.props.lang)}</a>
                                            </div>

                                            {/* <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                try {
                                    if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/sign/v5') !== -1) {
                                        this.setState({
                                            _hideIframe: true
                                        })
                                    }
                                } catch (e) { }
                            }} src={this.state.redirect_url} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: 350, border: 0 }} /> */}
                                        </Fragment>
                                        : this.state.sign && ((hasNetId && !skipNetId && skipBankId) || this.state.pickedLoginMethod === 'netid') ?
                                            <Fragment key='e-connect-modal-sign-1'>
                                                {
                                                    hasBankId && hasNetId && !skipBankId && !skipNetId ?
                                                        <button onClick={() => {
                                                            this.setState({
                                                                redirect_url: false,
                                                                qrCodeLink: false,
                                                                key: false,
                                                                openSiths: false,
                                                                pickedLoginMethod: false
                                                            })
                                                        }} className="login-back-e-connect"> <Isvg src={ArrowBack} /> {'go back'.translate(this.props.lang)}</button>
                                                        :
                                                        null
                                                }

                                                <div className='bank-id-checkout'>
                                                    <div>

                                                        <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                                                            try {
                                                                if (this.bankIdIframe && this.bankIdIframe.contentWindow && this.bankIdIframe.contentWindow.location && this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('ui/bankidse') !== -1) {
                                                                    this.setState({
                                                                        _hideIframe: true
                                                                    })
                                                                }
                                                            } catch (e) { }
                                                        }} src={this.state.openSiths} style={this.state._hideIframe ? { opacity: 0 } : { width: '100%', height: 770, border: 'none' }} />
                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            this.state.sign && hasBankId && hasNetId && !skipBankId && !skipNetId ?
                                                <div className='login-btns-e-connect'>
                                                    <button onClick={() => {
                                                        this.setState({
                                                            pickedLoginMethod: 'bankid'
                                                        }, () => {
                                                            this.handler('sign');
                                                        })
                                                    }}><img src={BankId} /> {'Sign with BankID'.translate(this.props.lang)}</button>
                                                    <button onClick={() => {
                                                        this.setState({
                                                            pickedLoginMethod: 'netid'
                                                        }, () => {
                                                            this.handler('sign');
                                                        })

                                                    }}><img src={NetId} /> {'Sign with SITHS'.translate(this.props.lang)}</button>

                                                </div>

                                                :
                                                // <Fragment key='e-connect-modal-sign-2'>
                                                //     <div className='bank-id-checkout'>
                                                //         <h3 >{'Sign with BankID'.translate(this.props.lang)}</h3>
                                                //         <div className='bank-id'>
                                                //             <img src={bankIdIcon} />
                                                //             <Input value={this.state.bankId} onChange={(e) => {
                                                //                 const value = e?.target?.value;
                                                //                  ADD COUNTRY IF THIS PART IS EVER UNCOMMENTED!
                                                //                 if (value.length > 12 || (value !== '' && !isStringAValidNumber(value))) return;
                                                //                 if (value.length === 12) {
                                                //                     const check = validateSocialSecurityNumber(value, null, this.props.country);
                                                //                     if (check?.error || !isStringAValidNumber(value)) return this.setState({
                                                //                         bankId: value,
                                                //                         bankIdError: true
                                                //                     });

                                                //                     this.setState({ bankId: value, bankIdError: null })
                                                //                 } else this.setState({ bankId: value, bankIdError: null })
                                                //             }} placeholder="ÅÅÅÅMMDDNNNN" type='text' />
                                                //         </div>

                                                this.state.bankIdError ?
                                                    <p style={{ color: 'red', fontSize: 16, textAlign: 'center' }}>{'BankID sign failed!'.translate(this.props.lang)}</p>
                                                    :
                                                    null

                                    //     </null>
                                    // </Fragment>
                                }
                            </Fragment>
                            :
                            <Fragment key='e-connect-modal-finish'>
                                <div style={{ padding: 10 }}>
                                    <p style={{ color: 'blue', textAlign: 'center', fontSize: 16 }}>
                                        {'Your case has been successfully submitted. The receiving clinic will now handle this case.'.translate?.(this.props.lang)}
                                    </p>
                                </div>
                            </Fragment>}

                    {this.state.loading && !this.state.submitting && !this.state.sign ? <div className='econnect-loader'><Player
                        autoplay={true}
                        loop={true}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                    /></div> : null}
                </ModalBody>

                <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <button style={{ display: 'none', visibility: 'hidden' }} aria-hidden></button>

                    {this.state.step === 5 && this.state.uuid && !this.state.sign && !this.state.submitting && !this.state.error && !this.state.pdfGenerated ? <Button
                        color='info'
                        disabled={this.state.loading}
                        onClick={() => {
                            this.getPdf(this.state.uuid);
                        }}
                    >
                        {'Save as PDF'.translate(this.props.lang)}
                    </Button> : null}

                    {[1, 2, 3, 4].includes(this.state.step) && !this.state.sign && !this.state.submitting && !this.state.error ? <Button
                        color='primary'
                        disabled={renderButtonTextAndAction()?.disabled}
                        onClick={() => this.handler(renderButtonTextAndAction()?.action)}
                    >
                        {renderButtonTextAndAction()?.button?.translate(this.props.lang)}
                    </Button> : null}

                    <Button
                        color='danger'
                        onClick={this.props.closeModal}
                        disabled={this.state.loading}
                    >
                        {'Close'.translate(this.props.lang)}
                    </Button>

                    <button style={{ display: 'none', visibility: 'hidden' }} aria-hidden></button>
                </ModalFooter>
            </Modal>

        </>
        )
    }
}

export default EconnectExternalCasesModal