import React, { Component } from 'react'
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Switch from '../../../components/forms/fields/switch';
import PatientDocumentInfoTextTooltip from '../../../components/patientDocumentInfoTextTooltip';


/**
 * @typedef {Object} Props
 * @property {Object} data
 * @property {(data: any, isSharedWithPatient: boolean) => any} onSync
 * @property {String} lang
 * @property {() => any} onCancel
 */

class SyncPatientDocumentsModal extends Component {
    /**
     * 
     * @param {Props} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            isSharedWithPatient: false,
        }
    }


    render() {
        if (!this.props.data || !this.props.onSync || typeof this.props.onSync !== 'function' || !this.props.onCancel || typeof this.props.onCancel !== 'function') return null;

        return (
            <Modal
                isOpen={this.props.data ? true : false}
                centered
                size='md'
                backdrop
                toggle={() => { this.props.onCancel?.() }}
            >
                <ModalBody>
                    <p style={{ marginTop: 20, textAlign: 'center', fontSize: 16, fontWeight: '600', margin: 'auto', maxWidth: '85%' }}>{'Are you sure you want to sync this document to patient profile?'.translate(this.props.lang)}</p>
                    <br />
                    <p style={{ marginTop: 10, textAlign: 'center', fontSize: 16, fontWeight: '400', margin: 'auto', maxWidth: '85%', marginBottom: 20 }}>{'All clinicians from your clinic group will be able to see this document in Patient Search'.translate(this.props.lang)}</p>
                    <FormGroup style={{ marginLeft: '10%' }}>
                        <Switch
                            value={this.state.isSharedWithPatient}
                            onChange={e => {
                                const isSharedWithPatient = e.target.checked;
                                this.setState({ isSharedWithPatient });
                            }}
                            label={<>
                                {'Share with Patient'.translate(this.props.lang)}
                                {' '}
                                <PatientDocumentInfoTextTooltip lang={this.props.lang} />
                            </>}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button hidden aria-hidden style={{ display: 'none', visibility: 'hidden' }}></button>

                    <Button
                        color='primary'
                        onClick={(e) => {
                            e.stopPropagation();
                            this.props.onSync?.(this.props.data, this.state.isSharedWithPatient);
                        }}
                        style={{ minWidth: 100 }}
                    >
                        {'Sync'.translate(this.props.lang)}
                    </Button>
                    <Button
                        color='primary'
                        onClick={(e) => {
                            e.stopPropagation();
                            this.props.onCancel?.();
                        }}
                        style={{ minWidth: 100 }}
                    >
                        {'Cancel'.translate(this.props.lang)}
                    </Button>

                    <button hidden aria-hidden style={{ display: 'none', visibility: 'hidden' }}></button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default SyncPatientDocumentsModal