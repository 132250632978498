import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';
import File from './forms/fields/multipleFiles';



import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}




/**
* Modal for creating referral
* @author   Milan Stanojevic
*/
class CreateReferralModal extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            items: [],
            nextItems: [],
            sections: [],
            totalSubtotal: [],
            audioStarted: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        let arr = []
        for (let i = 0; i <= 100; i++) {
            let obj = {}
            obj.value = i;
            obj.name = i;

            arr.push(obj)
        }

        this.setState({
            items: arr
        })
        if (this.props.sections) {
            this.setState({
                sections: this.props.sections
            }, this.checkTotalItems)
        }

        if (this.props.answers) {
            this.setState({
                answers: this.props.answers
            }, this.checkPoints)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        let currentQuestion = this.getCurrentChatQuestion()
        if (!this.state.currentQuestion && currentQuestion) {
            this.setState({ currentQuestion: currentQuestion }, () => {
                if (this.state.currentQuestion.type == 16 || this.state.currentQuestion.type == 17)
                    this.answerSubtotal()
            })
        } else if (currentQuestion && this.state.currentQuestion && currentQuestion.id != this.state.currentQuestion.id) {
            this.setState({ currentQuestion: currentQuestion }, () => {
                if (this.state.currentQuestion.type == 16 || this.state.currentQuestion.type == 17)
                    this.answerSubtotal()
            })
        }

    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        for (var key in this.searchResultsWraps) {
            if (this.searchResultsWraps[key]) {
                if (this.searchResultsWraps[key] && this.searchResultsWraps[key] && !this.searchResultsWraps[key].contains(event.target)) {
                    let stateObj = {};
                    stateObj[`showIcd10Suggestions-${key}`] = false;
                    this.setState(stateObj)
                }

            }
        }
    }


    checkTotalItems = () => {
        let totalSubtotal = this.state.totalSubtotal;
        if (this.state.sections && this.state.sections.length) {
            for (let i = 0; i < this.state.sections.length; i++) {
                if (this.state.sections[i].questions && this.state.sections[i].questions.length) {
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        if (this.state.sections[i].questions[j].type == 16 || this.state.sections[i].questions[j].type == 17) {
                            totalSubtotal.push({
                                type: this.state.sections[i].questions[j].type,
                                id: this.state.sections[i].questions[j].id,
                                conditions: this.state.sections[i].questions[j].conditions,
                                sum: 0
                            })
                        }
                    }
                }
            }
        }
        this.setState({ totalSubtotal })
    }

    getQuestionAnswers = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j].answers ? this.state.sections[i].questions[j].answers : [];
                    }
                }
            }
        }


        return [];
    }
    getQuestion = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                    if (this.state.sections[i].questions[j].blocks) {
                        for (let k = 0; k < this.state.sections[i].questions[j].blocks.length; k++) {
                            if (this.state.sections[i].questions[j].blocks[k].id == id) {
                                return this.state.sections[i].questions[j].blocks[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }
    answerSubtotal = () => {

        if (this.state.totalSubtotal && this.state.totalSubtotal.length && this.state.answers) {
            let answers = this.state.answers;
            let sumSubtotal = 0;
            let sumTotal = 0;
            let totalSubtotal = this.state.totalSubtotal;
            for (var key in answers) {
                let question = this.getQuestion(key)
                if (question) {
                    if (question.type == 16) {
                        sumSubtotal = 0;
                    }
                    if (question.type == 17) {
                        sumTotal = 0;
                    }

                    if (question.type == 1 && question.answers && question.answers.filter(el => el.id == answers[key]).length && question.answers.filter(el => el.id == answers[key])[0].score) {
                        let score = question.answers.filter(el => el.id == answers[key])[0].score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }

                    if (question.type == 2 && question.answers && answers[key] && answers[key].length) {
                        for (let i = 0; i < answers[key].length; i++) {
                            if (question.answers.filter(el => el.id == answers[key][i]).length && question.answers.filter(el => el.id == answers[key][i])[0].score) {
                                let score = question.answers.filter(el => el.id == answers[key][i])[0].score;
                                sumSubtotal += score;
                                sumTotal += score;
                            }
                        }
                    }
                    if ((question.type == 5 || question.type == 6 || question.type == 12 || question.type == 13 || question.type == 14) && question.score) {
                        let score = question.score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }
                }
            }
            if (this.state.currentQuestion && this.state.currentQuestion.type == 16) {

                if (this.state.currentQuestion.blocks && this.state.currentQuestion.blocks.length) {
                    let blocks = this.state.currentQuestion.blocks;
                    for (let i = 0; i < blocks.length; i++) {
                        let start = 0;
                        let end = 0;
                        if (blocks[i].start) {
                            start = Number(blocks[i].start)
                        }
                        if (blocks[i].end) {
                            end = Number(blocks[i].end)
                        }
                        if (blocks[i].id) {
                            if (sumSubtotal >= start && sumSubtotal <= end && answers[key] != blocks[i].id) {
                                this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                            }
                        }
                        // if (blocks[i].id && blocks[i].value && Number(blocks[i].value) && blocks[i].comparison) {
                        //     if (blocks[i].comparison == 'equal' && sumSubtotal == Number(blocks[i].value)) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     } else if (blocks[i].comparison == 'less' && sumSubtotal < Number(blocks[i].value) && blocks[i].comparison) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     } else if (blocks[i].comparison == 'more' && sumSubtotal > Number(blocks[i].value) && blocks[i].comparison) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     }
                        // }
                    }
                }

            } else if (this.state.currentQuestion && this.state.currentQuestion.type == 17) {
                if (this.state.currentQuestion.blocks && this.state.currentQuestion.blocks.length) {
                    let blocks = this.state.currentQuestion.blocks;
                    for (let i = 0; i < blocks.length; i++) {
                        let start = 0;
                        let end = 0;
                        if (blocks[i].start) {
                            start = Number(blocks[i].start)
                        }
                        if (blocks[i].end) {
                            end = Number(blocks[i].end)
                        }
                        if (blocks[i].id) {
                            if (sumTotal >= start && sumTotal <= end && answers[key] != blocks[i].id) {
                                this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                            }
                        }
                        // if (blocks[i].id && blocks[i].value && Number(blocks[i].value) && blocks[i].comparison) {
                        //     if (blocks[i].comparison == 'equal' && sumTotal == Number(blocks[i].value)) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     } else if (blocks[i].comparison == 'less' && sumTotal < Number(blocks[i].value) && blocks[i].comparison) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     } else if (blocks[i].comparison == 'more' && sumTotal > Number(blocks[i].value) && blocks[i].comparison) {
                        //         this.answerQuestion(this.state.currentQuestion.id, blocks[i].id)
                        //     }
                        // }
                    }
                }

            }


        }
    }

    checkPoints = async () => {
        if (this.state.totalSubtotal && this.state.totalSubtotal.length && this.state.answers) {
            let answers = this.state.answers;
            let sumSubtotal = 0;
            let sumTotal = 0;
            let totalSubtotal = this.state.totalSubtotal;
            for (let i = 0; i < totalSubtotal.length; i++) {
                totalSubtotal[i].sum = 0;
            }


            let questionsArr = [];
            for (let i = 0; i < this.state.sections.length; i++) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.answers[this.state.sections[i].questions[j].id]) {
                        questionsArr.push(this.state.sections[i].questions[j].id)
                    }
                }
            }

            for (let questionIdx = 0; questionIdx < questionsArr.length; questionIdx++) {
                let key = questionsArr[questionIdx];
                let question = this.getQuestion(key)
                if (question) {
                    console.log(question)
                    if (question.type == 16) {
                        let index = totalSubtotal.findIndex(el => el.id == question.id)
                        console.log(index)
                        if (index > -1) {
                            // console.log('enter ', index, sumSubtotal, answers)
                            totalSubtotal[index].sum = sumSubtotal;
                            console.log(totalSubtotal[index])
                            if (question.blocks && question.blocks.length) {
                                let blocks = question.blocks;
                                for (let i = 0; i < blocks.length; i++) {
                                    let start = 0;
                                    let end = 0;
                                    if (blocks[i].start) {
                                        start = Number(blocks[i].start)
                                    }
                                    if (blocks[i].end) {
                                        end = Number(blocks[i].end)
                                    }
                                    if (blocks[i].id) {
                                        if (sumSubtotal >= start && sumSubtotal <= end && answers[key] != blocks[i].id) {
                                            await this.answerQuestion1(question.id, blocks[i].id)
                                        }
                                    }


                                    // if (blocks[i].id && blocks[i].value && Number(blocks[i].value) && blocks[i].comparison) {
                                    //     if (blocks[i].comparison == 'equal' && sumSubtotal == Number(blocks[i].value) && answers[key] != blocks[i].id) {
                                    //         await this.answerQuestion1(question.id, blocks[i].id)
                                    //     } else if (blocks[i].comparison == 'less' && sumSubtotal < Number(blocks[i].value) && blocks[i].comparison && answers[key] != blocks[i].id) {
                                    //         await this.answerQuestion1(question.id, blocks[i].id)
                                    //     } else if (blocks[i].comparison == 'more' && sumSubtotal > Number(blocks[i].value) && blocks[i].comparison && answers[key] != blocks[i].id) {
                                    //         await this.answerQuestion1(question.id, blocks[i].id)
                                    //     }
                                    // }
                                }
                            }
                            sumSubtotal = 0;
                        }

                    }
                    if (question.type == 17) {
                        let index = totalSubtotal.findIndex(el => el.id == question.id)
                        if (index > -1) {
                            // console.log('enter 17', index, sumTotal, answers)

                            totalSubtotal[index].sum = sumTotal;
                            if (question.blocks && question.blocks.length) {
                                let blocks = question.blocks;
                                for (let i = 0; i < blocks.length; i++) {
                                    let start = 0;
                                    let end = 0;
                                    if (blocks[i].start) {
                                        start = Number(blocks[i].start)
                                    }
                                    if (blocks[i].end) {
                                        end = Number(blocks[i].end)
                                    }
                                    if (blocks[i].id) {
                                        if (sumTotal >= start && sumTotal <= end && answers[key] != blocks[i].id) {
                                            await this.answerQuestion1(question.id, blocks[i].id)
                                        }

                                        // if (blocks[i].comparison == 'equal' && sumTotal == Number(blocks[i].value) && answers[key] != blocks[i].id) {
                                        //     await this.answerQuestion1(question.id, blocks[i].id)
                                        // } else if (blocks[i].comparison == 'less' && sumTotal < Number(blocks[i].value) && blocks[i].comparison && answers[key] != blocks[i].id) {
                                        //     await this.answerQuestion1(question.id, blocks[i].id)
                                        // } else if (blocks[i].comparison == 'more' && sumTotal > Number(blocks[i].value) && blocks[i].comparison && answers[key] != blocks[i].id) {
                                        //     await this.answerQuestion1(question.id, blocks[i].id)
                                        // }
                                    }
                                }
                            }
                            sumTotal = 0;
                        }
                    }

                    if (question.type == 1 && question.answers && question.answers.filter(el => el.id == answers[key]).length && question.answers.filter(el => el.id == answers[key])[0].score) {
                        let score = question.answers.filter(el => el.id == answers[key])[0].score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }

                    if (question.type == 2 && question.answers && answers[key] && answers[key].length) {
                        for (let i = 0; i < answers[key].length; i++) {
                            if (question.answers.filter(el => el.id == answers[key][i]).length && question.answers.filter(el => el.id == answers[key][i])[0].score) {
                                let score = question.answers.filter(el => el.id == answers[key][i])[0].score;
                                sumSubtotal += score;
                                sumTotal += score;
                            }
                        }
                    }
                    if ((question.type == 5 || question.type == 6 || question.type == 12 || question.type == 13 || question.type == 14) && question.score) {
                        let score = question.score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }
                }
            }

            this.setState({ totalSubtotal })

        }
    }



    answerQuestion(questionId, answer) {
        // console.log(questionId, answer)

        if (this.props._preview) {
            return;
        }
        this.setState({ nextItems: [] }, () => {
            let question = this.getQuestion(questionId);
            if (!question) {
                return;
            }
            let answers = this.state.answers;

            if (question.type == 1) {
                if (!answers[question.id]) {
                    answers[question.id] = null;
                }

                answers[question.id] = answer;
            } else if (question.type == 2) {
                if (!answers[question.id]) {
                    answers[question.id] = [];
                }

                if (answers[question.id].indexOf(answer) !== -1) {
                    answers[question.id].splice(answers[question.id].indexOf(answer), 1);
                } else {
                    answers[question.id].push(answer);
                }

            } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 9 || question.type == 12 || question.type == 13 || question.type == 14) {
                answers[question.id] = answer;
            } if (question.type == 16 || question.type == 17) {
                if (!answers[question.id]) {
                    answers[question.id] = null;
                }
                answers[question.id] = answer;
            }
            // clear not vissible fields

            for (let i = 0; i < this.state.sections.length; i++) {
                if (this.checkSectionCondition(i)) {
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        if (!this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.sections[i].questions[j].type != 17 && this.state.sections[i].questions[j].type != 16) {
                            delete answers[this.state.sections[i].questions[j].id];
                            if (this.state.sections[i].questions[j].type == 5) {
                                this.setState({
                                    icd10Search: ''
                                })
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        if (this.state.sections[i].questions[j].type != 17 && this.state.sections[i].questions[j].type != 16)
                            delete answers[this.state.sections[i].questions[j].id];
                        if (this.state.sections[i].questions[j].type == 5) {
                            this.setState({
                                icd10Search: ''
                            })
                        }
                    }
                }
            }

            this.setState({
                answers
            }, this.checkAnswers)
        })

    }
    checkAnswers = () => {
        let answers = this.state.answers;
        let newAnswers = {};
        if (answers) {
            let stop = false;
            for (var key in answers) {
                if (!stop) {
                    let question = this.getQuestion(key)
                    if ((question.type == 1 || question.type == 3 || question.type == 9) && !answers[key]) {
                        stop = true;
                    } else if (question.type == 2 && (!answers[key] || (answers[key] && answers[key].length == 0))) {
                        stop = true;
                    } else if (question.type == 5 && (!answers[key] || (answers[key] && (answers[key].length == 0 || (answers[key].length && !answers[key].filter(el => el != '').length))))) {
                        stop = true;
                    } else if (question.type == 6 && (!answers[key] || (answers[key] && (answers[key].length == 0 || answers[key].length < 2 || (answers[key].length && !answers[key].filter(el => el != '').length))))) {
                        stop = true;
                    } else if (question.type == 6 && (!answers[key] || (answers[key] && (answers[key].length == 0 || answers[key].length < 2 || (answers[key].length && !answers[key].filter(el => el != '').length))))) {
                        stop = true;
                    } else {
                        newAnswers[key] = answers[key];
                    }

                }
            }
        }
        if (JSON.stringify(answers) != JSON.stringify(newAnswers)) {
            answers = { ...newAnswers };
            this.setState({
                answers
            }, this.checkPoints)
        } else {
            this.checkPoints()
        }

    }

    answerQuestion1(questionId, answer) {

        return new Promise((resolve, reject) => {
            if (this.props._preview) {
                return;
            }
            this.setState({ nextItems: [] }, () => {
                let question = this.getQuestion(questionId);
                if (!question) {
                    return;
                }
                let answers = this.state.answers;

                if (question.type == 1) {
                    if (!answers[question.id]) {
                        answers[question.id] = null;
                    }

                    answers[question.id] = answer;
                } else if (question.type == 2) {
                    if (!answers[question.id]) {
                        answers[question.id] = [];
                    }

                    if (answers[question.id].indexOf(answer) !== -1) {
                        answers[question.id].splice(answers[question.id].indexOf(answer), 1);
                    } else {
                        answers[question.id].push(answer);
                    }

                } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 9 || question.type == 12 || question.type == 13 || question.type == 14) {
                    answers[question.id] = answer;
                } if (question.type == 16 || question.type == 17) {
                    if (!answers[question.id]) {
                        answers[question.id] = null;
                    }
                    answers[question.id] = answer;
                }
                // clear not vissible fields

                for (let i = 0; i < this.state.sections.length; i++) {
                    if (this.checkSectionCondition(i)) {
                        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                            if (!this.checkQuestionCondition(this.state.sections[i].questions[j].id)) {
                                delete answers[this.state.sections[i].questions[j].id];
                                if (this.state.sections[i].questions[j].type == 5) {
                                    this.setState({
                                        icd10Search: ''
                                    })
                                }
                            }
                        }
                    } else {
                        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                            delete answers[this.state.sections[i].questions[j].id];
                            if (this.state.sections[i].questions[j].type == 5) {
                                this.setState({
                                    icd10Search: ''
                                })
                            }
                        }
                    }
                }

                //console.log(answers)
                this.setState({
                    answers
                }, () => {
                    resolve()
                })
            })

        })

    }


    checkQuestionAnswer = (questionId) => {
        let question = this.getQuestion(questionId);
        if (!question) {
            return false;
        }

        if (question.type == 1) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 2) {
            if (this.state.answers[question.id] && this.state.answers[question.id].length) {
                return true;
            }

        } else if (question.type == 3) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 5) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 6) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 7) {
            if (this.state.answers[question.id] && this.state.answers[question.id][0] && this.state.answers[question.id][1] && this.state.answers[question.id][2] && this.state.answers[question.id][3]) {
                return true;
            }
        } else if (question.type == 8) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 9) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 10) {
            return true;
        } else if (question.type == 15) {
            return true;

        } else if (question.type == 12) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 13) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 14) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 16 || question.type == 17) {
            if (this.state.answers[question.id]) {
                return true;
            }
        }
        return false;
    }
    addToNextItems = (questionId, qidx) => {
        let nextItems = this.state.nextItems;
        nextItems = nextItems.sort((a, b) => a.qidx - b.qidx)
        if (nextItems.filter(item => item.questionId == questionId).length == 0) {
            for (let i = 0; i < nextItems.length; i++) {
                let question = this.getQuestion(nextItems[i]);
                if (question.type == 4) {
                    return false;
                }
            }
            nextItems.push({
                questionId,
                qidx
            })
            this.setState({ nextItems })
        }

        return true;

    }
    getPreviousVisibleChatQuestion = (sectionIdx, questionIdx) => {
        if (questionIdx == -1 && sectionIdx > 0 && this.state.sections[sectionIdx - 1] && this.state.sections[sectionIdx - 1].questions && this.state.sections[sectionIdx - 1].questions.length) {
            for (let i = this.state.sections[sectionIdx - 1].questions.length - 1; i >= 0; i--) {
                if (this.state.sections[sectionIdx - 1].questions[i].type == 10) {
                    continue;
                }

                if (this.checkQuestionCondition(this.state.sections[sectionIdx - 1].questions[i].id)) {
                    return this.state.sections[sectionIdx - 1].questions[i];
                }
            }

        } else {
            for (let i = questionIdx; i >= 0; i--) {
                if (this.state.sections[sectionIdx].questions[i].type == 10) {
                    continue;
                }


                if (this.checkQuestionCondition(this.state.sections[sectionIdx].questions[i].id)) {
                    return this.state.sections[sectionIdx].questions[i];
                }
            }
        }
        if (sectionIdx > 0) {
            let questions = this.state.sections[sectionIdx - 1].questions;
            for (let i = questions.length - 1; i >= 0; i--) {
                if (this.state.answers[questions[i].id]) {
                    return questions[i];
                }
            }

        }

        return null;
    }

    checkChatQuestionCondition = (questionId, qidx) => {
        if (!this.checkQuestionCondition(questionId, qidx)) {
            return false;
        }


        let check = this.getQuestion(questionId);
        if (this.state.nextItems[check.type] == 4) {
            if (this.state.nextButtonClicked) {
                // this.setState({
                //     nextButtonClicked: false
                // })
                return true
            } else {
                return true
            }
        }
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i] && this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == questionId) {
                        if (j == 0) {


                            return true;
                        } else {
                            let previousQuestion = this.getPreviousVisibleChatQuestion(i, j - 1, qidx);
                            let prevPrevQuestion = this.getPreviousVisibleChatQuestion(i, j - 2, qidx);

                            //console.log('previousQuestion', previousQuestion)
                            if (!previousQuestion) {
                                return false;
                            }


                            if (previousQuestion.type == 4) {
                                return false;
                            } else if (previousQuestion.type == 1 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 2 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 3 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 5 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 6 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1]) {
                                return true;
                            } else if (previousQuestion.type == 7 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1] && this.state.answers[previousQuestion.id][2] && this.state.answers[previousQuestion.id][3]) {
                                return true;
                            } else if (previousQuestion.type == 8 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 9 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 10 && this.state.answers[prevPrevQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 15) {
                                return true;
                            } else if (previousQuestion.type == 12 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1] && this.state.answers[previousQuestion.id][2]) {
                                return true;
                            } else if (previousQuestion.type == 13 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0]) {
                                return true;
                            } else if (previousQuestion.type == 14 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 16 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 17 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else {
                                return false;
                            }


                        }
                    }
                }
            }
        }

        return true;
    }

    checkQuestionCondition(questionId, qidx) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {

            return this.addToNextItems(questionId, qidx);
        }

        if (!question.conditions.length) {
            return this.addToNextItems(questionId, qidx);
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }




            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return this.addToNextItems(questionId, qidx);
                }
            } else if (conditionQuestion.type == 2) {
                if (question.conditions[i].conditionAnswer == '__user-input:' && this.state.answers[conditionQuestion.id]) {
                    let index = this.indexOfIndexOf(this.state.answers[conditionQuestion.id], '__user-input:');
                    if (index != -1 && this.state.answers[conditionQuestion.id][index] && this.state.answers[conditionQuestion.id][index].replace('__user-input:', '')) {
                        return this.addToNextItems(questionId, qidx);
                    }
                } else {

                    if (this.state.answers[conditionQuestion.id] && this.state.answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                        return this.addToNextItems(questionId, qidx);
                    }
                }

            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14) {
                if (this.state.answers[conditionQuestion.id]) {
                    return this.addToNextItems(questionId, qidx);
                }

            } else if (conditionQuestion.type == 10) {
                return this.addToNextItems(questionId, qidx);

            } else if (conditionQuestion.type == 15) {
                return this.addToNextItems(questionId, qidx);
            } else if (conditionQuestion.type == 16 || conditionQuestion.type == 17) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return this.addToNextItems(questionId, qidx);
                }
            }
        }
        return false;
    }
    checkSectionCondition(sectionIdx) {
        let section = this.state.sections[sectionIdx];
        if (!section) {
            return;
        }
        if (sectionIdx > 0) {
            let prevSection = this.state.sections[sectionIdx - 1]
            let questions = prevSection.questions;
            if (questions && questions.length) {
                let notAllAnswered = false;
                for (let i = 0; i < questions.length; i++) {
                    let check = false;
                    if (questions[i] && questions[i].conditions.length == 0) {
                        check = true;
                    } else if (questions[i] && questions[i].conditions.length > 0 && this.checkQuestionCondition(questions[i].id)) {
                        let check2 = true;
                        for (let j = 0; j < questions[i].conditions.length; j++) {
                            if (this.checkQuestionAnswer(questions[i].conditions[j].conditionQuestion) /*&& this.getAnswer(questions[i].id) == questions[i].conditions[j].conditionAnswer*/ && this.checkQuestionCondition(questions[i].id)) {
                                check2 = false;
                            }
                        }
                        if (check2) {
                            check = true;
                        }
                    }

                    if (!this.checkQuestionAnswer(questions[i].id) && check) {
                        notAllAnswered = true;
                    }

                }
                if (notAllAnswered) {
                    return false;
                }
            }
        }




        if (!section.conditions) {
            return true;
        }
        if (!section.conditions.length) {
            return true;
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.answers[question.id] && this.state.answers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 12) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 14) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 10) {
                return true;
            } else if (question.type == 15) {
                return true;
            } else if (question.type == 16) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } if (question.type == 17) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            }
        }

        return false;
    }

    checkReferralAnswers = () => {
        let lastQuestionType = -1;
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 1 || this.state.sections[i].questions[j].type == 2 || this.state.sections[i].questions[j] == 3 || this.state.sections[i].questions[j] == 5 || this.state.sections[i].questions[j] == 6 || this.props.section[i].question[j] == 10 || this.state.sections[i].questions[j] == 12 || this.state.sections[i].questions[j] == 13 || this.state.sections[i].questions[j].type == 16 || this.state.sections[i].questions[j].type == 17)) {
                        lastQuestionType = this.state.sections[i].questions[j].type;
                        if (!this.state.answers[this.state.sections[i].questions[j].id] && this.state.sections[i].questions[j].type != 10) {
                            // console.log(this.getQuestion(this.state.sections[i].questions[j].id))
                            return false;
                        }
                    }

                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.sections[i].questions[j].type == 4) {
                        lastQuestionType = this.state.sections[i].questions[j].type;
                    }
                }
            }
        }

        return lastQuestionType != 4 ? true : false;
    }


    generateReferral = (id) => {

        let referralData = '';
        let html = []
        // let html = ''
        // referralData += `<p><strong>Patient svar</strong></p>\n\n`
        // console.log(this.state.answers);

        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                if (this.state.sections[i].displayOnReferral) {
                    if (i > 0) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                }
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {

                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.answers[this.state.sections[i].questions[j].id])) {

                        if (this.state.sections[i].questions[j].displayOnReferral) {
                            if (this.state.sections[i].questions[j].type == 1) {
                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text)
                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text + ' ';
                            } else if (this.state.sections[i].questions[j].type == 2) {
                                if (this.state.answers[this.state.sections[i].questions[j].id]) {

                                    let arr = [];

                                    //for (let k = 0; k < this.state.answers[this.state.sections[i].questions[j].id].length; k++) {
                                    for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < this.state.answers[this.state.sections[i].questions[j].id].length; l++) {
                                            // console.log(this.state.answers[this.state.sections[i].questions[j].id][l], this.state.sections[i].questions[j].answers[k].id)
                                            if (this.state.sections[i].questions[j].answers[k] && this.state.answers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        // console.log(check)
                                        if (check != -1) {
                                            if (this.state.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.answers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.answers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    arr.push(this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text);
                                                // }
                                            } else {
                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                            }
                                        }
                                    }

                                    if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(this.state.answers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    // console.log(arr);

                                    if (arr.length && this.state.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                        } else {
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                    }
                                }
                            } else if (this.state.sections[i].questions[j].type == 3) {
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                            } else if (this.state.sections[i].questions[j].type == 5) {
                                //referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                                let newArr = JSON.parse(JSON.stringify(this.state.answers[this.state.sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }

                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }

                            } else if (this.state.sections[i].questions[j].type == 6) {
                                if (this.state.answers[this.state.sections[i].questions[j].id])
                                    referralData += 'Patientens längd: ' + this.state.answers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.answers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length ? (this.state.answers[this.state.sections[i].questions[j].id][0] / ((this.state.answers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.answers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                            } else if (this.state.sections[i].questions[j].type == 9) {
                                if (this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length) {
                                    for (let f = 0; f < this.state.answers[this.state.sections[i].questions[j].id].length; f++) {
                                        if (this.state.answers[this.state.sections[i].questions[j].id][f] && this.state.answers[this.state.sections[i].questions[j].id][f].file)
                                            referralData += `<a href='${API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                    }
                                }
                                // referralData += `<a href='${this.state.answers[this.state.sections[i].questions[j].id] ? API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id].file : ''}' target="_blank">File/photo</a><br/>` + ' ';
                            } else if (this.state.sections[i].questions[j].type == 10) {
                                if (this.state.sections[i].questions[j].userInput) {
                                    referralData += "<br/><br/><br/><br/>"
                                } else {
                                    referralData += "<br/><br/>"
                                }

                            } else if (this.state.sections[i].questions[j].type == 12) {
                                referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '/' : ''
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][1] ? this.state.answers[this.state.sections[i].questions[j].id][1] + '<br/>' : ''
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.answers[this.state.sections[i].questions[j].id][2] + '<br/>' : ''
                            } else if (this.state.sections[i].questions[j].type == 13) {
                                referralData += 'Patientens VAS-skalvärde är:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '<br/>' : ''
                            } else if (this.state.sections[i].questions[j].type == 14) {
                                referralData += 'Aktuell andningsfrekvens/minut:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] ? this.state.answers[this.state.sections[i].questions[j].id] + '<br/>' : ''
                            }
                            // else if (this.state.sections[i].questions[j].type == 16) {
                            //     if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response)
                            //         referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response + ' ';
                            // } else if (this.state.sections[i].questions[j].type == 17) {
                            //     if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response)
                            //         referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response + ' ';
                            // }
                        }
                    }

                    if (this.state.sections[i].questions[j].type == 16 || this.state.sections[i].questions[j].type == 17) {
                        // console.log(referralData)
                        if (this.state.sections[i].questions[j].id == id) {
                            let obj = {
                                id: id,
                                response: `${referralData}`
                            }
                            html.push({ ...obj })
                        }

                        // console.log(obj)
                        if (this.state.sections[i].displayOnReferral) {
                            if (i > 0) {
                                referralData += '<br/><br/>'
                            }
                            referralData = `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                        } else {
                            referralData = ''
                        }
                        // referralData += '<p>\n';
                    }
                }

                // referralData += '</p>\n';
            }
        }


        // let html = `       
        //     ${referralData}
        // `
        if (html && html.length) {
            for (let i = 0; i < html.length; i++) {
                // console.log(html[i])
                if (html[i].id == id)
                    return html[i].response
            }
        } else {
            return '';
        }
    }

    generateReferralHTML = () => {

        let selectedClinic = this.props.getSelectedClinic();
        let selectedGroup = this.props.getSelectedGroup();

        let patient = this.state.patientData;

        let recipient = this.state.recipientData;

        let clinicData = {
            ...selectedClinic,
            logo: selectedGroup && selectedGroup.logo
        }


        let referralData = '';
        let patientSvar = 'Patient svar'.translate(this.props.lang);
        referralData += `<p><strong>${patientSvar}</strong></p>\n\n`
        // console.log(this.state.answers);

        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                if (this.state.sections[i].displayOnReferral) {
                    if (i > 0) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                }
                referralData += '<p>\n';
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {

                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.answers[this.state.sections[i].questions[j].id])) {

                        if (this.state.sections[i].questions[j].displayOnReferral) {
                            if (this.state.sections[i].questions[j].type == 1) {
                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text)
                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text + ' ';
                            } else if (this.state.sections[i].questions[j].type == 2) {
                                if (this.state.answers[this.state.sections[i].questions[j].id]) {

                                    let arr = [];

                                    for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < this.state.answers[this.state.sections[i].questions[j].id].length; l++) {
                                            if (this.state.sections[i].questions[j].answers[k] && this.state.answers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        if (check != -1) {
                                            if (this.state.sections[i].questions[j].answerPrefix) {

                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    arr.push(this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text);
                                            } else {
                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                            }
                                        }
                                    }

                                    if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(this.state.answers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    if (arr.length && this.state.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                        } else {
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                    }
                                }
                            } else if (this.state.sections[i].questions[j].type == 18) {

                            } else if (this.state.sections[i].questions[j].type == 19) {
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                            } else if (this.state.sections[i].questions[j].type == 3) {
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                            } else if (this.state.sections[i].questions[j].type == 5) {
                                let newArr = JSON.parse(JSON.stringify(this.state.answers[this.state.sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }

                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }

                            } else if (this.state.sections[i].questions[j].type == 6) {
                                if (this.state.answers[this.state.sections[i].questions[j].id])
                                    referralData += 'Patientens längd: ' + this.state.answers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.answers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length ? (this.state.answers[this.state.sections[i].questions[j].id][0] / ((this.state.answers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.answers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                            } else if (this.state.sections[i].questions[j].type == 9) {
                                if (this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length) {
                                    for (let f = 0; f < this.state.answers[this.state.sections[i].questions[j].id].length; f++) {
                                        if (this.state.answers[this.state.sections[i].questions[j].id][f] && this.state.answers[this.state.sections[i].questions[j].id][f].file)
                                            referralData += `<a href='${API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                    }
                                }
                                // referralData += `<a href='${this.state.answers[this.state.sections[i].questions[j].id] ? API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id].file : ''}' target="_blank">File/photo</a><br/>` + ' ';
                            } else if (this.state.sections[i].questions[j].type == 10) {
                                if (this.state.sections[i].questions[j].userInput) {
                                    referralData += "<br/><br/><br/><br/>"
                                } else {
                                    referralData += "<br/><br/>"
                                }
                            } else if (this.state.sections[i].questions[j].type == 12) {
                                referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '/' : ''
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][1] ? this.state.answers[this.state.sections[i].questions[j].id][1] + '<br/>' : ''
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.answers[this.state.sections[i].questions[j].id][2] + '<br/>' : ''
                            } else if (this.state.sections[i].questions[j].type == 13) {
                                referralData += 'Patientens VAS-skalvärde är:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '<br/>' : ''
                            } else if (this.state.sections[i].questions[j].type == 14) {
                                referralData += 'Aktuell andningsfrekvens/minut:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] ? this.state.answers[this.state.sections[i].questions[j].id] + '<br/>' : ''
                            } else if (this.state.sections[i].questions[j].type == 16) {
                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response)
                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response + ' ';
                            } else if (this.state.sections[i].questions[j].type == 17) {
                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response)
                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).response + ' ';
                            }
                        }
                    }


                }

                referralData += '</p>\n';
            }
        }


        let html = `
            ${referralData}

        `

        return html;
    }

    translateReferralContent = (referralContent) => {
        let lang = this.props.lang;
        let returnValue = referralContent;
        returnValue = referralContent
            .replace(/Sökdatum/g, 'Sökdatum'.translate(lang))
            .replace(/Ärende-ID/g, 'Ärende-ID'.translate(lang))
            .replace('Personuppgifter', 'Personuppgifter'.translate(lang))
            .replace('Patientens längd', 'Patientens längd'.translate(lang))
            .replace('vikt', 'vikt'.translate(lang))
            .replace('BMI', 'BMI'.translate(lang))
            .replace('Frikortsnummer', 'Frikortsnummer'.translate(lang))
            .replace('Giltig från', 'Giltig från'.translate(lang))
            .replace('Giltig till', 'Giltig till'.translate(lang))
            .replace('Utfärdad av', 'Utfärdad av'.translate(lang))
            .replace(/File\/photo/g, 'File/photo'.translate(lang))
            .replace('Patientens blodtryck uppmätt till', 'Patientens blodtryck uppmätt till'.translate(lang))
            .replace(/Patientens VAS-skalvärde är/g, 'Patientens VAS-skalvärde är'.translate(lang))
            .replace('Aktuell andningsfrekvens/minut', 'Aktuell andningsfrekvens/minut'.translate(lang))
            .replace('Vårdnadshavare', 'Vårdnadshavare'.translate(lang))
            .replace('Samtycke', 'Samtycke'.translate(lang))
            .replace('Free kort number', 'Free kort number'.translate(lang))
            .replace('Valid from', 'Valid from'.translate(lang))
            .replace('Valid to', 'Valid to'.translate(lang))
            .replace('Issued at', 'Issued at'.translate(lang))
            .replace('Paid on', 'Paid on'.translate(lang))
            .replace('Visit date', 'Visit date'.translate(lang))
            .replace('Kvitto nr', 'Kvitto nr'.translate(lang))
            .replace('Referens', 'Referens'.translate(lang))
            .replace('Betalningssätt', 'Betalningssätt'.translate(lang))
            .replace('Specifikation', 'Specifikation'.translate(lang))
            .replace('Anledning till besök', 'Anledning till besök'.translate(lang))
            .replace('Patient svar', 'Patient svar'.translate(lang))
        return returnValue;
    }



    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }
    getCurrentChatQuestion = () => {
        for (let i = 0; i < this.state.sections.length; i++) {

            if (this.state.sections[i] && this.state.sections[i].questions && this.checkSectionCondition(i)) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {

                    if (this.state.sections[i].questions[j].type == 10) {
                        continue;
                    }



                    if (this.checkChatQuestionCondition(this.state.sections[i].questions[j].id)) {
                        if (this.state.sections[i].questions[j].type == 1 && !this.state.answers[this.state.sections[i].questions[j].id]) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 2 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length)) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 3 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 5 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length)) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 6 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] && this.state.answers[this.state.sections[i].questions[j].id][1])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 7 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] && this.state.answers[this.state.sections[i].questions[j].id][1] && this.state.answers[this.state.sections[i].questions[j].id][2] && this.state.answers[this.state.sections[i].questions[j].id][3])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 8 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 9 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 12 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] && this.state.answers[this.state.sections[i].questions[j].id][1] && this.state.answers[this.state.sections[i].questions[j].id][2])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 13 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 14 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 16 && !this.state.answers[this.state.sections[i].questions[j].id]) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 17 && !this.state.answers[this.state.sections[i].questions[j].id]) {
                            return this.state.sections[i].questions[j];
                        }


                    }
                }
            }
        }

        return null;
    }


    render() {
        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];


        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.state.sections.length ?
                            <Col lg="12">
                                <div className="panel referral-section">
                                    <Row>
                                        <Col lg={!this.props.hidePreview ? "6" : "12"} className="questionary-section-scroll scroll-questions">
                                            {
                                                this.state.sections.map((section, sidx) => {
                                                    let cssStyle = {};
                                                    if (section.fontSize) {
                                                        cssStyle.fontSize = section.fontSize;
                                                    }
                                                    if (section.fontWeight) {
                                                        cssStyle.fontWeight = section.fontWeight;
                                                    }
                                                    if (section.fontStyle) {
                                                        cssStyle.fontStyle = section.fontStyle;
                                                    }

                                                    if (this.checkSectionCondition(sidx))
                                                        return (
                                                            <div className="referral-section-item">
                                                                <div className="head">
                                                                    <h4 style={cssStyle}> {section.name} </h4>


                                                                </div>



                                                                {
                                                                    section.questions.map((question, qidx) => {


                                                                        let name = question.name;
                                                                        if (question.wikiLinks && question.wikiLinks) {
                                                                            for (let i = 0; i < question.wikiLinks.length; i++) {
                                                                                name = name.replace(question.wikiLinks[i].term, `<a href="#" widx="${i}">${question.wikiLinks[i].term}</a>`)
                                                                            }
                                                                        }

                                                                        let questionCssStyle = {};

                                                                        if (question.fontSize) {
                                                                            questionCssStyle.fontSize = question.fontSize;
                                                                        }
                                                                        if (question.fontWeight) {
                                                                            questionCssStyle.fontWeight = question.fontWeight;
                                                                        }
                                                                        if (question.fontStyle) {
                                                                            questionCssStyle.fontStyle = question.fontStyle;
                                                                        }
                                                                        let questionTitleCssStyle = {};
                                                                        if (question.type == 15) {
                                                                            if (question.titleFontSize) {
                                                                                questionTitleCssStyle.fontSize = question.titleFontSize;
                                                                            }
                                                                            if (question.titleFontWeight) {
                                                                                questionTitleCssStyle.fontWeight = question.titleFontWeight;
                                                                            }
                                                                            if (question.titleFontStyle) {
                                                                                questionTitleCssStyle.fontStyle = question.titleFontStyle;
                                                                            }
                                                                        }

                                                                        if (this.checkChatQuestionCondition(question.id, qidx))
                                                                            return (

                                                                                <div>
                                                                                    <FormGroup tag="fieldset">
                                                                                        {question.wikiLinks && question.wikiLinks.length ?
                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3) ? 'required-question' : ''}>{<div onClick={(e) => {
                                                                                                if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                    this.setState({
                                                                                                        wikiModal: question.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                    })
                                                                                                }
                                                                                            }} dangerouslySetInnerHTML={{ __html: name }}></div>}</Label>

                                                                                            :
                                                                                            question.type != 16 && question.type != 17 && (question.type != 15 || (question.type == 15 && !question.hideTitle)) ?
                                                                                                <Label style={{ ...questionCssStyle, ...questionTitleCssStyle }} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3) ? 'required-question' : ''}>{question.name}</Label>
                                                                                                :
                                                                                                (question.type == 16 || question.type == 17) && (this.props.uData && this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && !this.props.hideTotalAndSubtotal ?
                                                                                                    <Label style={{ ...questionCssStyle, backgroundColor: 'rgb(30, 140, 199, 0.19)', borderRadius: 10, maxWidth: '100%', padding: '10px 15px' }} size="sm" >
                                                                                                        <h6>
                                                                                                            {question.name}&nbsp;
                                                                                                            <br />
                                                                                                            {/* {
                                                                                                                this.state.totalSubtotal && this.state.totalSubtotal.filter(el => el.id == question.id).length && this.state.totalSubtotal.filter(el => el.id == question.id)[0] ?
                                                                                                                    this.state.totalSubtotal.filter(el => el.id == question.id)[0].sum
                                                                                                                    :
                                                                                                                    null
                                                                                                            } */}
                                                                                                            {` ${this.getAnswer(this.state.answers[question.id]).response}`}
                                                                                                            <br />
                                                                                                            {
                                                                                                                this.state.totalSubtotal && question && this.state.totalSubtotal.filter(el => el.id == question.id).length && this.state.totalSubtotal.filter(el => el.id == question.id)[0] ?
                                                                                                                    question.type === 16 ?
                                                                                                                        ("Subtotal score:".translate(this.props.lang) + " " + (this.state.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.state.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0))
                                                                                                                        :
                                                                                                                        question.type === 17 ?
                                                                                                                            ("Total score:".translate(this.props.lang) + " " + (this.state.totalSubtotal.filter(el => el.id == question.id)[0].sum ? this.state.totalSubtotal.filter(el => el.id == question.id)[0].sum : 0))
                                                                                                                            :

                                                                                                                            null
                                                                                                                    :
                                                                                                                    null
                                                                                                            }

                                                                                                            <br />
                                                                                                        </h6>

                                                                                                        <div dangerouslySetInnerHTML={{ __html: this.generateReferral(question.id) }}></div>

                                                                                                    </Label>
                                                                                                    :

                                                                                                    null
                                                                                        }

                                                                                        {question.type == 1 ?
                                                                                            question.answers.map((answer) => {
                                                                                                return (
                                                                                                    <FormGroup className="referal-checkbox" check>
                                                                                                        <Label size="sm" check>
                                                                                                            <Input size="sm" disabled={this.props.disabledForm ? true : false} name={question.id} type={'radio'} checked={this.state.answers[question.id] == answer.id ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />{' '}{answer.name} {answer.wikiHint ? <button onClick={() => this.setState({ wikiModal: answer.wikiHint })} className="wiki-hint"><i className="fa fa-info-circle" /> </button> : null}
                                                                                                        </Label>
                                                                                                        {/* {
                                                                                                            answer.score ?
                                                                                                                <span style={{ fontWeight: 700 }}>{answer.score}</span> : null
                                                                                                        } */}
                                                                                                    </FormGroup>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        {question.type == 2 ?
                                                                                            <>
                                                                                                {
                                                                                                    question.answers.map((answer) => {
                                                                                                        return (
                                                                                                            <FormGroup className="referal-checkbox" check>
                                                                                                                <Label size="sm" check>
                                                                                                                    <Input disabled={this.props.disabledForm ? true : false} size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />{' '}{answer.name}
                                                                                                                </Label>
                                                                                                                {/* {
                                                                                                                    answer.score ?
                                                                                                                        <span style={{ fontWeight: 700 }}>{answer.score}</span> : null
                                                                                                                } */}
                                                                                                            </FormGroup>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                                {
                                                                                                    question.answerPrefix && question.userInput ?
                                                                                                        <FormGroup className="referal-checkbox" check>
                                                                                                            <Label size="sm" check>
                                                                                                                <Input disabled={this.props.disabledForm ? true : false} size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') !== -1 ? true : false} onChange={() => {
                                                                                                                    let answers = this.state.answers;
                                                                                                                    if (!answers[question.id]) {
                                                                                                                        answers[question.id] = [];
                                                                                                                    }

                                                                                                                    let index = this.indexOfIndexOf(answers[question.id], '__user-input:');
                                                                                                                    // console.log(index, 'index');
                                                                                                                    if (index != -1) {
                                                                                                                        answers[question.id].splice(index, 1)
                                                                                                                    } else {
                                                                                                                        answers[question.id].push('__user-input:');
                                                                                                                    }
                                                                                                                    this.setState({
                                                                                                                        answers
                                                                                                                    }, this.forceUpdate)
                                                                                                                }} />{' '}{'Ytterligare'}
                                                                                                            </Label>
                                                                                                        </FormGroup>

                                                                                                        :

                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') != -1 ?
                                                                                                        <>
                                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '') ? 'required-question' : ''}>{question.answerPrefix}</Label>

                                                                                                            <Input disabled={this.props.disabledForm ? true : false} size="sm" type="text" value={this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '')} onChange={(e) => {
                                                                                                                let index = this.indexOfIndexOf(this.state.answers[question.id], '__user-input:');
                                                                                                                let answers = this.state.answers;
                                                                                                                answers[question.id][index] = '__user-input:' + e.target.value;
                                                                                                                this.setState({
                                                                                                                    answers
                                                                                                                })
                                                                                                            }}></Input>

                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            null

                                                                                        }


                                                                                        {
                                                                                            question.type == 3 ?
                                                                                                <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id]} onChange={(e) => {
                                                                                                    this.answerQuestion(question.id, e.target.value)
                                                                                                }}></Input>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 4 ?
                                                                                                <div>
                                                                                                    <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>


                                                                                                </div>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 15 ?
                                                                                                <div

                                                                                                >
                                                                                                    <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>


                                                                                                </div>
                                                                                                :

                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            question.type == 6 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Weight [kg]'.translate(this.props.lang)}</Label>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                val[0] = e.target.value;
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Height [cm]'.translate(this.props.lang)}</Label>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                val[1] = e.target.value;
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4" style={{ paddingTop: 35 }}>
                                                                                                        <div style={{
                                                                                                            width: 24,
                                                                                                            height: 24,
                                                                                                            backgroundColor: parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                                                                                :
                                                                                                                parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                                                                                    :
                                                                                                                    parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                                                                                        :
                                                                                                                        parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                                                                                            :
                                                                                                                            parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                                                                                :
                                                                                                                                bmiIndex[5]

                                                                                                        }}></div>
                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            // question.type == 9 ?
                                                                                            //     <FormGroup tag="fieldset">


                                                                                            //         <File onChange={(img) => {
                                                                                            //             this.answerQuestion(question.id, img)

                                                                                            //         }} value={this.state.answers[question.id]} />
                                                                                            //         :


                                                                                            //     </FormGroup>

                                                                                            //     :
                                                                                            //     null

                                                                                            question.type == 9 ?
                                                                                                <FormGroup tag="fieldset">
                                                                                                    {
                                                                                                        this.props.disabledForm ?
                                                                                                            <>
                                                                                                                {

                                                                                                                    this.state.answers && this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        this.state.answers[question.id].map((file, fidx) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <a href={file.file ? API_ENDPOINT + file.file + `?mt=${this.props.uData ? this.props.uData.mediaToken : ''}` : ''} target="_blank">{'File/photo'.translate(this.props.lang)}</a><br />
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        :
                                                                                                                        null
                                                                                                                }


                                                                                                            </>
                                                                                                            :

                                                                                                            <File lang={this.props.lang} testForms={true} disabled={this.props.disabledForm ? true : false} group={this.props.selectedGroup ? this.props.selectedGroup : null} onChange={(img) => {
                                                                                                                this.answerQuestion(question.id, img)

                                                                                                            }} value={this.state.answers[question.id]} />
                                                                                                    }

                                                                                                </FormGroup>

                                                                                                :
                                                                                                null

                                                                                        }
                                                                                        {
                                                                                            question.type == 14 ?
                                                                                                <Row>

                                                                                                    <Col>
                                                                                                        <FormGroup>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} type='select' value={this.state.answers[question.id] ? this.state.answers[question.id] : ''} min={0} max={100} onChange={(e) => {

                                                                                                                let val = e.target.value

                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}>
                                                                                                                {this.state.items.map(item => {
                                                                                                                    return (
                                                                                                                        <option value={item.value}>
                                                                                                                            {item.name}
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Input>
                                                                                                        </FormGroup>

                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 12 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Systolic'.translate(this.props.lang)}</Label>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[0] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[0] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[0] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Diastolic'.translate(this.props.lang)}</Label>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[1] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[1] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[1] = 0;
                                                                                                                }

                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Pulse'.translate(this.props.lang)}</Label>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][2] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[2] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[2] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[2] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 13 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Input disabled={this.props.disabledForm ? true : false} style={questionCssStyle} size="sm" type="number" min='0' max='10' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : [''];
                                                                                                                if (Number(e.target.value) > 10) {
                                                                                                                    val[0] = 10;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 10) {
                                                                                                                    val[0] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[0] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>



                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            question.type == 5 ?
                                                                                                <>
                                                                                                    {
                                                                                                        // console.log(this.state.answers[question.id])
                                                                                                    }
                                                                                                    {

                                                                                                        (this.state.answers[question.id] || [""]).map((diagnose, didx) => {
                                                                                                            return (
                                                                                                                <FormGroup>
                                                                                                                    <Input disabled={this.props.disabledForm ? true : false} onKeyUp={(e) => {
                                                                                                                        if (e.keyCode == 13) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: null,
                                                                                                                            })

                                                                                                                            if (this.state.suggestionIndex != null) {
                                                                                                                                let suggestion = JSON.parse(JSON.stringify(this.state[`icd10Suggestions-${question.id}-${didx}`][this.state.suggestionIndex]));

                                                                                                                                let arr = this.state.answers;
                                                                                                                                if (!arr[question.id]) {
                                                                                                                                    arr[question.id] = [];
                                                                                                                                }

                                                                                                                                arr[question.id][didx] = suggestion.code + ' - ' + suggestion.name;


                                                                                                                                this.answerQuestion(question.id, arr[question.id])

                                                                                                                                //this.answerQuestion(question.id, suggestion.code + ' - ' + suggestion.name);
                                                                                                                                let stateObj = {};
                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                this.setState(stateObj);
                                                                                                                                return;
                                                                                                                            }

                                                                                                                        }
                                                                                                                        if (e.keyCode == 38) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                                                                            })
                                                                                                                        }
                                                                                                                        if (e.keyCode == 40) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: this.state.suggestionIndex + 1 >= this.state[`icd10Suggestions-${question.id}-${didx}`].length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                                                                            })

                                                                                                                        }
                                                                                                                    }} onFocus={() => {
                                                                                                                        let stateObj = { suggestionIndex: null };
                                                                                                                        stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                        this.setState(stateObj);
                                                                                                                    }} style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] && this.state.answers[question.id][didx] ? this.state.answers[question.id][didx] : ''} onChange={(e) => {
                                                                                                                        //this.answerQuestion(question.id, e.target.value)
                                                                                                                        let arr = this.state.answers;
                                                                                                                        if (!arr[question.id]) {
                                                                                                                            arr[question.id] = [];
                                                                                                                        }

                                                                                                                        arr[question.id][didx] = e.target.value;


                                                                                                                        this.answerQuestion(question.id, arr[question.id])
                                                                                                                        fetch(API_ENDPOINT + '/data/icd10/suggest', {
                                                                                                                            method: 'POST',
                                                                                                                            headers: {
                                                                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                                                'content-type': 'application/json'
                                                                                                                            },
                                                                                                                            body: JSON.stringify({ search: e.target.value })
                                                                                                                        }).then(parseJSON).then(({ result, status }) => {
                                                                                                                            if (status >= 200 && status < 300) {
                                                                                                                                let stateObj = {};
                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = result;
                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                                this.setState(stateObj);
                                                                                                                            }
                                                                                                                        })


                                                                                                                    }}></Input>
                                                                                                                    {this.state[`showIcd10Suggestions-${question.id}-${didx}`] ?
                                                                                                                        <div className="search-results" ref={(node) => { this.searchResultsWraps[question.id + '-' + didx] = node; }}>
                                                                                                                            <ul >
                                                                                                                                {
                                                                                                                                    this.state[`icd10Suggestions-${question.id}-${didx}`] && this.state[`icd10Suggestions-${question.id}-${didx}`].map((icd10Item, idx) => {
                                                                                                                                        return (
                                                                                                                                            <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {

                                                                                                                                                let arr = this.state.answers;
                                                                                                                                                if (!arr[question.id]) {
                                                                                                                                                    arr[question.id] = [];
                                                                                                                                                }

                                                                                                                                                arr[question.id][didx] = icd10Item.code + ' - ' + icd10Item.name;


                                                                                                                                                this.answerQuestion(question.id, arr[question.id])


                                                                                                                                                let stateObj = {};
                                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                                this.setState(stateObj);
                                                                                                                                            }}>{icd10Item.code} - {icd10Item.name}</li>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }

                                                                                                                </FormGroup>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                    <button className="add-diagnose-button" onClick={() => {
                                                                                                        let answers = this.state.answers;
                                                                                                        if (!answers[question.id]) {
                                                                                                            answers[question.id] = [""];
                                                                                                        }

                                                                                                        answers[question.id].push("");
                                                                                                        this.setState({
                                                                                                            answers
                                                                                                        })

                                                                                                    }}>{'Add diagnos'.translate(this.props.lang)}</button>

                                                                                                </>
                                                                                                :

                                                                                                null
                                                                                        }



                                                                                    </FormGroup>

                                                                                </div>
                                                                            )
                                                                    }
                                                                    )

                                                                }


                                                            </div>
                                                        )
                                                })
                                            }





                                        </Col>
                                        {
                                            !this.props.hidePreview ?
                                                <Col lg="6">
                                                    <HTML value={this.translateReferralContent(this.generateReferralHTML())} disabled={true} onChange={() => { }}></HTML>

                                                </Col>
                                                :
                                                null
                                        }

                                    </Row>
                                </div>


                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default CreateReferralModal;