import InlineSVG from 'react-inlinesvg';
import infoIcon from '../assets/svg/info2.svg';

const PatientDocumentInfoTextTooltip = ({
    lang,
    className = ''
}) => {
    return (
        <div className={`patient-documents-info-text-tooltip-container ${className}`}>
            <InlineSVG src={infoIcon} />
            <span>{'If enabled, the patient will be able to access the document when signed in. Notifications to the patient can be managed by the clinic-admin.'.translate(lang)}</span>
        </div>
    )
}

export default PatientDocumentInfoTextTooltip