import React, { Component } from 'react';
import Page from '../../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import userChat from '../../assets/svg/chat.svg';
import addIcon from '../../assets/svg/add-icon.svg';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import HTML from '../../components/forms/fields/html';
import Checkbox from '../../components/forms/fields/checkbox';
import File from '../../components/forms/fields/multipleFiles';
import moment from 'moment';

import SidebarRight from '../../components/sidebarRight'
import ReferralClinics from '../../components/referralClinics';
import Search from '../../components/search';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import profileUser from '../../assets/images/user.png';
import xIcon from '../../assets/svg/x.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Form builder page
* @author   Milan Stanojevic
*/
class CreateReferral extends Component {
    constructor(props) {
        super(props);

        this.searchResultsWraps = {};


        this.state = {
            ...props.initialData,
            articles: [],
            formTags: [],
            tags: [],
            sections: [],
            answers: {},
            patientData: {},
            recipientData: {},
            referralSections: { header: '', footer: '', body: '' },
            selectClinicModal: false,
            version: 0,
            checkAudio: false,
            items: []
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    /**
    * Generate referral number
    * @author   Milan Stanojevic
    */
    // referralCurrentNumber = () => {
    //     fetch(API_ENDPOINT + '/referrals/current', {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //             'content-type': 'application/json'
    //         },
    //     }).then(parseJSON).then(({ result, status }) => {
    //         if (status >= 200 && status < 300) {
    //             let patientData = this.state.patientData;
    //             patientData.referralNumber = result.number;

    //             this.setState({ patientData });
    //         }
    //     });
    // }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        document.addEventListener('mousedown', this.handleClickOutside);

        this.get();
        //this.getQuestionary(
        let params = getSearchParams(this.props[0].location.search);

        // if (!params.referral) {
        //     this.referralCurrentNumber();
        //     this.intervalCheck = setInterval(this.referralCurrentNumber, 2000);
        // }

        let arr = []
        for (let i = 0; i <= 100; i++) {
            let obj = {}
            obj.value = i;
            obj.name = i;

            arr.push(obj)
        }

        this.setState({
            items: arr
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        // if (this.intervalCheck) {
        //     clearInterval(this.intervalCheck);
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.articles.length != this.state.articles.length && this.state.articles.length && !this.state.article) {
            let params = getSearchParams(this.props[0].location.search);
            if (params.article && params.article != 'None') {
                for (let i = 0; i < this.state.articles.length; i++) {
                    if (this.state.articles[i]._id == params.article) {
                        this.setState({
                            article: this.state.articles[i]._id,
                            articleName: this.state.articles[i].title,
                            haveQuestionary: this.state.articles[i].havePreparedQuestionary,

                        })
                    }
                }
            }
        }

        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevState.article != this.state.article) {
            this.getQuestionary();
        }

    }

    handleClickOutside = (event) => {
        for (var key in this.searchResultsWraps) {
            if (this.searchResultsWraps[key]) {
                if (this.searchResultsWraps[key] && this.searchResultsWraps[key] && !this.searchResultsWraps[key].contains(event.target)) {
                    let stateObj = {};
                    stateObj[`showIcd10Suggestions-${key}`] = false;
                    this.setState(stateObj)
                }

            }
        }

    }



    getQuestion = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }

    /**
    * Create new referral
    * @author   Milan Stanojevic
    */
    sendReferral = () => {

        let selectedClinic = this.props.getSelectedClinic();
        let selectedGroup = this.props.getSelectedGroup();


        let clinicData = {
            _id: selectedClinic._id,
            subgroup: selectedClinic.subgroup,
            subgroupName: selectedClinic.subgroupName,
            name: selectedClinic.name,
            street: selectedClinic.street,
            zip: selectedClinic.zip,
            city: selectedClinic.city,
            postAddress: selectedClinic.postAddress,
            logo: selectedGroup.logo
        }


        this.setState({
            _sendingReferral: true,
        }, () => {
            let params = getSearchParams(this.props[0].location.search);
            let patient = this.state.patientData;
            if (patient.phone) {
                patient.phone = patient.phone.replace(/\+/g, '00').replace(/[^\d]/g, '');
                if (patient.phone.indexOf('00') == -1) {
                    patient.phone = '00' + patient.phone;
                }
            }
            fetch(API_ENDPOINT + (params.referral ? `/referrals/${params.referral}` : '/referrals/new'), {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    answers: this.state.answers,
                    questionarySections: this.state.sections,
                    article: this.state.article,
                    patient: patient,
                    recipientClinic: this.state.recipientData._id,
                    senderClinic: selectedClinic._id,
                    senderClinicData: clinicData,
                    referralType: this.state.referralType,
                    consent: this.state.consent,
                    referralSections: this.state.referralSections,
                    version: this.state.version,
                    referralContent: this.state.referralContent ? this.state.referralContent : this.generateReferral(),
                    recipientClinicData: {
                        name: this.state.recipientData.name,
                        address1: this.state.recipientData.address1,
                        address2: this.state.recipientData.address2,
                        address3: this.state.recipientData.address3,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    //alert('sent');
                    this.setState({
                        _sendingReferral: null,
                        signinModal: false
                    })
                    this.props[0].history.push('/my-data/referrals/sent');
                }
            })

        })

    }

    /**
    * Function return questionary  
    * @author   Milan Stanojevic
    * @Objectparam    {String} article    selected article
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/questionary/' + this.state.article, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.setState(result);
            }
        });


        let params = getSearchParams(this.props[0].location.search);
        if (params.referral) {
            fetch(API_ENDPOINT + '/referrals/' + params.referral, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    result.recipientData = result.recipientClinicData;
                    result.patientData = result.patient;
                    result.version++;
                    this.setState(result);
                }
            });

        }


    }

    /**
    * Function return address of user  
    * @author   Milan Stanojevic
    * @Objectparam    {String} personalNumber    user personalNumber
    */
    getAddress = (personalNumber) => {
        fetch(API_ENDPOINT + '/personal-number/address', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ personalNumber: personalNumber })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300 && result) {
                let patientData = this.state.patientData;
                if (result.street) {
                    patientData.street = result.street;
                } else {
                    patientData.street = '';
                }
                if (result.zip) {
                    patientData.zip = result.zip;
                } else {
                    patientData.zip = '';
                }
                if (result.city) {
                    patientData.city = result.city;
                } else {
                    patientData.city = '';
                }

                if (result.name) {
                    patientData.name = result.name;
                } else {
                    patientData.name = '';
                }

                this.setState({
                    patientData
                })
            }
        })
    }



    answerQuestion(questionId, answer) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        let answers = this.state.answers;

        if (question.type == 1) {
            if (!answers[question.id]) {
                answers[question.id] = null;
            }

            answers[question.id] = answer;
        } else if (question.type == 2) {
            if (!answers[question.id]) {
                answers[question.id] = [];
            }

            if (answers[question.id].indexOf(answer) !== -1) {
                answers[question.id].splice(answers[question.id].indexOf(answer), 1);
            } else {
                answers[question.id].push(answer);
            }

        } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 9 || question.type == 12 || question.type == 13 || question.type == 14) {
            answers[question.id] = answer;
        }

        // clear not vissible fields

        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (!this.checkQuestionCondition(this.state.sections[i].questions[j].id)) {
                        delete answers[this.state.sections[i].questions[j].id];
                        /*if (this.state.sections[i].questions[j].type == 5){
                            this.setState({
                                icd10Search: ''
                            })
                        }*/
                    }
                }
            } else {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    delete answers[this.state.sections[i].questions[j].id];
                    /*if (this.state.sections[i].questions[j].type == 5){
                            this.setState({
                                icd10Search: ''
                            })
                        }*/
                }
            }
        }

        //console.log(answers)
        this.setState({
            answers
        }, () => {
            if (this.chatWrapper) {
                console.log(this.chatWrapper.scrollHeight)

                let lastElement = this.chatWrapper.children[this.chatWrapper.children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children.length - 1].children.length - 1];
                if (lastElement && lastElement.offsetHeight)
                    this.chatWrapper.scrollTop = this.chatWrapper.scrollHeight - lastElement.offsetHeight;
            }
        })
    }

    scrollToQuestion = () => {
        if (!this.chatWrapper) {
            return;
        }
        let lastElement = this.chatWrapper.children[this.chatWrapper.children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children[this.chatWrapper.children[this.chatWrapper.children.length - 1].children.length - 1].children.length - 1];
        if (lastElement && lastElement.offsetHeight)
            this.chatWrapper.scrollTop = this.chatWrapper.scrollHeight - lastElement.offsetHeight;

    }



    checkQuestionCondition(questionId) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }

            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (conditionQuestion.type == 2) {

                if (question.conditions[i].conditionAnswer == '__user-input:' && this.state.answers[conditionQuestion.id]) {
                    let index = this.indexOfIndexOf(this.state.answers[conditionQuestion.id], '__user-input:');
                    if (index != -1 && this.state.answers[conditionQuestion.id][index] && this.state.answers[conditionQuestion.id][index].replace('__user-input:', '')) {
                        return true;
                    }
                } else {

                    if (this.state.answers[conditionQuestion.id] && this.state.answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                        return true;
                    }
                }

            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14) {
                if (this.state.answers[conditionQuestion.id]) {
                    return true;
                }

            } else if (conditionQuestion.type == 10) {
                return true;
            } else if (conditionQuestion.type == 15) {
                return true;
            }
        }
        return false;
    }


    getPreviousVisibleChatQuestion = (sectionIdx, questionIdx) => {
        for (let i = questionIdx; i >= 0; i--) {
            if (this.checkQuestionCondition(this.state.sections[sectionIdx].questions[i].id)) {
                return this.state.sections[sectionIdx].questions[i];
            }
        }

        return null;
    }

    checkChatQuestionCondition = (questionId) => {
        if (!this.checkQuestionCondition(questionId)) {
            return false;
        }

        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i] && this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == questionId) {
                        if (j == 0) {
                            return true;
                        } else {
                            let previousQuestion = this.getPreviousVisibleChatQuestion(i, j - 1);
                            if (!previousQuestion) {
                                return false;
                            }


                            if (previousQuestion.type == 4) {
                                return true;
                            } else if (previousQuestion.type == 1 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 2 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 3 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 5 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 6 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 9 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 12 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 13 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 14 && this.state.answers[previousQuestion.id]) {
                                return true;
                            }
                            else if (previousQuestion.type == 10) {
                                return true;
                            } else if (previousQuestion.type == 15) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    }


    getCurrentChatQuestion = () => {
        for (let i = 0; i < this.state.sections.length; i++) {

            if (this.state.sections[i] && this.state.sections[i].questions && this.checkSectionCondition(i)) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].type == 4 && this.state.sections[i].questions[j].userInput && !this.state.checkAudio) {
                        this.setState({
                            checkAudio: true
                        })
                        this.props.ring112Audio(true)
                    }
                    if (this.state.sections[i].questions[j].type == 4 || this.state.sections[i].questions[j].type == 10) {
                        continue;
                    }

                    if (this.checkChatQuestionCondition(this.state.sections[i].questions[j].id)) {
                        if (this.state.sections[i].questions[j].type == 1 && !this.state.answers[this.state.sections[i].questions[j].id]) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 2 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length)) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 3 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 5 && !(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length)) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 6 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 9 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 12 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 13 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        } else if (this.state.sections[i].questions[j].type == 14 && !(this.state.answers[this.state.sections[i].questions[j].id])) {
                            return this.state.sections[i].questions[j];
                        }
                    }
                }
            }
        }

        return null;
    }

    checkQuestionAnswer = (questionId) => {
        let question = this.getQuestion(questionId);
        if (!question) {
            return false;
        }

        if (question.type == 1) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 2) {
            if (this.state.answers[question.id] && this.state.answers[question.id].length) {
                return true;
            }

        } else if (question.type == 3) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 5) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 6) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 7) {
            if (this.state.answers[question.id] && this.state.answers[question.id][0] && this.state.answers[question.id][1] && this.state.answers[question.id][2] && this.state.answers[question.id][3]) {
                return true;
            }
        } else if (question.type == 8) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 9) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 10) {
            return true;
        } else if (question.type == 15) {
            return true;

        } else if (question.type == 12) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 13) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 14) {
            if (this.state.answers[question.id]) {
                return true;
            }

        }
        return false;
    }

    checkSectionCondition(sectionIdx) {
        let section = this.state.sections[sectionIdx];
        if (!section) {
            return;
        }
        if (sectionIdx > 0) {
            let prevSection = this.state.sections[sectionIdx - 1]
            let questions = prevSection.questions;
            if (questions && questions.length) {
                let notAllAnswered = false;
                for (let i = 0; i < questions.length; i++) {
                    let check = false;
                    if (questions[i] && questions[i].conditions.length == 0) {
                        check = true;
                    } else if (questions[i] && questions[i].conditions.length > 0 && this.checkQuestionCondition(questions[i].id)) {
                        let check2 = true;
                        for (let j = 0; j < questions[i].conditions.length; j++) {
                            if (this.checkQuestionAnswer(questions[i].conditions[j].conditionQuestion) /*&& this.getAnswer(questions[i].id) == questions[i].conditions[j].conditionAnswer*/ && this.checkQuestionCondition(questions[i].id)) {
                                check2 = false;
                            }
                        }
                        if (check2) {
                            check = true;
                        }
                    }


                    if (!this.checkQuestionAnswer(questions[i].id) && check) {
                        notAllAnswered = true;
                    }

                }
                if (notAllAnswered) {
                    return false;
                }
            }
        }
        if (!section.conditions) {
            return true;
        }
        if (!section.conditions.length) {
            return true;
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.answers[question.id] && this.state.answers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 10) {
                return true;
            } else if (question.type == 15) {
                return true;
            } else if (question.type == 12) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 14) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            }
        }

        return false;
    }

    checkReferralAnswers = () => {
        let lastQuestionType = -1;
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 1 || this.state.sections[i].questions[j].type == 2 || this.state.sections[i].questions[j].type == 3 || this.state.sections[i].questions[j].type == 5 || this.state.sections[i].questions[j].type == 6 || this.state.sections[i].questions[j].type == 9 || this.state.sections[i].questions[j].type == 10 || this.state.sections[i].questions[j].type == 12 || this.state.sections[i].questions[j].type == 13 || this.state.sections[i].questions[j].type == 14)) {
                        lastQuestionType = this.state.sections[i].questions[j].type;
                        if (!this.state.answers[this.state.sections[i].questions[j].id] && this.state.sections[i].questions[j].type != 10) {
                            console.log(this.getQuestion(this.state.sections[i].questions[j].id))
                            return false;
                        }
                    }

                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.sections[i].questions[j].type == 4) {
                        lastQuestionType = this.state.sections[i].questions[j].type;
                    }
                }
            }
        }

        return lastQuestionType != 4 ? true : false;
    }


    generateReferral = () => {

        let selectedClinic = this.props.getSelectedClinic();
        let selectedGroup = this.props.getSelectedGroup();

        let patient = this.state.patientData;

        let recipient = this.state.recipientData;

        let clinicData = {
            ...selectedClinic,
            logo: selectedGroup.logo
        }


        let referralData = '';
        referralData += `<p><strong>Patient svar</strong></p>\n\n`
        // console.log(this.state.answers);

        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                if (this.state.sections[i].displayOnReferral) {
                    if (i > 0) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                }
                referralData += '<p>\n';
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.answers[this.state.sections[i].questions[j].id])) {
                        if (this.state.sections[i].questions[j].displayOnReferral) {
                            if (this.state.sections[i].questions[j].type == 1) {
                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text)
                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id]).text + ' ';
                            } else if (this.state.sections[i].questions[j].type == 2) {
                                if (this.state.answers[this.state.sections[i].questions[j].id]) {

                                    let arr = [];

                                    //for (let k = 0; k < this.state.answers[this.state.sections[i].questions[j].id].length; k++) {
                                    for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < this.state.answers[this.state.sections[i].questions[j].id].length; l++) {
                                            console.log(this.state.answers[this.state.sections[i].questions[j].id][l], this.state.sections[i].questions[j].answers[k].id)
                                            if (this.state.sections[i].questions[j].answers[k] && this.state.answers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        console.log(check)
                                        if (check != -1) {
                                            if (this.state.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.answers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.answers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    arr.push(this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text);
                                                // }
                                            } else {
                                                if (this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text)
                                                    referralData += this.getAnswer(this.state.answers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                            }
                                        }
                                    }

                                    if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(this.state.answers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.answers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    console.log(arr);

                                    if (arr.length && this.state.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                        } else {
                                            referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                    }
                                }
                            } else if (this.state.sections[i].questions[j].type == 3) {
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                            } else if (this.state.sections[i].questions[j].type == 5) {
                                //referralData += this.state.answers[this.state.sections[i].questions[j].id] + '. ';
                                let newArr = JSON.parse(JSON.stringify(this.state.answers[this.state.sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }
                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }

                            } else if (this.state.sections[i].questions[j].type == 6) {
                                if (this.state.answers[this.state.sections[i].questions[j].id])
                                    referralData += 'Patientens längd: ' + this.state.answers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.answers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length ? (this.state.answers[this.state.sections[i].questions[j].id][0] / ((this.state.answers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.answers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                            } else if (this.state.sections[i].questions[j].type == 10) {
                                if (this.state.sections[i].questions[j].userInput) {
                                    referralData += "<br/><br/>"
                                } else {
                                    referralData += "<br/>"
                                }

                            } else if (this.state.sections[i].questions[j].type == 9) {
                                if (this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id].length) {
                                    for (let f = 0; f < this.state.answers[this.state.sections[i].questions[j].id].length; f++) {
                                        if (this.state.answers[this.state.sections[i].questions[j].id][f] && this.state.answers[this.state.sections[i].questions[j].id][f].file)
                                            referralData += `<a href='${API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                    }
                                }
                                // referralData += `<a href='${API_ENDPOINT + this.state.answers[this.state.sections[i].questions[j].id].file}' target="_blank">File/photo</a><br/>` + ' ';
                            } else if (this.state.sections[i].questions[j].type == 12) {
                                referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '/' : '';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][1] ? this.state.answers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.answers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
                            } else if (this.state.sections[i].questions[j].type == 13) {
                                referralData += 'Patientens VAS-skalvärde är:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] && this.state.answers[this.state.sections[i].questions[j].id][0] ? this.state.answers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
                            } else if (this.state.sections[i].questions[j].type == 14) {
                                referralData += 'Aktuell andningsfrekvens/minut:';
                                referralData += this.state.answers[this.state.sections[i].questions[j].id] ? this.state.answers[this.state.sections[i].questions[j].id] + '<br/>' : '';
                            }
                        }
                    }
                }

                referralData += '</p>\n';
            }
        }


        let html = `
        <div style="display: flex; justify-content: space-between; align-items: center;">
        ${clinicData.logo ? `<p><img style="float: left; max-height: 80px; object-fit: contain; max-width: 220px;" src="${API_ENDPOINT + clinicData.logo}" /></p>` : ''}
<p style="text-align: right; float: right;"><strong>Sökdatum:</strong> <span style="white-space: nowrap;">${moment().format(this.props.dateFormat)}</span></p>
</div>
<div style="clear: both; margin-top: 10px;">
    <div>${clinicData.name ? clinicData.name : ''}</div>
    <div>${clinicData.street ? clinicData.street : ''}, ${clinicData.zip ? clinicData.zip : ''} ${clinicData.city ? clinicData.city : ''}</div>
    <div>${clinicData.phone ? clinicData.phone : ''} ${clinicData.email ? clinicData.email : ''}</div>
    <div style="margin-top: 10px;">${this.props.uData.name ? this.props.uData.name : ''}</div>
</div>
<hr />
<div style="margin: 15px 0px; padding-left: 55%;">
    <p>${recipient.name ? recipient.name : ''} ${recipient.subgroupName ? `(${recipient.subgroupName})` : ''}<br />${recipient.address1 ? recipient.address1 : ''}<br />${recipient.address2 ? recipient.address2 : ''}<br />${recipient.address3 ? recipient.address3 : ''}
    </p>
</div>
<div>
    <p><strong>Ärende ID:</strong> ${patient.referralNumber ? patient.referralNumber + (this.state.version != 0 ? '.' + this.state.version : '') : ''}</p>
</div>
<div>
    <p><strong>Personuppgifter</strong></p>
</div>
<div>
    <p>${patient.socialSecurityNumber ? patient.socialSecurityNumber : ''}<br />${patient.name ? patient.name : ''}<br />${patient.street ? patient.street : ''}<br />${patient.zip ? patient.zip : ''}
        ${patient.city ? patient.city : ''}<br />${patient.phone ? patient.phone : ''}<br />${patient.email ? patient.email : ''}</p>
</div>
${this.state.referralSections && this.state.referralSections.header ? this.state.referralSections.header : ''}
<hr />
${referralData}
${this.state.referralSections && this.state.referralSections.body ? this.state.referralSections.body + '<br/>' : ''}

<p><strong>Samtycke</strong></p>
${this.state.consent == 1 ? '<p>Patienten har l&auml;mnat samtycke till att ni som remissmottagare f&aring;r ta del av tidigare journalhandlingar/anteckningar.</p>' : '<p>Patienten har inte l&auml;mnat samtycke till att ni som remissmottagare f&aring;r ta del av tidigare journalhandlingar/anteckningar.</p>'}

<hr />
<p><em>Mycket tacksam f&ouml;r er m&ouml;jlighet att kalla patienten f&ouml;r vidare bed&ouml;mning och
        st&auml;llningstagande om &aring;tg&auml;rd. Mvh</em></p>


        ${this.state.referralSections && this.state.referralSections.footer ? '<hr/>' + this.state.referralSections.footer : ''}


        `

        return html;

    }

    changePatientData = (name, value) => {
        let patientData = this.state.patientData;
        patientData[name] = value;
        this.setState({
            patientData
        })
    }


    changeRecipientData = (name, value) => {
        let recipientData = this.state.recipientData;
        recipientData[name] = value;
        this.setState({
            recipientData
        })
    }

    changeReferralSections = (name, value) => {

        let referralSections = this.state.referralSections;
        referralSections[name] = value;
        this.setState({
            referralSections
        })

    }


    suggest = () => {
        let suggestions = [];
        if (this.state.search) {
            for (let i = 0; i < this.state.articles.length; i++) {
                if (this.state.articles[i].title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    if (suggestions.length < 10)
                        suggestions.push(this.state.articles[i]);
                }
            }
        }


        this.setState({
            suggestions
        })
    }

    splitAddress = (address) => {
        //let address = 'Hudmottagning, Skaraborgs Sjukhus Falköping, Danska vägen 62, 521 85, Falköping';

        let addressLines = ['', '', ''];

        let numbersCount = 0;
        for (let i = address.length - 1; i >= 0; i--) {
            if (address[i] == '0' || address[i] == '1' || address[i] == '2' || address[i] == '3' || address[i] == '4' || address[i] == '5' || address[i] == '6' || address[i] == '7' || address[i] == '8' || address[i] == '9') {
                numbersCount++;
            }

            if (numbersCount == 5) {
                addressLines[2] = address.substring(i - 1, address.length);
                address = address.replace(addressLines[2], '').trim();
                break;
            }
        }

        if (address[address.length - 1] == ',') {
            address = address.substring(0, address.length - 1);
            address = address.trim();
        }


        let splitted = address.split(',');
        addressLines[0] = splitted[0];


        let arr = [];
        for (let i = 1; i < splitted.length; i++) {
            arr.push(splitted[i]);
        }

        addressLines[1] = arr.join(', ');

        if (addressLines[1] == '' && addressLines[2]) {
            addressLines[1] = addressLines[2];
            addressLines[2] = '';
        }

        return addressLines;
    }


    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }
    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }


        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];


        let currentQuestion = this.getCurrentChatQuestion();
        console.log('check', this.checkReferralAnswers());

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="9">
                                    {/* {this.state.message ?
                                        <UncontrolledAlert color="success">
                                            {this.state.message.translate(this.props.lang)}
                                        </UncontrolledAlert>
                                        :
                                        null
                                    } */}
                                </Col>

                                {this.props.selectedGroup ?

                                    <Col lg="12">
                                        <div className="panel">
                                            <h4>{'Search for disease'.translate(this.props.lang)} </h4>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Input onKeyUp={(e) => {
                                                            if (e.keyCode == 13) {
                                                                this.setState({
                                                                    suggestionIndex: null,
                                                                    search: ''
                                                                })

                                                                if (this.state.suggestionIndex != null) {
                                                                    let suggestion = JSON.parse(JSON.stringify(this.state.suggestions[this.state.suggestionIndex]));
                                                                    this.updateParams('article', suggestion._id);
                                                                    this.setState({
                                                                        suggestions: [],
                                                                        showSuggestions: null,
                                                                        article: suggestion._id,
                                                                        articleName: suggestion.title,
                                                                        haveQuestionary: suggestion.havePreparedQuestionary,
                                                                        suggestionIndex: null,
                                                                        search: ''
                                                                    })

                                                                    return;
                                                                }

                                                            }
                                                            if (e.keyCode == 38) {
                                                                this.setState({
                                                                    suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                })
                                                            }
                                                            if (e.keyCode == 40) {
                                                                this.setState({
                                                                    suggestionIndex: this.state.suggestionIndex + 1 >= this.state.suggestions.length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                })

                                                            }
                                                        }} onFocus={() => this.setState({ showSuggestions: true, suggestionIndex: null })} type="text" placeholder={'Search database...'.translate(this.props.lang)} value={this.state.search} onChange={(e) => this.setState({ search: e.target.value, showSuggestions: true }, this.suggest)}></Input>
                                                        {this.state.suggestions && this.state.suggestions.length && this.state.showSuggestions ?
                                                            <div className="search-results" >
                                                                <ul >
                                                                    {
                                                                        this.state.suggestions.map((item, idx) => {
                                                                            return (
                                                                                <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {
                                                                                    this.updateParams('article', item._id);

                                                                                    this.setState({
                                                                                        suggestions: [],
                                                                                        showSuggestions: null,
                                                                                        article: item._id,
                                                                                        articleName: item.title,
                                                                                        haveQuestionary: item.havePreparedQuestionary,
                                                                                        suggestionIndex: null,
                                                                                        search: ''
                                                                                    })
                                                                                }}>{item.title}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    :
                                    <Col lg="12">
                                        <div className="panel">
                                            <h4>{'In order to create a referral you must first register your clinic details.'.translate(this.props.lang)} </h4>
                                            <Link to='/my-data/clinic-data'>{'Register your clinic details'}</Link>
                                        </div>
                                    </Col>
                                }

                                {
                                    this.state.haveQuestionary === false ?
                                        <Col lg="12">
                                            <div className="panel referral-section">
                                                <Row>
                                                    <Col lg="6">
                                                        <h4>{'Questionary not found'.translate(this.props.lang)}</h4>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        :

                                        null
                                }
                                <Col lg="12" className="panels-container">
                                    {this.state.article && this.state.sections.length ?
                                        <div className="panel-left">
                                            <div className="panel referral-section">
                                                <h4>{'Referral for'.translate(this.props.lang)} {this.state.articleName}</h4>
                                                <Row>

                                                    <Col lg="12">
                                                        { /*!this.state.referralType || ((( this.state.referralType == 1 &&  this.state.sections && this.state.sections.length && (!this.checkReferralAnswers() || !this.state.consent) ) || (this.state.referralType == 2 && !this.state.consent ) ) ) */ true ?

                                                            <div className="main-panel owx-hidden" ref={(node) => this.chatWrapper = node}>

                                                                <div class="referal-content referal-content-big">
                                                                    <div className="referral-section-item">
                                                                        <div className="head">
                                                                            <div>
                                                                                <FormGroup tag="fieldset" className="center">
                                                                                    <Label size="sm" className={this.state.referralType != 1 && this.state.referralType != 2 ? 'required-question' : ''}>{'Select referral type'.translate(this.props.lang)}</Label>
                                                                                    <div className="referal-container">
                                                                                        <FormGroup check>
                                                                                            <div className="referal-checkbox referal-checkbox-gray">
                                                                                                <Label size="sm" check>
                                                                                                    <Input size="sm" name={'referral-type'} type={'radio'} checked={this.state.referralType == 1 ? true : false} onChange={() => this.setState({ referralType: 1 }, this.scrollToQuestion)} />{' '}{'Intelligent remitteringsstöd'.translate(this.props.lang)}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                        <FormGroup check>
                                                                                            <div className="referal-checkbox referal-checkbox-gray">
                                                                                                <Label size="sm" check>
                                                                                                    <Input size="sm" name={'referral-type'} type={'radio'} checked={this.state.referralType == 2 ? true : false} onChange={() => this.setState({ referralType: 2 }, this.scrollToQuestion)} />{' '}{'Write yourself'.translate(this.props.lang)}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>

                                                                                </FormGroup>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                {
                                                                    this.state.referralType == 1 ?
                                                                        this.state.sections.map((section, sidx) => {
                                                                            let cssStyle = {};
                                                                            if (section.fontSize) {
                                                                                cssStyle.fontSize = section.fontSize;
                                                                            }
                                                                            if (section.fontWeight) {
                                                                                cssStyle.fontWeight = section.fontWeight;
                                                                            }
                                                                            if (section.fontStyle) {
                                                                                cssStyle.fontStyle = section.fontStyle;
                                                                            }

                                                                            if (this.checkSectionCondition(sidx))
                                                                                return (
                                                                                    <div className="referral-section-item referal-content-big">
                                                                                        <div className="referal-content-container">
                                                                                            <div className="head">
                                                                                                <h4 style={cssStyle}> {section.name} </h4>


                                                                                            </div>

                                                                                            {
                                                                                                section.questions.map((question, qidx) => {
                                                                                                    let name = question.name;
                                                                                                    if (question.wikiLinks && question.wikiLinks) {
                                                                                                        for (let i = 0; i < question.wikiLinks.length; i++) {
                                                                                                            name = name.replace(question.wikiLinks[i].term, `<a href="#" widx="${i}">${question.wikiLinks[i].term}</a>`)
                                                                                                        }
                                                                                                    }

                                                                                                    let questionCssStyle = {};
                                                                                                    if (question.fontSize) {
                                                                                                        questionCssStyle.fontSize = question.fontSize;
                                                                                                    }
                                                                                                    if (question.fontWeight) {
                                                                                                        questionCssStyle.fontWeight = question.fontWeight;
                                                                                                    }
                                                                                                    if (question.fontStyle) {
                                                                                                        questionCssStyle.fontStyle = question.fontStyle;
                                                                                                    }


                                                                                                    if (this.checkChatQuestionCondition(question.id) && question.type != 10)
                                                                                                        return (

                                                                                                            <div className={question.type == 1 && question.answers && question.answers.length <= 2 ? currentQuestion && currentQuestion.id == question.id ? "message-container current-message-container" : "message-container" : "referal-container"}>
                                                                                                                <FormGroup tag="fieldset" className={question.type == 2 ? 'group-short' : ''}>
                                                                                                                    {question.wikiLinks && question.wikiLinks.length ?
                                                                                                                        <Label style={questionCssStyle} size="sm" className={question.type == 1 && question.answers && question.answers.length <= 2 ? 'message-question' : ''}>
                                                                                                                            {
                                                                                                                                question.type == 1 && question.answers && question.answers.length <= 2 ?
                                                                                                                                    <Isvg src={userChat} />

                                                                                                                                    :
                                                                                                                                    null
                                                                                                                            }

                                                                                                                            {<div className={question.type == 1 && question.answers && question.answers.length <= 2 ? "question" : null} onClick={(e) => {
                                                                                                                                if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                                    this.setState({
                                                                                                                                        wikiModal: question.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            }} dangerouslySetInnerHTML={{ __html: name }}></div>}</Label>

                                                                                                                        :
                                                                                                                        <Label style={questionCssStyle} size="sm" className={question.type == 1 && question.answers && question.answers.length <= 2 ? 'message-question' : ''}>
                                                                                                                            {
                                                                                                                                question.type == 1 && question.answers && question.answers.length <= 2 ?
                                                                                                                                    <Isvg src={userChat} />
                                                                                                                                    :
                                                                                                                                    null
                                                                                                                            }
                                                                                                                            <div className={question.type == 1 && question.answers && question.answers.length <= 2 ? "question" : null}>
                                                                                                                                {question.name}
                                                                                                                            </div>

                                                                                                                        </Label>

                                                                                                                    }

                                                                                                                    {question.type == 1 ?
                                                                                                                        question.answers && question.answers.length > 2 ?
                                                                                                                            question.answers.map((answer) => {
                                                                                                                                let answerName = answer.name;
                                                                                                                                if (answer.wikiLinks) {
                                                                                                                                    for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                                                                        answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                return (
                                                                                                                                    <FormGroup check>
                                                                                                                                        <div className="referal-checkbox">
                                                                                                                                            <Label size="sm" check>
                                                                                                                                                <Input size="sm" name={question.id} type={'radio'} checked={this.state.answers[question.id] == answer.id ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                                                                                {' '}{<div onClick={(e) => {
                                                                                                                                                    if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                                                        this.setState({
                                                                                                                                                            wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                                                        })
                                                                                                                                                    }
                                                                                                                                                }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                                                                            </Label>
                                                                                                                                        </div>
                                                                                                                                    </FormGroup>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            :
                                                                                                                            question.answers && question.answers.length <= 2 ?
                                                                                                                                question.answers.map((answer) => {
                                                                                                                                    let answerName = answer.name;
                                                                                                                                    if (answer.wikiLinks) {
                                                                                                                                        for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                                                                            answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (this.state.answers[question.id] == answer.id) {
                                                                                                                                        return (
                                                                                                                                            <div className="message-answer" onClick={(e) => {
                                                                                                                                                if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                                                    this.setState({
                                                                                                                                                        wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            }} dangerouslySetInnerHTML={{ __html: answerName }}></div>

                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    else if (this.state.answers[question.id]) {
                                                                                                                                        return (
                                                                                                                                            <div className="message-option" onClick={(e) => {
                                                                                                                                                this.answerQuestion(question.id, answer.id);
                                                                                                                                            }} dangerouslySetInnerHTML={{ __html: answerName }}></div>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                })
                                                                                                                                :
                                                                                                                                null
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }
                                                                                                                    {question.type == 2 ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                question.answers.map((answer) => {
                                                                                                                                    let answerName = answer.name;
                                                                                                                                    if (answer.wikiLinks) {
                                                                                                                                        for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                                                                            answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                                                                                        }
                                                                                                                                    }


                                                                                                                                    return (
                                                                                                                                        <FormGroup check>
                                                                                                                                            <div className="referal-checkbox">
                                                                                                                                                <Label size="sm" className="checkbox-label" check>
                                                                                                                                                    <Input className="referal-default-checkbox" size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />
                                                                                                                                                    <span className="custom-checkbox"></span>
                                                                                                                                                    {' '}{<div onClick={(e) => {
                                                                                                                                                        if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                                                                            this.setState({
                                                                                                                                                                wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                                                                            })
                                                                                                                                                        }
                                                                                                                                                    }} dangerouslySetInnerHTML={{ __html: answerName }}></div>}
                                                                                                                                                </Label>
                                                                                                                                            </div>
                                                                                                                                        </FormGroup>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            {
                                                                                                                                question.answerPrefix && question.userInput ?
                                                                                                                                    <FormGroup check>
                                                                                                                                        <div className="referal-checkbox">
                                                                                                                                            <Label className="checkbox-label" size="sm" check>
                                                                                                                                                <Input className="referal-default-checkbox" size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') !== -1 ? true : false} onChange={() => {
                                                                                                                                                    let answers = this.state.answers;
                                                                                                                                                    if (!answers[question.id]) {
                                                                                                                                                        answers[question.id] = [];
                                                                                                                                                    }

                                                                                                                                                    let index = this.indexOfIndexOf(answers[question.id], '__user-input:');
                                                                                                                                                    console.log(index, 'index');
                                                                                                                                                    if (index != -1) {
                                                                                                                                                        answers[question.id].splice(index, 1)
                                                                                                                                                    } else {
                                                                                                                                                        answers[question.id].push('__user-input:');
                                                                                                                                                    }
                                                                                                                                                    this.setState({
                                                                                                                                                        answers
                                                                                                                                                    }, this.forceUpdate)
                                                                                                                                                }} />
                                                                                                                                                <span className="custom-checkbox"></span>
                                                                                                                                                {' '}{'Further'.translate(this.props.lang)}
                                                                                                                                            </Label>
                                                                                                                                        </div>
                                                                                                                                    </FormGroup>

                                                                                                                                    :

                                                                                                                                    null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') != -1 ?
                                                                                                                                    <>
                                                                                                                                        <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '') ? 'required-question' : ''}>{question.answerPrefix}</Label>

                                                                                                                                        <Input size="sm" type="text" value={this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '')} onChange={(e) => {
                                                                                                                                            let index = this.indexOfIndexOf(this.state.answers[question.id], '__user-input:');
                                                                                                                                            let answers = this.state.answers;
                                                                                                                                            answers[question.id][index] = '__user-input:' + e.target.value;
                                                                                                                                            this.setState({
                                                                                                                                                answers
                                                                                                                                            })
                                                                                                                                        }}></Input>

                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    null
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        null

                                                                                                                    }


                                                                                                                    {
                                                                                                                        question.type == 3 ?
                                                                                                                            <Input style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id]} onChange={(e) => {
                                                                                                                                this.answerQuestion(question.id, e.target.value)
                                                                                                                            }}></Input>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }

                                                                                                                    {
                                                                                                                        question.type == 6 ?
                                                                                                                            <Row>
                                                                                                                                <Col lg="2">
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>{'Weight [kg]'.translate(this.props.lang)}</Label>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                                            val[0] = e.target.value;
                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                                <Col lg="2">
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>{'Height [cm]'.translate(this.props.lang)}</Label>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                                            val[1] = e.target.value;
                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                                <Col lg="2" style={{ paddingTop: 35 }}>
                                                                                                                                    <div style={{
                                                                                                                                        width: 24,
                                                                                                                                        height: 24,
                                                                                                                                        backgroundColor: parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                                                                                                            :
                                                                                                                                            parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                                                                                                                :
                                                                                                                                                parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                                                                                                                    :
                                                                                                                                                    parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                                                                                                                        :
                                                                                                                                                        parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                                                                                                            :
                                                                                                                                                            bmiIndex[5]

                                                                                                                                    }}></div>
                                                                                                                                </Col>

                                                                                                                            </Row>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.type == 12 ?
                                                                                                                            <Row>
                                                                                                                                <Col lg="4">
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>{'Systolic'.translate(this.props.lang)}</Label>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {

                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                                            if (Number(e.target.value) > 300) {
                                                                                                                                                val[0] = 300;
                                                                                                                                            } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                                                val[0] = e.target.value;
                                                                                                                                            } else if (Number(e.target.value) < 0) {
                                                                                                                                                val[0] = 0;
                                                                                                                                            }
                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                                <Col lg="4">
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>{'Diastolic'.translate(this.props.lang)}</Label>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                                            if (Number(e.target.value) > 300) {
                                                                                                                                                val[1] = 300;
                                                                                                                                            } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                                                val[1] = e.target.value;
                                                                                                                                            } else if (Number(e.target.value) < 0) {
                                                                                                                                                val[1] = 0;
                                                                                                                                            }

                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                                <Col lg="4">
                                                                                                                                    <FormGroup>
                                                                                                                                        <Label>{'Pulse'.translate(this.props.lang)}</Label>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][2] : ''} onChange={(e) => {
                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                                            if (Number(e.target.value) > 300) {
                                                                                                                                                val[2] = 300;
                                                                                                                                            } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                                                val[2] = e.target.value;
                                                                                                                                            } else if (Number(e.target.value) < 0) {
                                                                                                                                                val[2] = 0;
                                                                                                                                            }

                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.type == 9 ?
                                                                                                                            <>
                                                                                                                                <File lang={this.props.lang} onChange={(img) => {
                                                                                                                                    this.answerQuestion(question.id, img)

                                                                                                                                }} value={this.state.answers[question.id]} />


                                                                                                                            </>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.type == 13 ?
                                                                                                                            <Row>
                                                                                                                                <Col lg={{ size: 4, offset: 4 }}>
                                                                                                                                    <FormGroup>
                                                                                                                                        <Input style={questionCssStyle} size="sm" type="number" min='0' max='10' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {

                                                                                                                                            let val = this.state.answers[question.id] ? this.state.answers[question.id] : [''];
                                                                                                                                            if (Number(e.target.value) > 10) {
                                                                                                                                                val[0] = 10;
                                                                                                                                            } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 10) {
                                                                                                                                                val[0] = e.target.value;
                                                                                                                                            } else if (Number(e.target.value) < 0) {
                                                                                                                                                val[0] = 0;
                                                                                                                                            }
                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}></Input>

                                                                                                                                    </FormGroup>
                                                                                                                                </Col>



                                                                                                                            </Row>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }

                                                                                                                    {
                                                                                                                        question.type == 14 ?
                                                                                                                            <Row>

                                                                                                                                <Col>
                                                                                                                                    <FormGroup>
                                                                                                                                        <Input type='select' value={this.state.answers[question.id] ? this.state.answers[question.id] : ''} min={0} max={100} onChange={(e) => {
                                                                                                                                            let val = e.target.value
                                                                                                                                            this.answerQuestion(question.id, val)
                                                                                                                                        }}>
                                                                                                                                            {
                                                                                                                                                this.state.items.map(item => {
                                                                                                                                                    return (
                                                                                                                                                        <option value={item.value}>
                                                                                                                                                            {item.name}
                                                                                                                                                        </option>
                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        </Input>
                                                                                                                                    </FormGroup>

                                                                                                                                </Col>

                                                                                                                            </Row>
                                                                                                                            :
                                                                                                                            null
                                                                                                                    }


                                                                                                                    {
                                                                                                                        question.type == 4 ?
                                                                                                                            <div>
                                                                                                                                <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>
                                                                                                                                {/* <Checkbox checked={question.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} /> */}

                                                                                                                            </div>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.type == 15 ?
                                                                                                                            <div>
                                                                                                                                <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>

                                                                                                                            </div>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.type == 5 ?
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    console.log(this.state.answers[question.id])
                                                                                                                                }
                                                                                                                                {

                                                                                                                                    (this.state.answers[question.id] || [""]).map((diagnose, didx) => {
                                                                                                                                        return (
                                                                                                                                            <FormGroup>
                                                                                                                                                <Input onKeyUp={(e) => {
                                                                                                                                                    if (e.keyCode == 13) {
                                                                                                                                                        this.setState({
                                                                                                                                                            suggestionIndex: null,
                                                                                                                                                        })

                                                                                                                                                        if (this.state.suggestionIndex != null) {
                                                                                                                                                            let suggestion = JSON.parse(JSON.stringify(this.state[`icd10Suggestions-${question.id}-${didx}`][this.state.suggestionIndex]));

                                                                                                                                                            let arr = this.state.answers;
                                                                                                                                                            if (!arr[question.id]) {
                                                                                                                                                                arr[question.id] = [];
                                                                                                                                                            }

                                                                                                                                                            arr[question.id][didx] = suggestion.code + ' - ' + suggestion.name;


                                                                                                                                                            this.answerQuestion(question.id, arr[question.id])

                                                                                                                                                            //this.answerQuestion(question.id, suggestion.code + ' - ' + suggestion.name);
                                                                                                                                                            let stateObj = {};
                                                                                                                                                            stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                                            stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                                            this.setState(stateObj);
                                                                                                                                                            return;
                                                                                                                                                        }

                                                                                                                                                    }
                                                                                                                                                    if (e.keyCode == 38) {
                                                                                                                                                        this.setState({
                                                                                                                                                            suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                                                                                                        })
                                                                                                                                                    }
                                                                                                                                                    if (e.keyCode == 40) {
                                                                                                                                                        this.setState({
                                                                                                                                                            suggestionIndex: this.state.suggestionIndex + 1 >= this.state[`icd10Suggestions-${question.id}-${didx}`].length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                                                                                                        })

                                                                                                                                                    }
                                                                                                                                                }} onFocus={() => {
                                                                                                                                                    let stateObj = { suggestionIndex: null };
                                                                                                                                                    stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                                                    this.setState(stateObj);
                                                                                                                                                }} style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] && this.state.answers[question.id][didx] ? this.state.answers[question.id][didx] : ''} onChange={(e) => {
                                                                                                                                                    //this.answerQuestion(question.id, e.target.value)
                                                                                                                                                    let arr = this.state.answers;
                                                                                                                                                    if (!arr[question.id]) {
                                                                                                                                                        arr[question.id] = [];
                                                                                                                                                    }

                                                                                                                                                    arr[question.id][didx] = e.target.value;


                                                                                                                                                    this.answerQuestion(question.id, arr[question.id])
                                                                                                                                                    console.log('aaa');
                                                                                                                                                    fetch(API_ENDPOINT + '/data/icd10/suggest', {
                                                                                                                                                        method: 'POST',
                                                                                                                                                        headers: {
                                                                                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                                                                            'content-type': 'application/json'
                                                                                                                                                        },
                                                                                                                                                        body: JSON.stringify({ search: e.target.value })
                                                                                                                                                    }).then(parseJSON).then(({ result, status }) => {
                                                                                                                                                        if (status >= 200 && status < 300) {
                                                                                                                                                            let stateObj = {};
                                                                                                                                                            stateObj[`icd10Suggestions-${question.id}-${didx}`] = result;
                                                                                                                                                            stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                                                            this.setState(stateObj);
                                                                                                                                                        }
                                                                                                                                                    })


                                                                                                                                                }}></Input>
                                                                                                                                                {this.state[`showIcd10Suggestions-${question.id}-${didx}`] ?
                                                                                                                                                    <div className="search-results" ref={(node) => { this.searchResultsWraps[question.id + '-' + didx] = node; }}>
                                                                                                                                                        <ul >
                                                                                                                                                            {
                                                                                                                                                                this.state[`icd10Suggestions-${question.id}-${didx}`] && this.state[`icd10Suggestions-${question.id}-${didx}`].map((icd10Item, idx) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {

                                                                                                                                                                            let arr = this.state.answers;
                                                                                                                                                                            if (!arr[question.id]) {
                                                                                                                                                                                arr[question.id] = [];
                                                                                                                                                                            }

                                                                                                                                                                            arr[question.id][didx] = icd10Item.code + ' - ' + icd10Item.name;


                                                                                                                                                                            this.answerQuestion(question.id, arr[question.id])


                                                                                                                                                                            let stateObj = {};
                                                                                                                                                                            stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                                                            stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                                                            this.setState(stateObj);
                                                                                                                                                                        }}>{icd10Item.code} - {icd10Item.name}</li>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </ul>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    null
                                                                                                                                                }

                                                                                                                                            </FormGroup>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }

                                                                                                                                <button className="add-diagnose-button" onClick={() => {
                                                                                                                                    let answers = this.state.answers;
                                                                                                                                    if (!answers[question.id]) {
                                                                                                                                        answers[question.id] = [""];
                                                                                                                                    }

                                                                                                                                    answers[question.id].push("");
                                                                                                                                    this.setState({
                                                                                                                                        answers
                                                                                                                                    })

                                                                                                                                }}>{'Add diagnos'.translate(this.props.lang)}</button>

                                                                                                                            </>
                                                                                                                            :

                                                                                                                            null
                                                                                                                    }




                                                                                                                </FormGroup>

                                                                                                            </div>
                                                                                                        )
                                                                                                }
                                                                                                )

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                        })

                                                                        :
                                                                        null

                                                                }


                                                                {this.state.article && (this.checkReferralAnswers() || this.state.referralType == 2) ?

                                                                    <div className="referral-section-item centered">
                                                                        <div className="head">
                                                                            <h4> {'Samtycke'.translate(this.props.lang)} </h4>
                                                                            <div>
                                                                                <FormGroup tag="fieldset">
                                                                                    <Label size="sm" className={this.state.consent != 1 && this.state.consent != 2 ? 'required-question' : ''}>{'Has the patient given his consent for the recipient of the referral to take part in previous medical records?'.translate(this.props.lang)}</Label>
                                                                                    <div className="referal-container justify-center non-vcenter">
                                                                                        <FormGroup check>
                                                                                            <div className="referal-checkbox referal-checkbox-gray">
                                                                                                <Label size="sm" check>
                                                                                                    <Input disabled={params.referral} size="sm" name={'consent'} type={'radio'} checked={this.state.consent == 1 ? true : false} onChange={() => this.setState({ consent: 1 })} />{' '}{'Yes'.translate(this.props.lang)}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                        <FormGroup check>
                                                                                            <div className="referal-checkbox referal-checkbox-gray">
                                                                                                <Label size="sm" check>
                                                                                                    <Input disabled={params.referral} size="sm" name={'consent'} type={'radio'} checked={this.state.consent == 2 ? true : false} onChange={() => this.setState({ consent: 2 })} />{' '}{'No'.translate(this.props.lang)}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </FormGroup>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }

                                                                {this.state.article && (this.checkReferralAnswers() || this.state.referralType == 2) && this.state.consent !== null ?
                                                                    <>


                                                                        <div>
                                                                            <h6 className="referal-form-subtitle">{'Referral number and patient information'.translate(this.props.lang)}</h6>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Referral number'.translate(this.props.lang)}</Label>
                                                                                <Input size="sm" disabled type="text" value={this.state.patientData.referralNumber + (this.state.version != 0 ? '.' + this.state.version : null)} ></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Social security number'.translate(this.props.lang)} ({`${this.props.country === 'denmark' ? 'DDMMYYNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}`.substr(0, this.state.patientData.socialSecurityNumber ? this.state.patientData.socialSecurityNumber.length : 0)}<span style={{ color: 'red' }}>{`${this.props.country === 'denmark' ? 'DDMMYYNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}`.substr(this.state.patientData.socialSecurityNumber ? this.state.patientData.socialSecurityNumber.length : 0, 12)}</span> )</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.socialSecurityNumber} onChange={(e) => {
                                                                                    let value = e.target.value.replace(/[^\d]/g, '')
                                                                                    if ((this.props.country === 'denmark' && value.length > 10) || value.length > 12) {
                                                                                        return;
                                                                                    }

                                                                                    if ((this.props.country === 'denmark' && value.length == 10) || value.length == 12) {
                                                                                        this.getAddress(value);
                                                                                    }

                                                                                    this.changePatientData('socialSecurityNumber', value)
                                                                                }}></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'First and last name'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.name} onChange={(e) => this.changePatientData('name', e.target.value)}></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Street name and number'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.street} onChange={(e) => this.changePatientData('street', e.target.value)}></Input>
                                                                            </FormGroup>
                                                                            <Row>
                                                                                <Col lg="3">
                                                                                    <FormGroup>
                                                                                        <Label size="sm">{'ZIP code'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.zip} onChange={(e) => {
                                                                                            let value = e.target.value //.replace(/[^\d]/g, '')
                                                                                            this.changePatientData('zip', value)
                                                                                        }}></Input>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="9">
                                                                                    <FormGroup>
                                                                                        <Label size="sm">{'City'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.city} onChange={(e) => this.changePatientData('city', e.target.value)}></Input>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label size="sm">{'Phone number'.translate(this.props.lang)}</Label>
                                                                                        {/*<Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.patientData.phone} onChange={(e) => this.changePatientData('phone', e.target.value)}></Input>
                                                                    */}

                                                                                        {params.referral ?
                                                                                            <Input disabled size="sm" type="text" value={this.state.patientData.phone} />
                                                                                            :
                                                                                            <PhoneInput

                                                                                                disabled={params.referral || this.state._lockFields}
                                                                                                countryTwoLetter={this.props.countryTwoLetter}
                                                                                                country={this.props.country === 'denmark' ? 'dk' : 'se'}
                                                                                                value={this.state.patientData.phone}
                                                                                                onChange={(e) => this.changePatientData('phone', e)}
                                                                                            />
                                                                                        }

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <Label size="sm">{'E-mail'.translate(this.props.lang)}</Label>
                                                                                        <Input disabled={params.referral || this.state._lockFields} size="sm" type="email" value={this.state.patientData.email} onChange={(e) => this.changePatientData('email', e.target.value)}></Input>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <h6 className="referal-form-subtitle">{'Referral recipient'.translate(this.props.lang)} <button onClick={() => this.setState({ selectClinicModal: true })}><i className="fa fa-search" /></button></h6>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Hospital / Specialist reception'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.recipientData.name} onChange={(e) => this.changeRecipientData('name', e.target.value)}></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Address line 1'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.recipientData.address1} onChange={(e) => this.changeRecipientData('address1', e.target.value)}></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Address line 2'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.recipientData.address2} onChange={(e) => this.changeRecipientData('address2', e.target.value)}></Input>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label size="sm">{'Address line 3'.translate(this.props.lang)}</Label>
                                                                                <Input disabled={params.referral || this.state._lockFields} size="sm" type="text" value={this.state.recipientData.address3} onChange={(e) => this.changeRecipientData('address3', e.target.value)}></Input>
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                {/*this.state.recipientData && (this.state.recipientData._referralReceiving || params.referral) ?
                                                                    <Button disabled={!(this.state.recipientData.name && this.state.recipientData.address1 && this.state.referralType)} color="primary" onClick={() => this.sendReferral()}>
                                                                        {params.referral ? 'Send updated version'.translate(this.props.lang) : 'Send'.translate(this.props.lang)}
                                                                    </Button>
                                                                    :
                                                                    null
                                                                */}

                                                                                {!this.state._previewReferral && !(this.state.referralType == 2 && params.referral) ?
                                                                                    <Button color="primary" onClick={() => {
                                                                                        this.setState({
                                                                                            _previewReferral: true,
                                                                                        })

                                                                                        if (this.state.referralType == 2) {
                                                                                            this.setState({
                                                                                                referralContent: this.generateReferral()
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                        {'Preview'.translate(this.props.lang)}
                                                                                    </Button>
                                                                                    :
                                                                                    null
                                                                                }

                                                                            </FormGroup>


                                                                        </div>
                                                                    </>

                                                                    :
                                                                    null
                                                                }

                                                            </div>

                                                            : null}
                                                        <div className="referal-buttons">
                                                            {
                                                                currentQuestion && currentQuestion.type == 1 && currentQuestion.answers && currentQuestion.answers.length <= 2 ?
                                                                    currentQuestion.answers.map((answer, aidx) => {
                                                                        let answerName = answer.name;
                                                                        if (answer.wikiLinks) {
                                                                            for (let i = 0; i < answer.wikiLinks.length; i++) {
                                                                                answerName = answerName.replace(answer.wikiLinks[i].term, `<a href="#" widx="${i}">${answer.wikiLinks[i].term}</a>`)
                                                                            }
                                                                        }

                                                                        return (
                                                                            <Button className="question-button" key={aidx} size="lg" onClick={() => this.answerQuestion(currentQuestion.id, answer.id)}>

                                                                                <div onClick={(e) => {
                                                                                    if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                        this.setState({
                                                                                            wikiModal: answer.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                        })
                                                                                    }
                                                                                }} dangerouslySetInnerHTML={{ __html: answerName }}></div>




                                                                            </Button>
                                                                        )
                                                                    })
                                                                    :

                                                                    null
                                                            }
                                                        </div>



                                                    </Col>
                                                    <Col lg="6">
                                                    </Col>
                                                </Row>
                                            </div>


                                        </div>

                                        :
                                        null

                                    }

                                    <div className="panel-right">
                                        {(this.state._previewReferral) || this.state.referralType == 1 || (this.state.referralType == 2 && params.referral) ?

                                            <div className="panel diagnosis-sidebar">

                                                <div onMouseEnter={() => {
                                                    this.setState({
                                                        _previewReferral: true
                                                    })
                                                }}
                                                    onMouseLeave={() => {
                                                        this.setState({
                                                            _previewReferral: false
                                                        })
                                                    }}
                                                    className="referral-sidebar" style={this.state._previewReferral || (params.referral && this.state.referralType == 2) ? { right: 0 } : {}}>
                                                    {this.state.referralType == 2 || params.referral ?
                                                        <HTML value={this.state.referralContent} onChange={(text) => { this.setState({ referralContent: text, _lockFields: true }) }} height={typeof window !== 'undefined' ? window.innerHeight - 100 : 500}></HTML>

                                                        :

                                                        <div style={{ height: typeof window !== 'undefined' ? window.innerHeight - 100 : 500 }} className="content" dangerouslySetInnerHTML={{ __html: this.generateReferral() }}>

                                                        </div>
                                                    }
                                                    <div className="footer-div">
                                                        <FormGroup>
                                                            {this.state.recipientData && (this.state.recipientData._referralReceiving || params.referral) ?

                                                                <Button color="primary" disabled={this.state._sendingReferral} onClick={params.referral ? () => this.sendReferral() : () => this.setState({ signinModal: true })}>
                                                                    {this.state._sendingReferral ? 'Processing...'.translate(this.props.lang) : params.referral ? 'Send updated version'.translate(this.props.lang) : 'Sign and send'.translate(this.props.lang)}

                                                                </Button>
                                                                :
                                                                null
                                                            }

                                                            <Button color="primary" onClick={() => {
                                                                let w = window.open();
                                                                w.document.write(this.state.referralContent ? this.state.referralContent : this.generateReferral());
                                                                w.print();
                                                                w.close();

                                                            }}><i className="fa fa-print"> Print</i></Button>

                                                            {this.state._previewReferral ?
                                                                <Button color="primary" style={{ marginLeft: 15 }} onClick={() => this.setState({ _previewReferral: null })}>
                                                                    {'Hide'.translate(this.props.lang)}
                                                                </Button>
                                                                :
                                                                null
                                                            }


                                                        </FormGroup>
                                                    </div>
                                                </div>

                                            </div>

                                            :

                                            null
                                        }
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>


                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.selectClinicModal ?
                        <Modal wrapClassName="referral-clinics-modal" centered isOpen={this.state.selectClinicModal} toggle={() => this.setState({ selectClinicModal: !this.state.selectClinicModal })} size="xl">
                            <ModalHeader>
                                <h4>{'Search Curoflow database'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody className="referral-clinics-modal-body">
                                <ReferralClinics {...this.props} patientData={this.state.patientData} searchFor={this.state.articleName} includeTags={this.state.tags} importClinic={(clinic) => {


                                    let addressLines = [];
                                    if (clinic.postAddress) {
                                        addressLines = this.splitAddress(clinic.postAddress);
                                    } else {
                                        addressLines = [clinic.street, clinic.zip + ' ' + clinic.city, ''];
                                    }

                                    this.setState({
                                        recipientData: {
                                            ...clinic,
                                            name: clinic.vantetiderName ? clinic.vantetiderName : clinic.name,
                                            address1: addressLines[0],
                                            address2: addressLines[1],
                                            address3: addressLines[2]
                                        },
                                        selectClinicModal: null
                                    })
                                }}></ReferralClinics>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.wikiModal ?
                        <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}><Isvg src={xIcon} /></button>}>{this.state.wikiModal.name}</ModalHeader>
                            <ModalBody>
                                <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                                {this.state.wikiModal.image ? <img src={API_ENDPOINT + this.state.wikiModal.image} /> : null}
                            </ModalBody>
                        </Modal>
                        :
                        null
                }



                {this.state.signinModal ?

                    <Modal isOpen={this.state.signinModal} centered>
                        <ModalHeader toggle={() => this.setState({ signinModal: !this.state.signinModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ signinModal: !this.state.signinModal })}><Isvg src={xIcon} /></button>}>
                            <h4>{'Sign in with your NET ID'.translate(this.props.lang)}</h4>

                        </ModalHeader>
                        <ModalBody>
                            <Button onClick={() => this.sendReferral()}>{'Continue'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null}




            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(CreateReferral));