import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label,
    Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import Search from '../../components/search';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import Toggle from '../../components/forms/fields/toggle';

import importIcon from '../../assets/svg/import.svg'

import saveFormsIcon from '../../assets/svg/save_forms_icon.svg'


import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import view from '../../assets/svg/eye.svg'
import xIcon from '../../assets/svg/x.svg';
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import CreatePersonalReferralModal from '../../components/createPersonalReferralModal';
import CreateReferralModal from '../../components/createReferralModal';

import CreateDaybookModal from '../../components/createDaybookModal';


import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg';
import duplicateIcon from '../../assets/svg/duplicate-icon.svg';
import generateLinkIcon from '../../assets/svg/generate_link_icon.svg';



import InfoModal from '../../components/infoModal';
import FormPreview from '../../components/formPreview';

import FormsListComponent from '../../components/formsListComponent';

import PreviewAnswerForm from '../forms/previewAnswerForm';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Page to view list of referral forms and options to add new referral forms
* @author   Milan Stanojevic
*/
class ReferralsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            loadingDuplicate: false,
            tabIndex: 1
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        const urlObj = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 });

        if (this.props.selectedGroup) urlObj.group = this.props.selectedGroup;
        if (this.props.selectedClinic) urlObj.clinic = this.props.selectedClinic;
        if (this.state.tabIndex) urlObj.tabIndex = this.state.tabIndex;

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : urlObj).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : urlObj).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState({
                    ...dataToSet,
                    // loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        if (this.props[0].location.state && this.props[0].location.state.initialState) {
            let obj = this.props[0].location.state.initialState;
            if (obj && obj.page) {
                this.updateParams('page', obj.page)
            }
            if (obj && obj.tabIndex) {
                this.setState({ tabIndex: obj.tabIndex }, () => {
                    this.get()
                })
            }

        } else {
            this.get();
        }


    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup) || (!prevProps?.selectedClinic && this.props.selectedClinic) || (prevProps?.selectedClinic !== this.props.selectedClinic)) {
            this.get();
        }

        // if (prevProps.selectedGroup != this.props.selectedGroup) {
        //     this.get();
        // }

        // if (prevProps.selectedClinic != this.props.selectedClinic) {
        //     this.get();
        // }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }

    /**
    * Update referral form
    * @author   Milan Stanojevic
    * @Objectparam    {String} id               referral form id
    * @Objectparam    {Object} data             form data
    */
    update = (data) => {

        delete data.subgroupName;
        delete data.groupName;
        delete data.additionalSubgroupsObjects;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/data/personal-questionary/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    form: null
                })
            })

        })
    }

    /**
    * Delete referral form
    * @author   Milan Stanojevic
    * @Objectparam    {String} id               referral form id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/personal-questionary/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }

    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }
    duplicateModal = () => {
        let obj = this.state.duplicateModal;
        obj.name = obj.name + ' (copy)';

        this.setState({ loadingDuplicate: true }, () => {
            fetch(API_ENDPOINT + '/data/personal-questionary/new', {
                method: 'PUT',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        message: 'Questionary saved successfully',
                        duplicateModal: null,
                        loadingDuplicate: false
                    }, () => this.get())

                }
            })
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }
        let clinics = [];
        if (this.props.uData && this.props.selectedGroup && this.props.uData.clinicGroups && this.props.uData.clinicGroups.filter(el => el._id == this.props.selectedGroup).length && this.props.uData.clinicGroups.filter(el => el._id == this.props.selectedGroup)[0] && this.props.uData.clinicGroups.filter(el => el._id == this.props.selectedGroup)[0].clinics) {
            clinics = this.props.uData.clinicGroups.filter(el => el._id == this.props.selectedGroup)[0].clinics;
        }
        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        const selectedGroupData = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx];
        const linksToForms = selectedGroupData?.linksToForms;
        let fields = [
            { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },
            { type: 'text', name: 'schedulingType', label: 'Scheduling type'.translate(this.props.lang) },
            { type: 'text', name: 'assignedProfessionEn', label: 'Assigned'.translate(this.props.lang), allowSort: true, maxWidth: 220 },
            { type: 'text', name: 'switch', label: 'Inactiv/Active'.translate(this.props.lang), allowSort: true },
        ]
        if (linksToForms) {
            fields.push({ type: 'text', name: 'visibility', label: 'Visible'.translate(this.props.lang), allowSort: true })
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">


                                <Col lg="12" className='col-no-padding'>
                                    <div className="tabs-buttons-wrap" style={{ marginBottom: 35 }}>
                                        <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 }, () => this.get(), this.updateParams('page', 0))}>{'Booking by clinic'.translate(this.props.lang)}</Button>
                                        <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 }, () => this.get(), this.updateParams('page', 0))}>{'Web booking'.translate(this.props.lang)}</Button>
                                        <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 }, () => this.get(), this.updateParams('page', 0))}>{'Drop-in'.translate(this.props.lang)}</Button>
                                    </div>
                                </Col>

                                <div className="panel-header">

                                    <h5 className="component-header">{this.state.tabIndex == 1 ? 'Booking by clinic'.translate(this.props.lang) : this.state.tabIndex == 2 ? 'Web booking'.translate(this.props.lang) : this.state.tabIndex == 3 ? 'Drop-in'.translate(this.props.lang) : 'Booking by clinic'.translate(this.props.lang)}</h5>
                                    <div>
                                        <button className="btn add-button" onClick={() => {
                                            let obj = {
                                                tabIndex: this.state.tabIndex,
                                            }
                                            let wayOfScheduling = this.state.tabIndex == 1 ? 'bookingByClinic' : this.state.tabIndex == 2 ? 'webBooking' : this.state.tabIndex == 3 ? 'dropIn' : null
                                            this.props[0].history.push({
                                                pathname: '/personal-referrals/new',
                                                state: { redirectFrom: obj, wayOfScheduling }

                                            })
                                            // this.props[0].history.push('/personal-referrals/new')
                                        }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                        {
                                            clinics && clinics.length > 1 ?
                                                <button style={{ marginLeft: 15 }} className="btn add-button" onClick={() => {
                                                    this.setState({ importFromDepartmentModal: true, selectedDepartment: null })
                                                }}><Isvg src={importIcon} /> {'Import from department'.translate(this.props.lang)}</button>
                                                :
                                                null
                                        }
                                    </div>



                                </div>



                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={fields}
                                    rawItems={this.state.items}
                                    items={this.state.items.map((item => {
                                        return {
                                            ...item,
                                            // schedulingType: item.active && item.onlineVisit && (((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) || ((item.freeOfCharge && item.price == 0))) ?
                                            //     <><span>{item.wayOfScheduling == 'clinic' ? 'Sök vård'.translate(this.props.lang) : ''}</span><br /><span>{'Drop in'.translate(this.props.lang)}</span><br /><span>{item.wayOfScheduling == 'patient' ? 'Web bokning'.translate(this.props.lang) : ''}</span></> :
                                            //     item.active && item.onlineVisit && (((this.state.clinicGroup && this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey) || (this.state.clinicGroup && this.state.clinicGroup.bankAccount)) || ((item.freeOfCharge && item.price == 0) || (item.under18 || item.under20 || item.under23 || item.older85 || item.under18child))) ?
                                            //         <><span>{item.wayOfScheduling == 'clinic' ? 'Sök vård'.translate(this.props.lang) : ''}</span><br /><span>{'Drop in (Age dependent)'.translate(this.props.lang)}</span><br /><span>{item.wayOfScheduling == 'patient' ? 'Web bokning'.translate(this.props.lang) : ''}</span></> :
                                            //         item.active ?
                                            //             <><span>{item.wayOfScheduling == 'clinic' ? 'Sök vård'.translate(this.props.lang) : ''}</span><br /><span>{item.wayOfScheduling == 'patient' ? 'Web bokning'.translate(this.props.lang) : ''}</span></> :
                                            //             '',
                                            schedulingType: item.wayOfScheduling == 'bookingByClinic' ? 'Booking by clinic'.translate(this.props.lang) : item.wayOfScheduling == 'dropIn' ? 'Drop in'.translate(this.props.lang) : item.wayOfScheduling == 'webBooking' ? 'Web booking'.translate(this.props.lang) : null,
                                            assignedProfessionEn: this.props.lang == 'en' ? item.assignedProfessionEn ? item.assignedProfessionEn : '' : item.assignedProfessionSv ? item.assignedProfessionSv : '',
                                            name: this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)'),
                                            active: item.wayOfScheduling == 'patient' && item.doctorsListQuestionary && (item.doctorsListQuestionary.length == 0 || item.doctorsListQuestionary.filter((listItem) => listItem.checked == true).length == 0) ? 'No (No caregivers selected)'.translate(this.props.lang) : item.wayOfScheduling == 'clinic' ? 'N/A' : 'Yes'.translate(this.props.lang),
                                            switch: <div style={{ height: 33, width: 100, marginTop: -18 }}>
                                                <Toggle value={item.active ? item.active : false} onChange={(e) => {
                                                    // if (!(item.wayOfScheduling == 'patient' && item.doctorsListQuestionary && (item.doctorsListQuestionary.length == 0 || item.doctorsListQuestionary.filter((listItem) => listItem.checked == true).length == 0)))
                                                    //     fetch(API_ENDPOINT + '/data/personal-questionary/update-active-status/' + item._id, {
                                                    //         method: 'POST',
                                                    //         headers: {
                                                    //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                    //             'content-type': 'application/json'
                                                    //         },
                                                    //         body: JSON.stringify({})
                                                    //     }).then(parseJSON).then(({ result, status }) => {
                                                    //         if (result.error) {
                                                    //             this.setState({ error: result.error })
                                                    //         } else {
                                                    //             this.get();
                                                    //         }
                                                    //     })

                                                    let canChangeActiveState = false;
                                                    if (item.professionList && item.professionList.length > 0 && item.items && item.items.length) {
                                                        canChangeActiveState = true;
                                                    } else if (item.active) {
                                                        canChangeActiveState = true;
                                                    }
                                                    if (canChangeActiveState)
                                                        fetch(API_ENDPOINT + '/data/personal-questionary/update-active-status/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify({})
                                                        }).then(parseJSON).then(({ result, status }) => {
                                                            if (result.error) {
                                                                this.setState({ error: result.error })
                                                            } else {
                                                                this.get();
                                                            }
                                                        })
                                                }} />
                                            </div>,
                                            visibility: <div style={{ height: 33, width: 100, marginTop: -18 }}>
                                                <Toggle value={item.nonVisible || !item.active ? false : true} onChange={(e) => {
                                                    if (item.active) {
                                                        fetch(API_ENDPOINT + '/data/personal-questionary/update-visible-status/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify({})
                                                        }).then(parseJSON).then(({ result, status }) => {
                                                            if (result.error) {
                                                                this.setState({ error: result.error })
                                                            } else {
                                                                this.get();
                                                            }
                                                        })
                                                    }



                                                }} />
                                            </div>,
                                        }
                                    }))}





                                    actions={
                                        [
                                            {
                                                renderComponent: (item) =>
                                                    <div className="tabel-action-tooltip">
                                                        <Isvg src={generateLinkIcon} className={this.state.copiedToClipboard === item._id ? "duplicate-icon-active" : "duplicate-icon"} />
                                                        {
                                                            this.state.copiedToClipboard === item._id ?
                                                                <div style={{ visibility: 'visible' }} className="chat-icons-tooltip">{'Link copied to the clipboard'.translate(this.props.lang)}</div>
                                                                :
                                                                <div className="chat-icons-tooltip">{'Generate link'.translate(this.props.lang)}</div>
                                                        }

                                                    </div>,
                                                onClick: (item) => {
                                                    let link;
                                                    if (typeof window !== 'undefined' && item) {
                                                        let endpoint = window.origin;
                                                        let alias = selectedGroupData?.alias;
                                                        let formId = item._id ?? null;

                                                        if (endpoint && alias && formId) {
                                                            link = `${endpoint}/${alias}/forms/${formId}`;
                                                            navigator.clipboard.writeText(link)
                                                        }
                                                    }
                                                    //
                                                    if (link) {
                                                        this.setState({ copiedToClipboard: item?._id }, () => {
                                                            if (this.copyToClipboard) {
                                                                clearTimeout(this.copyToClipboard)
                                                            }
                                                            this.copyToClipboard = setTimeout(() => {
                                                                this.setState({ copiedToClipboard: false })
                                                            }, 2000);
                                                        })
                                                    }
                                                },
                                                condition: (item) => {
                                                    if (linksToForms) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }

                                                }
                                            },
                                            {
                                                renderComponent: (item) => item.saveForm ?
                                                    <div className="tabel-action-tooltip"> <Isvg src={saveFormsIcon} className="save-form-icon active-save-form" /> <div className="chat-icons-tooltip">{'Save and resume form is on'.translate(this.props.lang)}</div> </div>
                                                    :
                                                    <div className="tabel-action-tooltip"> <Isvg src={saveFormsIcon} className="save-form-icon inactive-save-form" /> <div className="chat-icons-tooltip">{'Save and resume form is off'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    let disabledFormsClick = this.state.disabledFormsClick ? this.state.disabledFormsClick : [];
                                                    if (disabledFormsClick.indexOf(item._id) != -1) {
                                                        return;
                                                    }
                                                    disabledFormsClick.push(item._id);
                                                    this.setState({ disabledFormsClick }, () => {
                                                        fetch(API_ENDPOINT + '/data/personal-questionary/update-save-form-status/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify({})
                                                        }).then(parseJSON).then(({ result, status }) => {
                                                            if (result.error) {
                                                                this.setState({ error: result.error })
                                                            } else {
                                                                let disabledFormsClick = this.state.disabledFormsClick ? this.state.disabledFormsClick : [];
                                                                let index = disabledFormsClick.findIndex(el => el === item._id)
                                                                if (index > -1) {
                                                                    disabledFormsClick.splice(index, 1)
                                                                }
                                                                this.setState({ disabledFormsClick })
                                                                this.get();
                                                            }
                                                        })
                                                    })
                                                    this.setState({})
                                                },
                                                condition: (item) => {
                                                    if (this.state.keepingForms) {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            },

                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={duplicateIcon} className="duplicate-icon" /> <div className="chat-icons-tooltip">{'Copy form'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ duplicateModal: item })
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={view} /> <div className="chat-icons-tooltip">{'Preview'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ previewModal: item })
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={edit} /> <div className="chat-icons-tooltip">{'Edit'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => {
                                                    let obj = {
                                                        tabIndex: this.state.tabIndex,
                                                        page: params.page
                                                    }
                                                    this.props[0].history.push({
                                                        pathname: '/personal-referrals/' + item._id,
                                                        state: { redirectFrom: obj }
                                                    })
                                                }
                                            },
                                            {
                                                component: <div className="tabel-action-tooltip"> <Isvg src={garabage} /> <div className="chat-icons-tooltip">{'Delete'.translate(this.props.lang)}</div> </div>,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete referral'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.props.lang == 'en' ? this.state.deleteModal.name.replace(' (onlinebesök)', ' (online visit)') : this.state.deleteModal ? this.state.deleteModal.name.replace(' (online visit)', ' (onlinebesök)') : ''}</strong>?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.duplicateModal ?
                        <InfoModal
                            isOpen={this.state.duplicateModal}
                            toggle={() => this.setState({ duplicateModal: !this.state.duplicateModal })}
                            header={'Duplicate form'.translate(this.props.lang)}
                            info={`Do you want to duplicate the form`.translate(this.props.lang) + ' (' + `${this.props.lang == 'en' ? this.state.duplicateModal.name.replace(' (onlinebesök)', ' (online visit)') : this.state.duplicateModal.name.replace(' (online visit)', ' (onlinebesök)')}` + ')?'}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} disabled={this.state.loadingDuplicate} onClick={() => this.duplicateModal()}>{'Yes'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ duplicateModal: false })}>{'No'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :
                        null
                }
                {/* {
                    this.state.previewModal ?
                        <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                            <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormPreview {...this.props} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)} />

                            </ModalBody>
                        </Modal>
                        :
                        null
                } */}
                {
                    this.state.previewModal ?
                        <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                            <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                {/* <CreateDaybookModal {...this.props} daybookFormView={true} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)}></CreateDaybookModal> */}

                                <PreviewAnswerForm
                                    {...this.props}
                                    referralContent={true}
                                    referral={{
                                        name: this.state.previewModal.name,
                                        description: this.state.previewModal.description,
                                        intelligentReferral: this.state.previewModal.intelligentReferral,
                                        items: this.state.previewModal.items
                                    }}
                                />
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.importFromDepartmentModal ?
                        <Modal isOpen={this.state.importFromDepartmentModal} centered toggle={() => this.setState({ importFromDepartmentModal: !this.state.importFromDepartmentModal })} size={this.state.selectedDepartment ? "xl" : "lg"}>
                            <ModalHeader toggle={() => this.setState({ importFromDepartmentModal: !this.state.importFromDepartmentModal, selectedDepartment: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ importFromDepartmentModal: !this.state.importFromDepartmentModal, selectedDepartment: null })}><Isvg src={xIcon} /></button>}>{'Import from department'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className='import-from-department-wrap'>
                                <div className='select-department'>
                                    <FormGroup>
                                        <Label>{'Select department'.translate(this.props.lang)}</Label>
                                        <Input type='select' value={this.state.selectedDepartment} onChange={(e) => {
                                            let val = e.target.value;
                                            if (val == -1) {
                                                val = null;
                                            }

                                            this.setState({ selectedDepartment: val })
                                        }}>
                                            <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                            {
                                                clinics && clinics.filter(el => el._id != this.props.selectedClinic).map((item, idx) => {
                                                    return (
                                                        <option value={item._id}>{item.name} - {item.subgroupName} </option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                </div>
                                {
                                    this.state.selectedDepartment ?
                                        <FormsListComponent
                                            {...this.props}
                                            type={'visit-forms'}
                                            selectedDepartment={this.state.selectedDepartment}
                                            closeComponent={() => {
                                                this.setState({ importFromDepartmentModal: null, selectedDepartment: null }, () => this.get())
                                            }}
                                            get={() => {
                                                this.get()
                                            }}
                                        />
                                        :
                                        null
                                }
                                <div>

                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' onClick={() => { this.setState({ importFromDepartmentModal: null, selectedDepartment: null }) }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }


            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(ReferralsList));