import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert,
    Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';

import { Link } from 'react-router-dom';

import ModalFormBuilder from '../../components/forms/modalFormBuilder';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import lIcon from '../../assets/svg/upitnik.svg'
import Comment from '../../assets/svg/koment.svg'
import Hart from '../../assets/svg/srce.svg';

import Isvg from 'react-inlinesvg'
import error from '../../assets/images/error.png';
import BankIdModal from '../../components/bankIdModal';
import InfoModal from '../../components/infoModal';


import ErrorModal from '../../components/errorModal';
import LogoHeader from '../../assets/svg/header-logo.png';
import LogoFooter from '../../assets/svg/footer-logo.png';
import Woman from '../../assets/svg/woman.png';
import i1 from '../../assets/svg/i1.png';
import i2 from '../../assets/svg/i2.png';
import i3 from '../../assets/svg/i3.png';
import locationIcon from '../../assets/svg/location.png'
import arrowLeftIcon from '../../assets/svg/arrow-left-icon.svg'

// import BankIDModal_v2 from '../../components/bankIDModal_v2';

import FooterComponent from './footerComponent';



import JsonToHtmlV2 from '../landingBuilder/jsonToHtml';
import defaultJSON_v2 from '../landingBuilder/default.json';

import defaultMainClinicGroup from '../landingBuilder/defaultMainClinicGroup.json';

import MainClinicLandingPage from '../../components/mainClinicLandingPage';
import CustomSelectWithSearch from '../../components/forms/fields/customSelectWithSearch';
import TwoFactorAuthModal from '../../components/TwoFactorAuthModal';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';

function prepareJSON(json) {
    let arr = [];
    for (var key in json) {
        arr.push({
            ...json[key],
            __id: key
        })
    }
    return arr;
}

export const isStringAValidObjectId = (string) => {
    if (!string || typeof string !== 'string') return false;
    const regex = /^[a-f\d]{24}$/i;
    return regex.test(String(string))
}

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function iosDevice() {
    //return true;
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


/**
* Landing page
* @author   Milan Stanojevic, Stefan Pantic, Aleksandar Dabic
*/
class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
            data: null,
            selectedClinic: null,
            estimatedWaitingTime: null,
            faceIdEnv: false,
            initialLanding: false,
            lastVisitedClinics: [],

            show2FAModal: false,
            queryUid: null
        };
    }

    setLastGroupAlias = () => {
        if (this.props.uData && this.props.uData.patientGroups && this.props.uData.patientGroups.length) {
            let check = false
            for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
                if (localStorage.groupAlias && localStorage.getItem('groupAlias') == this.props.uData.patientGroups[i].alias && this.props.uData.patientGroups[i]._noReferrals) {
                    localStorage.setItem('lastGroup', this.props.uData.patientGroups[i].alias);
                    break;
                }
            }

        }
    }

    get = () => {


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        let alias = this.props[0].match.params.alias;
        let mainAlias = this.props[0].match.params.mainAlias;





        if (mainAlias && alias) {
            localStorage.setItem('groupAlias', alias);

            this.checkVisitedGroups()

            this.forceUpdate();


            fetch(API_ENDPOINT + '/landing/page/clinic-group', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push(`/`);
                    /*this.setState({
                        error: result.error
                        
                    })*/
                } else {
                    // console.log(JSON.stringify(result.landingJSON, null, 4));
                    //console.log(JSON.stringify(result.loginJSON, null , 4));

                    this.setState({
                        data: result
                    }, () => {

                        // if (result.logoChat) {
                        //     let favicon = document.getElementById("favicon");
                        //     favicon.href = result.logoChat;

                        // } else {
                        //     favicon.href = '/favicon.ico';
                        // }

                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                }
            });

            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias, mainAlias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                } else {

                    if (result.favicon) {
                        let favicon = document.getElementById("favicon");
                        favicon.href = result.favicon;
                    } else if (result.logoChat) {
                        let favicon = document.getElementById("favicon");
                        favicon.href = result.logoChat;

                    } else {
                        favicon.href = '/favicon.ico';
                    }


                }
            });

        } else {
            localStorage.setItem('groupAlias', alias);
            this.checkVisitedGroups()
            this.forceUpdate();


            fetch(API_ENDPOINT + '/landing/page', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ alias })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.props[0].history.push(`/`);
                    /*this.setState({
                        error: result.error
                        
                    })*/
                } else {
                    // console.log(JSON.stringify(result.landingJSON, null, 4));
                    // console.log(JSON.stringify(result.loginJSON, null , 4));
                    if (result && result.landingJSON_v2) {
                        let backgroundColor = ""
                        for (const key in result.landingJSON_v2) {

                            if (result.landingJSON_v2[key]['parent'] === 'root' && result.landingJSON_v2[key]['visibility']) {
                                if (typeof window != 'undefined' && window.innerWidth < 480 && result.landingJSON_v2[key]['visibility']['xs']) {
                                    if (result.landingJSON_v2[key]['style']['backgroundColor']) {
                                        backgroundColor = result.landingJSON_v2[key]['style']['backgroundColor']
                                    } else {
                                        backgroundColor = ""
                                    }
                                } else if (typeof window != 'undefined' && window.innerWidth >= 480 && window.innerWidth < 1200 && result.landingJSON_v2[key]['visibility']['sm']) {
                                    if (result.landingJSON_v2[key]['style']['backgroundColor']) {
                                        backgroundColor = result.landingJSON_v2[key]['style']['backgroundColor']
                                    } else {
                                        backgroundColor = ""
                                    }
                                } else if (typeof window != 'undefined' && window.innerWidth >= 1200 && result.landingJSON_v2[key]['visibility']['lg']) {
                                    if (result.landingJSON_v2[key]['style']['backgroundColor']) {
                                        backgroundColor = result.landingJSON_v2[key]['style']['backgroundColor']
                                    } else {
                                        backgroundColor = ""
                                    }
                                }

                            }
                        }

                        this.setState({
                            landingBackgroundColor: backgroundColor
                        })
                    }

                    this.setState({
                        data: result,
                        faceIdEnv: result.clinicGroupFaceIdEnv
                    }, () => {
                        if (result.favicon) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.favicon;
                        } else if (result.logoChat) {
                            let favicon = document.getElementById("favicon");
                            favicon.href = result.logoChat;

                        } else {
                            favicon.href = '/favicon.ico';
                        }

                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                }
            });
        }

        this.setLastGroupAlias();



    }
    getLastVisitedClinics = () => {
        if (typeof window != 'undefined' && localStorage.lastVisitedClinics) {
            let lastVisitedClinics = JSON.parse(localStorage.lastVisitedClinics);
            if (lastVisitedClinics && lastVisitedClinics.length > 3) {
                let newArr = [lastVisitedClinics[0], lastVisitedClinics[1], lastVisitedClinics[2]]
                lastVisitedClinics = newArr;
            }
            let alias = this.props[0]?.match?.params?.alias;

            if (lastVisitedClinics && lastVisitedClinics.length) {
                fetch(API_ENDPOINT + '/users/clinic-groups/last/visited', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ lastVisitedClinics: lastVisitedClinics, alias: alias })
                }).then(res => res.json()).then((result) => {
                    if (result && result.length) {
                        let arr = [];
                        for (let i = 0; i < lastVisitedClinics.length; i++) {
                            if (result.filter(el => el._id == lastVisitedClinics[i]).length) {
                                arr.push(result.filter(el => el._id == lastVisitedClinics[i])[0])
                            }
                        }
                        this.setState({ lastVisitedClinics: arr })
                    }
                });
            }
        }
    }

    checkVisitedGroups = () => {
        setTimeout(() => {
            if (typeof window != 'undefined' && localStorage.groupAlias && this.props.uData && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (this.props.uData.userLevel == 20 && localStorage?.getItem?.('profile') == 'patient'))) {
                if (!this.props.uData.lastVisitedGroups || (this.props.uData.lastVisitedGroups && !this.props.uData.lastVisitedGroups.length) || (this.props.uData.lastVisitedGroups && this.props.uData.lastVisitedGroups[this.props.uData.lastVisitedGroups.length - 1].alias != localStorage.groupAlias)) {
                    this.props.checkVisitedGroups()

                }
            }
            if (this.state.data && this.state.data._id && !this.state.data.mainClinicGroup) {
                this.props.checkLastVisitedClinics(this.state.data._id)
            }
        }, 1000);
    }

    componentDidMount() {
        let params = getSearchParams(this.props[0].location.search);
        // console.log(params);
        if (params && params.qrCodeLink && params.stateForOpen) {

            if (decodeURIComponent(params.stateForOpen) === 'bankIdModal') {
                this.setState({
                    bankIdModal: true
                })
            } else if (decodeURIComponent(params.stateForOpen) === 'bankIdModalDaybook') {
                this.setState({
                    bankIdModalDaybook: true
                })
            } else if (decodeURIComponent(params.stateForOpen).indexOf('dropinBankId') !== -1) {
                this.setState({
                    bankIdModalDropIn: true,
                    selectedDropInForm: decodeURIComponent(params.stateForOpen).replace('dropinBankId', '')
                })
            } else if (decodeURIComponent(params.stateForOpen) === 'mainGroupLoginBankIdModal') {
                this.setState({
                    mainGroupLoginBankIdModal: true
                })
            } else if (decodeURIComponent(params.stateForOpen) === 'loginBankIdModal') {
                this.setState({
                    loginBankIdModal: true
                })
            } else if (decodeURIComponent(params.stateForOpen) === 'bankIdModal1') {
                this.setState({
                    bankIdModal1: true
                })
            } else if (decodeURIComponent(params.stateForOpen) === 'bankIdModalWaitingRoom') {
                this.setState({
                    bankIdModalWaitingRoom: true
                })
            }


        }
        //if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        window.addEventListener('resize', this.resizeWindow);

        this.get();

        this.getLastVisitedClinics()

        // if (this.props[0].location.state && typeof this.props[0].location.state.initialLanding == 'boolean') {
        //     this.setState({ initialLanding: this.props[0].location.state.initialLanding })
        // }

        if (this.props?.[0]?.location?.search && this.props[0].location.search.indexOf?.('uid') !== -1) {
            let s = this.props[0].location.search;
            let uid = s.split('uid=')[1];
            if (uid && uid.includes('&')) uid = uid.split('&')[0];

            if (uid && isStringAValidObjectId(uid)) {
                this.open2FAModal(uid);
            }
        }

        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }

    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        let windowHeight = this.state.windowHeight;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        } else if (typeof window != 'undefined' && window.innerHeight && Math.abs(windowHeight - window.innerHeight) > 50) {
            this.setState({ windowHeight: window.innerHeight })
        }

    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        // if (!prevState.data && this.state.data) {
        //     if (this.state.data.bundleId && typeof window !== 'undefined' && iosDevice()) {
        //         //this.appIframe.src = this.state.data.bundleId + '://';
        //         window.location.replace(this.state.data.bundleId + '://');
        //     }

        // }

        if (!prevProps.uData && this.props.uData) {
            this.setLastGroupAlias();
        }

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // console.log("------------\n\n", this.props[0].location.pathname, ' --> ' + prevProps[0].location.pathname, '\n\n\n')
            this.get();
        }

        if (typeof prevProps.selectedWaitingTimeType != 'undefined' && prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType) {
            if (this.props.selectedWaitingTimeType == 0) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 1) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 2) {
                this.props.changeMinValue('');
                this.props.changeMaxValue(0);
            } else if (this.props.selectedWaitingTimeType == 3) {
                this.props.changeMinValue(0);
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 4) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            } else if (this.props.selectedWaitingTimeType == 5) {
                this.props.changeMinValue('');
                this.props.changeMaxValue('');
            }
        }

        if (prevProps.uData != this.props.uData && this.props.uData && this.state.initialLanding) {
            this.setState({ initialLanding: false })
        }

        if (prevState.mainGroupLoginBankIdModal != this.state.mainGroupLoginBankIdModal && !this.state.mainGroupLoginBankIdModal && this.fetchMainClGroupBankIdLoginInterval) {
            clearTimeout(this.fetchMainClGroupBankIdLoginInterval)
        }


        if (typeof window != 'undefined' && !localStorage.groupAlias && this.props[0].match.params.alias) {
            localStorage.groupAlias = this.props[0].match.params.alias;
        }

        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }
    }



    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }
    checkDropInForms = () => {
        let alias = this.props[0].match.params.alias;
        // if (clinic) {
        //     this.setState({ clinicsForSelect: null, clinicSelectOpen: null })
        // }

        fetch(API_ENDPOINT + '/clinicGroup/check/drop-in/forms', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result && result.availableTerms) {
                this.setState({
                    checkWorkingHoursError: 'We are currently unable to accept your drop in referral. Please try again later within the enclosed working hours'.translate(this.props.lang),
                    checkWorkingHoursAvailableTerms: result.availableTerms
                })
            } else if (result && result.dropInForms && result.dropInForms.length) {
                this.setState({
                    dropInForms: result.dropInForms,
                    hideSearchField: result.hideSearchField,
                    selectedDropInForm: null,
                    dropInFormsSelectOpen: true
                })
            } else {
                this.setState({
                    infoMessage: 'There is no active Drop-in form'.translate(this.props.lang)
                })
            }
        });
    }
    checkDepartments = (clinic) => {
        let alias = this.props[0].match.params.alias;

        if (clinic) {
            this.setState({ clinicsForSelect: null, clinicSelectOpen: null })
        }

        fetch(API_ENDPOINT + '/clinicGroup/check/departments', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias, clinic })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result.canCreateDropIn) {
                fetch(API_ENDPOINT + '/referrals/check/estimated/time', {
                    method: 'POST',
                    headers: {
                        // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        alias: localStorage.getItem('groupAlias'),
                        clinic: clinic

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result) {
                        if (result.estimatedTime > 0 && this.state.dropInForms) {
                            this.setState({
                                estimatedWaitingTime: result.estimatedTime
                            }, () => {
                                this.forceUpdate()
                            })
                        } else {
                            this.continueDropIn()
                        }


                    }

                })




            } else if (result.canCreateDropIn === false) {
                this.setState({
                    checkWorkingHoursError: 'We are currently unable to accept your drop in referral. Please try again later within the enclosed working hours'.translate(this.props.lang),
                    checkWorkingHoursAvailableTerms: result.availableTerms
                })
            } else if (result.clinics && result.clinics.length) {
                this.setState({
                    clinicsForSelect: result.clinics,
                    selectedClinic: result.clinics[0]._id,
                    clinicSelectOpen: true
                })
            }
        });



    }

    webBookingCheck = () => {
        let alias = this.props[0].match.params.alias;

        fetch(API_ENDPOINT + '/clinicGroup/check/webbooking', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {

            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result.canCreateWebBooking) {
                if (this.props[0].match.params.mainAlias) {
                    // this.props[0].history.push({
                    //     pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/choose-time`,
                    //     state: { isDropIn: false }
                    // })
                    fetch(API_ENDPOINT + '/data/without-token/professions/all/' + this.props[0].match.params.alias, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            isDropIn: false,
                            uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                        })
                    }).then(res => res.json()).then((result) => {
                        if (result) {
                            let availableProfessions = [];
                            for (let i = 0; i < result.length; i++) {
                                availableProfessions.push({
                                    name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                                    value: result[i]._id
                                })
                            }
                            this.setState({ availableProfessions }, () => this.getPersonalQuestionaries())
                        }
                    })
                } else {

                    // this.props[0].history.push({
                    //     pathname: `/${this.props[0].match.params.alias}/choose-time`,
                    //     state: { isDropIn: false }
                    // })
                    fetch(API_ENDPOINT + '/data/without-token/professions/all/' + this.props[0].match.params.alias, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            isDropIn: false,
                            uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                        })
                    }).then(res => res.json()).then((result) => {
                        if (result) {
                            let availableProfessions = [];
                            for (let i = 0; i < result.length; i++) {
                                availableProfessions.push({
                                    name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                                    value: result[i]._id
                                })
                            }

                            this.setState({ availableProfessions }, () => this.getPersonalQuestionaries())

                        }



                    })
                }
            } else if (result.canCreateWebBooking === false) {
                this.setState({
                    checkWebBookingError: 'There are no available times to book.'.translate(this.props.lang),
                })
            }
        });



    }

    openCalendar = () => {
        // if (this.state.selectedReferral && this.state.referral && this.state.clinicGroup && typeof window !== 'undefined') {
        //     localStorage.setItem('tempSelectedReferral', JSON.stringify(this.state.selectedReferral))
        //     localStorage.setItem('tempReferral', JSON.stringify(this.state.referral))
        //     localStorage.setItem('tempClinicGroup', JSON.stringify(this.state.clinicGroup))
        // }
        if (this.props[0].match.params.mainAlias) {
            this.props[0].history.push({
                pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/choose-time`,
                state: {
                    selectedReferral: this.state.selectedReferral,
                    referral: this.state.referral,
                    clinicGroup: this.state.clinicGroup
                }
            })
        } else {

            // if (this.state.selectedReferral && this.state.referral && this.state.clinicGroup && typeof window !== 'undefined') {
            //     localStorage.setItem('tempSelectedReferral', JSON.stringify(this.state.selectedReferral))
            //     localStorage.setItem('tempReferral', JSON.stringify(this.state.referral))
            //     localStorage.setItem('tempClinicGroup', JSON.stringify(this.state.clinicGroup))
            // }
            this.props[0].history.push({
                pathname: `/${this.props[0].match.params.alias}/choose-time`,
                state: {
                    selectedReferral: this.state.selectedReferral,
                    referral: this.state.referral,
                    clinicGroup: this.state.clinicGroup
                }
            })
        }
    }

    getPersonalQuestionaries = () => {
        if (!this.state.availableProfessions || (this.state.availableProfessions && this.state.availableProfessions.length == 0)) {
            this.setState({ referrals: [] })
        } else {
            fetch(API_ENDPOINT + '/data/without-token/personal-questionary/all/' + this.props[0].match.params.alias, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    availableProfessions: this.state.availableProfessions,
                    page: this.state.page,
                    filter: this.state.searchQuestionaries ? this.state.searchQuestionaries : '',
                })
            }).then(res => res.json()).then((result) => {
                // clinicGroup: clinicGroup,
                // referrals: referrals
                if (result.clinicGroup) {
                    this.setState({ clinicGroup: result.clinicGroup })
                }
                if (result.referrals) {
                    this.setState({ referrals: result.referrals }, this.checkSearchedReferrals)
                }
                if (result.total) {
                    this.setState({ total: result.total })
                } else {
                    this.setState({ total: 0 })
                }

            })

        }
    }
    checkSearchedReferrals = () => {
        let searchedReferrals = []
        if (this.state.referrals && this.state.referrals.length) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.searchQuestionaries) {
                    let name = this.state.referrals[i].name;
                    let anotherName = this.state.referrals[i].anotherName;
                    name = name.toLowerCase()
                    anotherName = anotherName.toLowerCase()
                    let searchQuestionaries = this.state.searchQuestionaries;
                    searchQuestionaries = searchQuestionaries.toLowerCase()
                    if ((name.indexOf(searchQuestionaries) != -1 || anotherName.indexOf(searchQuestionaries) != -1) && searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0)
                        searchedReferrals.push(this.state.referrals[i])
                } else if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                    searchedReferrals.push(this.state.referrals[i])
                }
                // if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                //     searchedReferrals.push(this.state.referrals[i])
                // }

            }
        }
        this.setState({ searchedReferrals })
    }
    continueDropIn = () => {
        if (this.props.uData) {
            this.props.registerClinicGroupLogs({
                type: 'startCreateReferral',
                referralType: 'Drop in',
                questionaryId: this.state.selectedDropInForm
            })
            if (this.props[0].match.params.mainAlias) {

                this.props[0].history.push({
                    pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                    state: { isDropIn: true, selectedDropInForm: this.state.selectedDropInForm }
                })
            } else {
                this.props[0].history.push({
                    pathname: `/${this.props[0].match.params.alias}/drop-in`,
                    state: { isDropIn: true, selectedDropInForm: this.state.selectedDropInForm }
                })
            }


        } else {

            this.setState({
                bankIdModalDropIn: true
            }, () => {
                // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                //     method: 'POST',
                //     headers: {
                //         'content-type': 'application/json'
                //     },
                //     body: JSON.stringify({ group: this.state.data._id })
                // }).then(res => res.json()).then((result) => {
                //     if (result.redirect_url) {
                //         this.setState({

                //             bankIdModalDropIn: result.redirect_url
                //         })
                //     }
                // })
                this.fetchBankIdDropIn()
            })

        }
    }

    fetchBankIdDropIn = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             bankIdModalDropIn: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankIdDropIn()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdModalDropIn: true
        })
    }
    fetchBankIdLogin = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             loginBankIdModal: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankIdLogin()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            loginBankIdModal: true
        })

    }
    fetchMainClGroupBankIdLogin = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             mainGroupLoginBankIdModal: result.redirect_url
        //         }, () => {
        //             this.fetchMainClGroupBankIdLoginInterval = setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchMainClGroupBankIdLogin()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            mainGroupLoginBankIdModal: true
        })

    }
    fetchBankId1 = () => {

        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             bankIdModal1: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankId1()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdModal1: true
        })

    }


    fetchBankId = () => {
        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             bankIdModal: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankId()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdModal: true
        })
    }
    fetchBankIdDaybook = () => {

        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify({ group: this.state.data._id })
        // }).then(res => res.json()).then((result) => {
        //     if (result.redirect_url) {
        //         this.setState({

        //             bankIdModalDaybook: result.redirect_url
        //         }, () => {
        //             setTimeout(() => {
        //                 if (!this.props.uData) {
        //                     this.fetchBankIdDaybook()
        //                 }
        //             }, 1000 * 60 * 4);
        //         })
        //     }
        // })
        this.setState({
            bankIdModalDaybook: true
        })

    }








    setLocalStorageGroupAlias = () => {
        let alias;
        if (this.props[0].match.params.alias) {
            alias = this.props[0].match.params.alias;
        }
        if (alias && typeof window != 'undefined') {
            localStorage.groupAlias = alias;
        }
    }

    open2FAModal = (queryUid) => this.setState({
        show2FAModal: true,
        queryUid
    })

    close2FAModal = () => this.setState({ show2FAModal: false, uid: null });

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }

    render() {
        if (!this.state.data) {
            return null;
        }
        if (typeof window == 'undefined')
            return null;

        if (this.state.initialLanding && !this.props.uData && this.state.data.mainClinicGroup/* && typeof window != 'undefined' && (!localStorage.disableInitialLanding || localStorage.disableInitialLanding != this.state.data._id)*/) {
            return (
                <div>
                    <MainClinicLandingPage {...this.props}
                        bankIdLogin={() => {
                            this.setState({
                                loginBankIdModal: true
                            }, () => {


                                this.fetchBankIdLogin()
                            })
                        }}
                        data={this.state.data}
                        changeInitialLanding={(initialLanding) => this.setState({ initialLanding }, () => {
                            let disableInitialLanding = this.state.data._id;
                            if (typeof window != 'undefined') {
                                localStorage.setItem('disableInitialLanding', disableInitialLanding);
                            }
                        })}
                    />
                    {/* {
                        this.state.loginBankIdModal ?
                            <BankIdModal
                                {...this.props}
                                isOpen={this.state.loginBankIdModal}
                                faceIdEnv={this.state.faceIdEnv}
                                checkLastLogin={true}
                                openLastLoginLanding={true}
                                clinicGroupsItems={this.state.data.clinicGroupsItems ? this.state.data.clinicGroupsItems : []}
                                toggle={(alias) => {

                                    if (this.props.uData) {
                                        this.props.registerClinicGroupLogs({
                                            type: 'login',
                                            loginType: 'bankid'
                                        })
                                        let link = '/';
                                        if (this.props[0].match.params.alias) {
                                            link += `${this.props[0].match.params.alias}`
                                        }
                                        if (alias) {
                                            link += '/';
                                            link += alias;
                                        }

                                        this.props[0].history.push(link)
                                        // localStorage.setItem('groupAlias', alias);

                                    }
                                    this.setState({ loginBankIdModal: null })

                                }}
                                redirect_url={this.state.loginBankIdModal}></BankIdModal>
                            :
                            null
                    } */}
                    {
                        this.state.show2FAModal && this.state.queryUid ? <TwoFactorAuthModal
                            secondaryLanguageDisabled={this.props.secondaryLanguageDisabled}
                            countryTwoLetter={this.props.countryTwoLetter}
                            country={this.props.country}
                            lang={this.props.lang}
                            closeModal={this.close2FAModal}
                            uid={this.state.queryUid}
                            show2FAModal={this.state.show2FAModal}
                            type='verify-account'
                            alias={this.props[0]?.match?.params?.alias}
                        /> : null
                    }
                </div>
            )
        } else {

            let backToMain = 'Back to main clinic group'.translate(this.props.lang);
            if (this.state.data && this.state.data.backToMain) {
                if (this.props.lang == 'en' && this.state.data.backToMain.backToMainEn) {
                    backToMain = this.state.data.backToMain.backToMainEn;
                } else if (this.props.lang == 'se' && this.state.data.backToMain.backToMainSv) {
                    backToMain = this.state.data.backToMain.backToMainSv;
                }
            }

            let maxHeight = typeof window !== 'undefined' && document.getElementById('main-container')?.offsetHeight;
            let shameStyle = this.state.selectdLandingConfig && this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground } : {}
            if (maxHeight) {
                // shameStyle.maxHeight = maxHeight;
                shameStyle.overflow = 'hidden';
            }


            return (

                <div className={this.state.data.mainClinicGroup ? "shame shame-overflow-h" : "shame"} style={shameStyle}>
                    {
                        this.props[0].match.params.mainAlias ?
                            <div className='landing-back-to-main-group-wrap'>
                                <div className='landing-back-to-main-group' onClick={() => {
                                    this.props[0].history.push({ pathname: `/${this.props[0].match.params.mainAlias}`, state: { initialLanding: false } })
                                }}>
                                    <Isvg src={arrowLeftIcon} /> {backToMain}
                                </div>
                            </div>
                            :
                            null

                    }

                    {this.state.estimatedWaitingTime ?
                        <InfoModal
                            isOpen={this.state.estimatedWaitingTime}
                            // No onClose prop in InfoModal
                            // onClose={(item) => {
                            //     this.setState({
                            //         estimatedWaitingTime: item
                            //     })
                            // }}
                            close={true}
                            header={'Info'.translate(this.props.lang)}
                            info={'Estimated waiting time is'.translate(this.props.lang) + ' ' + (this.state.estimatedWaitingTime ? parseInt(this.state.estimatedWaitingTime / 60) == 0 ? this.state.estimatedWaitingTime + ' ' + 'minutes'.translate(this.props.lang) : + parseInt(this.state.estimatedWaitingTime / 60) + 'h'.translate(this.props.lang) + ' ' + 'and'.translate(this.props.lang) + ' ' + this.state.estimatedWaitingTime % 60 + ' ' + 'minutes'.translate(this.props.lang) : '/')}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ estimatedWaitingTime: null }, () => {
                                    this.continueDropIn()
                                })}>{'Continue'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    this.setState({ estimatedWaitingTime: null, dropInFormsSelectOpen: null })
                                    // if (this.props[0].match.params.mainAlias) {
                                    //     this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`)
                                    // } else {
                                    //     this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                    // }
                                }}>{'Cancel'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        : null}

                    {
                        this.state.clinicsForSelect ?
                            <Modal isOpen={this.state.clinicSelectOpen} centered>
                                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                    {'Select department'.translate(this.props.lang)}
                                </ModalHeader>
                                <ModalBody className="delete-modal">
                                    <Input type='select' value={this.state.selectedClinic} onChange={(e) => {
                                        this.setState({ selectedClinic: e.target.value })
                                    }}>
                                        {
                                            this.state.clinicsForSelect.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}, {item.subgroupName}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </ModalBody>
                                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button color='primary' onClick={() => {
                                        this.checkDepartments(this.state.selectedClinic)
                                    }}>{'Ok'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }
                    {
                        this.state.dropInForms ?
                            <Modal isOpen={this.state.dropInFormsSelectOpen} centered>
                                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                    {'What do you need help with?'.translate(this.props.lang)}
                                </ModalHeader>
                                <ModalBody className="delete-modal">
                                    <CustomSelectWithSearch
                                        isInputClearable={true}
                                        lang={this.props.lang}
                                        items={this.state.dropInForms.map((item, idx) => {
                                            return {
                                                name: item.name,
                                                value: item._id
                                            }
                                        })}
                                        value={this.state.selectedDropInForm}
                                        hideSearchField={this.state.hideSearchField}
                                        maxHeight={'50vh'}
                                        onChange={(e) => {
                                            if (e && e.value && this.state.dropInForms.filter(item => item._id === e.value).length) {
                                                this.setState({ selectedDropInForm: e.value }, () => {
                                                    let clinic;
                                                    if (this.state.dropInForms && this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm).length) {
                                                        clinic = this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm)[0].clinic;
                                                    }
                                                    if (clinic) {
                                                        this.checkDepartments(clinic)
                                                    }
                                                })
                                            } else {
                                                this.setState({ selectedDropInForm: null })
                                            }


                                        }}
                                    />
                                    {/* <Input type='select' value={this.state.selectedDropInForm} onChange={(e) => {
                                        console.log(e.target.value)
                                        if (e.target.value && e.target.value != 'Select'.translate(this.props.lang)) {
                                            this.setState({ selectedDropInForm: e.target.value })
                                        } else {
                                            this.setState({ selectedDropInForm: null })
                                        }

                                    }}>
                                        <option value={null}>{'Select'.translate(this.props.lang)}</option>
                                        {
                                            this.state.dropInForms.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input> */}
                                </ModalBody>
                                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                                    {/* <Button color='primary' disabled={!this.state.selectedDropInForm} onClick={() => {
                                        let clinic;
                                        if (this.state.dropInForms && this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm).length) {
                                            clinic = this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm)[0].clinic;
                                        }
                                        if (clinic) {
                                            this.checkDepartments(clinic)
                                        }

                                    }}>{'Ok'.translate(this.props.lang)}</Button> */}
                                    <Button color='primary' onClick={() => {
                                        this.setState({ dropInFormsSelectOpen: false })
                                    }}>{'Cancel'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }
                    {
                        this.state.infoMessage ?
                            <InfoModal
                                isOpen={this.state.infoMessage}
                                onClose={(item) => {
                                    this.setState({
                                        infoMessage: item
                                    })
                                }}
                                close={true}
                                header={'Info'.translate(this.props.lang)}
                                info={this.state.infoMessage}
                                buttons={[
                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ infoMessage: false }, () => {

                                        // let url = '/';
                                        // if (this.props[0].match.params.mainAlias) {
                                        //     url += this.props[0].match.params.mainAlias + '/';
                                        // }
                                        // url += this.props[0].match.params.alias;
                                        // this.props[0].history.push(url)
                                    }
                                    )}>{'Ok'.translate(this.props.lang)}</Button>,

                                ]}
                            />
                            :
                            null
                    }

                    {
                        this.state.checkWebBookingError ?
                            <InfoModal
                                isOpen={this.state.checkWebBookingError}
                                onClose={(item) => {
                                    this.setState({
                                        checkWebBookingError: item
                                    })
                                }}
                                close={true}
                                header={'Info'.translate(this.props.lang)}
                                info={this.state.checkWebBookingError}
                                buttons={[
                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkWebBookingError: false }, () => {

                                        // let url = '/';
                                        // if (this.props[0].match.params.mainAlias) {
                                        //     url += this.props[0].match.params.mainAlias + '/';
                                        // }
                                        // url += this.props[0].match.params.alias;
                                        // this.props[0].history.push(url)
                                    }
                                    )}>{'Go back'.translate(this.props.lang)}</Button>,

                                ]}
                            />
                            :
                            null
                    }

                    {
                        this.state.checkWorkingHoursError ?
                            <InfoModal
                                isOpen={this.state.checkWorkingHoursError}
                                onClose={(item) => {
                                    this.setState({
                                        checkWorkingHoursError: item
                                    })
                                }}
                                close={true}
                                header={'Info'.translate(this.props.lang)}
                                info={
                                    <>
                                        <span style={{ fontWeight: 600 }}>{this.state.checkWorkingHoursError}</span>
                                        <div className='table-error-dropin'>
                                            {
                                                this.state.checkWorkingHoursAvailableTerms && this.state.checkWorkingHoursAvailableTerms.map((item, idx) => {
                                                    const startTime = this.props.getStringDate(item.startTimeUTC, 'HH:mm');
                                                    // console.log(idx, startTime, item.startTimeUTC);
                                                    const endTime = this.props.getStringDate(item.endTimeUTC, 'HH:mm');
                                                    const timeUTC = `${startTime} - ${endTime}`
                                                    return (


                                                        idx == 0 ?
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Monday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                            :
                                                            idx == 1 ?
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Tuesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                :
                                                                idx == 2 ?
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Wednesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                    :
                                                                    idx == 3 ?
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Thursday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                        :
                                                                        idx == 4 ?
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Friday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                            :
                                                                            idx == 5 ?
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Saturday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                                :
                                                                                idx == 6 ?
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Sunday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                                    :
                                                                                    null


                                                    )
                                                })
                                            }
                                        </div>
                                    </>

                                }
                                buttons={[
                                    <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkWorkingHoursError: false }, () => {
                                        this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                    })}>{'Ok'.translate(this.props.lang)}</Button>,

                                ]}
                            />
                            :
                            null
                    }
                    {this.state.error ? null :
                        <Container fluid id="main-container" style={this.state.landingBackgroundColor ? { height: '100%', backgroundColor: this.state.landingBackgroundColor } : { height: '100%', backgroundColor: 'white' }}>
                            <Row className={!this.props[0].match.params.mainAlias ? "main-container-wrap" : "main-container-wrap-v2"}>
                                <Col lg="12" className="p-0" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="landing-page-container">
                                        {
                                            typeof window != 'undefined' && window.innerWidth > 480 ?


                                                <div style={{ marginTop: this.props.uData && typeof window != 'undefined' && window.innerWidth < 1200 ? 70 : 0 }}>

                                                    {
                                                        !this.state.data.mainClinicGroup ?
                                                            <div className='landing-header' >
                                                                {
                                                                    !this.state.data.mainClinicGroup || (this.state.data.mainClinicGroup && this.state.data.showHeader) ?
                                                                        <Container>
                                                                            <Row>
                                                                                <Col lg="12">
                                                                                    <div className="login-header">


                                                                                        <div className="logo">
                                                                                            <img src={API_ENDPOINT + this.state.data.logo} className="landing-page-logo" />
                                                                                        </div>


                                                                                        <div className="navigation">
                                                                                            {
                                                                                                this.state.data && !this.state.data.mainClinicGroup ?
                                                                                                    <>
                                                                                                        {this.props.uData ?
                                                                                                            <div>
                                                                                                                <span className="username">{this.props.uData.userData.name}</span>
                                                                                                                <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                                                                    onMouseEnter={() => {
                                                                                                                        let data = this.state.data;
                                                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                                                        this.setState({
                                                                                                                            data
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    onMouseLeave={() => {
                                                                                                                        let data = this.state.data;
                                                                                                                        data.loginOutline = !this.state.data.loginOutline
                                                                                                                        this.setState({
                                                                                                                            data
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</button>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <button className={'login-button-landing-page'} style={this.state.data.loginOutline ? { backgroundColor: 'transparent', border: `1px solid ${this.state.data.loginColor}`, color: this.state.data.loginColor } : { background: this.state.data.loginColor, border: `1px solid transparent`, color: 'white' }}
                                                                                                                onMouseEnter={() => {
                                                                                                                    let data = this.state.data;
                                                                                                                    data.loginOutline = !this.state.data.loginOutline
                                                                                                                    this.setState({
                                                                                                                        data
                                                                                                                    })
                                                                                                                }}
                                                                                                                onMouseLeave={() => {
                                                                                                                    let data = this.state.data;
                                                                                                                    data.loginOutline = !this.state.data.loginOutline
                                                                                                                    this.setState({
                                                                                                                        data
                                                                                                                    })
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        loginBankIdModal: true
                                                                                                                    }, () => {

                                                                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                                                                        //     method: 'POST',
                                                                                                                        //     headers: {
                                                                                                                        //         'content-type': 'application/json'
                                                                                                                        //     },
                                                                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                                                                        // }).then(res => res.json()).then((result) => {
                                                                                                                        //     if (result.redirect_url) {
                                                                                                                        //         this.setState({

                                                                                                                        //             loginBankIdModal: result.redirect_url
                                                                                                                        //         })
                                                                                                                        //     }
                                                                                                                        // })
                                                                                                                        // this.fetchBankIdLogin()
                                                                                                                    })

                                                                                                                }}>{'Login'.translate(this.props.lang)}</button>
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                        </div>
                                                                                    </div>

                                                                                </Col>
                                                                            </Row>


                                                                        </Container>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }




                                                </div>



                                                :
                                                null
                                        }
                                    </div>


                                    {/* Landing style v2 */}
                                    <div>
                                        {
                                            typeof window != 'undefined' && window.innerWidth < 480 ?
                                                this.state.data && this.state.data.landingJSON_v2 ?
                                                    <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                        <JsonToHtmlV2
                                                            layout={'xs'}
                                                            nodes={JSON.parse(JSON.stringify(this.state.data.landingJSON_v2))}
                                                            uData={this.props.uData}
                                                            getSelectedItem={(item) => {
                                                                this.setState({ selectdLandingConfig: item })
                                                            }}
                                                            {...this.props}
                                                            data={this.state.data}
                                                            dropInButtonShow={this.state.data.dropInButtonShow}
                                                            lastVisitedClinics={this.state.lastVisitedClinics}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                }
                                                            }}
                                                            loginBtn={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             loginBankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                } else {
                                                                    this.props.signOut()
                                                                }

                                                            }}
                                                            myReferralHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                        this.setState({
                                                                            error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                            bankIdModal1: null
                                                                        })
                                                                        return;
                                                                    }
                                                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal1: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal1: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId1()
                                                                    })
                                                                }
                                                            }}
                                                            loginBankId={() => {
                                                                this.setState({
                                                                    mainGroupLoginBankIdModal: true
                                                                }, () => {


                                                                    this.fetchMainClGroupBankIdLogin()
                                                                })
                                                            }}
                                                            informationHandler={() => {
                                                                if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                    window.open(this.state.data.informationLink, '_blank');
                                                                else {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    } else {
                                                                        window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    }
                                                                }

                                                            }}
                                                            seekCareHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                    } else {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                            daybookHandler={() => {
                                                                this.setLocalStorageGroupAlias();
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push({
                                                                        pathname: `/my-data/account`,
                                                                        state: { tabIndex: 2 }
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalDaybook: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModalDaybook: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdDaybook()
                                                                    })
                                                                }
                                                            }}
                                                            dropInHandler={() => {
                                                                //if (this.props.uData) {
                                                                // if (this.props[0].match.params.mainAlias) {
                                                                //     this.props[0].history.push({
                                                                //         pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                //         state: { isDropIn: true }
                                                                //     })
                                                                // } else {
                                                                //     this.props[0].history.push({
                                                                //         pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                //         state: { isDropIn: true }
                                                                //     })
                                                                // }

                                                                //} else {

                                                                this.checkDropInForms();
                                                                // this.checkDepartments();

                                                                // this.setState({
                                                                //     bankIdModalDropIn: true
                                                                // }, () => {
                                                                //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                //         method: 'POST',
                                                                //         headers: {
                                                                //             'content-type': 'application/json'
                                                                //         },
                                                                //         body: JSON.stringify({ group: this.state.data._id })
                                                                //     }).then(res => res.json()).then((result) => {
                                                                //         if (result.redirect_url) {
                                                                //             this.setState({

                                                                //                 bankIdModalDropIn: result.redirect_url
                                                                //             })
                                                                //         }
                                                                //     })
                                                                // })
                                                                //}
                                                            }}
                                                            chooseTimeHandle={() => {
                                                                this.webBookingCheck()
                                                            }}

                                                            waitingRoomHandler={() => {
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push(`/waiting-room`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalWaitingRoom: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                        <JsonToHtmlV2

                                                            layout={'xs'}
                                                            nodes={this.state.data && this.state.data.mainClinicGroup ? JSON.parse(JSON.stringify(defaultMainClinicGroup)) : JSON.parse(JSON.stringify(defaultJSON_v2))}
                                                            uData={this.props.uData}
                                                            loginBankId={() => {
                                                                this.setState({
                                                                    mainGroupLoginBankIdModal: true
                                                                }, () => {


                                                                    this.fetchMainClGroupBankIdLogin()
                                                                })
                                                            }}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                }
                                                            }}
                                                            lastVisitedClinics={this.state.lastVisitedClinics}
                                                            getSelectedItem={(item) => {
                                                                this.setState({ selectdLandingConfig: item })
                                                            }}
                                                            {...this.props}
                                                            data={this.state.data}
                                                            dropInButtonShow={this.state.data.dropInButtonShow}
                                                            loginBtn={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             loginBankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                } else {
                                                                    this.props.signOut()
                                                                }

                                                            }}
                                                            myReferralHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                        this.setState({
                                                                            error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                            bankIdModal1: null
                                                                        })
                                                                        return;
                                                                    }
                                                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal1: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal1: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId1()
                                                                    })
                                                                }
                                                            }}
                                                            informationHandler={() => {
                                                                if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                    window.open(this.state.data.informationLink, '_blank');
                                                                else {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    } else {
                                                                        window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    }
                                                                }
                                                            }}
                                                            seekCareHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                    } else {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                            daybookHandler={() => {
                                                                this.setLocalStorageGroupAlias();
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push({
                                                                        pathname: `/my-data/account`,
                                                                        state: { tabIndex: 2 }
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalDaybook: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModalDaybook: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdDaybook()
                                                                    })
                                                                }
                                                            }}
                                                            dropInHandler={() => {
                                                                // if (this.props.uData) {
                                                                //     if (this.props[0].match.params.mainAlias) {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     } else {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     }
                                                                // } else {

                                                                this.checkDropInForms();
                                                                // this.checkDepartments();

                                                                // this.setState({
                                                                //     bankIdModalDropIn: true
                                                                // }, () => {
                                                                //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                //         method: 'POST',
                                                                //         headers: {
                                                                //             'content-type': 'application/json'
                                                                //         },
                                                                //         body: JSON.stringify({ group: this.state.data._id })
                                                                //     }).then(res => res.json()).then((result) => {
                                                                //         if (result.redirect_url) {
                                                                //             this.setState({

                                                                //                 bankIdModalDropIn: result.redirect_url
                                                                //             })
                                                                //         }
                                                                //     })
                                                                // })
                                                                //}
                                                            }}
                                                            chooseTimeHandle={() => {
                                                                this.webBookingCheck()
                                                            }}

                                                            waitingRoomHandler={() => {
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push(`/waiting-room`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalWaitingRoom: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                :
                                                typeof window != 'undefined' && window.innerWidth >= 480 && window.innerWidth < 1200 ?
                                                    this.state.data && this.state.data.landingJSON_v2 ?
                                                        <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                            <JsonToHtmlV2
                                                                getSelectedItem={(item) => {
                                                                    this.setState({ selectdLandingConfig: item })
                                                                }}
                                                                layout={'sm'}
                                                                nodes={JSON.parse(JSON.stringify(this.state.data.landingJSON_v2))}
                                                                loginBankId={() => {
                                                                    this.setState({
                                                                        mainGroupLoginBankIdModal: true
                                                                    }, () => {


                                                                        this.fetchMainClGroupBankIdLogin()
                                                                    })
                                                                }}
                                                                lastVisitedClinics={this.state.lastVisitedClinics}
                                                                loginHandler={() => {
                                                                    if (!this.props.uData) {
                                                                        this.setState({
                                                                            loginBankIdModal: true
                                                                        }, () => {

                                                                            this.fetchBankIdLogin()
                                                                        })
                                                                    }
                                                                }}
                                                                uData={this.props.uData}
                                                                {...this.props}
                                                                data={this.state.data}
                                                                dropInButtonShow={this.state.data.dropInButtonShow}
                                                                loginBtn={() => {
                                                                    if (!this.props.uData) {
                                                                        this.setState({
                                                                            loginBankIdModal: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             loginBankIdModal: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankIdLogin()
                                                                        })
                                                                    } else {
                                                                        this.props.signOut()
                                                                    }

                                                                }}
                                                                myReferralHandler={() => {
                                                                    if (this.props.uData) {
                                                                        if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                            this.setState({
                                                                                error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                                bankIdModal1: null
                                                                            })
                                                                            return;
                                                                        }
                                                                        this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModal1: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModal1: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankId1()
                                                                        })
                                                                    }
                                                                }}
                                                                informationHandler={() => {
                                                                    if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                        window.open(this.state.data.informationLink, '_blank');
                                                                    else {
                                                                        if (this.props[0].match.params.mainAlias) {
                                                                            window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                        } else {
                                                                            window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                        }
                                                                    }
                                                                }}
                                                                seekCareHandler={() => {
                                                                    if (this.props.uData) {
                                                                        if (this.props[0].match.params.mainAlias) {
                                                                            this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                        } else {
                                                                            this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                        }
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModal: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModal: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankId()
                                                                        })
                                                                    }
                                                                }}
                                                                daybookHandler={() => {
                                                                    this.setLocalStorageGroupAlias();
                                                                    if (this.props.uData) {
                                                                        this.props[0].history.push({
                                                                            pathname: `/my-data/account`,
                                                                            state: { tabIndex: 2 }
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModalDaybook: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModalDaybook: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankIdDaybook()
                                                                        })
                                                                    }
                                                                }}
                                                                dropInHandler={() => {
                                                                    // if (this.props.uData) {
                                                                    //     if (this.props[0].match.params.mainAlias) {
                                                                    //         this.props[0].history.push({
                                                                    //             pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                    //             state: { isDropIn: true }
                                                                    //         })
                                                                    //     } else {
                                                                    //         this.props[0].history.push({
                                                                    //             pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                    //             state: { isDropIn: true }
                                                                    //         })
                                                                    //     }
                                                                    // } else {

                                                                    this.checkDropInForms();
                                                                    // this.checkDepartments();

                                                                    // this.setState({
                                                                    //     bankIdModalDropIn: true
                                                                    // }, () => {
                                                                    //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                    //         method: 'POST',
                                                                    //         headers: {
                                                                    //             'content-type': 'application/json'
                                                                    //         },
                                                                    //         body: JSON.stringify({ group: this.state.data._id })
                                                                    //     }).then(res => res.json()).then((result) => {
                                                                    //         if (result.redirect_url) {
                                                                    //             this.setState({

                                                                    //                 bankIdModalDropIn: result.redirect_url
                                                                    //             })
                                                                    //         }
                                                                    //     })
                                                                    // })
                                                                    // }
                                                                }}
                                                                chooseTimeHandle={() => {
                                                                    this.webBookingCheck()
                                                                }}

                                                                waitingRoomHandler={() => {
                                                                    if (this.props.uData) {

                                                                        this.props[0].history.push(`/waiting-room`)
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModalWaitingRoom: true
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div style={{ marginTop: this.props.uData ? 70 : 0 }}>
                                                            <JsonToHtmlV2
                                                                layout={'sm'}
                                                                nodes={this.state.data && this.state.data.mainClinicGroup ? JSON.parse(JSON.stringify(defaultMainClinicGroup)) : JSON.parse(JSON.stringify(defaultJSON_v2))}
                                                                uData={this.props.uData}
                                                                loginBankId={() => {
                                                                    this.setState({
                                                                        mainGroupLoginBankIdModal: true
                                                                    }, () => {


                                                                        this.fetchMainClGroupBankIdLogin()
                                                                    })
                                                                }}
                                                                loginHandler={() => {
                                                                    if (!this.props.uData) {
                                                                        this.setState({
                                                                            loginBankIdModal: true
                                                                        }, () => {

                                                                            this.fetchBankIdLogin()
                                                                        })
                                                                    }
                                                                }}
                                                                lastVisitedClinics={this.state.lastVisitedClinics}
                                                                getSelectedItem={(item) => {
                                                                    this.setState({ selectdLandingConfig: item })
                                                                }}
                                                                {...this.props}
                                                                data={this.state.data}
                                                                dropInButtonShow={this.state.data.dropInButtonShow}
                                                                loginBtn={() => {
                                                                    if (!this.props.uData) {
                                                                        this.setState({
                                                                            loginBankIdModal: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             loginBankIdModal: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankIdLogin()
                                                                        })
                                                                    } else {
                                                                        this.props.signOut()
                                                                    }

                                                                }}
                                                                myReferralHandler={() => {
                                                                    if (this.props.uData) {
                                                                        if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                            this.setState({
                                                                                error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                                bankIdModal1: null
                                                                            })
                                                                            return;
                                                                        }
                                                                        this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModal1: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModal1: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankId1()
                                                                        })
                                                                    }
                                                                }}
                                                                informationHandler={() => {
                                                                    if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                        window.open(this.state.data.informationLink, '_blank');
                                                                    else {
                                                                        if (this.props[0].match.params.mainAlias) {
                                                                            window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                        } else {
                                                                            window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                        }
                                                                    }
                                                                }}
                                                                seekCareHandler={() => {
                                                                    if (this.props.uData) {
                                                                        if (this.props[0].match.params.mainAlias) {
                                                                            this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                        } else {
                                                                            this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                        }
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModal: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModal: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankId()
                                                                        })
                                                                    }
                                                                }}
                                                                daybookHandler={() => {
                                                                    this.setLocalStorageGroupAlias();
                                                                    if (this.props.uData) {
                                                                        this.props[0].history.push({
                                                                            pathname: `/my-data/account`,
                                                                            state: { tabIndex: 2 }
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModalDaybook: true
                                                                        }, () => {
                                                                            // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                            //     method: 'POST',
                                                                            //     headers: {
                                                                            //         'content-type': 'application/json'
                                                                            //     },
                                                                            //     body: JSON.stringify({ group: this.state.data._id })
                                                                            // }).then(res => res.json()).then((result) => {
                                                                            //     if (result.redirect_url) {
                                                                            //         this.setState({

                                                                            //             bankIdModalDaybook: result.redirect_url
                                                                            //         })
                                                                            //     }
                                                                            // })
                                                                            this.fetchBankIdDaybook()
                                                                        })
                                                                    }
                                                                }}
                                                                dropInHandler={() => {
                                                                    // if (this.props.uData) {
                                                                    //     if (this.props[0].match.params.mainAlias) {
                                                                    //         this.props[0].history.push({
                                                                    //             pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                    //             state: { isDropIn: true }
                                                                    //         })
                                                                    //     } else {
                                                                    //         this.props[0].history.push({
                                                                    //             pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                    //             state: { isDropIn: true }
                                                                    //         })
                                                                    //     }
                                                                    // } else {

                                                                    this.checkDropInForms();
                                                                    // this.checkDepartments();

                                                                    // this.setState({
                                                                    //     bankIdModalDropIn: true
                                                                    // }, () => {
                                                                    //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                    //         method: 'POST',
                                                                    //         headers: {
                                                                    //             'content-type': 'application/json'
                                                                    //         },
                                                                    //         body: JSON.stringify({ group: this.state.data._id })
                                                                    //     }).then(res => res.json()).then((result) => {
                                                                    //         if (result.redirect_url) {
                                                                    //             this.setState({

                                                                    //                 bankIdModalDropIn: result.redirect_url
                                                                    //             })
                                                                    //         }
                                                                    //     })
                                                                    // })
                                                                    //  }
                                                                }}
                                                                chooseTimeHandle={() => {
                                                                    this.webBookingCheck()
                                                                }}

                                                                waitingRoomHandler={() => {
                                                                    if (this.props.uData) {

                                                                        this.props[0].history.push(`/waiting-room`)
                                                                    } else {
                                                                        this.setState({
                                                                            bankIdModalWaitingRoom: true
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    :

                                                    typeof window != 'undefined' && window.innerWidth >= 1200 && this.state.data && this.state.data.landingJSON_v2 ?
                                                        <JsonToHtmlV2
                                                            getSelectedItem={(item) => {
                                                                this.setState({ selectdLandingConfig: item })
                                                            }}
                                                            layout={'lg'}
                                                            nodes={JSON.parse(JSON.stringify(this.state.data.landingJSON_v2))}
                                                            dropInButtonShow={this.state.data.dropInButtonShow}
                                                            {...this.props}
                                                            data={this.state.data}
                                                            loginBankId={() => {
                                                                this.setState({
                                                                    mainGroupLoginBankIdModal: true
                                                                }, () => {


                                                                    this.fetchMainClGroupBankIdLogin()
                                                                })
                                                            }}
                                                            lastVisitedClinics={this.state.lastVisitedClinics}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                }
                                                            }}
                                                            myReferralHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                        this.setState({
                                                                            error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                            bankIdModal1: null
                                                                        })
                                                                        return;
                                                                    }
                                                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal1: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal1: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId1()
                                                                    })
                                                                }
                                                            }}
                                                            informationHandler={() => {
                                                                if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                    window.open(this.state.data.informationLink, '_blank');
                                                                else {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    } else {
                                                                        window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    }
                                                                }
                                                            }}
                                                            seekCareHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                    } else {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                            daybookHandler={() => {
                                                                this.setLocalStorageGroupAlias();
                                                                if (this.props.uData) {
                                                                    this.props[0].history.push({
                                                                        pathname: `/my-data/account`,
                                                                        state: { tabIndex: 2 }
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalDaybook: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModalDaybook: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdDaybook()
                                                                    })
                                                                }
                                                            }}
                                                            dropInHandler={() => {
                                                                // if (this.props.uData) {
                                                                //     if (this.props[0].match.params.mainAlias) {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     } else {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     }
                                                                // } else {

                                                                this.checkDropInForms();
                                                                // this.checkDepartments();

                                                                // this.setState({
                                                                //     bankIdModalDropIn: true
                                                                // }, () => {
                                                                //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                //         method: 'POST',
                                                                //         headers: {
                                                                //             'content-type': 'application/json'
                                                                //         },
                                                                //         body: JSON.stringify({ group: this.state.data._id })
                                                                //     }).then(res => res.json()).then((result) => {
                                                                //         if (result.redirect_url) {
                                                                //             // console.log(result)
                                                                //             this.setState({

                                                                //                 bankIdModalDropIn: result.redirect_url
                                                                //             })
                                                                //         }
                                                                //     })
                                                                // })
                                                                // }
                                                            }}
                                                            chooseTimeHandle={() => {
                                                                this.webBookingCheck()
                                                            }}

                                                            waitingRoomHandler={() => {
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push(`/waiting-room`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalWaitingRoom: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <JsonToHtmlV2
                                                            layout={'lg'}
                                                            nodes={this.state.data && this.state.data.mainClinicGroup ? JSON.parse(JSON.stringify(defaultMainClinicGroup)) : JSON.parse(JSON.stringify(defaultJSON_v2))}
                                                            dropInButtonShow={this.state.data.dropInButtonShow}
                                                            dada={'dada'}
                                                            loginBankId={() => {
                                                                this.setState({
                                                                    mainGroupLoginBankIdModal: true
                                                                }, () => {


                                                                    this.fetchMainClGroupBankIdLogin()
                                                                })
                                                            }}
                                                            lastVisitedClinics={this.state.lastVisitedClinics}
                                                            loginHandler={() => {
                                                                if (!this.props.uData) {
                                                                    this.setState({
                                                                        loginBankIdModal: true
                                                                    }, () => {

                                                                        this.fetchBankIdLogin()
                                                                    })
                                                                }
                                                            }}
                                                            getSelectedItem={(item) => {
                                                                this.setState({ selectdLandingConfig: item })
                                                            }}
                                                            {...this.props}
                                                            data={this.state.data}
                                                            myReferralHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                                                        this.setState({
                                                                            error: 'You have no active issues with us...'.translate(this.props.lang),
                                                                            bankIdModal1: null
                                                                        })
                                                                        return;
                                                                    }
                                                                    this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal1: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal1: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId1()
                                                                    })
                                                                }
                                                            }}
                                                            informationHandler={() => {
                                                                if (this.state.data.informationLink && this.state.data.informationLink != '')
                                                                    window.open(this.state.data.informationLink, '_blank');
                                                                else {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        window.open(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    } else {
                                                                        window.open(`/${this.props[0].match.params.alias}/information`, '_blank');
                                                                    }
                                                                }
                                                            }}
                                                            seekCareHandler={() => {
                                                                if (this.props.uData) {
                                                                    if (this.props[0].match.params.mainAlias) {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/personal-referral`)
                                                                    } else {
                                                                        this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                                                    }

                                                                } else {
                                                                    this.setState({
                                                                        bankIdModal: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModal: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankId()
                                                                    })
                                                                }
                                                            }}
                                                            daybookHandler={() => {
                                                                this.setLocalStorageGroupAlias();
                                                                if (this.props.uData) {
                                                                    this.props[0].history.push({
                                                                        pathname: `/my-data/account`,
                                                                        state: { tabIndex: 2 }
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalDaybook: true
                                                                    }, () => {
                                                                        // fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                        //     method: 'POST',
                                                                        //     headers: {
                                                                        //         'content-type': 'application/json'
                                                                        //     },
                                                                        //     body: JSON.stringify({ group: this.state.data._id })
                                                                        // }).then(res => res.json()).then((result) => {
                                                                        //     if (result.redirect_url) {
                                                                        //         this.setState({

                                                                        //             bankIdModalDaybook: result.redirect_url
                                                                        //         })
                                                                        //     }
                                                                        // })
                                                                        this.fetchBankIdDaybook()
                                                                    })
                                                                }
                                                            }}
                                                            dropInHandler={() => {
                                                                // if (this.props.uData) {
                                                                //     if (this.props[0].match.params.mainAlias) {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     } else {
                                                                //         this.props[0].history.push({
                                                                //             pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                                                //             state: { isDropIn: true }
                                                                //         })
                                                                //     }
                                                                // } else {

                                                                this.checkDropInForms();
                                                                // this.checkDepartments();

                                                                // this.setState({
                                                                //     bankIdModalDropIn: true
                                                                // }, () => {
                                                                //     fetch(API_ENDPOINT + '/users/bankid/v2/start', {
                                                                //         method: 'POST',
                                                                //         headers: {
                                                                //             'content-type': 'application/json'
                                                                //         },
                                                                //         body: JSON.stringify({ group: this.state.data._id })
                                                                //     }).then(res => res.json()).then((result) => {
                                                                //         if (result.redirect_url) {
                                                                //             // console.log(result)
                                                                //             this.setState({

                                                                //                 bankIdModalDropIn: result.redirect_url
                                                                //             })
                                                                //         }
                                                                //     })
                                                                // })
                                                                //}
                                                            }}
                                                            chooseTimeHandle={() => {
                                                                this.webBookingCheck()
                                                            }}

                                                            waitingRoomHandler={() => {
                                                                if (this.props.uData) {

                                                                    this.props[0].history.push(`/waiting-room`)
                                                                } else {
                                                                    this.setState({
                                                                        bankIdModalWaitingRoom: true
                                                                    })
                                                                }
                                                            }}
                                                        />
                                        }
                                    </div>

                                    {/* ----- */}

                                    {
                                        !this.state.data.mainClinicGroup ?
                                            this.state.selectdLandingConfig ?
                                                <div className="landing-footer footer-space" style={this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground, marginTop: 'auto' } : {}}>

                                                    <Container>
                                                        <Row>
                                                            <Col lg="12">
                                                                <ul className="landing-footer">
                                                                    {this.state.selectdLandingConfig.privacyPolicy ? <li><Link to={`/${this.state.selectdLandingConfig.alias}/privacy-policy`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                                    {this.state.selectdLandingConfig.termsOfUse ?
                                                                        <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                                                        :
                                                                        null}
                                                                    {this.state.selectdLandingConfig.termsOfUse ? <li><a href={`/${this.state.selectdLandingConfig.alias}/terms-of-use`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                                    {this.state.selectdLandingConfig.accessibilityReport ?
                                                                        <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                                                        :
                                                                        null}
                                                                    {this.state.selectdLandingConfig.accessibilityReport ? <li><a href={`/${this.state.selectdLandingConfig.alias}/accessibility-report`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                                </ul>
                                                            </Col>
                                                            <Col lg="12">
                                                                <ul className="landing-footer landing-footer-list" >
                                                                    {this.state.selectdLandingConfig && this.state.selectdLandingConfig.address && this.state.selectdLandingConfig.address.map((address) => {
                                                                        if (address && address !== '' && address.trim() !== '')
                                                                            return (
                                                                                <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{address}</li>
                                                                            )
                                                                    })}
                                                                    {/* <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.selectdLandingConfig.address ? this.state.selectdLandingConfig.address[0] ? this.state.selectdLandingConfig.address[1] || this.state.selectdLandingConfig.address[2] ? this.state.selectdLandingConfig.address[0] + ',' : this.state.selectdLandingConfig.address[0] : '' : ''}</li>
                                                                    <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.selectdLandingConfig.address ? this.state.selectdLandingConfig.address[1] ? this.state.selectdLandingConfig.address[2] ? this.state.selectdLandingConfig.address[1] + ',' : this.state.selectdLandingConfig.address[1] : '' : ''}</li>
                                                                    <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.selectdLandingConfig.address ? this.state.selectdLandingConfig.address[2] : ''}</li> */}
                                                                </ul>

                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                                :
                                                this.state.data.footerSettings ?
                                                    <FooterComponent {...this.props} data={this.state.data} />

                                                    :
                                                    <div className="landing-footer footer-space" style={{ marginTop: 'auto' }}>
                                                        <Container>
                                                            <Row>
                                                                <Col lg="12">

                                                                    <ul className="landing-footer" style={{ paddingLeft: 0 }}>
                                                                        {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank"> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                                                        {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank"> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                                                        {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank"> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                                                    </ul>
                                                                </Col>
                                                                <Col lg="12">
                                                                    <ul className="landing-footer landing-footer-list" style={{ paddingLeft: 0 }}>
                                                                        {this.state.data && this.state.data.address && this.state.data.address.map((address) => {
                                                                            if (address && address !== '' && address.trim() !== '')
                                                                                return (
                                                                                    <li>{address}</li>
                                                                                )
                                                                        })}
                                                                        {this.state.data && this.state.data.phone && <li>
                                                                            {this.state.data.phone}
                                                                            {/* <PhoneInput
                                                                                disabled={true}
                                                                                buttonStyle={{ display: 'none' }}
                                                                                inputStyle={{ backgroundColor: 'transparent', cursor: 'default', }}
                                                                                value={this.state.data.phone}
                                                                            /> */}
                                                                        </li>}
                                                                        {/* <li>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                                                    <li>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                                                    <li>{this.state.data.address ? this.state.data.address[2] : ''}</li> */}
                                                                    </ul>

                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </div>
                                            :
                                            null
                                        // <div className="landing-footer footer-space" style={this.state.data.footerBackground ? { backgroundColor: this.state.data.footerBackground } : {}}>
                                        //     <Container>
                                        //         <Row>
                                        //             <Col lg="12">
                                        //                 <ul className="landing-footer">
                                        //                     {this.state.data.privacyPolicy ? <li><Link to={`/${this.props[0].match.params.alias}/privacy-policy`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Privacy policy'.translate(this.props.lang)} </Link></li> : null}
                                        //                     {this.state.data.termsOfUse ?
                                        //                         <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                        //                         :
                                        //                         null}
                                        //                     {this.state.data.termsOfUse ? <li><a href={`/${this.props[0].match.params.alias}/terms-of-use`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Terms of use'.translate(this.props.lang)} </a></li> : null}
                                        //                     {this.state.data.accessibilityReport ?
                                        //                         <a style={{ color: this.state.data.footerTextColor ? this.state.data.footerTextColor : '#7e8fa7' }}>|</a>
                                        //                         :
                                        //                         null}
                                        //                     {this.state.data.accessibilityReport ? <li><a href={`/${this.props[0].match.params.alias}/accessibility-report`} target="_blank" style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}> {'Accessibility Report'.translate(this.props.lang)} </a></li> : null}
                                        //                 </ul>
                                        //             </Col>
                                        //             <Col lg="12">
                                        //                 <ul className="landing-footer landing-footer-list">
                                        //                     <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[0] ? this.state.data.address[1] || this.state.data.address[2] ? this.state.data.address[0] + ',' : this.state.data.address[0] : '' : ''}</li>
                                        //                     <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[1] ? this.state.data.address[2] ? this.state.data.address[1] + ',' : this.state.data.address[1] : '' : ''}</li>
                                        //                     <li style={this.state.data.footerTextColor ? { color: this.state.data.footerTextColor } : {}}>{this.state.data.address ? this.state.data.address[2] : ''}</li>
                                        //                 </ul>

                                        //             </Col>
                                        //         </Row>
                                        //     </Container>
                                        // </div>
                                    }




                                </Col>

                            </Row>
                        </Container>
                    }

                    {this.state.error ?

                        <Modal isOpen={this.state.error} centered>

                            <ModalBody className="delete-modal">
                                {/* <img src={error} /> */}
                                <div className="message">
                                    {
                                        this.state.error
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} type='button' color='primary' onClick={() => this.setState({ error: null })}>
                                    {'Close'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal>

                        :
                        null
                    }

                    {
                        this.state.bankIdModal ?
                            <BankIdModal
                                {...this.props}
                                checkLastLogin={true}
                                isOpen={this.state.bankIdModal}
                                stateForOpen='bankIdModal'
                                toggle={() => {

                                    if (this.props.uData) {


                                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                        this.props[0].history.push(`/${this.props[0].match.params.alias}/personal-referral`)
                                    }
                                    this.setState({ bankIdModal: null })

                                }}
                                redirect_url={this.state.bankIdModal}></BankIdModal>
                            :

                            null
                    }
                    {
                        this.state.bankIdModalDaybook ?
                            <BankIdModal
                                {...this.props}
                                checkLastLogin={true}
                                isOpen={this.state.bankIdModalDaybook}
                                stateForOpen='bankIdModalDaybook'
                                setVerifyRedirectLink={() => {
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    this.props[0].history.push({
                                        pathname: `/my-data/account`,
                                        state: { tabIndex: 2 }
                                    })
                                }}
                                toggle={() => {

                                    // if (this.props.uData) {

                                    //     // setTimeout(() => {
                                    //     localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                    //     this.props[0].history.push({
                                    //         pathname: `/my-data/account`,
                                    //         state: { tabIndex: 2 }
                                    //     })
                                    //     // }, 1000);
                                    // }
                                    this.setState({ bankIdModalDaybook: null })

                                }}
                                redirect_url={this.state.bankIdModalDaybook}></BankIdModal>
                            :

                            null
                    }
                    {
                        this.state.bankIdModalWaitingRoom ?
                            <BankIdModal
                                {...this.props}
                                checkLastLogin={true}
                                isOpen={this.state.bankIdModalWaitingRoom}
                                stateForOpen='bankIdModalWaitingRoom'
                                toggle={() => {

                                    if (this.props.uData) {

                                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                        this.props[0].history.push({
                                            pathname: `/waiting-room`,
                                        })
                                    }
                                    this.setState({ bankIdModalWaitingRoom: null })

                                }}
                                redirect_url={this.state.bankIdModalWaitingRoom}
                            ></BankIdModal>
                            :
                            null
                    }

                    {
                        this.state.bankIdModalDropIn ?
                            <BankIdModal
                                {...this.props}
                                checkLastLogin={true}
                                isOpen={this.state.bankIdModalDropIn}
                                stateForOpen={this.state.selectedDropInForm + 'dropinBankId'}
                                toggle={() => {

                                    if (this.props.uData) {
                                        this.props.registerClinicGroupLogs({
                                            type: 'startCreateReferral',
                                            referralType: 'Drop in',
                                            questionaryId: this.state.selectedDropInForm
                                        })

                                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                        this.props[0].history.push({
                                            pathname: `/${this.props[0].match.params.alias}/drop-in`,
                                            state: { isDropIn: true, dropInClinic: this.state.selectedClinic, selectedDropInForm: this.state.selectedDropInForm }
                                        })
                                    }
                                    this.setState({ bankIdModalDropIn: null })

                                }}
                                redirect_url={this.state.bankIdModalDropIn}></BankIdModal>
                            :

                            null
                    }
                    {
                        this.state.mainGroupLoginBankIdModal ?
                            <BankIdModal
                                {...this.props}
                                isOpen={this.state.mainGroupLoginBankIdModal}
                                stateForOpen='mainGroupLoginBankIdModal'
                                faceIdEnv={this.state.faceIdEnv}
                                checkLastLogin={true}
                                openLastLoginLanding={true}
                                clinicGroupsItems={this.state.data.clinicGroupsItems ? this.state.data.clinicGroupsItems : []}
                                toggle={(alias) => {

                                    if (this.props.uData) {
                                        this.props.registerClinicGroupLogs({
                                            type: 'login',
                                            loginType: 'bankid'
                                        })
                                        let link = '/';
                                        if (this.props[0].match.params.alias) {
                                            link += `${this.props[0].match.params.alias}`
                                        }
                                        if (alias) {
                                            link += '/';
                                            link += alias;
                                        }

                                        this.props[0].history.push(link)
                                        // localStorage.setItem('groupAlias', alias);

                                    }
                                    this.setState({ mainGroupLoginBankIdModal: null })

                                }}
                                redirect_url={this.state.mainGroupLoginBankIdModal}></BankIdModal>
                            :
                            null
                    }
                    {
                        this.state.loginBankIdModal ?
                            <BankIdModal
                                {...this.props}
                                isOpen={this.state.loginBankIdModal}
                                stateForOpen='loginBankIdModal'
                                faceIdEnv={this.state.faceIdEnv}
                                checkLastLogin={true}
                                toggle={() => {

                                    if (this.props.uData) {
                                        this.props.registerClinicGroupLogs({
                                            type: 'login',
                                            loginType: 'bankid'
                                        })
                                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);

                                    }
                                    this.setState({ loginBankIdModal: null })

                                }}
                                redirect_url={this.state.loginBankIdModal}></BankIdModal>
                            // <Modal isOpen={this.state.loginBankIdModal} centered>
                            //     <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ loginBankIdModal: !this.state.loginBankIdModal })}>&times;</button>}>{'Login'.translate(this.props.lang)}</ModalHeader>
                            //     <ModalBody>
                            //         <div className="login-form">
                            //             <BankIDModal_v2 {...this.props} />
                            //         </div>
                            //     </ModalBody>

                            // </Modal>

                            :

                            null
                    }
                    {
                        this.state.bankIdModal1 ?
                            <BankIdModal
                                {...this.props}
                                checkLastLogin={true}
                                isOpen={this.state.bankIdModal1}
                                stateForOpen='bankIdModal1'
                                toggle={() => {

                                    if (this.props.uData) {

                                        localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                        if (!this.props.uData.haveReferrals && !this.props.uData.havePersonalReferrals) {
                                            this.setState({
                                                error: 'You have no active issues with us...'.translate(this.props.lang),
                                                bankIdModal1: null
                                            })
                                            return;
                                        }

                                        this.props[0].history.push(`/my-data/personal-referrals/referrals`)
                                    }
                                    this.setState({ bankIdModal1: null })

                                }}
                                redirect_url={this.state.bankIdModal1}></BankIdModal>
                            :

                            null
                    }


                    {this.state.error ?
                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>
                        :
                        null

                    }
                    {
                        this.state.searchedReferrals ?
                            <Modal isOpen={this.state.searchedReferrals} centered>
                                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                    {'What do you need help with?'.translate(this.props.lang)}
                                </ModalHeader>
                                <ModalBody className="delete-modal">
                                    <CustomSelectWithSearch
                                        isInputClearable={true}
                                        lang={this.props.lang}
                                        items={this.state.searchedReferrals.filter(item => !item.nonVisible).map((item, idx) => {
                                            return {
                                                name: item.name,
                                                value: item._id
                                            }
                                        })}
                                        value={this.state.selectedReferral}
                                        hideSearchField={this.state.clinicGroup?.hideFormSearchFild}
                                        maxHeight={'50vh'}
                                        onChange={(e) => {
                                            if (e && e.value && this.state.searchedReferrals.filter(item => item._id === e.value).length) {
                                                this.setState({ selectedReferral: e.value }, () => {
                                                    let referral = null;
                                                    for (let i = 0; i < this.state.searchedReferrals.length; i++) {
                                                        if (this.state.searchedReferrals[i]._id == e.value) {
                                                            referral = this.state.searchedReferrals[i];
                                                            break;
                                                        }
                                                    }
                                                    this.setState({
                                                        selectedReferral: e.value,
                                                        referral,
                                                    }, () => {
                                                        this.openCalendar()
                                                    });
                                                })
                                            } else {
                                                this.setState({ selectedReferral: null })
                                            }


                                        }}
                                    />
                                </ModalBody>
                                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                                    <Button color='primary' onClick={() => {
                                        this.setState({ searchedReferrals: false })
                                    }}>{'Cancel'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }

                    <iframe ref={(node) => this.appIframe = node} width="1" height="1" style={{ visibility: "hidden" }}></iframe>

                    {
                        this.state.show2FAModal && this.state.queryUid ? <TwoFactorAuthModal
                            secondaryLanguageDisabled={this.props.secondaryLanguageDisabled}
                            countryTwoLetter={this.props.countryTwoLetter}
                            country={this.props.country}
                            lang={this.props.lang}
                            closeModal={this.close2FAModal}
                            uid={this.state.queryUid}
                            show2FAModal={this.state.show2FAModal}
                            type='verify-account'
                            alias={this.props[0]?.match?.params?.alias}
                        /> : null
                    }

                    {
                        this.state.twoFactorAuthForbidden ? <Modal
                            zIndex={99999}
                            size='md'
                            centered
                            isOpen={this.state.twoFactorAuthForbidden}
                        >
                            <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                                <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                    {'Ok'.translate(this.props.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal> : null
                    }

                </div>
            );
        }



    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { selectedWaitingTimeType: selector(state, 'selectedWaitingTimeType') }

}, {
    changeMinValue: value => change("modalForm", "waitingMin", value),
    changeMaxValue: value => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: value => change("modalForm", "selectedWaitingTimeType", value),

})(Page(Account));
