import React, { Component, Fragment } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, Label, FormGroup, Row, Col, Container } from 'reactstrap';
import InfoWithTooltip from '../InfoWithTooltip';
import Checkbox from '../forms/fields/checkbox';
import { API_ENDPOINT } from '../../constants';
import { defaultError, isStringAValidNumber } from '../TwoFactorAuthModal';
import InlineSVG from 'react-inlinesvg';

import infoIcon from '../../assets/svg/link-new-tab.svg';
import trashIcon from '../../assets/svg/garbage.svg';
import uploadIcon from '../../assets/svg/upload.svg';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import bankIdIcon from '../../assets/images/icon-bankid2.png';
import ErrorModal from '../errorModal';
import DatePicker from '../forms/fields/datePicker';
import TimePickerV2 from '../forms/fields/timepicker_v2';

const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'application/rtf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'video/mp4', 'application/json', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

/**
 * @typedef {Object} Props
 * @property {Boolean} isOpen - asdf
 * @property {String} clinicGroupId - id from clinic group
 * @property {Function} closeModal - function to close the modal
 * @property {String} lang - language
 */

/**
 * @class
 * @augments {Component<Props, State>}
 */
export class ConsultationModal extends Component {
    /**
     * 
     * @param {Props} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: null,
            availableClinics: [],
            availableDepartments: [],
            availableDoctors: [],
            value: {
                selectedClinic: null,
                selectedGroup: null,
                selectedDoctor: null,
                comment: '',
                attachments: [],
                patientConsent: false,
                transferCase: false

            },
            search: {
                group: '',
                clinic: '',
                doctor: ''
            }
        }
    }

    getaClinicGroups = () => {
        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/clinics?departments=true', {
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            });

            if (!response || !response.ok) return this.setState({ loading: false, error: defaultError });
            const result = await response.json();
            if (!result || !result.clinics) return this.setState({ loading: false, error: defaultError });
            const availableClinics = result.clinics?.map?.(clinic => ({
                _id: clinic._id,
                logo: API_ENDPOINT + clinic?.logo,
                name: clinic?.name,
                address: clinic?.landingConfig?.address?.length ? clinic.landingConfig.address.join?.('; ') : '',
                phone: clinic?.landingConfig?.phone ?? '',
                departments: clinic.departments ?? []
            }))

            this.setState({
                loading: false,
                error: null,
                availableClinics: availableClinics
            });
        });
    }
    checkAvailableDepartments = () => {
        let value = this.state.value;
        value.selectedClinic = null;
        value.selectedDoctor = null;
        this.setState({ value, availableDepartments: [], availableDoctors: [] }, () => {
            if (this.state.value?.selectedGroup) {
                let clinicGroup = this.state.availableClinics?.filter(item => item._id === this.state.value.selectedGroup)[0];
                if (clinicGroup?.departments) {
                    this.setState({ availableDepartments: clinicGroup.departments })
                }
            }
        })
    }
    getDoctors = () => {
        if (!this.state.value?.selectedGroup || !this.state.value?.selectedClinic) return;
        this.setState({ loading: true, availableDoctors: [] }, async () => {
            const newState = {
                loading: false,
            }
            const response = await fetch(API_ENDPOINT + `/e-connect/doctors?groupId=${this.state.value.selectedGroup}&departmentId=${this.state.value.selectedClinic}&includeLoggedDoctor=true`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response || !response.ok) {
                newState.error = defaultError;
                return this.setState(newState);
            };
            const result = await response.json();
            if (!result || result.error || !result.doctors) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            };

            newState.availableDoctors = result.doctors && Array.isArray(result.doctors) ? result.doctors : [];
            this.setState(newState);
        })
    }


    componentDidMount() {
        this.getaClinicGroups()

        const _uuid = uuid();
        const uploadHeader = 'curoflow-econnect';
        const uploadToken = this.generateRandomToken();
        this.setState({ uuid: _uuid, uploadHeader, uploadToken });
    }
    generateRandomToken = () => {
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        const generateRandomNumber = (max, min = 0) => Math.floor(Math.random() * (max - min) + min);
        const possibilities = [...numbers, ...letters, ...LETTERS];

        let token = '';

        for (let i = 0; i < 5; i++) {
            let code = '';
            for (let j = 0; j < 5; j++) {
                if (i === 0 && j === 0) code += 'r';
                else if (i === 1 && j === 1) code += 'a';
                else if (i === 2 && j === 2) code += 'd';
                else if (i === 3 && j === 3) code += 'a';
                else if (i === 4 && j === 4) code += 'n';
                else code += possibilities[generateRandomNumber(possibilities.length)];
            }

            if (i === 0) token = code;
            else token += `-${code}`
        }

        return token;
    }

    componentWillUnmount() {

    }


    componentDidUpdate(prevProps, prevState) {

    }

    uploadFiles = (files) => {
        let sameClinic = true;
        if (this.state.value?.selectedClinic !== this.props.selectedClinic) {
            sameClinic = false;
        }
        if (sameClinic) return;
        if (!files) return;
        try {
            files = Array.from(files);
            if (files && Array.isArray(files) && files.length > 0) {
                this.setState({ _uploading: true, _dragging: false }, async () => {
                    let attachments = [];
                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        if (!file || !file.type || !file.name) continue;
                        if (!allowedFileTypes.includes(file.type)) continue;
                        const obj = {
                            type: (file.type?.startsWith?.('video') ? (
                                'video'
                            ) : file.type?.startsWith?.('image') ? (
                                'image'
                            ) : 'file'),
                            name: file.name
                        };

                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('group', this.props.clinicGroupId);
                        formData.append('fileType', 'eConnect');

                        try {
                            const response = await axios.post(API_ENDPOINT + '/data/upload-document', formData, {
                                headers: {
                                    Accept: 'application/json',
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'x-cf-upload-token': this.state.uploadToken,
                                    'x-cf-upload-header': this.state.uploadHeader
                                }
                            });
                            if (response && response.status && response.status == 200 && response.data) {
                                obj.url = response.data;
                                attachments.push(obj);
                            }
                        } catch (err) {
                            console.log('upload err: ', err);
                            continue;
                        }
                    };
                    let value = this.state.value;

                    this.setState(prev => ({
                        ...prev,
                        _uploading: false,
                        value: {
                            ...value,
                            attachments: [...(prev?.value?.attachments || []), ...attachments]
                        }
                    }));

                    if (attachments && attachments.length > 0) {
                        fetch(API_ENDPOINT + '/e-connect/uploads', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'x-cf-upload-token': this.state.uploadToken,
                                'x-cf-upload-header': this.state.uploadHeader
                            },
                            body: JSON.stringify({
                                attachments,
                                uuid: this.state.uuid
                            })
                        });
                    }
                });
            }
        } catch (err) {
            console.log('upload files err: ', err);
        }
    }

    submit = (tranferEventData = null) => {
        let disabledSubmit = this.checkDisabledSubmit()
        if (!disabledSubmit) {
            let data = {
                ...this.state.value,
                // conversationId: this.props.conversation?._id
            }
            if (this.props.conversation?._id) {
                data.conversationId = this.props.conversation?._id;
            }
            if (this.props.referral?._id) {
                data.referralId = this.props.referral?._id;
            }
            if (tranferEventData) {
                data = { ...data, ...tranferEventData }
            }

            this.setState({ loading: true }, async () => {
                const response = await fetch(API_ENDPOINT + '/consultation/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

                if (!response || (!response.ok && !response.error)) return this.setState({ loading: false, error: defaultError });
                const result = await response.json();
                if (result?.ok) {
                    this.props.closeModal?.(true);
                } else if (result?.error) {
                    this.setState({ error: result.error })
                }

                this.setState({
                    loading: false,
                    // error: null,
                    // availableClinics: availableClinics
                });
            });



        }
    }
    checkDisabledSubmit = () => {
        if (this.state.loading) {
            return true;
        }

        let disabled = false;

        let value = this.state.value;

        if (!value?.selectedGroup || !value?.selectedClinic) {
            disabled = true;
        }
        let doctorRequired = false;
        if (value?.selectedClinic === this.props.selectedClinic) {
            doctorRequired = true;
        }

        if (doctorRequired && !value.selectedDoctor) {
            disabled = true;
        }

        if (value?.selectedClinic !== this.props.selectedClinic) {
            if (!value.comment || !value.patientConsent) {
                disabled = true;
            }
        }
        if (this.state.loadingTransfer) {
            disabled = true;
        }


        return disabled;
    }
    openChangeTimeModal = () => {
        let referralId = this.props.referral ? this.props.referral?._id : this.props.conversation?.referral?._id;
        let doctorId = this.state.value?.selectedDoctor;
        if (referralId && doctorId) {
            this.setState({ loadingTransfer: true }, async () => {
                const response = await fetch(API_ENDPOINT + `/referral/consultation/transfer/data/${doctorId}/${referralId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                })

                const result = await response.json();
                if (result?.error) {
                    this.setState({ error: result.error, loadingTransfer: false })
                } else {
                    let caseData = result?.referral;
                    if (!caseData?.event?._id) {
                        this.setState({ loadingTransfer: false }, () => {
                            this.submit();
                        })

                    } else {
                        let data = {
                        }
                        let date = new Date();

                        data.min = new Date();
                        data.min.setHours(8, 30, 0)
                        data.max = new Date();
                        data.max.setHours(20, 30, 0)
                        data.min = new Date(this.props.getStringDateTs(Math.floor(new Date(data.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                        data.max = new Date(this.props.getStringDateTs(Math.floor(new Date(data.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                        let workingTimeConfig = result?.doctor?.workingTimeConfig?.[this.props.selectedGroup]?.[this.props.selectedClinic];
                        if (workingTimeConfig) {
                            data.min = new Date(workingTimeConfig.min)
                            data.max = new Date(workingTimeConfig.max)
                        }
                        let startDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD');
                        let endDate = this.props.getStringDateTs(Math.floor(date.getTime() / 1000), 'YYYY-MM-DD');
                        let start = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 600), 'HH:mm');
                        let end = this.props.getStringDateTs(Math.floor(date.getTime() / 1000 + 900), 'HH:mm');

                        if (caseData?.event?.startTime) {
                            startDate = this.props.getStringDateTs(caseData.event.startTime, 'YYYY-MM-DD')
                            endDate = this.props.getStringDateTs(caseData.event.startTime, 'YYYY-MM-DD')
                            start = this.props.getStringDateTs(caseData.event.startTime, 'HH:mm');
                            if (caseData?.event?.endTime) {
                                end = this.props.getStringDateTs(caseData.event.endTime, 'HH:mm');
                            }
                        }
                        data.date = startDate;
                        data.endDate = endDate;
                        data.startTime = start;
                        data.endTime = end;

                        this.setState({ changeBookedTimeModal: data, loadingTransfer: false })
                    }


                }


            });
        }
    }


    render() {
        const search = this.state.search;
        const referral = this.props.referral ?? this.props.conversation?.referral;


        let availableClinics = this.state.availableClinics?.length ? this.state.availableClinics.filter(item => !search?.group || ((search?.group && item?.name?.toLowerCase()?.indexOf(search.group.toLowerCase()) !== -1) || item._id === this.state.value?.selectedGroup)) : [];
        let availableDepartments = this.state.availableDepartments?.length ? this.state.availableDepartments.filter(item => !search?.clinic || ((search?.clinic && item?.name?.toLowerCase()?.indexOf(search.clinic.toLowerCase()) !== -1) || item._id === this.state.value?.selectedClinic)) : [];
        let availableDoctors = this.state.availableDoctors?.length ? this.state.availableDoctors.filter(item => !search?.doctor || ((search?.doctor && item?.name?.toLowerCase()?.indexOf(search.doctor.toLowerCase()) !== -1) || item._id === this.state.value?.selectedDoctor)) : [];

        availableClinics = [...availableClinics.filter(item => item._id === this.props.selectedGroup), ...availableClinics.filter(item => item._id !== this.props.selectedGroup)]
        availableDepartments = [...availableDepartments.filter(item => item._id === this.props.selectedClinic), ...availableDepartments.filter(item => item._id !== this.props.selectedClinic)]
        availableDoctors = [...availableDoctors.filter(item => item._id === referral?.recipientUser), ...availableDoctors.filter(item => item._id !== referral?.recipientUser)]

        let doctorRequired = false;
        let showCurrentDoctor = true;
        let sameGroup = true;
        let sameClinic = true;

        if (this.state.value?.selectedGroup === this.props.selectedGroup && this.state.value?.selectedClinic === this.props.selectedClinic) {
            showCurrentDoctor = false;
        }

        if (!showCurrentDoctor) {
            availableDoctors = availableDoctors.filter(item => item._id !== referral?.recipientUser)
        }

        if (this.state.value?.selectedGroup === this.props.selectedGroup) {
            doctorRequired = true;
        } else {
            sameGroup = false;
        }
        if (this.state.value?.selectedClinic !== this.props.selectedClinic) {
            sameClinic = false;
        }
        const selectedGroupName = this.state.value?.selectedGroup ? availableClinics.filter(item => item._id === this.state.value.selectedGroup)?.[0]?.name : '';

        const disabledSubmit = this.checkDisabledSubmit()

        const attachmentsData = sameClinic ? [] : this.state.value?.attachments;

        return (<>
            {this.state.error ?

                <ErrorModal lang={this.props.lang}
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}

                >
                    {this.state.error.translate(this.props.lang)}
                </ErrorModal>

                :
                null
            }
            {
                this.state.changeBookedTimeModal ?
                    <Modal isOpen={this.state.changeBookedTimeModal} centered>
                        <ModalHeader
                            toggle={() => this.setState({ scheduleReferral: !this.state.changeBookedTimeModal })}
                            close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ changeBookedTimeModal: !this.state.changeBookedTimeModal })}>&times;</button>}>
                            {'Transfer case'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Doctor'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled={true} value={availableDoctors?.filter(item => this.state.value?.selectedDoctor && item?._id === this.state.value.selectedDoctor)?.[0]?.name} />
                            </FormGroup>

                            <FormGroup>
                                <DatePicker
                                    label={'Start date'.translate(this.props.lang)}
                                    value={this.state.changeBookedTimeModal.date}
                                    onChange={(e) => {
                                        let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                        changeBookedTimeModal.date = e;
                                        this.setState({
                                            changeBookedTimeModal
                                        })
                                        if (Math.floor(new Date(this.state.changeBookedTimeModal.date + ' ' + this.state.changeBookedTimeModal.startTime) / 1000) > Math.floor(new Date(this.state.changeBookedTimeModal.endDate + ' ' + this.state.changeBookedTimeModal.endTime) / 1000)) {
                                            changeBookedTimeModal.endDate = this.state.changeBookedTimeModal.date
                                            this.setState({
                                                changeBookedTimeModal
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;

                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.changeBookedTimeModal.startTime}
                                    minValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.min?.getTime() / 1000), 'HH:mm')}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.max?.getTime() / 1000 - 300), 'HH:mm')}
                                    flag={0}
                                    onChange={(time1, timeString) => {
                                        let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                        changeBookedTimeModal.startTime = timeString
                                        let startTimeSplit = changeBookedTimeModal.startTime.split(":");
                                        let endTimeSplit = changeBookedTimeModal.endTime.split(":");
                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ changeBookedTimeModal })
                                        } else {
                                            changeBookedTimeModal.endTime = Math.trunc((Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) % 60
                                            const padded = changeBookedTimeModal?.endTime?.split?.(':');
                                            if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                const h = padded[0]?.padStart?.(2, '0');
                                                const m = padded[1]?.padStart?.(2, '0');
                                                if (h && m) changeBookedTimeModal.endTime = `${h}:${m}`;
                                            }
                                            this.setState({
                                                changeBookedTimeModal
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.changeBookedTimeModal.endTime}
                                    minValue={Math.trunc((Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.changeBookedTimeModal.startTime.split(':')[0]) * 60 + Number(this.state.changeBookedTimeModal.startTime.split(':')[1]) + 5) % 60}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.changeBookedTimeModal?.max?.getTime() / 1000), 'HH:mm')}
                                    flag={0}
                                    onChange={(time1, timeString) => {
                                        let changeBookedTimeModal = this.state.changeBookedTimeModal;
                                        changeBookedTimeModal.endTime = timeString

                                        let startTimeSplit = changeBookedTimeModal.startTime.split(":");
                                        let endTimeSplit = changeBookedTimeModal.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ changeBookedTimeModal })
                                        }
                                    }}
                                />

                            </FormGroup>

                            <Button color='primary' onClick={() => {
                                let date = this.state.changeBookedTimeModal?.date;
                                let startTime = this.state.changeBookedTimeModal?.startTime;
                                let endTime = this.state.changeBookedTimeModal?.endTime;
                                let data = {
                                    startEventReservedDateOriginal: date + ' ' + startTime,
                                    endEventReservedDateOriginal: date + ' ' + endTime,
                                    startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(date + ' ' + startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(date + ' ' + endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`)
                                }
                                this.submit(data)
                            }}>
                                {'Transfer'.translate(this.props.lang)}
                            </Button>

                        </ModalBody>
                    </Modal>
                    :
                    null
            }
            <Modal
                isOpen={this.props.isOpen}
                size='xl'
                centered
            >
                <ModalHeader>
                    {"Consultation".translate(this.props.lang)}

                </ModalHeader>

                <ModalBody className='consultation-modal-body-wrap'>

                    <Container>
                        <Row>
                            <Col lg='4'>
                                <div className='select-box-wrap'>
                                    <h5>{'Clinic group'.translate(this.props.lang)}*</h5>
                                    <Input type='text' placeholder={'Search'.translate(this.props.lang) + '...'} value={search?.group} onChange={(e) => {
                                        let searchState = search;
                                        searchState.group = e.target.value;
                                        this.setState({ search: searchState })
                                    }} />
                                    <div className='list-box-wrap'>
                                        {
                                            availableClinics.map((item, idx) => {
                                                return (
                                                    <div
                                                        className={item._id === this.state.value?.selectedGroup ? 'list-box-item list-box-item-active' : 'list-box-item'}
                                                        key={idx}
                                                        onClick={() => {
                                                            let value = this.state.value;
                                                            if (value.selectedGroup === item._id) {
                                                                value.selectedGroup = null;
                                                            } else {
                                                                value.selectedGroup = item._id;
                                                            }
                                                            if (value?.selectedGroup === this.props.selectedGroup) {
                                                                value.patientConsent = false;
                                                                value.transferCase = false;
                                                            }
                                                            this.setState({ value }, () => {
                                                                this.checkAvailableDepartments()
                                                            })
                                                        }}>
                                                        {item.name}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='select-box-wrap'>
                                    <h5>{'Clinic'.translate(this.props.lang)}*</h5>
                                    <Input type='text' placeholder={'Search'.translate(this.props.lang) + '...'} value={search?.clinic} onChange={(e) => {
                                        let searchState = search;
                                        searchState.clinic = e.target.value;
                                        this.setState({ search: searchState })
                                    }} />
                                    <div className='list-box-wrap'>
                                        {
                                            availableDepartments.map((item, idx) => {
                                                return (
                                                    <div
                                                        className={item._id === this.state.value?.selectedClinic ? 'list-box-item list-box-item-active' : 'list-box-item'}
                                                        key={idx}
                                                        onClick={() => {
                                                            let value = this.state.value;
                                                            if (value.selectedClinic === item._id) {
                                                                value.selectedClinic = null;
                                                            } else {
                                                                value.selectedClinic = item._id;
                                                            }
                                                            value.selectedDoctor = null;
                                                            this.setState({ value }, () => {
                                                                this.getDoctors()
                                                            })
                                                        }}>
                                                        {item.name}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='select-box-wrap'>
                                    <h5>{'Doctor'.translate(this.props.lang)}</h5>
                                    <Input type='text' placeholder={'Search'.translate(this.props.lang) + '...'} value={search?.doctor} onChange={(e) => {
                                        let searchState = this.state.search;
                                        searchState.doctor = e.target.value;
                                        this.setState({ search: searchState })
                                    }} />
                                    <div className='list-box-wrap'>
                                        {
                                            availableDoctors.map((item, idx) => {
                                                return (
                                                    <div
                                                        className={item._id === this.state.value?.selectedDoctor ? 'list-box-item list-box-item-active' : 'list-box-item'}
                                                        key={idx}
                                                        onClick={() => {
                                                            let value = this.state.value;
                                                            if (value.selectedDoctor === item._id) {
                                                                value.selectedDoctor = null;
                                                            } else {
                                                                value.selectedDoctor = item._id;
                                                            }
                                                            this.setState({ value })
                                                        }}>
                                                        {item.name}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>{'Comment'.translate(this.props.lang)}{!sameClinic ? '*' : null}</Label>
                                    <Input
                                        type="textarea"
                                        value={sameClinic ? '' : this.state.value?.comment}
                                        disabled={sameClinic}
                                        onChange={(e) => {
                                            let value = this.state.value;
                                            value.comment = e.target.value;
                                            this.setState({ value })
                                        }}
                                        style={{ height: 120 }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <h6 className='e-connect-title ext-ec-attch-title'>
                                    {'Attachments'.translate(this.props.lang)}:
                                    {' '}
                                    <InfoWithTooltip style={{}} text='You can upload as many files and/or images as you need. Just click on the upload button, or drag and drop your files' lang={this.props.lang} />
                                </h6>
                                <div className='e-connect-modal-attachments'>
                                    <div
                                        onDragEnter={e => { if (sameClinic) return; e.preventDefault(); e.stopPropagation(); this.setState({ _dragging: true }) }}
                                        onDragOver={e => { if (sameClinic) return; e.preventDefault(); e.stopPropagation(); }}
                                        onDragLeave={e => {
                                            if (sameClinic) return;
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.setState({ _dragging: false })
                                        }}
                                        onDrop={e => {
                                            if (sameClinic) return;
                                            e.preventDefault();
                                            const files = e?.dataTransfer?.files;
                                            if (files) this.uploadFiles(files);
                                        }}
                                        className={this.state._uploading || this.state._dragging ? `${sameClinic ? 'ec-uploading-pl disabled-input' : 'ec-uploading-pl'}` : `${sameClinic ? 'disabled-input' : ''}`}
                                    >
                                        {this.state._uploading || this.state._dragging ? <div key='_uploading'>
                                            {this.state._uploading ? 'Uploading...'.translate(this.props.lang) : this.state._dragging ? 'Drop files here'.translate(this.props.lang) : ''}
                                        </div>
                                            :
                                            attachmentsData?.map?.((item, i) => {
                                                if (!item?.url) return null;
                                                return <div
                                                    key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                    className='econnect-attachment-item'
                                                >
                                                    <div>
                                                        <button
                                                            title={'Opens in new browser tab'.translate(this.props.lang)}
                                                            onClick={() => {
                                                                if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                            }}
                                                        >
                                                            &#91;
                                                            {'Open'.translate(this.props.lang)}
                                                            &#93;
                                                        </button>
                                                        <div>{item?.name}</div>
                                                    </div>
                                                    <button onClick={() => {
                                                        const url = item.url;
                                                        const attachments = attachmentsData?.filter?.(item => item.url !== url);
                                                        let value = this.state.value;
                                                        value.attachments = attachments;
                                                        this.setState({ value })
                                                    }}>
                                                        <InlineSVG src={trashIcon} title={'Delete'.translate(this.props.lang)} />
                                                    </button>
                                                </div>
                                            })}
                                    </div>
                                    <div className={`${this.state._uploading ? 'ec2-disabled-indom' : ''}`}>
                                        <Label className={!sameClinic ? 'ext-ec-upload-btn' : 'ext-ec-upload-btn disabled-ext-ec-upload-btn'} title={'Upload'.translate(this.props.lang)}>
                                            <InlineSVG src={uploadIcon} />
                                            <Input disabled={this.state._uploading || sameClinic} multiple accept={`.png,.jpg,.jpeg,.webp,.pdf,.doc,.docx,.txt,.xls,.xlsx,.mp4,.ppt,.pptx,.json,.csv,${allowedFileTypes.join(',')}`} type='file' onChange={(e) => {
                                                const files = e?.target?.files;
                                                if (files) this.uploadFiles(files);
                                            }} />
                                        </Label>
                                    </div>
                                </div>
                            </Col>
                            {
                                this.state.value?.selectedGroup && this.state.value?.selectedClinic && !sameClinic ?
                                    <Col lg='12'>
                                        <div className='consent-wrap'>
                                            <p>{'Consultation request will be sent'.translate(this.props.lang)}
                                                {/* {this.state.value?.selectedDoctor && availableDoctors.filter(item => item._id === this.state.value.selectedDoctor)?.[0]?.name ? ` ${'to'.translate(this.props.lang)}` : null}  */}
                                                <span>{this.state.value?.selectedDoctor && availableDoctors.filter(item => item._id === this.state.value.selectedDoctor)?.[0]?.name ? ` ${availableDoctors.filter(item => item._id === this.state.value.selectedDoctor)[0].name}` : null}</span>
                                                {/* {selectedGroupName ? ` ${'at'.translate(this.props.lang)}` : ''} */}
                                                {this.state.value?.selectedDoctor && availableDoctors.filter(item => item._id === this.state.value.selectedDoctor)?.[0]?.name ? ` ${'at'.translate(this.props.lang)}` : null}
                                                {selectedGroupName ? <span>{` ${selectedGroupName}`}</span> : ''}.</p>

                                            <Checkbox small={true} checked={this.state.value?.transferCase} onChange={e => {
                                                let value = this.state.value;
                                                value.transferCase = e?.target?.checked;
                                                this.setState({ value });
                                            }
                                            } label={'Request transfer of case to receiver'.translate(this.props.lang)} />



                                            <div style={{ marginTop: 15 }}>
                                                <Checkbox small={true} checked={this.state.value?.patientConsent} onChange={e => {
                                                    let value = this.state.value;
                                                    value.patientConsent = e?.target?.checked;
                                                    this.setState({ value });
                                                }
                                                } label={`*${'Patient has given consent to be referred and their information to be shared'.translate(this.props.lang)}`} />
                                            </div>
                                        </div>
                                    </Col>
                                    :
                                    null
                            }


                        </Row>

                    </Container>

                </ModalBody>

                <ModalFooter className='buttons-right-blue' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={() => this.props.closeModal()}
                        disabled={this.state.loading}
                    >
                        {'Close'.translate(this.props.lang)}
                    </Button>

                    <Button
                        onClick={() => {
                            if (sameClinic) {
                                this.openChangeTimeModal()
                            } else {
                                this.submit()
                            }

                        }}
                        disabled={disabledSubmit}
                    >
                        {sameClinic ? 'Transfer'.translate(this.props.lang) : 'External Consultation'.translate(this.props.lang)}
                    </Button>


                </ModalFooter>
            </Modal>

        </>
        )
    }
}

export default ConsultationModal