import React, { Component } from 'react';
import SelectWithSearch from './forms/fields/selectWithSearch_v2'
import { v4 as uuidv4 } from 'uuid';
import { GOOGLE_MAPS_API_KEY } from '../constants';

import { formatCSS } from '../appBuilder/cssHelper';

import { API_ENDPOINT } from '../constants';


/**
* Showing map with specific coords
* @author   Milan Stanojevic
*/
export class Map extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.gmapCallback = this.gmapCallback.bind(this);
        // if (typeof window !== 'undefined')
        //     window.googleMapsCallback = this.gmapCallback;

        this.state = {
            selectedValue: null
        };
    }

    gmapCallback() {
        this.setState({
            _googleMapsLoaded: true
        })
    }



    initMap() {
        this.setState({
            _mapInit: true
        });

        if (this.state.value) {
            var latLng = new window.google.maps.LatLng(this.state.value[0].coords.split(',')[0], this.state.value[0].coords.split(',')[1]);
            var map = new window.google.maps.Map(this.GoogleMap, {
                zoom: this.state.selectedValue ? 16 : 8,
                center: latLng,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                gestureHandling: "greedy",
            });


            for (let i = 0; i < this.state.value.length; i++) {
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.state.value[i].coords.split(',')[0], this.state.value[i].coords.split(',')[1]),
                    map: map,
                });
                let props = this.props;
                let state = this.state;
                window.google.maps.event.addListener(marker, 'click', (function (marker, i, props, state) {
                    return function () {
                        props[0].history.push(state.value[i].link)
                    }
                })(marker, i, props, state));


            }



            this.setState({ googleMap: map });
        }




    }

    componentDidMount() {

        // var ref = window.document.getElementsByTagName("script")[0];
        // var script = window.document.createElement("script");
        // script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${GOOGLE_MAPS_API_KEY}&callback=googleMapsCallback&language=se`;
        // script.async = true;
        // script.defer = true;

        // ref.parentNode.insertBefore(script, ref);


        // if (this.state._googleMapsLoaded && !this.state._mapInit) {
        //     this.initMap();
        // }
        this.setState({ value: this.props.value }, () => {
            // if (this.state.value && this.state.value.length) {
            //     this.setState({ selectedValue: { name: this.state.value[0].name, value: this.state.value[0].value } })
            // }
        })

    }

    componentDidUpdate(prevProps, prevState) {

        // if (this.state._googleMapsLoaded && !this.state._mapInit) {
        //     this.initMap();
        // }

        if (this.state._googleMapsLoaded && prevProps.value[0].coords != this.props.value[0].coords) {
            var latLng = new window.google.maps.LatLng(this.props.value[0].coords.split(',')[0], this.props.value[0].coords.split(',')[1]);
            this.state.googleMap.setCenter(latLng);
        }
        // if (prevState.updateNeeded != this.state.updateNeeded) {
        //     this.initMap();
        // }
        if (prevState.selectedValue != this.state.selectedValue && this.props.getValue) {
            this.props.getValue(this.state.selectedValue)
        }

    }


    render() {
        const buttonsMainLayout = this.props.value?.length < 4 ? true : false;
        let logo;
        let name;
        let loginTxt = 'Login text'.translate(this.props.lang);
        if (this.props.mainData) {
            if (this.props.mainData.logo) {
                logo = API_ENDPOINT + this.props.mainData.logo;
            }
            if (this.props.mainData.name) {
                name = this.props.mainData.name;
            }
            if (this.props.mainData.loginTxt) {
                loginTxt = this.props.mainData.loginTxt;
            }
        }

        let groupNameStyle = {};
        let searchLabelStyle = {};
        let showAllLinStyle = {};
        let loginTextStyle = { whiteSpace: 'pre-line' };
        let loginButtonStyle = {}
        let clinicButtonStyle = {}
        let clinicButtonActiveStyle = {}

        if (this.props.style) {
            if (this.props.style.groupNameColor) {
                groupNameStyle.color = this.props.style.groupNameColor;
            }
            if (this.props.style.groupNameFontWeight) {
                groupNameStyle.fontWeight = this.props.style.groupNameFontWeight;
            }
            if (this.props.style.groupNameFontSize) {
                groupNameStyle.fontSize = this.props.style.groupNameFontSize;
            }
            if (this.props.style.customGroupNameEn) {
                groupNameStyle.customGroupNameEn = this.props.style.customGroupNameEn;
            }
            if (this.props.style.customGroupNameSv) {
                groupNameStyle.customGroupNameSv = this.props.style.customGroupNameSv;
            }
            if (this.props.style.visibilityGroupName) {
                groupNameStyle.visibilityGroupName = this.props.style.visibilityGroupName;
            }

            if (this.props.style.searchLabelColor) {
                searchLabelStyle.color = this.props.style.searchLabelColor;
            }
            if (this.props.style.searchLabelFontWeight) {
                searchLabelStyle.fontWeight = this.props.style.searchLabelFontWeight;
            }
            if (this.props.style.searchLabelFontSize) {
                searchLabelStyle.fontSize = this.props.style.searchLabelFontSize;
            }

            if (this.props.style.showAllLinkColor) {
                showAllLinStyle.color = this.props.style.showAllLinkColor;
            }
            if (this.props.style.showAllLinkFontWeight) {
                showAllLinStyle.fontWeight = this.props.style.showAllLinkFontWeight;
            }
            if (this.props.style.showAllLinkFontSize) {
                showAllLinStyle.fontSize = this.props.style.showAllLinkFontSize;
            }

            if (this.props.style.loginTextColor) {
                loginTextStyle.color = this.props.style.loginTextColor;
            }
            if (this.props.style.loginTextFontWeight) {
                loginTextStyle.fontWeight = this.props.style.loginTextFontWeight;
            }
            if (this.props.style.loginTextFontSize) {
                loginTextStyle.fontSize = this.props.style.loginTextFontSize;
            }
            if (this.props.style.loginButtonBackgroundColor) {
                loginButtonStyle.backgroundColor = this.props.style.loginButtonBackgroundColor;
            }
            if (this.props.style.loginButtonColor) {
                loginButtonStyle.color = this.props.style.loginButtonColor;
            }
            if (this.state.activeElement === 'login-btn' && this.props.activeStyle) {
                if (this.props.activeStyle.loginButtonBackgroundColor) {
                    loginButtonStyle.backgroundColor = this.props.activeStyle.loginButtonBackgroundColor;
                }
                if (this.props.activeStyle.loginButtonColor) {
                    loginButtonStyle.color = this.props.activeStyle.loginButtonColor;
                }
            }



            if (this.props.style.clinicButtonBackgroundColor) {
                clinicButtonStyle.backgroundColor = this.props.style.clinicButtonBackgroundColor;
            }
            if (this.props.style.clinicButtonColor) {
                clinicButtonStyle.color = this.props.style.clinicButtonColor;
            }
            if (this.props.style.clinicButtonFontSize) {
                clinicButtonStyle.fontSize = this.props.style.clinicButtonFontSize;
            }
            if (this.props.style.clinicButtonFontWeight) {
                clinicButtonStyle.fontWeight = this.props.style.clinicButtonFontWeight;
            }
            clinicButtonActiveStyle = { ...clinicButtonStyle }
            if (this.props.activeStyle) {
                if (this.props.activeStyle.clinicButtonBackgroundColor) {
                    clinicButtonActiveStyle.backgroundColor = this.props.activeStyle.clinicButtonBackgroundColor;
                }
                if (this.props.activeStyle.clinicButtonColor) {
                    clinicButtonActiveStyle.color = this.props.activeStyle.clinicButtonColor;
                }
                if (this.props.activeStyle.clinicButtonFontSize) {
                    clinicButtonActiveStyle.fontSize = this.props.activeStyle.clinicButtonFontSize;
                }
                if (this.props.activeStyle.clinicButtonFontWeight) {
                    clinicButtonActiveStyle.fontWeight = this.props.activeStyle.clinicButtonFontWeight;
                }
            }


        }
        let showAllText = 'Show all'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.showAllLinkTextEn) {
            showAllText = this.props.style.showAllLinkTextEn;
        } else if (this.props.style.showAllLinkTextSv) {
            showAllText = this.props.style.showAllLinkTextSv;
        }
        let searchLabelText = 'Search'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.searchLabelTextEn) {
            searchLabelText = this.props.style.searchLabelTextEn;
        } else if (this.props.style.searchLabelTextSv) {
            searchLabelText = this.props.style.searchLabelTextSv;
        }

        let loginButtonText = 'Login'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.loginButtonTextEn) {
            loginButtonText = this.props.style.loginButtonTextEn;
        } else if (this.props.style.loginButtonTextSv) {
            loginButtonText = this.props.style.loginButtonTextSv;
        }

        let searchLabelVisibility = true;
        if (this.props.style.searchLabelVisibility == 'invisible') {
            searchLabelVisibility = false;
        }
        let lastVisitedClinics = this.props.lastVisitedClinics ? this.props.lastVisitedClinics : [];


        if (this.props.lang == 'en' && this.props.style.customGroupNameEn) {
            name = this.props.style.customGroupNameEn;
        } else if (this.props.lang == 'se' && this.props.style.customGroupNameSv) {
            name = this.props.style.customGroupNameSv;
        }
        let visibilityGroupName = this.props.style.visibilityGroupName === 'invisible' ? false : true;


        return (
            <div style={{ ...formatCSS(this.props.style) }} className='main-clinic-data-wrap'>

                <div >
                    <div className='main-data-wrap-v2'>
                        {
                            logo ?

                                <div className='main-data-image-wrap'>
                                    <img src={logo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                                :
                                null

                        }

                        {
                            name && visibilityGroupName ?
                                <p style={{ ...groupNameStyle }}>{name}</p>
                                :
                                null

                        }
                        {
                            buttonsMainLayout ?
                                <div className='clinic-buttons-wrap'>
                                    {
                                        this.props.value?.map((item, idx) => {
                                            const styleElement = this.state.activeElement && this.state.activeElement === item?.link ? { ...clinicButtonActiveStyle } : { ...clinicButtonStyle }

                                            return (
                                                <div
                                                    onMouseEnter={() => this.setState({ activeElement: item?.link })}
                                                    onMouseLeave={() => this.setState({ activeElement: null })}
                                                    className='clinic-btn'
                                                    style={styleElement}
                                                    onClick={() => {
                                                        if (item?.link) {
                                                            this.props[0].history.push(item.link)
                                                        }

                                                    }}>
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            !buttonsMainLayout ?
                                <>
                                    <div className='search-field-wrap'>
                                        {
                                            searchLabelVisibility ?
                                                <h5 style={{ ...searchLabelStyle }}>{searchLabelText}</h5>
                                                :
                                                null
                                        }

                                        {/* <input type='text' disabled /> */}
                                        <SelectWithSearch
                                            width={'300px'}
                                            mapHeight={this.props.mapHeight}
                                            list={this.props.value}
                                            value={this.state.selectedValue}
                                            lastVisitedClinics={lastVisitedClinics}
                                            clearable={true}
                                            lang={this.props.lang}
                                            history={this.props[0].history}
                                            showAll={this.state.showAll}
                                            changeShowAll={(showAll) => this.setState({ showAll })}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    let oldList = this.props.value;
                                                    let newList = [];

                                                    for (let i = 0; i < oldList.length; i++) {

                                                        if (oldList[i].value == e.value) {
                                                            newList.push(oldList[i])
                                                        }
                                                    }
                                                    if (e && e.link) {
                                                        this.props[0].history.push(e.link)
                                                    }
                                                    this.setState({ selectedValue: { name: e.label, value: e.value }, value: newList }, () => this.setState({ updateNeeded: uuidv4() }))
                                                } else if (e == null && this.state.selectedValue) {
                                                    this.setState({ selectedValue: null, value: this.props.value }, () => this.setState({ updateNeeded: uuidv4() }))

                                                }

                                            }}
                                        />
                                    </div>

                                    <div className='show-all-link' onClick={() => this.setState({ showAll: true })}>
                                        <h6 style={{ ...showAllLinStyle }}>{showAllText}</h6>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            !this.props.uData ?

                                <div className='login-info'>
                                    {
                                        loginTxt ?
                                            <p style={{ ...loginTextStyle }}>{loginTxt}</p>
                                            :
                                            null

                                    }
                                    <div
                                        onMouseEnter={() => this.setState({ activeElement: 'login-btn' })}
                                        onMouseLeave={() => this.setState({ activeElement: null })}
                                        className='login-btn'
                                        style={{ ...loginButtonStyle }} onClick={() => {
                                            if (this.props.loginBankId) {
                                                this.props.loginBankId()
                                            }
                                        }}>
                                        {/* {'Login'.translate(this.props.lang)} */}
                                        {loginButtonText}
                                    </div>
                                </div>
                                :
                                null
                        }


                    </div>

                </div>

            </div>

        )
    }
}

export default Map;