import React, { Component } from 'react';
import Page from '../../../containers/page';
import { v4 as uuidv4 } from 'uuid';
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import { formatCSS } from '../../landingBuilder/cssHelper';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT } from '../../../constants';
import ErrorModal from '../../../components/errorModal';
import InfoModal from '../../../components/infoModal';

import defaultJson from '../../landingBuilder/default.json'


import JsonToHtml from '../../landingBuilder/jsonToHtml'
import { th } from 'date-fns/locale';

import CustomSelectWithSearch from '../../../components/forms/fields/customSelectWithSearch';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
class NewCase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            estimatedWaitingTime: null,
            total: 0,
            loading: true,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,

        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        let alias = localStorage.getItem('groupAlias');

        // if(alias)
        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else {

                this.setState({
                    data: result
                }, () => {
                })

            }
        });

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
        if (typeof window != undefined) {
            if (window.innerWidth < 768) {
                this.setState({
                    layout: 'xs'
                })
            } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
                this.setState({
                    layout: 'sm'
                })
            } else if (window.innerWidth > 1024) {
                this.setState({
                    layout: 'lg'
                })
            }
        }
        window.addEventListener('resize', this.resizeWindow);


    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {

        let windowWidth = this.state.windowWidth;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        }

    }

    webBookingCheck = () => {

        let alias = localStorage.getItem('groupAlias');


        fetch(API_ENDPOINT + '/clinicGroup/check/webbooking', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result.canCreateWebBooking) {

                // this.props[0].history.push({
                //     pathname: `/${alias}/choose-time`,
                //     state: { isDropIn: false }
                // })
                fetch(API_ENDPOINT + '/data/without-token/professions/all/' + localStorage.getItem('groupAlias'), {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        isDropIn: false,
                        uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                    })
                }).then(res => res.json()).then((result) => {
                    if (result) {
                        let availableProfessions = [];
                        for (let i = 0; i < result.length; i++) {
                            availableProfessions.push({
                                name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                                value: result[i]._id
                            })
                        }

                        this.setState({ availableProfessions }, () => this.getPersonalQuestionaries())

                    }



                })
            } else if (result.canCreateWebBooking === false) {
                this.setState({
                    error: 'There are no available times to book.'.translate(this.props.lang),
                })
            }
        });



    }
    getPersonalQuestionaries = () => {
        if (!this.state.availableProfessions || (this.state.availableProfessions && this.state.availableProfessions.length == 0)) {
            this.setState({ referrals: [] })
        } else {
            fetch(API_ENDPOINT + '/data/without-token/personal-questionary/all/' + localStorage.getItem('groupAlias'), {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    availableProfessions: this.state.availableProfessions,
                    page: this.state.page,
                    filter: this.state.searchQuestionaries ? this.state.searchQuestionaries : '',
                })
            }).then(res => res.json()).then((result) => {
                // clinicGroup: clinicGroup,
                // referrals: referrals
                if (result.clinicGroup) {
                    this.setState({ clinicGroup: result.clinicGroup })
                }
                if (result.referrals) {
                    this.setState({ referrals: result.referrals }, this.checkSearchedReferrals)
                }
                if (result.total) {
                    this.setState({ total: result.total })
                } else {
                    this.setState({ total: 0 })
                }

            })

        }
    }
    checkSearchedReferrals = () => {
        let searchedReferrals = []
        if (this.state.referrals && this.state.referrals.length) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.searchQuestionaries) {
                    let name = this.state.referrals[i].name;
                    let anotherName = this.state.referrals[i].anotherName;
                    name = name.toLowerCase()
                    anotherName = anotherName.toLowerCase()
                    let searchQuestionaries = this.state.searchQuestionaries;
                    searchQuestionaries = searchQuestionaries.toLowerCase()
                    if ((name.indexOf(searchQuestionaries) != -1 || anotherName.indexOf(searchQuestionaries) != -1) && searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0)
                        searchedReferrals.push(this.state.referrals[i])
                } else if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                    searchedReferrals.push(this.state.referrals[i])
                }
                // if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                //     searchedReferrals.push(this.state.referrals[i])
                // }

            }
        }
        this.setState({ searchedReferrals })
    }
    openCalendar = () => {

        this.props[0].history.push({
            pathname: `/${localStorage.getItem('groupAlias')}/choose-time`,
            state: {
                selectedReferral: this.state.selectedReferral,
                referral: this.state.referral,
                clinicGroup: this.state.clinicGroup
            }
        })

    }
    checkDepartments = (clinic) => {
        let alias = typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null;

        if (clinic) {
            this.setState({ clinicsForSelect: null, clinicSelectOpen: null })
        }

        fetch(API_ENDPOINT + '/clinicGroup/check/departments', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias, clinic })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result.canCreateDropIn) {
                fetch(API_ENDPOINT + '/referrals/check/estimated/time', {
                    method: 'POST',
                    headers: {
                        // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        alias: localStorage.getItem('groupAlias'),
                        clinic: clinic

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result) {
                        if (result.estimatedTime > 0 && this.state.dropInForms) {
                            this.setState({
                                estimatedWaitingTime: result.estimatedTime
                            }, () => {
                                this.forceUpdate()
                            })
                        } else {
                            this.continueDropIn()
                        }


                    }

                })




            } else if (result.canCreateDropIn === false) {
                this.setState({
                    checkWorkingHoursError: 'We are currently unable to accept your drop in referral. Please try again later within the enclosed working hours'.translate(this.props.lang),
                    checkWorkingHoursAvailableTerms: result.availableTerms
                })
            } else if (result.clinics && result.clinics.length) {
                this.setState({
                    clinicsForSelect: result.clinics,
                    selectedClinic: result.clinics[0]._id,
                    clinicSelectOpen: true
                })
            }
        });



    }
    continueDropIn = () => {
        if (this.props.uData) {
            this.props.registerClinicGroupLogs({
                type: 'startCreateReferral',
                referralType: 'Drop in',
                questionaryId: this.state.selectedDropInForm
            })
            let alias = typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null;
            this.props[0].history.push({
                pathname: `/${alias}/drop-in`,
                state: { isDropIn: true, selectedDropInForm: this.state.selectedDropInForm }
            })
        }
    }

    isElementVisible = (key, layout, data) => {
        if (key == 'root') {
            return true;
        }

        if (data[key] && data[key].visibility && !data[key].visibility[layout]) {
            return false;
        }

        if (data[key] && data[key].parent) {
            return this.isElementVisible(data[key].parent, layout, data);
        }

        if (!data[key]) {
            return false;
        }

        //console.log('data', data , key, 'key')

        return true;
    }

    checkDropInForms = () => {
        let alias = typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null;

        if (!alias) {
            return;
        }

        fetch(API_ENDPOINT + '/clinicGroup/check/drop-in/forms', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ alias })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.props[0].history.push(`/`);

            } else if (result && result.availableTerms) {
                this.setState({
                    checkWorkingHoursError: 'We are currently unable to accept your drop in referral. Please try again later within the enclosed working hours'.translate(this.props.lang),
                    checkWorkingHoursAvailableTerms: result.availableTerms
                })
            } else if (result && result.dropInForms && result.dropInForms.length) {
                console.log(result);
                this.setState({
                    dropInForms: result.dropInForms,
                    hideSearchField: result.hideSearchField,
                    selectedDropInForm: null,
                    dropInFormsSelectOpen: true
                })
            } else {
                this.setState({
                    infoMessage: 'There is no active Drop-in form'.translate(this.props.lang)
                })
            }
        });
    }

    setFontSize = (value, fontSize) => {

        let split = value.split('font-size:')
        let returnValue = '';
        if (split && split.length) {
            for (let i = 0; i < split.length; i++) {
                if (i != split.length - 1) {
                    if (i > 0) {
                        let splitFontSize = split[i].split(';')
                        if (splitFontSize && splitFontSize.length) {
                            returnValue += `${fontSize};`;
                            if (splitFontSize.length > 0) {
                                for (let j = 1; j < splitFontSize.length; j++) {
                                    returnValue += splitFontSize[j];
                                    if (j != splitFontSize.length - 1) {
                                        returnValue += ';';
                                    }
                                }
                            }

                        }
                    } else {
                        returnValue += split[i];
                    }
                    returnValue += 'font-size: ';
                } else {
                    let splitFontSize = split[i].split(';')
                    if (splitFontSize && splitFontSize.length) {
                        returnValue += `${fontSize};`;
                        if (splitFontSize.length > 0) {
                            for (let j = 1; j < splitFontSize.length; j++) {
                                returnValue += splitFontSize[j];
                                if (j != splitFontSize.length - 1) {
                                    returnValue += ';';
                                }
                            }
                        }

                    }
                }


            }
        }

        return returnValue;
    }


    render() {
        let layout = 'lg';
        if (typeof window != 'undefined') {
            if (window.innerWidth < 480) {
                layout = 'xs';
            } else if (window.innerWidth >= 480 && window.innerWidth < 1200) {
                layout = 'sm';
            }
        }

        let selectedGroup
        let landingButtons = {
            'grid':
            {
                "lg": {
                    "i": "grid",
                    "w": 100,
                    "h": 16,
                    "x": 0,
                    "y": 0
                },
                "sm": {
                    "i": "grid",
                    "w": 100,
                    "h": 16,
                    "x": 0,
                    "y": 0
                },
                "xs": {
                    "w": 100,
                    "h": 40,
                    "x": 0,
                    "y": 0,
                    "i": "grid",
                    "moved": false,
                    "static": false
                },
                "visibility": {
                    "lg": true,
                    "sm": true,
                    "xs": true
                },
                "style": {},
                "responsiveStyle": {
                    "sm": {},
                    "xs": {
                        "paddingTop": "10",
                        "paddingLeft": "0",
                        "paddingRight": "0",
                        "backgroundColor": "#fff",
                        "borderTopRightRadius": "",
                        "marginTop": "",
                        "borderTopLeftRadius": ""
                    }
                },
                "props": {},
                "responsiveProps": {
                    "sm": {},
                    "xs": {}
                },
                "parent": "root",
                "type": "grid"
            }
        }

        if (this.props.uData.patientGroups && this.props.uData.patientGroups.length && localStorage.groupAlias) {
            for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
                if (localStorage.groupAlias && localStorage.getItem('groupAlias') == this.props.uData.patientGroups[i].alias) {
                    selectedGroup = this.props.uData.patientGroups[i]
                    break;
                }
            }

        }


        if (selectedGroup && selectedGroup.landingJSON_v2) {
            let landingJSON_v2 = JSON.parse(JSON.stringify(selectedGroup.landingJSON_v2));
            for (var key in landingJSON_v2) {


                if (landingJSON_v2[key].type == 'button' && this.isElementVisible(key, layout, landingJSON_v2) && ((landingJSON_v2[key].props && (landingJSON_v2[key].props.type == 'seekCare' || landingJSON_v2[key].props.type == 'dropIn' || landingJSON_v2[key].props.type == 'chooseTime')) || (landingJSON_v2[key].responsiveProps && landingJSON_v2[key].responsiveProps[layout] && (landingJSON_v2[key].responsiveProps[layout].type == 'seekCare' || landingJSON_v2[key].responsiveProps[layout].type == 'dropIn' || landingJSON_v2[key].responsiveProps[layout].type == 'chooseTime')))) {

                    // if (landingJSON_v2[key].type == 'button' && landingJSON_v2[key].props && (landingJSON_v2[key].props.type == 'seekCare' || landingJSON_v2[key].props.type == 'dropIn' || landingJSON_v2[key].props.type == 'chooseTime')) {
                    landingJSON_v2[key].parent = 'grid'
                    landingJSON_v2[key].lg.w = 18
                    landingJSON_v2[key].lg.h = 10
                    landingJSON_v2[key].sm.w = 18
                    landingJSON_v2[key].sm.h = 10
                    landingJSON_v2[key].xs.w = 100
                    landingJSON_v2[key].xs.h = 12
                    if (landingJSON_v2[key].props.iconWidth)
                        landingJSON_v2[key].props.iconWidth = 36
                    if (landingJSON_v2[key].props.iconHeight)
                        landingJSON_v2[key].props.iconHeight = 36

                    let value = landingJSON_v2[key].value;
                    if (value && value.indexOf('font-size:') !== -1) {
                        let check = this.setFontSize(value, '12pt')
                        landingJSON_v2[key].value = check;
                    }


                    landingButtons[key] = (landingJSON_v2[key])

                }


            }
            // console.log('landingButtons', landingButtons)
        } else {
            let landingJson = defaultJson;
            for (var key in landingJson) {
                if (landingJson[key].type == 'button' && landingJson[key].props && (landingJson[key].props.type == 'seekCare' || landingJson[key].props.type == 'dropIn' || landingJson[key].props.type == 'chooseTime')) {
                    landingJson[key].parent = 'grid'
                    landingJson[key].lg.w = 18
                    landingJson[key].lg.h = 10
                    landingJson[key].sm.w = 18
                    landingJson[key].sm.h = 10
                    landingJson[key].xs.w = 100
                    landingJson[key].xs.h = 12
                    if (landingJson[key].props.iconWidth)
                        landingJson[key].props.iconWidth = 36
                    if (landingJson[key].props.iconHeight)
                        landingJson[key].props.iconHeight = 36
                    let value = landingJson[key].value;
                    if (value && value.indexOf('font-size:') !== -1) {
                        let check = this.setFontSize(value, '12pt')
                        landingJson[key].value = check;
                    }

                    landingButtons[key] = (landingJson[key])
                }
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {this.state.estimatedWaitingTime ?
                    <InfoModal
                        isOpen={this.state.estimatedWaitingTime}
                        // No onClose prop in InfoModal
                        // onClose={(item) => {
                        //     this.setState({
                        //         estimatedWaitingTime: item
                        //     })
                        // }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Estimated waiting time is'.translate(this.props.lang) + ' ' + (this.state.estimatedWaitingTime ? parseInt(this.state.estimatedWaitingTime / 60) == 0 ? this.state.estimatedWaitingTime + ' ' + 'minutes'.translate(this.props.lang) : + parseInt(this.state.estimatedWaitingTime / 60) + 'h'.translate(this.props.lang) + ' ' + 'and'.translate(this.props.lang) + ' ' + this.state.estimatedWaitingTime % 60 + ' ' + 'minutes'.translate(this.props.lang) : '/')}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ estimatedWaitingTime: null }, () => {
                                this.continueDropIn()
                            })}>{'Continue'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.setState({ estimatedWaitingTime: null, dropInFormsSelectOpen: null })
                                // if (this.props[0].match.params.mainAlias) {
                                //     this.props[0].history.push(`/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`)
                                // } else {
                                //     this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                // }
                            }}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">

                                <div className='create-case-content' style={{ overflow: 'auto' }}>
                                    {/* <h5>{selectedGroup && selectedGroup.name ? selectedGroup.name : 'Choose clinic'.translate(this.props.lang)}</h5> */}


                                    {/* <p>{selectedGroup ? 'Select how you want so schedule your meeting.'.translate(this.props.lang) : ''}</p> */}
                                    {
                                        selectedGroup?.logo ?
                                            <div className='group-logo-wrap'>
                                                <img src={API_ENDPOINT + selectedGroup.logo} />
                                            </div>
                                            :
                                            null
                                    }

                                    {this.state.data && selectedGroup ?
                                        <JsonToHtml
                                            layout={layout}
                                            nodes={JSON.parse(JSON.stringify(landingButtons))}
                                            uData={this.props.uData}
                                            {...this.props}
                                            data={this.state.data}
                                            dropInButtonShow={this.state.data.dropInButtonShow}
                                            createNewCasePage={true}
                                            // dropInButtonShow={true}

                                            seekCareHandler={() => {
                                                if (this.props.uData) {

                                                    this.props[0].history.push(`/${localStorage.getItem('groupAlias')}/personal-referral`)
                                                }
                                            }}
                                            dropInHandler={() => {
                                                this.checkDropInForms();
                                                // this.checkDepartments()
                                                // if (this.props.uData) {

                                                //     this.props[0].history.push({
                                                //         pathname: `/${localStorage.getItem('groupAlias')}/drop-in`,
                                                //         state: { isDropIn: true }
                                                //     })

                                                // }
                                            }}
                                            chooseTimeHandle={() => {
                                                this.webBookingCheck()
                                            }}
                                        />
                                        :
                                        null
                                    }



                                </div>



                            </div>
                        </Col>
                    </Row>
                </Container>


                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    :
                    null
                }
                {
                    this.state.clinicsForSelect ?
                        <Modal isOpen={this.state.clinicSelectOpen} centered>
                            <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                {'Select department'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="delete-modal">
                                <Input type='select' value={this.state.selectedClinic} onChange={(e) => {
                                    this.setState({ selectedClinic: e.target.value })
                                }}>
                                    {
                                        this.state.clinicsForSelect.map((item, idx) => {
                                            return (
                                                <option value={item._id}>{item.name}, {item.subgroupName}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button color='primary' onClick={() => {
                                    this.checkDepartments(this.state.selectedClinic)
                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.dropInForms ?
                        <Modal isOpen={this.state.dropInFormsSelectOpen} centered>
                            <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                {'Select Drop-in form'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="delete-modal">
                                <CustomSelectWithSearch
                                    isInputClearable={true}
                                    lang={this.props.lang}
                                    items={this.state.dropInForms.map((item, idx) => {
                                        return {
                                            name: item.name,
                                            value: item._id
                                        }
                                    })}
                                    hideSearchField={this.state.hideSearchField}
                                    value={this.state.selectedDropInForm}
                                    maxHeight={'50vh'}
                                    onChange={(e) => {
                                        if (e && e.value && this.state.dropInForms.filter(item => item._id === e.value).length) {
                                            this.setState({ selectedDropInForm: e.value }, () => {
                                                let clinic;
                                                if (this.state.dropInForms && this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm).length) {
                                                    clinic = this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm)[0].clinic;
                                                }
                                                if (clinic) {
                                                    this.checkDepartments(clinic)
                                                }
                                            })
                                        } else {
                                            this.setState({ selectedDropInForm: null })
                                        }


                                    }}
                                />
                                {/* <Input type='select' value={this.state.selectedDropInForm} onChange={(e) => {
                                        console.log(e.target.value)
                                        if (e.target.value && e.target.value != 'Select'.translate(this.props.lang)) {
                                            this.setState({ selectedDropInForm: e.target.value })
                                        } else {
                                            this.setState({ selectedDropInForm: null })
                                        }

                                    }}>
                                        <option value={null}>{'Select'.translate(this.props.lang)}</option>
                                        {
                                            this.state.dropInForms.map((item, idx) => {
                                                return (
                                                    <option value={item._id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input> */}
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                                {/* <Button color='primary' disabled={!this.state.selectedDropInForm} onClick={() => {
                                    let clinic;
                                    if (this.state.dropInForms && this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm).length) {
                                        clinic = this.state.dropInForms.filter(item => item._id == this.state.selectedDropInForm)[0].clinic;
                                    }
                                    if (clinic) {
                                        this.checkDepartments(clinic)
                                    }

                                }}>{'Ok'.translate(this.props.lang)}</Button> */}
                                <Button color='primary' onClick={() => {
                                    this.setState({ dropInFormsSelectOpen: false })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.checkWorkingHoursError ?
                        <InfoModal
                            isOpen={this.state.checkWorkingHoursError}
                            onClose={(item) => {
                                this.setState({
                                    checkWorkingHoursError: item
                                })
                            }}
                            close={true}
                            header={'Info'.translate(this.props.lang)}
                            info={
                                <>
                                    <span style={{ fontWeight: 600 }}>{this.state.checkWorkingHoursError}</span>
                                    <div className='table-error-dropin'>
                                        {
                                            this.state.checkWorkingHoursAvailableTerms && this.state.checkWorkingHoursAvailableTerms.map((item, idx) => {
                                                const startTime = this.props.getStringDate(item.startTimeUTC, 'HH:mm');
                                                // console.log(idx, startTime, item.startTimeUTC);
                                                const endTime = this.props.getStringDate(item.endTimeUTC, 'HH:mm');
                                                const timeUTC = `${startTime} - ${endTime}`
                                                return (


                                                    idx == 0 ?
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Monday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                        :
                                                        idx == 1 ?
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Tuesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                            :
                                                            idx == 2 ?
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Wednesday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                :
                                                                idx == 3 ?
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Thursday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                    :
                                                                    idx == 4 ?
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Friday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                        :
                                                                        idx == 5 ?
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Saturday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                            :
                                                                            idx == 6 ?
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Sunday'.translate(this.props.lang).toUpperCase()}: <span>{item.available ? timeUTC : 'Closed'.translate(this.props.lang)}</span></div>
                                                                                :
                                                                                null


                                                )
                                            })
                                        }
                                    </div>
                                </>

                            }
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkWorkingHoursError: false }, () => {
                                    let alias = typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null;
                                    if (alias)
                                        this.props[0].history.push(`/${alias}`)
                                })}>{'Ok'.translate(this.props.lang)}</Button>,

                            ]}
                        />
                        :
                        null
                }
                {
                    this.state.searchedReferrals ?
                        <Modal isOpen={this.state.searchedReferrals} centered>
                            <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                                {'What do you need help with?'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="delete-modal">
                                <CustomSelectWithSearch
                                    isInputClearable={true}
                                    lang={this.props.lang}
                                    items={this.state.searchedReferrals.filter(item => !item.nonVisible).map((item, idx) => {
                                        return {
                                            name: item.name,
                                            value: item._id
                                        }
                                    })}
                                    value={this.state.selectedReferral}
                                    hideSearchField={this.state.clinicGroup?.hideFormSearchFild}
                                    maxHeight={'50vh'}
                                    onChange={(e) => {
                                        if (e && e.value && this.state.searchedReferrals.filter(item => item._id === e.value).length) {
                                            this.setState({ selectedReferral: e.value }, () => {
                                                let referral = null;
                                                for (let i = 0; i < this.state.searchedReferrals.length; i++) {
                                                    if (this.state.searchedReferrals[i]._id == e.value) {
                                                        referral = this.state.searchedReferrals[i];
                                                        break;
                                                    }
                                                }
                                                this.setState({
                                                    selectedReferral: e.value,
                                                    referral,
                                                }, () => {
                                                    this.openCalendar()
                                                });
                                            })
                                        } else {
                                            this.setState({ selectedReferral: null })
                                        }


                                    }}
                                />
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                                <Button color='primary' onClick={() => {
                                    this.setState({ searchedReferrals: false })
                                }}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
            </div >

        );

    }
}

export default Page(NewCase);