import React, { Component, Fragment } from 'react';
import PageWithLayout from '../../containers/page';
import { API_ENDPOINT } from '../../constants';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Switch from '../../components/forms/fields/switch'
import EconnectCasesList from '../../components/e-connect/EconnectCasesList';
import InfoWithTooltip from '../../components/InfoWithTooltip';
import Checkbox from '../../components/forms/fields/checkbox';
import { Player } from '@lottiefiles/react-lottie-player';
import ErrorModal from '../../components/errorModal';
import InfoModal from '../../components/infoModal';
import { defaultError } from '../../components/TwoFactorAuthModal';
import defaultProfilePicture from '../../assets/images/user.png';
import UserIsNotVerifiedTooltip from '../../components/UserIsNotVerifiedTooltip';
import { getAvailableDocumentTypesForPatientDocuments } from '../patients/components/patientDocuments';
import SyncPatientDocumentsModal from '../patients/components/syncPatientDocumentsModal';

export class EconnectReceivedCasesPage extends Component {
    constructor(props) {
        super(props);

        const adminPermissionId = props?.uData?.permissions?.admin;

        this.state = {
            num: 0,
            view: 'active',
            allCases: adminPermissionId && props?.uData?.groupPermissionsUser?.[props?.selectedGroup]?.[props?.selectedClinic]?.includes?.(adminPermissionId) ? true : false,
            form: null,
            clinicGroupSettings: null,
            error: null,

            message: '',

            highlightedCases: [],
        }
    }

    componentDidMount() {
        this.getClinicGroupSettings();
        this.getPermissionIds();

        if (this.props?.[0]?.location?.state?.messageModal) {
            const highlightedCases = this.state.highlightedCases;
            highlightedCases.push(this.props?.[0]?.location?.state?.messageModal);
            this.setState({ highlightedCases });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
        }
    }

    getPermissionIds = async () => {
        try {
            const response = await fetch(API_ENDPOINT + '/e-connect/permission-types', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response) return;
            const result = await response.json();
            if (!result || result.error || !result.permissions) return;
            this.setState({
                permissions: result.permissions
            });
        } catch (err) {
            console.log('getPermissions err: ', err);
        }
    }

    refresh = () => {
        this.setState(prev => ({ ...prev, num: +prev?.num + 1 }));
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs?.({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    getUserPermissions = () => {
        let isAdmin = false;
        let isDoctor = false;

        if (this.state.permissions?.nurse && this.props.uData?.groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.includes?.(this.state.permissions?.nurse)) isAdmin = true;

        if (this.state.permissions?.doctor && this.props.uData?.groupPermissionsUser?.[this.props.selectedGroup]?.[this.props.selectedClinic]?.includes?.(this.state.permissions?.doctor)) isDoctor = true;

        this.setState({
            isAdmin,
            isDoctor
        });
    }

    approveOrRejectCase = (id, action, _newState) => {
        if (!id || !action || !_newState) return;
        if (!['reject', 'accept', 'assign', 'referral-response', 'forward'].includes(action)) return;
        if ((action === 'reject' || action === 'referral-response' || action === 'forward') && !this.state.message?.trim?.()) return;
        if ((action === 'assign' || action === 'accept') && !this.state.selectedUserId) return;
        this.setState({ _savingProgress: true, loading: true }, async () => {
            let newState = {
                ...(_newState || {}),
                _savingProgress: false,
                loading: false
            };

            let url = `${API_ENDPOINT}/e-connect/cases/${id}`
            let method = 'PUT';
            let body = JSON.stringify({
                action,
                groupId: this.props.selectedGroup,
                departmentId: this.props.selectedClinic,
                data: {
                    message: this.state.message,
                    userId: this.state.selectedUserId,
                    forwardData: this.state.forwardData ?? {}
                }
            });

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body,
            });

            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }

            const result = await response.json();

            if (!result || result.error) {
                newState.error = result.error || defaultError;
                return this.setState(newState, () => {
                    this.refresh();
                });
            }

            newState.form = null;
            newState.answerAndResponseModal = null;
            this.setState(newState, () => {
                this.refresh();
            });
        });
    }

    archiveCase = () => {
        if (!this.state.archiveModal) return;
        this.setState({ loading: true }, async () => {
            const from = this.state._archiveType === 'forwarded' ? 'forwarder' : 'receiver';
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/${this.state.archiveModal}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                },
                body: JSON.stringify({
                    action: 'archive',
                    from,
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic
                })
            });
            if (!response) return this.setState({
                loading: false,
                error: defaultError,
                archiveModal: null,
                _archiveType: null
            });
            const result = await response.json();
            if (!result || result.error) return this.setState({
                loading: false,
                error: result.error || defaultError,
                archiveModal: null,
                _archiveType: null
            });

            return this.setState({
                loading: false,
                archiveModal: null,
                _archiveType: null,
                form: null
            }, () => {
                this.refresh();
            })
        })
    }

    getDoctors = (id, type) => {
        if (!id) return;
        this.setState({ _savingProgress: true, loading: true, doctors: [] }, async () => {
            const newState = {
                loading: false,
                _savingProgress: false
            }
            const response = await fetch(API_ENDPOINT + `/e-connect/doctors?groupId=${this.props.selectedGroup}&departmentId=${this.props.selectedClinic}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response || !response.ok) {
                newState.error = defaultError;
                return this.setState(newState);
            };
            const result = await response.json();
            if (!result || result.error || !result.doctors) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            };

            newState.doctors = result.doctors && Array.isArray(result.doctors) ? result.doctors : [];
            newState.assignModal = {
                id: id,
                external: type
            };
            this.setState(newState);
        })
    }

    print = (id) => {
        if (!id) return;
        this.setState({ loading: true, _savingProgress: true }, async () => {
            const newState = {
                loading: false,
                _savingProgress: false
            }
            const response = await fetch(API_ENDPOINT + `/e-connect/cases/pdf/${id}?lang=${this.props.lang || 'se'}&groupId=${this.props.selectedGroup}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                }
            });
            if (!response) {
                newState.error = defaultError;
                return this.setState(newState);
            }
            const result = await response.json();
            if (!result || !result.url || result.error) {
                newState.error = result.error || defaultError;
                return this.setState(newState);
            }

            this.setState(newState, () => {
                window?.open?.(API_ENDPOINT + result.url, '_blank');
            });
        })
    }

    addClinicModalHandler = () => {
        if (this.state._availableClinics) return this.setState({
            loading: false,
            error: null,
            addClinicModal: {
                availableClinics: this.state._availableClinics,
                manual: {
                    name: '',
                    street: '',
                    zip: '',
                    city: '',
                    phone: '',
                    email: ''
                }
            }
        });


        this.setState({ loading: true }, async () => {
            const response = await fetch(API_ENDPOINT + '/e-connect/clinics?selectedGroupId=' + this.props.selectedGroup, {
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            });

            if (!response || !response.ok) return this.setState({ loading: false, error: defaultError });

            const result = await response.json();
            if (!result || !result.clinics) return this.setState({ loading: false, error: defaultError });

            const availableClinics = result.clinics?.filter?.(c => String(c._id) !== this.props.selectedGroup && String(c._id) !== String(this.state._originalSender))?.map?.(clinic => ({
                id: clinic._id,
                logo: API_ENDPOINT + clinic?.logo,
                name: clinic?.name,
                address: clinic?.landingConfig?.address?.length ? clinic.landingConfig.address.join?.('; ') : '',
                phone: clinic?.landingConfig?.phone ?? ''
            }))

            this.setState({
                loading: false,
                error: null,
                addClinicModal: {
                    availableClinics,
                    manual: {
                        name: '',
                        street: '',
                        zip: '',
                        city: '',
                        phone: '',
                        email: ''
                    }
                },
                _availableClinics: availableClinics
            });
        });
    }

    render() {
        let canOpenProfile = false;
        if (this.state.clinicGroupSettings?.enterprise || this.state.clinicGroupSettings?.canSearchPatients) canOpenProfile = true;
        let enterprise = this.state.clinicGroupSettings?.enterprise ? true : false;
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row yle={{ marginBottom: 10 }}>
                        <Col lg='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
                            {/* <h2 style={{ fontWeight: '800' }}>{'All Received E-Connect Cases'.translate(this.props.lang)}</h2> */}
                            {this.props.uData?.isGroupAdmin?.[this.props.selectedGroup]?.[this.props.selectedClinic] ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, position: 'relative', right: '6%' }}>
                                <span>{'Show all e-connect cases'.translate(this.props.lang)}</span>
                                <Switch value={this.state.allCases} onChange={(e) => {
                                    this.setState({ allCases: e?.target?.checked })
                                }} />
                            </div> : null}
                        </Col>
                    </Row>

                    <div className='e-connect-tabs'>
                        <Button className={this.state.view === 'active' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'active') return;
                            this.setState({ view: 'active' })
                        }}>{'Active'.translate(this.props.lang)}</Button>
                        <Button className={this.state.view === 'archive' ? 'active-tab-button' : ''} color='secondary' onClick={() => {
                            if (this.state.view === 'archive') return;
                            this.setState({ view: 'archive' })
                        }}>{'Archive'.translate(this.props.lang)}</Button>
                    </div>

                    {this.state.view === 'active' ? <Fragment key='received-active'>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Received e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='received'
                                        _page='received'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.getUserPermissions();
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) this.print(item._id);
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.getUserPermissions();
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases });
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Answered e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='answered'
                                        _page='received'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.getUserPermissions();
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onArchive={item => {
                                            if (item && item._id) this.setState({ archiveModal: item._id });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) this.print(item._id);
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.getUserPermissions();
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Forwarded e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='forwarded'
                                        _page='received'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.getUserPermissions();
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onArchive={item => {
                                            if (item && item._id) this.setState({ archiveModal: item._id, _archiveType: 'forwarded' });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) this.print(item._id);
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.getUserPermissions();
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                    // onChat={item => {
                                    //     if (item && item.conversationId) {
                                    //         this.setState({ chatModal: item });
                                    //         if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                    //             const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                    //             this.setState({ highlightedCases })
                                    //         }
                                    //     }
                                    // }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment> : this.state.view === 'archive' ? <Fragment key='received-archive'>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Archived e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='received-archive'
                                        _page='received'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.getUserPermissions();
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }
                                        }
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) this.print(item._id);
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.getUserPermissions();
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onChat={item => {
                                            if (item && item.conversationId) {
                                                this.setState({ chatModal: item });
                                                if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                    const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                    this.setState({ highlightedCases })
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <div className='panel aeb-panel'>
                                    <div className='panel-header'>
                                        <h5 className='component-header'>
                                            {'Archived Forwarded e-Connect Cases'.translate(this.props?.lang || 'en')}
                                        </h5>
                                    </div>
                                    <EconnectCasesList
                                        {...this.props}
                                        highlightedCases={this.state.highlightedCases}
                                        num={this.state.num}
                                        refresh={this.refresh}
                                        allCases={this.state.allCases}
                                        type='forwarded-archive'
                                        _page='received'
                                        canOpenProfile={canOpenProfile}
                                        enterprise={this.state.clinicGroupSettings?.enterprise}
                                        onPreview={(item) => {
                                            this.getUserPermissions();
                                            this.setState({ form: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onHistory={(item) => {
                                            this.setState({ history: item });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }
                                        }
                                        uData={this.props.uData}
                                        onPrint={(item) => {
                                            if (item && item._id) this.print(item._id);
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                        onReferralResponse={(item) => {
                                            this.getUserPermissions();
                                            this.setState({
                                                answerAndResponseModal: item
                                            });
                                            if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                                const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                                this.setState({ highlightedCases })
                                            }
                                        }}
                                    // onChat={item => {
                                    //     if (item && item.conversationId) {
                                    //         this.setState({ chatModal: item });
                                    //         if (item?._id && this.state.highlightedCases?.includes(item._id)) {
                                    //             const highlightedCases = this.state.highlightedCases.filter(id => id !== item._id);
                                    //             this.setState({ highlightedCases })
                                    //         }
                                    //     }
                                    // }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment> : null}
                </Container>

                {
                    this.state.form ? <Modal key='form-modal' isOpen={this.state.form} size='xl' centered>
                        <ModalHeader toggle={() => this.setState({ form: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                            this.setState({ form: null })
                        }}>&times;</button>}>
                            {'Received Case'.translate(this.props.lang)}
                            <InfoWithTooltip
                                style={{ bottom: 4 }}
                                text={this.state.form?.receiver?.status === 'received' ? (
                                    'This case has been received from another clinic. You can either accept the case and let your clinic handle the patient, or reject it and return the patient handling to sender clinic'
                                ) : this.state.form?.receiver?.status === 'rejected' ? (
                                    'This case has been rejected by your clinic. It will now be further handled by the sender clinic. You can archive this case now'
                                ) : this.state.form?.receiver?.status === 'in-progress' ? (
                                    'This case has been accepted by your clinic and is not in progress. Once the case is done, you can archive it'
                                ) : this.state.form?.receiver?.status === 'archived' ? (
                                    'This case has been archived. No further actions can be taken at this time'
                                ) : ''}
                                lang={this.props.lang}
                            />
                        </ModalHeader>
                        <ModalBody style={{ position: 'relative' }}>
                            {this.state.form?.forward ? <Row>
                                <Col>
                                    <Alert className='ec-details-container'>
                                        <details>
                                            <summary>
                                                <strong>{'Referral Forwarded'.translate(this.props.lang)}</strong>: {this.state.form?.forward?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric'
                                                }).format(this.state.form?.forward?.ts) : null}
                                            </summary>
                                            <div>
                                                <Row>
                                                    {/* <Col lg='3'>
                                                        <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                        <div className={this.state.form?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.form?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                            {`${this.state.form?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.form?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                        </div>

                                                    </Col> */}
                                                    <Col lg='6'>
                                                        <h6>{'Forwarding department'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.forward?.user?.departmentName || 'N/A'}</div>
                                                    </Col>
                                                    <Col lg='6'>
                                                        <h6>{'Forwarding personel'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.forward?.user?.name || 'N/A'}</div>
                                                    </Col>
                                                    {/* <Col lg='3'>
                                                        <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                        <div>{this.state.form?.answer?.user?.assignedDoctorName || this.state.form?.answer?.user?.name || 'N/A'}</div>
                                                    </Col> */}
                                                </Row>
                                                <hr />
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col>
                                                        <FormGroup>
                                                            <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                            <p>
                                                                {this.state.form?.forward?.message}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </details>
                                    </Alert>
                                </Col>
                            </Row> : null}
                            <Row>
                                <Col lg='7' >
                                    <div key='div-patient-asdf' className='econnect-create-patient'>
                                        <div>
                                            <div>
                                                <h6>{'Social security number'.translate(this.props.lang)}:</h6>
                                                <p>{this.state.form?.patient?.ssn} {this.state.form?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</p>
                                            </div>
                                            <div>
                                                <h6>{'Name'.translate(this.props.lang)}:</h6>
                                                <p>{this.state.form?.patient?.name}</p>
                                            </div>
                                        </div>
                                        {this.state.form?._id ? <button onClick={() => {
                                            const patient = this.state.form?.patient;
                                            this.setState({
                                                casePatientModal: patient
                                            })
                                        }}>{'See patient info'.translate(this.props.lang)}</button> : null}
                                    </div>
                                    <FormGroup key='fg-consent'>
                                        <Checkbox disabled checked={this.state.form.patientConsent} label={'Patient has given consent to be referred and their information to be shared'.translate(this.props.lang)} />
                                    </FormGroup>
                                    <FormGroup key='fg-subject'>
                                        <Label className='e-connect-title' htmlFor='e-cr-subject'>{'Subject'.translate(this.props.lang)}:</Label>
                                        <Input disabled type='textarea' value={this.state.form?.subject || ''} rows='2' />
                                    </FormGroup>
                                </Col>
                                <Col lg='5'>
                                    <h6 className='e-connect-title'>{'Sending clinic'.translate(this.props.lang)}:</h6>
                                    {this.state.form?.sender?.type ? <div className={`econnect-receiver-card disabled`}>
                                        <p style={this.state.form?.sender?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.form?.sender?.userName ? undefined : 'ec-clinic-name'}>{this.state.form?.sender?.name}</p>
                                        {this.state.form?.sender?.userName ? <p className='ec-clinic-name'>{this.state.form?.sender?.userTitle ? `${this.state.form?.sender?.userTitle} ` : ''}{this.state.form?.sender?.userName}</p> : null}

                                        {this.state.form?.sender?.phone || this.state.form?.sender?.email ? <div className='ec-clinic-contact-div'>
                                            {this.state.form?.sender?.phone ? <p className='ec-clinic-phone'>{this.state.form?.sender?.phone}</p> : null}
                                            {this.state.form?.sender?.email ? <p className='ec-clinic-email'>{this.state.form?.sender?.email}</p> : null}
                                        </div> : null}

                                        {this.state.form?.sender?.address ? <div className='ec-clinic-address'>
                                            {this.state.form?.sender?.address?.split?.(';')?.map?.(addr => <p>
                                                {addr}
                                            </p>)}
                                        </div> : null}

                                        <div className={`ec-clinic-type ec-clinic-type-${this.state.form?.sender?.type}`}>{this.state.form?.sender?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                    </div> : null}
                                    {this.state.form?.forwarder ? <div style={{ marginTop: 10 }}>
                                        {this.state.form?.forwarder?.id === this.props.selectedGroup ? <>
                                            <h6 className='e-connect-title'>{'Receiving clinic'.translate(this.props.lang)}:</h6>
                                            {this.state.form?.receiver?.type ? <div className={`econnect-receiver-card disabled`}>
                                                <p style={this.state.form?.receiver?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.form?.receiver?.userName ? undefined : 'ec-clinic-name'}>{this.state.form?.receiver?.name}</p>
                                                {this.state.form?.receiver?.userName ? <p className='ec-clinic-name'>{this.state.form?.receiver?.userTitle ? `${this.state.form?.receiver?.userTitle} ` : ''}{this.state.form?.receiver?.userName}</p> : null}

                                                {this.state.form?.receiver?.phone || this.state.form?.receiver?.email ? <div className='ec-clinic-contact-div'>
                                                    {this.state.form?.receiver?.phone ? <p className='ec-clinic-phone'>{this.state.form?.receiver?.phone}</p> : null}
                                                    {this.state.form?.receiver?.email ? <p className='ec-clinic-email'>{this.state.form?.receiver?.email}</p> : null}
                                                </div> : null}

                                                {this.state.form?.receiver?.address ? <div className='ec-clinic-address'>
                                                    {this.state.form?.receiver?.address?.split?.(';')?.map?.(addr => <p>
                                                        {addr}
                                                    </p>)}
                                                </div> : null}

                                                <div className={`ec-clinic-type ec-clinic-type-${this.state.form?.receiver?.type}`}>{this.state.form?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                            </div> : null}
                                        </> : <>
                                            <h6 className='e-connect-title'>{'Forwarding clinic'.translate(this.props.lang)}:</h6>
                                            {this.state.form?.forwarder?.type ? <div className={`econnect-receiver-card disabled`}>
                                                <p style={this.state.form?.forwarder?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.form?.forwarder?.userName ? undefined : 'ec-clinic-name'}>{this.state.form?.forwarder?.name}</p>
                                                {this.state.form?.forwarder?.userName ? <p className='ec-clinic-name'>{this.state.form?.forwarder?.userTitle ? `${this.state.form?.forwarder?.userTitle} ` : ''}{this.state.form?.forwarder?.userName}</p> : null}

                                                {this.state.form?.forwarder?.phone || this.state.form?.forwarder?.email ? <div className='ec-clinic-contact-div'>
                                                    {this.state.form?.forwarder?.phone ? <p className='ec-clinic-phone'>{this.state.form?.forwarder?.phone}</p> : null}
                                                    {this.state.form?.forwarder?.email ? <p className='ec-clinic-email'>{this.state.form?.forwarder?.email}</p> : null}
                                                </div> : null}

                                                {this.state.form?.forwarder?.address ? <div className='ec-clinic-address'>
                                                    {this.state.form?.forwarder?.address?.split?.(';')?.map?.(addr => <p>
                                                        {addr}
                                                    </p>)}
                                                </div> : null}

                                                <div className={`ec-clinic-type ec-clinic-type-${this.state.form?.forwarder?.type}`}>{this.state.form?.forwarder?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                            </div> : null}
                                        </>}
                                    </div> : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label className='e-connect-title' htmlFor='e-conn-anamnesis'>
                                            {'Anamnesis'.translate(this.props.lang)}:
                                        </Label>
                                        <Input disabled rows='5' id='e-conn-anamnesis' type='textarea' value={this.state.form?.anamnesis || ''} />
                                    </FormGroup>
                                </Col>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label className='e-connect-title' htmlFor='e-conn-status'>
                                            {'Status'.translate(this.props.lang)}:
                                        </Label>
                                        <Input disabled rows='5' id='e-conn-status' type='textarea' value={this.state.form?.status || ''} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.form?.attachments?.length > 0 ? <Row>
                                <Col>
                                    <h6 className='e-connect-title'>
                                        {'Attachments'.translate(this.props.lang)}:
                                    </h6>
                                    <div className='e-connect-modal-attachments'>
                                        <div className={`ec-disabled`}>
                                            {this.state.form?.attachments?.map?.((item, i) => {
                                                if (!item?.url) return null;
                                                let sync;
                                                try {
                                                    if (this.props.selectedGroup && this.state.form.patient?.type === 'internal' && this.state.form.patient.id && enterprise && this.state.form._id) {
                                                        const alreadySynced = this.state.form.patientDocumentsSync?.find?.(doc => doc && doc.url === item.url && doc.patientId === this.state.form.patient.id && doc.groupId === this.props.selectedGroup);
                                                        const extension = '.' + item.url.split('.').pop().toLowerCase();
                                                        const isValidExt = extension && getAvailableDocumentTypesForPatientDocuments(true).includes(extension);
                                                        if (!alreadySynced && isValidExt) sync = {
                                                            isEnabled: true,
                                                            onSync: async (e) => {
                                                                e?.stopPropagation?.();
                                                                this.setState({
                                                                    _syncPatientDocumentsModal: {
                                                                        data: {
                                                                            view: 'doctor',
                                                                            groupId: this.props.selectedGroup,
                                                                            patientId: this.state.form.patient.id,
                                                                            document: { id: 'new', isSharedWithPatient: false },
                                                                            src: 'eConnect',
                                                                            eConnectCaseId: this.state.form._id,
                                                                            documentUrl: item.url,
                                                                        },
                                                                        lang: this.props.lang,
                                                                        onSync: (bodyData, isSharedWithPatient = false) => {
                                                                            this.setState({ loading: true }, async () => {
                                                                                try {
                                                                                    const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                                                    const body = JSON.stringify({
                                                                                        ...bodyData,
                                                                                        document: {
                                                                                            ...bodyData.document,
                                                                                            isSharedWithPatient
                                                                                        }
                                                                                    });

                                                                                    const response = await fetch(url, {
                                                                                        method: 'POST',
                                                                                        headers: {
                                                                                            'Content-Type': 'application/json',
                                                                                            'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                                                                                        },
                                                                                        body
                                                                                    });

                                                                                    if (!response) return this.setState({
                                                                                        error: 'Something went wrong! Please refresh your browser and try again!',
                                                                                        loading: false,
                                                                                        _syncPatientDocumentsModal: null
                                                                                    });

                                                                                    const data = await response.json();
                                                                                    if (!data || data.error || !data.documents) return this.setState({
                                                                                        error: data.error || 'Something went wrong! Please refresh your browser and try again!',
                                                                                        loading: false,
                                                                                        _syncPatientDocumentsModal: null
                                                                                    });

                                                                                    this.setState({ loading: false, _syncPatientDocumentsModal: null }, () => {
                                                                                        this.refresh();

                                                                                        this.setState(prev => ({
                                                                                            ...prev,
                                                                                            loading: false,
                                                                                            form: {
                                                                                                ...(prev?.form || {}),
                                                                                                patientDocumentsSync: [
                                                                                                    ...(prev?.form?.patientDocumentsSync || []),
                                                                                                    {
                                                                                                        url: item.url,
                                                                                                        patientId: this.state.form.patient.id,
                                                                                                        groupId: this.props.selectedGroup
                                                                                                    }
                                                                                                ]
                                                                                            }
                                                                                        }));
                                                                                    });
                                                                                } catch (err) {
                                                                                    console.log('Error syncing document', err);
                                                                                    this.setState({
                                                                                        error: 'Something went wrong! Please refresh your browser and try again!',
                                                                                        loading: false
                                                                                    })
                                                                                }
                                                                            })
                                                                        },
                                                                        onCancel: () => this.setState({ _syncPatientDocumentsModal: null })
                                                                    }
                                                                })
                                                                // this.setState({ loading: true }, async () => {
                                                                //     try {
                                                                //         const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                                //         const body = JSON.stringify({
                                                                //             view: 'doctor',
                                                                //             groupId: this.props.selectedGroup,
                                                                //             patientId: this.state.form.patient.id,
                                                                //             document: { id: 'new' },
                                                                //             src: 'eConnect',
                                                                //             eConnectCaseId: this.state.form._id,
                                                                //             documentUrl: item.url,
                                                                //         });

                                                                //         const response = await fetch(url, {
                                                                //             method: 'POST',
                                                                //             headers: {
                                                                //                 'Content-Type': 'application/json',
                                                                //                 'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                                                                //             },
                                                                //             body
                                                                //         });

                                                                //         if (!response) return this.setState({
                                                                //             error: 'Something went wrong! Please refresh your browser and try again!',
                                                                //             loading: false
                                                                //         });

                                                                //         const data = await response.json();
                                                                //         if (!data || data.error || !data.documents) return this.setState({
                                                                //             error: data.error || 'Something went wrong! Please refresh your browser and try again!',
                                                                //             loading: false
                                                                //         });

                                                                //         this.setState({ loading: false }, () => {
                                                                //             this.refresh();

                                                                //             this.setState(prev => ({
                                                                //                 ...prev,
                                                                //                 loading: false,
                                                                //                 form: {
                                                                //                     ...(prev?.form || {}),
                                                                //                     patientDocumentsSync: [
                                                                //                         ...(prev?.form?.patientDocumentsSync || []),
                                                                //                         {
                                                                //                             url: item.url,
                                                                //                             patientId: this.state.form.patient.id,
                                                                //                             groupId: this.props.selectedGroup
                                                                //                         }
                                                                //                     ]
                                                                //                 }
                                                                //             }));
                                                                //         });
                                                                //     } catch (err) {
                                                                //         console.log('Error syncing document', err);
                                                                //         this.setState({
                                                                //             error: 'Something went wrong! Please refresh your browser and try again!',
                                                                //             loading: false
                                                                //         })
                                                                //     }
                                                                // })
                                                            }
                                                        }
                                                    }
                                                } catch (err) { }
                                                return <div
                                                    key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                    className='econnect-attachment-item'
                                                >
                                                    <div>
                                                        <button
                                                            title={'Opens in new browser tab'.translate(this.props.lang)}
                                                            onClick={() => {
                                                                if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                            }}
                                                        >
                                                            &#91;
                                                            {'Open'.translate(this.props.lang)}
                                                            &#93;
                                                        </button>
                                                        <div>{item?.name}</div>
                                                    </div>
                                                    {sync?.isEnabled ? <button
                                                        className='sync-button'
                                                        type='button'
                                                        onClick={sync.onSync}
                                                    >
                                                        &#91;
                                                        {'Sync to patient profile'.translate(this.props.lang)}
                                                        &#93;
                                                    </button> : null}
                                                </div>
                                            })}
                                        </div>
                                        <div className={`ec2-disabled`} />
                                    </div>
                                </Col>
                            </Row> : null}
                            {this.state.loading ? <div className='econnect-loader'><Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            /></div> : null}
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                            {false && this.state.isDoctor && this.state.form?.sender?.type !== 'external' && this.state.form?.receiver?.status === 'in-progress' && this.state.form?.receiver?.user && String(this.state.form?.receiver?.user) === String(this.props.uData?._id) ? <Button
                                color='info'
                                disabled={this.state._savingProgress}
                                onClick={() => {
                                    if (this.state.isDoctor && this.state.form?.receiver?.status === 'in-progress' && this.state.form?.receiver?.user && String(this.state.form?.receiver?.user) === String(this.props.uData?._id)) {
                                        console.log(item?.receiver?.type, item?.sender?.type, item?.receiver?.type === 'internal' && item?.sender?.type === 'internal');
                                        this.setState({
                                            referralResponseModal: {
                                                id: this.state.form?._id,
                                            }
                                        })
                                    }
                                }}
                            >
                                {'Send Referral Response'.translate(this.props.lang)}
                            </Button> : null}

                            <Button
                                style={{ minWidth: 100 }}
                                color='primary'
                                onClick={() => {
                                    if (!this.state.form?._id) return;
                                    this.print(this.state.form?._id);
                                }}
                            >{'Save as PDF'.translate(this.props.lang)}</Button>

                            {this.state.isDoctor && this.state.form?.receiver?.status === 'received' && this.state.form?.receiver?.id === this.props.selectedGroup ? <Button color='info' disabled={this.state._savingProgress} onClick={() => {
                                if (!this.state.isDoctor || !this.state.form?.receiver?.status || this.state.form?.receiver?.status !== 'received' || !this.state.form?._id) return;
                                this.setState({
                                    pickUpModal: {
                                        id: this.state.form?._id,
                                        external: this.state.form?.sender?.type === 'external'
                                    }
                                });
                            }}>
                                {'Accept and Pick Up'.translate(this.props.lang)}
                            </Button> : null}

                            {this.state.isAdmin && this.state.form?.receiver?.status === 'received' && this.state.form?.receiver?.id === this.props.selectedGroup ? <Button color='info' disabled={this.state._savingProgress} onClick={() => {
                                if (!this.state.isAdmin || !this.state.form?.receiver?.status || this.state.form?.receiver?.status !== 'received' || !this.state.form?._id) return;
                                this.getDoctors(this.state.form?._id, this.state.form?.sender?.type);
                            }}>
                                {'Accept and Assign'.translate(this.props.lang)}
                            </Button> : null}

                            {(this.state.isAdmin || this.state.isDoctor) && this.state.form?.receiver?.status === 'received' /*&& this.state.form?.sender?.type === 'internal'*/ && !this.state.form?.forwarder && this.state.form?.receiver?.id === this.props.selectedGroup ? <Button
                                color='primary'
                                disabled={this.state._savingProgress}
                                onClick={() => {
                                    if ((!this.state.isAdmin && !this.state.isDoctor) || !this.state.form?._id) return;
                                    this.setState({
                                        forwardCase: this.state.form?._id,
                                        _originalSender: this.state.form?.sender?.id,
                                        forwardData: {
                                            patientConsent: false,
                                            receiver: {
                                                type: '',
                                            }
                                        }
                                    });
                                }}
                            >
                                {'Forward referral'.translate(this.props.lang)}
                            </Button> : null}

                            {(this.state.isAdmin || this.state.isDoctor) && this.state.form?.receiver?.status === 'received' && this.state.form?.receiver?.id === this.props.selectedGroup ? <Button color='danger' disabled={this.state._savingProgress} onClick={() => {
                                if (!this.state.isAdmin && !this.state.isDoctor || !this.state.form?._id) return;
                                this.setState({
                                    rejectCase: this.state.form?._id,
                                    rejectCaseExternal: this.state.form?.sender?.type === 'external'
                                });
                            }}>
                                {'Deny'.translate(this.props.lang)}
                            </Button> : null}

                            <Button style={{ minWidth: 100 }} onClick={() => {
                                this.setState({ form: null })
                            }} color='primary' disabled={this.state._savingProgress}>
                                {'Close'.translate(this.props.lang)}
                            </Button>

                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        </ModalFooter>
                    </Modal> : null
                }

                {this.state._syncPatientDocumentsModal ? <SyncPatientDocumentsModal {...this.state._syncPatientDocumentsModal} /> : null}

                {this.state.pickUpModal ? <Modal size='md' key='pick-up-modal' isOpen={this.state.pickUpModal} centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ pickUpModal: null, message: '', selectedUserId: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ pickUpModal: null, message: '', selectedUserId: null })}>&times;</button>}>
                        {'Pick up this case?'.translate(this.props.lang)}
                        {' '}
                        <InfoWithTooltip style={{ bottom: 4 }} text={this.state.pickUpModal?.external ? 'If you pick this case up it will be assigned to you. Since sending clinic is external, you have to manually notify them of any progress on the case, should you choose to do so.' : 'If you pick this case up, sending clinic will be notified that the case will now be processed by your clinic, and it will be assigned to you'} lang={this.props.lang} />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >{'Message for sending clinic (optional)'.translate(this.props.lang)}:</Label>
                                    {
                                        this.state.pickUpModal.external ? <p className='text-red'>{'External referral - response not sent automatically'.translate(this.props.lang)}</p> : null
                                    }
                                    <Input type='textarea' value={this.state.message} onChange={e => {
                                        const message = e?.target?.value;
                                        this.setState({ message });
                                    }} rows='5' />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                        <Button disabled={this.state._savingProgress || !this.props.uData?._id} color='info' onClick={() => {
                            if (!this.state.isDoctor || !this.state.pickUpModal?.id || !this.props.uData?._id) return;
                            this.setState({ selectedUserId: this.props.uData?._id }, () => {
                                this.approveOrRejectCase(this.state.pickUpModal?.id, 'accept', { rejectCase: null, rejectCaseExternal: null, message: '', form: null, selectedUserId: null, pickUpModal: null })
                            })
                        }}>
                            {'Pick Up'.translate(this.props.lang)}
                        </Button>

                        <Button disabled={this.state._savingProgress} color='primary' onClick={() => { this.setState({ pickUpModal: null, message: '', selectedUserId: null }) }}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>

                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.referralResponseModal?.id ? <Modal size='md' key='pick-up-modal' isOpen={this.state.referralResponseModal?.id} centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ referralResponseModal: null, message: '', selectedUserId: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referralResponseModal: null, message: '', selectedUserId: null })}>&times;</button>}>
                        {'Send Referral Response'.translate(this.props.lang)}
                        {' '}
                        <InfoWithTooltip style={{ bottom: 4 }} text='Here you can send additional information about the case and its handling to sending clinic. Sending clinic will be able to read this message' lang={this.props.lang} />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    {
                                        this.state.referralResponseModal?.external ? <p className='text-red'>{'External referral - response not sent automatically'.translate(this.props.lang)}</p> : null
                                    }
                                    <Label>*{'Comment'.translate(this.props.lang)}:</Label>
                                    <Input type='textarea' value={this.state.message} onChange={e => {
                                        const message = e?.target?.value;
                                        this.setState({ message });
                                    }} rows='5' />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                        <Button disabled={this.state._savingProgress || !this.props.uData?._id || !this.state.message?.trim?.()} color='info' onClick={() => {
                            if (!this.state.isDoctor || !this.state.referralResponseModal?.id || !this.props.uData?._id) return;
                            this.approveOrRejectCase(this.state.referralResponseModal?.id, 'referral-response', { rejectCase: null, rejectCaseExternal: null, message: '', form: null, selectedUserId: null, referralResponseModal: null })
                        }}>
                            {'Send'.translate(this.props.lang)}
                        </Button>

                        <Button disabled={this.state._savingProgress} color='primary' onClick={() => { this.setState({ referralResponseModal: null, message: '', selectedUserId: null }) }}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>

                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.assignModal ? <Modal size='md' key='assign-up-modal' isOpen={this.state.assignModal} centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ assignModal: null, message: '', selectedUserId: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ assignModal: null, message: '', selectedUserId: null })}>&times;</button>}>
                        {'Assign this case ?'.translate(this.props.lang)}
                        {' '}
                        <InfoWithTooltip style={{ bottom: 4 }} text='From the list bellow you can choose a caregiver from this department to whom you want to assign this case, if any is available. If you want to assign this case to a caregiver from another department, please change your selected department first' lang={this.props.lang} />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>*{'Select caregiver'.translate(this.props.lang)}:</Label>
                                    {this.state.doctors?.length > 0 ? <div className='econnect-doctors-list'>
                                        {this.state.doctors?.map?.(doctor => <div key={doctor?._id} onClick={() => {
                                            if (doctor?._id) this.setState({ selectedUserId: doctor._id })
                                        }} title={'Select'.translate(this.props.lang)} className={this.state.selectedUserId && this.state.selectedUserId === doctor?._id ? 'selected-doctor' : undefined}>
                                            <div>
                                                <div>
                                                    <img alt='profile pic' src={doctor?.profilePicture ? API_ENDPOINT + doctor.profilePicture : defaultProfilePicture} />
                                                </div>
                                                <div>
                                                    <h6>{doctor?.userData?.name || doctor?.name || ''}</h6>
                                                    <p>{doctor?.username}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <Checkbox checked={this.state.selectedUserId && this.state.selectedUserId === doctor?._id} onChange={() => {
                                                    if (doctor?._id) this.setState({ selectedUserId: doctor._id });
                                                }} />
                                            </div>
                                        </div>)}
                                    </div> : <p>
                                        {'There are no available caregivers in this department, at this time.'.translate(this.props.lang)}
                                    </p>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >{'Message for sending clinic (optional)'.translate(this.props.lang)}:</Label>
                                    {
                                        this.state.assignModal.external === 'external' ? <p className='text-red'>{'External referral - response not sent automatically'.translate(this.props.lang)}</p> : null
                                    }
                                    <Input type='textarea' value={this.state.message} onChange={e => {
                                        const message = e?.target?.value;
                                        this.setState({ message });
                                    }} rows='5' />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        <Button disabled={this.state._savingProgress || !this.props.uData?._id || !this.state.selectedUserId} color='info' onClick={() => {
                            if (!this.state.isAdmin || !this.state.assignModal || !this.props.uData?._id || !this.state.selectedUserId) return;
                            this.approveOrRejectCase(this.state.assignModal, 'assign', { rejectCase: null, rejectCaseExternal: null, message: '', form: null, selectedUserId: null, assignModal: null })
                        }}>
                            {'Assign'.translate(this.props.lang)}
                        </Button>

                        <Button disabled={this.state._savingProgress} color='primary' onClick={() => { this.setState({ assignModal: null, message: '', selectedUserId: null }) }}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>

                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.forwardCase ? <Modal size='md' key='forward-modal' isOpen={this.state.forwardCase} centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ forwardCase: null, message: '', forwardData: null, _originalSender: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ forwardCase: null, message: '', forwardData: null, _originalSender: null })}>&times;</button>}>
                        {'Forward this referral?'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>

                                <FormGroup>
                                    <Label >*{'Reason for forwarding this case'.translate(this.props.lang)}:</Label>
                                    <Input type='textarea' value={this.state.message} onChange={e => {
                                        const message = e?.target?.value;
                                        this.setState({ message });
                                    }} rows='5' />
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        checked={this.state.forwardData?.patientConsent}
                                        onChange={e => {
                                            const patientConsent = e?.target?.checked;
                                            const forwardData = this.state.forwardData;
                                            forwardData.patientConsent = patientConsent;
                                            this.setState({ forwardData });
                                        }}
                                        label={`*${'Patient has given consent to be referred and their information to be shared'.translate(this.props.lang)}`}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='e-connect-title'>*{'Receiving clinic'.translate(this.props.lang)}<InfoWithTooltip style={{ bottom: 3 }} lang={this.props.lang} text='Forwarding of referral can only be done to connected clinics' />:</h6>
                                    {this.state.forwardData?.receiver?.type ? <div className={`econnect-receiver-card`}>
                                        <p style={this.state.forwardData?.receiver?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.forwardData?.receiver?.userName ? undefined : 'ec-clinic-name'}>{this.state.forwardData?.receiver?.name}</p>
                                        {this.state.forwardData?.receiver?.userName ? <p className='ec-clinic-name'>{this.state.form?.receiver?.userTitle ? `${this.state.form?.receiver?.userTitle} ` : ''}{this.state.forwardData?.receiver?.userName}</p> : null}

                                        {this.state.forwardData?.receiver?.phone || this.state.forwardData?.receiver?.email ? <div className='ec-clinic-contact-div'>
                                            {this.state.forwardData?.receiver?.phone ? <p className='ec-clinic-phone'>{this.state.forwardData?.receiver?.phone}</p> : null}
                                            {this.state.forwardData?.receiver?.email ? <p className='ec-clinic-email'>{this.state.forwardData?.receiver?.email}</p> : null}
                                        </div> : null}

                                        {this.state.forwardData?.receiver?.address ? <div className='ec-clinic-address'>
                                            {this.state.forwardData?.receiver?.address?.split?.(';')?.map?.(addr => <p>
                                                {addr}
                                            </p>)}
                                        </div> : null}

                                        <div className={`ec-clinic-type ec-clinic-type-${this.state.forwardData?.receiver?.type}`}>{this.state.forwardData?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                        <button onClick={() => {
                                            const receiver = { type: '' };
                                            const forwardData = this.state.forwardData;
                                            forwardData.receiver = receiver;
                                            this.setState({ forwardData });

                                        }}>{'Delete receiver'.translate(this.props.lang)}</button>
                                    </div> : <div>
                                        <Button color='primary' size='lg' style={{
                                            minWidth: 100
                                        }} onClick={this.addClinicModalHandler}>{'Add'.translate(this.props.lang)}</Button>
                                    </div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                        <Button disabled={!this.state.message?.trim?.() || this.state._savingProgress || !this.state.forwardData?.patientConsent || !this.state.forwardData?.receiver?.type} color='info' onClick={() => {
                            if ((!this.state.isAdmin && !this.state.isDoctor) || !this.state.forwardCase || !this.state.message?.trim?.() || !this.state.forwardData?.patientConsent || !this.state.forwardData?.receiver?.type) return;
                            this.approveOrRejectCase(this.state.forwardCase, 'forward', { forwardCase: null, message: '', form: null, forwardData: null, _originalSender: null })
                        }}>
                            {'Forward referral'.translate(this.props.lang)}
                        </Button>

                        <Button disabled={this.state._savingProgress} color='primary' onClick={() => { this.setState({ forwardCase: null, message: '', forwardData: null, _originalSender: null }) }}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>

                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {
                    this.state.addClinicModal && this.state.forwardData ? <Modal key='clinic-add-modal' isOpen={this.state.addClinicModal} size='lg' centered>
                        <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ addClinicModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ addClinicModal: null })}>&times;</button>}>
                            {'Add Receiving Clinic'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <div key='select-clinic-div' className='econnect-available-clinics'>
                                <h6>{'Available Clinics'.translate(this.props.lang)} <InfoWithTooltip style={{ bottom: 4 }} text='Forwarding of referral can only be done to connected clinics' lang={this.props.lang} /></h6>
                                {this.state._availableClinics?.length > 0 ? <div className='econnect-available-clinics-container'>
                                    <Input type='search' placeholder={'Search...'.translate(this.props.lang)} onChange={(e) => {
                                        const value = e?.target?.value?.toLocaleLowerCase?.();
                                        const ac = this.state._availableClinics;
                                        if (!ac || !Array.isArray(ac) || !ac.length) return;
                                        if (!value || !value?.trim?.()) {
                                            const addClinicModal = this.state.addClinicModal;
                                            addClinicModal.availableClinics = ac;
                                            return this.setState({ addClinicModal });
                                        }

                                        const availableClinics = [];

                                        for (const clinic of ac) {
                                            const name = clinic?.name?.toLocaleLowerCase?.();
                                            const address = clinic?.address?.toLocaleLowerCase?.();
                                            const phone = clinic?.phone?.toLocaleLowerCase?.();

                                            if (
                                                (name && name.includes?.(value))
                                                || (address && address.includes?.(value))
                                                || (phone && phone.includes?.(value))
                                            ) availableClinics.push(clinic);
                                        }

                                        const addClinicModal = this.state.addClinicModal;
                                        addClinicModal.availableClinics = availableClinics;
                                        this.setState({ addClinicModal });
                                    }} />
                                    {this.state.addClinicModal?.availableClinics?.length ? <div className='econnect-available-clinics-list'>
                                        {this.state.addClinicModal?.availableClinics?.map?.(clinic => <div
                                            key={clinic.id}
                                            onClick={() => {
                                                const receiver = {
                                                    type: 'internal',
                                                    id: clinic.id,
                                                    name: clinic.name,
                                                    address: clinic.address,
                                                    phone: clinic.phone,
                                                };
                                                const forwardData = this.state.forwardData;
                                                forwardData.receiver = receiver;
                                                this.setState({ forwardData, addClinicModal: null });
                                            }}
                                            title={'Select this clinic'.translate(this.props.lang)}
                                        >
                                            <div>
                                                <img src={clinic.logo} alt='logo' />
                                            </div>
                                            <div>
                                                <div>{clinic.name}</div>
                                                {clinic.address || clinic.phone ? <div>
                                                    {clinic.address ? <div>{clinic.address}</div> : null}
                                                    {clinic.phone ? <div>{clinic.phone?.startsWith?.('+') ? '' : '+'}{clinic.phone}</div> : null}
                                                </div> : null}
                                            </div>
                                        </div>)}
                                    </div> : <p className='e-connect-no-avail-clin'>{'There are no available clinics for the searched term'.translate(this.props.lang)}.</p>}
                                </div> : <p className='e-connect-no-avail-clin'>{'There are no available clinics at the moment'.translate(this.props.lang)}.</p>}
                            </div>
                            {this.state.addClinicModal?.availableClinics?.length > 0 && false ? <div key='or-div' className='econnect-or-div'>
                                <div />
                                <span>{'or'.translate(this.props.lang)}</span>
                            </div> : null}
                            {/* <div key='manual-clinics-div' className='econnect-manual-clinic'>
                                <Row>
                                    <Col lg='12'>
                                        <FormGroup key='fg-m-clname'>
                                            <Label>
                                                *{'Clinic name'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.name || ''}
                                                onChange={(e) => {
                                                    const name = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                name
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clstr'>
                                            <Label>
                                                *{'Street'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.street || ''}
                                                onChange={(e) => {
                                                    const street = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                street
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='2'>
                                        <FormGroup key='fg-m-clzip'>
                                            <Label>
                                                *{'Zip'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.zip || ''}
                                                onChange={(e) => {
                                                    const zip = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                zip
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='4'>
                                        <FormGroup key='fg-m-clcty'>
                                            <Label>
                                                *{'City'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.city || ''}
                                                onChange={(e) => {
                                                    const city = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                city
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clph'>
                                            <Label>
                                                *{'Phone'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.phone || ''}
                                                onChange={(e) => {
                                                    const phone = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                phone
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <FormGroup key='fg-m-clemail'>
                                            <Label>
                                                {'Email address'.translate(this.props.lang)}:
                                            </Label>
                                            <Input
                                                type='text'
                                                value={this.state.addClinicModal?.manual?.email || ''}
                                                onChange={(e) => {
                                                    const email = e?.target?.value;
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        addClinicModal: {
                                                            ...prev?.addClinicModal,
                                                            manual: {
                                                                ...prev?.addClinicModal?.manual,
                                                                email
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div> */}
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                            {/* <Button disabled={!this.state.addClinicModal?.manual || !this.state.addClinicModal?.manual?.name || !this.state.addClinicModal?.manual?.street || !this.state.addClinicModal?.manual?.zip || !this.state.addClinicModal?.manual?.city || !this.state.addClinicModal?.manual?.phone} color='primary' style={{ minWidth: 100 }} onClick={() => {
                                const data = this.state.addClinicModal?.manual;
                                if (!data || !data.name || !data.street || !data.zip || !data.city || !data.phone) return;
                                const address = `${data.street}; ${data.zip} ${data.city}`
                                this.setState(prev => ({
                                    ...prev,
                                    forwardData: {
                                        ...prev?.forwardData,
                                        _edited: true,
                                        receiver: {
                                            ...(prev?.forwardData?.receiver || {}),
                                            type: 'external',
                                            name: data.name,
                                            address,
                                            phone: data.phone,
                                            email: data.email || '',
                                        }
                                    },
                                    addClinicModal: null
                                }))
                            }}>
                                {'Add Manually'.translate(this.props.lang)}
                            </Button> */}
                            <Button style={{ minWidth: 100 }} onClick={() => this.setState({ addClinicModal: null })} color='danger'>
                                {'Close'.translate(this.props.lang)}
                            </Button>
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        </ModalFooter>
                    </Modal> : null
                }

                {this.state.rejectCase ? <Modal size='md' key='reject-modal' isOpen={this.state.rejectCase} centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ rejectCase: null, rejectCaseExternal: null, message: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ rejectCase: null, rejectCaseExternal: null, message: '' })}>&times;</button>}>
                        {'Reject this case?'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    {
                                        !this.state.rejectCaseExternal ? null : <p style={{ color: 'red' }}>{'External referral - response not sent automatically'.translate(this.props.lang)}</p>
                                    }
                                    <Label >*{'Reason for rejecting this case'.translate(this.props.lang)}:</Label>
                                    <Input type='textarea' value={this.state.message} onChange={e => {
                                        const message = e?.target?.value;
                                        this.setState({ message });
                                    }} rows='5' />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.loading ? <div className='econnect-loader'><Player
                            autoplay={true}
                            loop={true}
                            src="/lf30_editor_l5cxzdyf.json"
                            style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                        /></div> : null}
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>

                        <Button disabled={!this.state.message?.trim?.() || this.state._savingProgress} color='danger' onClick={() => {
                            if (!this.state.isAdmin && !this.state.isDoctor || !this.state.rejectCase || !this.state.message?.trim?.()) return;
                            this.approveOrRejectCase(this.state.rejectCase, 'reject', { rejectCase: null, rejectCaseExternal: null, message: '', form: null })
                        }}>
                            {'Deny'.translate(this.props.lang)}
                        </Button>

                        <Button disabled={this.state._savingProgress} color='primary' onClick={() => { this.setState({ rejectCase: null, rejectCaseExternal: null, message: '' }) }}>
                            {'Cancel'.translate(this.props.lang)}
                        </Button>

                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.casePatientModal ? <Modal key='clinic-patient-info' isOpen={this.state.casePatientModal} size='md' centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ casePatientModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ casePatientModal: null })}>&times;</button>}>
                        {'Patient'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Information about patient correct as of the moment of case creation' />
                    </ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.name || 'N/A'}</div>
                        </div>
                        {this.state.casePatientModal?.ssn ? <div className='eb-modal-div'>
                            <h6>{'Social security number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.ssn || 'N/A'}</div>
                        </div> : this.state.casePatientModal?.dateOfBirth ? <div className='eb-modal-div'>
                            <h6>{'Date of Birth'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.dateOfBirth || 'N/A'}</div>
                        </div> : null}
                        <div className='eb-modal-div'>
                            <h6>{'Sex'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.gender?.translate?.(this.props.lang) || 'N/A'}</div>
                        </div>
                        {/* <div className='eb-modal-div'>
                            <h6>{'Language'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.language?.translate?.(this.props.lang) || 'N/A'}</div>
                        </div> */}
                        <div className='eb-modal-div'>
                            <h6>{'Phone Number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.phone || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.casePatientModal?.email || 'N/A'}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        {canOpenProfile && this.state.casePatientModal?.type && this.state.casePatientModal?.type === 'internal' ? <Button color='primary' onClick={() => {
                            if (!canOpenProfile) return;
                            this.searchPatientWithRedirect(this.state.casePatientModal?.ssn, this.state.clinicGroupSettings?.enterprise);
                        }}>
                            {'Open Patient Profile'.translate(this.props.lang)}
                        </Button> : null}
                        <Button color='primary' onClick={() => this.setState({ casePatientModal: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.archiveModal ? <InfoModal
                    isOpen={this.state.archiveModal}
                    onClose={() => {
                        this.setState({
                            archiveModal: null,
                            _archiveType: null
                        })
                    }}
                    header={'Warning'.translate(this.props.lang)}
                    info={'Move this case to the archive?'.translate(this.props.lang)}
                    buttons={[
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={this.archiveCase}
                        >
                            {'Archive case'.translate(this.props.lang)}
                        </Button>,
                        <Button
                            color='primary'
                            style={{ margin: 10, minWidth: 100 }}
                            onClick={() => {
                                this.setState({
                                    archiveModal: null,
                                    _archiveType: null
                                });
                            }}
                        >
                            {'Cancel'.translate(this.props.lang)}
                        </Button>,
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    ]}
                /> : null
                }


                {this.state.history && this.state.history.history ? <Modal key='history-modal' isOpen={this.state.history} size='lg' centered>
                    <ModalHeader toggle={() => { this.setState({ history: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ history: null })
                    }}>&times;</button>}>
                        {'Case history'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can see all the actions that were taken on this case, as well as other cases that are connected to it' />
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <div className='e-connect-history-container'>
                                    {this.state.history?.history?.map?.((obj, i) => {
                                        if (!obj || !obj.action || !obj.ts || !obj.user || obj.action === 'sender-archived') return null;

                                        let type;
                                        if (this.state.history?.forwarder?.id === this.props.selectedGroup) type = 'forwarder';
                                        else if (this.state.history?.receiver?.id === this.props.selectedGroup) type = 'receiver';

                                        if (type) {
                                            if (type === 'forwarder' && obj.action === 'receiver-archived') return null;
                                            if (type === 'receiver' && obj.action === 'forwarder-archived') return null;
                                        }

                                        const caseNumber = obj.no;
                                        const text = obj.action === 'sent' && i === 0 ? (
                                            'Created and sent'.translate(this.props.lang)
                                        ) : obj.action === 'new-created' ? (
                                            'Created new case'.translate(this.props.lang)
                                        ) : obj.action === 'new-sent' ? (
                                            'Created and sent new case'.translate(this.props.lang)
                                        ) : obj.action === 'referral-response' ? (
                                            'Sent a referral response'.translate(this.props.lang)
                                        ) : obj.action?.includes?.('archived') ? (
                                            'Archived'.translate(this.props.lang)
                                        ) : obj.action?.includes?.('assigned') ? (
                                            'Assigned to'.translate(this.props.lang) + ` ${obj.assignToUser?.username || 'N/A'}`
                                        ) : `${obj.action?.charAt?.(0)?.toUpperCase?.() + obj.action?.slice?.(1)}`.translate(this.props.lang);
                                        const date = new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        }).format(obj.ts);
                                        const user = obj.user?.username || 'N/A';
                                        return <div key={obj.ts} className='e-connect-history-item'>
                                            <div>{caseNumber}</div>
                                            <div>{text}</div>
                                            <div>{date}</div>
                                            <div>{user}</div>
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button color='primary' onClick={() => { this.setState({ history: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.answerAndResponseModal ? <Modal key='referral-response-modal' isOpen={this.state.answerAndResponseModal} size='xl' centered className='ec-res-modal-apoisugf'>
                    <ModalHeader toggle={() => { this.setState({ answerAndResponseModal: null }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ answerAndResponseModal: null })
                    }}>&times;</button>}>
                        {'Referral Responses'.translate(this.props.lang)}
                        <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can see all the referral responses attached to this case' />
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        <Row>
                            {this.state.answerAndResponseModal?.receiver?.id === this.props.selectedGroup ? this.state.answerAndResponseModal?.responses?.map?.(responseObj => <Col sm='12' key={responseObj?.ts}>
                                <Alert className='ec-details-container'>
                                    <details>
                                        <summary>
                                            <strong>{'Referral Response Sent'.translate(this.props.lang)}</strong>: {responseObj?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(responseObj?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                <Col lg='4'>
                                                    <h6>{'Answering department'.translate(this.props.lang)}:</h6>
                                                    <div>{responseObj?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='4'>
                                                    <h6>{'Answering personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                <Col lg='4'>
                                                    <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{responseObj?.user?.name ? `${responseObj?.user?.title ? `${responseObj?.user?.title} ` : ''}${responseObj?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Comment'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {responseObj?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col>) : null}
                            {this.state.answerAndResponseModal?.answer ? <Col sm='12' key='answer-col'>
                                <Alert className={`ec-details-container ${!this.state.answerAndResponseModal?.answer?.forwarderSeen && this.state.answerAndResponseModal?.forwarder?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.forwarder?.user) === String(this.props.uData?._id) ? 'ec-details-container-not-seen' : ''}`} onClick={() => {
                                    if (!this.state.answerAndResponseModal?.answer?.forwarderSeen && this.state.answerAndResponseModal?.forwarder?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.forwarder?.user) === String(this.props.uData?._id)) {
                                        const answerAndResponseModal = this.state.answerAndResponseModal;
                                        answerAndResponseModal.answer.forwarderSeen = true;
                                        this.setState({ answerAndResponseModal });
                                    }
                                }}>
                                    <details>
                                        <summary>
                                            <strong>{this.state.answerAndResponseModal?.forwarder?.id && this.state.answerAndResponseModal?.forwarder?.id === this.props.selectedGroup ? 'Answer received'.translate(this.props.lang) : 'Answer sent'.translate(this.props.lang)}</strong>: {this.state.answerAndResponseModal?.answer?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(this.state.answerAndResponseModal?.answer?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                <Col lg='3'>
                                                    <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                    <div className={this.state.answerAndResponseModal?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.answerAndResponseModal?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                        {`${this.state.answerAndResponseModal?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.answerAndResponseModal?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                    </div>

                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Answering department'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Answering personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                <Col lg='3'>
                                                    <h6>{'Assigned caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName ? `${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorTitle ? `${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorTitle} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName}` : this.state.answerAndResponseModal?.answer?.user?.name ? `${this.state.answerAndResponseModal?.answer?.user?.title ? `${this.state.answerAndResponseModal?.answer?.user?.title} ` : ''}${this.state.answerAndResponseModal?.answer?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {this.state.answerAndResponseModal?.answer?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col> : null}
                            {this.state.answerAndResponseModal?.forward ? <Col sm='12' key='forward-col'>
                                <Alert className={`ec-details-container ${false && !this.state.answerAndResponseModal?.forward?.receiverSeen && this.state.answerAndResponseModal?.receiver?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.receiver?.user) === String(this.props.uData?._id) ? 'ec-details-container-not-seen' : ''}`} onClick={() => {
                                    if (!this.state.answerAndResponseModal?.forward?.receiverSeen && this.state.answerAndResponseModal?.receiver?.user && this.props.uData?._id && String(this.state.answerAndResponseModal?.receiver?.user) === String(this.props.uData?._id)) {
                                        const answerAndResponseModal = this.state.answerAndResponseModal;
                                        answerAndResponseModal.forward.receiverSeen = true;
                                        this.setState({ answerAndResponseModal });
                                    }
                                }}>
                                    <details>
                                        <summary>
                                            <strong>{'Referral Forwarded'.translate(this.props.lang)}</strong>: {this.state.answerAndResponseModal?.forward?.ts ? new Intl.DateTimeFormat(this.props.lang === 'se' ? 'sv-SE' : 'en-EN', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            }).format(this.state.answerAndResponseModal?.forward?.ts) : null}
                                        </summary>
                                        <div>
                                            <Row>
                                                {/* <Col lg='3'>
                                                    <h6>{'Answer'.translate(this.props.lang)}:</h6>
                                                    <div className={this.state.answerAndResponseModal?.answer?.type === 'rejected' ? 'e-connect-list-status-rejected' : this.state.answerAndResponseModal?.answer?.type === 'accepted' ? 'e-connect-list-status-accepted' : undefined} style={{ fontWeight: '500' }}>
                                                        {`${this.state.answerAndResponseModal?.answer?.type?.charAt?.(0)?.toUpperCase?.() + this.state.answerAndResponseModal?.answer?.type?.slice?.(1)}`.translate(this.props.lang)}
                                                    </div>

                                                </Col> */}
                                                <Col lg='6'>
                                                    <h6>{'Forwarding department'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.forward?.user?.departmentName || 'N/A'}</div>
                                                </Col>
                                                <Col lg='6'>
                                                    <h6>{'Forwarding personel'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.forward?.user?.name ? `${this.state.answerAndResponseModal?.forward?.user?.title ? `${this.state.answerAndResponseModal?.forward?.user?.title} ` : ''}${this.state.answerAndResponseModal?.forward?.user?.name}` : 'N/A'}</div>
                                                </Col>
                                                {/* <Col lg='4'>
                                                    <h6>{'Forwarding caregiver'.translate(this.props.lang)}:</h6>
                                                    <div>{this.state.answerAndResponseModal?.answer?.user?.assignedDoctorName || this.state.answerAndResponseModal?.answer?.user?.name || 'N/A'}</div>
                                                </Col> */}
                                            </Row>
                                            <hr />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <h6>{'Referral confirmation'.translate(this.props.lang)}:</h6>
                                                        <p>
                                                            {this.state.answerAndResponseModal?.forward?.message}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </details>
                                </Alert>
                            </Col> : null}
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>

                        {this.state.isDoctor && this.state.answerAndResponseModal?.receiver?.status === 'in-progress' && this.state.answerAndResponseModal?.receiver?.user && String(this.state.answerAndResponseModal?.receiver?.user) === String(this.props.uData?._id) ? <Button
                            color='info'
                            disabled={this.state._savingProgress}
                            onClick={() => {
                                if (this.state.isDoctor && this.state.answerAndResponseModal?.receiver?.status === 'in-progress' && this.state.answerAndResponseModal?.receiver?.user && String(this.state.answerAndResponseModal?.receiver?.user) === String(this.props.uData?._id)) {
                                    this.setState({
                                        referralResponseModal: {
                                            id: this.state.answerAndResponseModal?._id,
                                            external: this.state.answerAndResponseModal?.sender?.type === 'external'
                                        }
                                    })
                                }
                            }}
                        >
                            {'Send Referral Response'.translate(this.props.lang)}
                        </Button> : null}

                        {/* <Button
                            style={{ minWidth: 100 }}
                            color='primary'
                            onClick={() => {
                                if (!this.state.answerAndResponseModal?._id) return;
                                this.print(this.state.answerAndResponseModal?._id);
                            }}
                        >{'Save as PDF'.translate(this.props.lang)}</Button> */}

                        <Button color='primary' onClick={() => { this.setState({ answerAndResponseModal: null }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.chatModal ? <Modal key='chat-modal' isOpen={this.state.chatModal} size='xl' centered >
                    <ModalHeader toggle={() => { this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() }) }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => {
                        this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() })
                    }}>&times;</button>}>
                        {'Chat'.translate(this.props.lang)}
                        {/* <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Here you can chat with other party about the case progress' /> */}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg='6' style={{ overflow: 'auto' }}>
                                <div key='div-patient-asdf' className='econnect-create-patient'>
                                    <div>
                                        <div>
                                            <h6>{'Social security number'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.patient?.ssn} {this.state.chatModal?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</p>
                                        </div>
                                        <div>
                                            <h6>{'Name'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.patient?.name}</p>
                                        </div>
                                    </div>
                                    {this.state.chatModal?._id ? <button onClick={() => {
                                        const patient = this.state.chatModal?.patient;
                                        this.setState({
                                            casePatientModal: patient
                                        })
                                    }}>{'See patient info'.translate(this.props.lang)}</button> : null}
                                </div>
                                <div key='div-clinic-asdfasdfg' className='econnect-create-patient'>
                                    <div>
                                        <div>
                                            <h6>{'Sending clinic'.translate(this.props.lang)}:</h6>
                                            <p>{this.state.chatModal?.sender?.name}</p>
                                        </div>
                                        <div>
                                            <h6>{'Type'.translate(this.props.lang)}:</h6>
                                            <p style={{ color: this.state.chatModal?.receiver?.type === 'internal' ? 'green' : 'red' }}>{this.state.chatModal?.sender?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</p>
                                        </div>
                                    </div>
                                    <button onClick={() => {
                                        const clinic = this.state.chatModal?.sender;
                                        this.setState({
                                            clinicDetails: clinic
                                        })
                                    }}>{'See clinic info'.translate(this.props.lang)}</button>
                                </div>

                                {/* <h6 className='e-connect-title'>{'Receiving clinic'.translate(this.props.lang)}:</h6>
                                {this.state.chatModal?.receiver?.type ? <div className={`econnect-receiver-card disabled`}>
                                    <p style={this.state.chatModal?.receiver?.userName ? { padding: 0, margin: 0 } : undefined} className={this.state.chatModal?.receiver?.userName ? undefined : 'ec-clinic-name'}>{this.state.chatModal?.receiver?.name}</p>
                                    {this.state.chatModal?.receiver?.userName ? <p className='ec-clinic-name'>{this.state.chatModal?.receiver?.userName}</p> : null}

                                    {this.state.chatModal?.receiver?.phone || this.state.chatModal?.receiver?.email ? <div className='ec-clinic-contact-div'>
                                        {this.state.chatModal?.receiver?.phone ? <p className='ec-clinic-phone'>{this.state.chatModal?.receiver?.phone}</p> : null}
                                        {this.state.chatModal?.receiver?.email ? <p className='ec-clinic-email'>{this.state.chatModal?.receiver?.email}</p> : null}
                                    </div> : null}

                                    {this.state.chatModal?.receiver?.address ? <div className='ec-clinic-address'>
                                        {this.state.chatModal?.receiver?.address?.split?.(';')?.map?.(addr => <p>
                                            {addr}
                                        </p>)}
                                    </div> : null}

                                    <div className={`ec-clinic-type ec-clinic-type-${this.state.chatModal?.receiver?.type}`}>{this.state.chatModal?.receiver?.type === 'internal' ? 'e-Connect' : 'External'.translate(this.props.lang)}</div>
                                </div> : null} */}

                                <FormGroup key='fg-subject'>
                                    <Label className='e-connect-title' htmlFor='e-cr-subject'>{'Subject'.translate(this.props.lang)}:</Label>
                                    <Input disabled type='textarea' value={this.state.chatModal?.subject || ''} rows='2' />
                                </FormGroup>

                                <FormGroup>
                                    <Label className='e-connect-title' htmlFor='e-conn-anamnesis'>
                                        {'Anamnesis'.translate(this.props.lang)}:
                                    </Label>
                                    <Input disabled rows='4' id='e-conn-anamnesis' type='textarea' value={this.state.chatModal?.anamnesis || ''} />
                                </FormGroup>

                                <FormGroup>
                                    <Label className='e-connect-title' htmlFor='e-conn-status'>
                                        {'Status'.translate(this.props.lang)}:
                                    </Label>
                                    <Input disabled rows='4' id='e-conn-status' type='textarea' value={this.state.chatModal?.status || ''} />
                                </FormGroup>

                                {this.state.chatModal?.attachments?.length > 0 ? <Row>
                                    <Col>
                                        <h6 className='e-connect-title'>
                                            {'Attachments'.translate(this.props.lang)}:
                                        </h6>
                                        <div className='e-connect-modal-attachments'>
                                            <div className={`ec-disabled`}>
                                                {this.state.chatModal?.attachments?.map?.((item, i) => {
                                                    if (!item?.url) return null;
                                                    return <div
                                                        key={item?.url ? item.url : item?.name ? `${item.name}-${i}` : i}
                                                        className='econnect-attachment-item'
                                                    >
                                                        <div>
                                                            <button
                                                                title={'Opens in new browser tab'.translate(this.props.lang)}
                                                                onClick={() => {
                                                                    if (typeof window !== 'undefined') window.open(API_ENDPOINT + item.url, '_blank')
                                                                }}
                                                            >
                                                                &#91;
                                                                {'Open'.translate(this.props.lang)}
                                                                &#93;
                                                            </button>
                                                            <div>{item?.name}</div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`ec2-disabled`} />
                                        </div>
                                    </Col>
                                </Row> : null}
                            </Col>

                            <Col lg='6'>
                                <div className="e-connect-chat-container">
                                    <iframe
                                        src={`/chat/${this.state.chatModal?.conversationId}?patientSearchModule=true&doNotOpenPatientProfile=true`}
                                        onLoad={() => {
                                            this.timer2 = setTimeout(() => {
                                                this.props.checkEConnectCount?.();
                                            }, 3000);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button hidden aria-hidden='true'></button>
                        <Button color='primary' onClick={() => { this.setState({ chatModal: null }, () => { this.props.checkEConnectCount?.() }) }}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button hidden aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.clinicDetails ? <Modal key='clinic-patient-info' isOpen={this.state.clinicDetails} size='md' centered>
                    <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }} toggle={() => this.setState({ clinicDetails: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ clinicDetails: null })}>&times;</button>}>
                        {'Receiving clinic'.translate(this.props.lang)}
                        {/* <InfoWithTooltip style={{ bottom: 4 }} lang={this.props.lang} text='Information about patient correct as of the moment of case creation' /> */}
                    </ModalHeader>
                    <ModalBody>
                        <div className='eb-modal-div'>
                            <h6>{'Name'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.name || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.address || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Personnel in charge of the case'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.userName ? `${this.state.clinicDetails?.userTitle ? `${this.state.clinicDetails?.userTitle} ` : ''}${this.state.clinicDetails?.userName}` : 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Phone Number'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.phone ? '+' : null} {this.state.clinicDetails?.phone || 'N/A'}</div>
                        </div>
                        <div className='eb-modal-div'>
                            <h6>{'Email Address'.translate(this.props.lang || 'en')}</h6>
                            <div>{this.state.clinicDetails?.email || 'N/A'}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        <Button color='primary' onClick={() => this.setState({ clinicDetails: null })}>
                            {'Close'.translate(this.props.lang)}
                        </Button>
                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                    </ModalFooter>
                </Modal> : null}

                {
                    this.state.error ? <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal> : null
                }
            </div>
        )
    }
}

export default PageWithLayout(EconnectReceivedCasesPage);