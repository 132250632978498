import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert, Alert

} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from "react-inlinesvg";
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/formBuilder';
import { email, required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Link } from 'react-router-dom';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import link from "../../assets/svg/link-new-tab.svg";

import Iframe from 'react-iframe';
import Checkbox from '../../components/forms/fields/checkbox';

import infoIcon from "../../assets/svg/link-new-tab.svg";



function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicData extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            regions: [],
            showForm: false,
            tabIndex: 1,
            template: -1,
            type: 'bankid',
            bankIdRedirectUrl: null,


        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].notifications && this.props.uData.clinicGroups[selectedGroupIdx].notifications[this.props.uData._id]) {

            this.setState({
                notificationsDoctor: this.props.uData.clinicGroups[selectedGroupIdx].notifications[this.props.uData._id].doctor,
                notificationsPatient: this.props.uData.clinicGroups[selectedGroupIdx].notifications[this.props.uData._id].patient,
                notificationsGlobal: this.props.uData.clinicGroups[selectedGroupIdx].notifications['global'] && this.props.uData.clinicGroups[selectedGroupIdx].notifications['global']['global'] ? this.props.uData.clinicGroups[selectedGroupIdx].notifications['global']['global'] : {}
            }, () => {
                let arr = this.state.notificationsDoctor;
                if (!arr.set1) { arr.set1 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set2) { arr.set2 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set3) { arr.set3 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set4) { arr.set4 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set5) { arr.set5 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set6) { arr.set6 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set7) { arr.set7 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr.set8) { arr.set8 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                this.setState({ notificationsDoctor: arr })

                let arr2 = this.state.notificationsPatient;
                if (!arr2.set1) { arr2.set1 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set2) { arr2.set2 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set3) { arr2.set3 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set4) { arr2.set4 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set5) { arr2.set5 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set6) { arr2.set6 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set7) { arr2.set7 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set8) { arr2.set8 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set9) { arr2.set9 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set10) { arr2.set10 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set11) { arr2.set11 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set12) { arr2.set12 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set13) { arr2.set13 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set14) { arr2.set14 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr2.set15) { arr2.set15 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                this.setState({ notificationsPatient: arr2 })

                let arr3 = this.state.notificationsGlobal;
                if (!arr3.set1) { arr3.set1 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr3.set2) { arr3.set2 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr3.set3) { arr3.set3 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                if (!arr3.set4) { arr3.set4 = { email: false, sms: false, webpush: false, notif: false, default: false }; }
                this.setState({ notificationsGlobal: arr3 })

            })
        } else {
            let notificationsDoctorObj = {
                "set1": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set2": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set3": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set4": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set5": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set6": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set7": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set8": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                }
            }
            let notificationsPatientObj = {
                "set1": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set2": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set3": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set4": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set5": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set6": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set7": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set8": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set9": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set10": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set11": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set12": {
                    "email": true, "sms": true, "webpush": true, "notif": true, "default": true
                },
                "set13": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                },
                "set14": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                },
                "set15": {
                    "email": false, "sms": false, "webpush": false, "notif": false, "default": false
                }
            }
            let arr3 = this.props.uData.clinicGroups[selectedGroupIdx].notifications['global'] && this.props.uData.clinicGroups[selectedGroupIdx].notifications['global']['global'] ?
                this.props.uData.clinicGroups[selectedGroupIdx].notifications['global']['global']
                :
                {}

            if (!arr3.set1) { arr3.set1 = { email: true, sms: true, webpush: true, notif: true, default: true }; }
            if (!arr3.set2) { arr3.set2 = { email: true, sms: true, webpush: true, notif: true, default: true }; }
            if (!arr3.set3) { arr3.set3 = { email: true, sms: true, webpush: true, notif: true, default: true }; }
            if (!arr3.set4) { arr3.set4 = { email: true, sms: true, webpush: true, notif: true, default: true }; }

            let notificationsGlobalObj = arr3

            // let notificationsGlobalObj = {
            //     "set1": {
            //         "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            //     },
            //     "set2": {
            //         "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            //     },
            //     "set3": {
            //         "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            //     },
            //     "set4": {
            //         "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            //     }
            // }
            this.setState({
                notificationsDoctor: notificationsDoctorObj,
                notificationsPatient: notificationsPatientObj,
                notificationsGlobal: notificationsGlobalObj
            })
        }

        let notificationsDoctorObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        let notificationsPatientObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set9": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set10": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set11": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set12": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set13": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set14": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set15": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }

        }
        let notificationsGlobalDefault = {
            "set1": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set2": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set3": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set4": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
        }
        this.setState({
            notificationsDoctorDefault: notificationsDoctorObjDefault,
            notificationsPatientDefault: notificationsPatientObjDefault,
            notificationsGlobalDefault: notificationsGlobalDefault
        })
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();



        this.setState({ showForm: true })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            // this.setState({
            //     showForm: false
            // }, () => {
            //     setTimeout(() => {
            //         this.setState({ showForm: true })
            //     }, 100)
            // })
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }

    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };




    /**
   * Update clinic settings
   * @author   Milan Stanojevic
   * @Objectparam    {String} informationLink      link for information page
   * @Objectparam    {String} name                 group name
   * @Objectparam    {String} smsName              subject for sms
   * @Objectparam    {String} emailName            subject for email
   * @Objectparam    {String} privacyPolicy        privacyPolicy pdf
   * @Objectparam    {String} termsOfUse           termsOfUse pdf
   * @Objectparam    {String} accessibilityReport  accessibilityReport pdf
   * @Objectparam    {String} adress               clinic adress
   */
    update = () => {
        let data = {};

        if (this.props.uData && this.props.uData._id) {
            data.notifications = {};

            let selectedGroupIdx = 0;
            if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                        selectedGroupIdx = i;
                        break;
                    }
                }
            }
            if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].notifications) {
                data.notifications = this.props.uData.clinicGroups[selectedGroupIdx].notifications;
            }

            if (this.state.notificationsDoctor) {
                for (const key in this.state.notificationsDoctor) {
                    if (this.state.notificationsDoctor[key].email && this.state.notificationsDoctor[key].sms && this.state.notificationsDoctor[key].webpush && this.state.notificationsDoctor[key].notif) {
                        let notificationsDoctor = this.state.notificationsDoctor;
                        notificationsDoctor[key].default = true;
                        this.setState({
                            notificationsDoctor
                        })

                    }
                }
            }

            if (this.state.notificationsPatient) {
                for (const key in this.state.notificationsPatient) {
                    if (this.state.notificationsPatient[key].email && this.state.notificationsPatient[key].sms && this.state.notificationsPatient[key].webpush) {
                        let notificationsPatient = this.state.notificationsPatient;
                        notificationsPatient[key].default = true;
                        this.setState({
                            notificationsPatient
                        })

                    }
                }
            }

            if (this.state.notificationsGlobal) {
                for (const key in this.state.notificationsGlobal) {
                    if (this.state.notificationsGlobal[key].email && this.state.notificationsGlobal[key].sms && this.state.notificationsGlobal[key].webpush) {
                        let notificationsGlobal = this.state.notificationsGlobal;
                        notificationsGlobal[key].default = true;
                        this.setState({
                            notificationsGlobal
                        })

                    }
                }
            }

            data.notifications[this.props.uData._id] = {
                doctor: this.state.notificationsDoctor,
                patient: this.state.notificationsPatient
            }
            data.notifications['global'] = {
                global: this.state.notificationsGlobal
            }
            data.myNotifUpdate = true
            // console.log(data)
            this.setState(
                {
                    showPlayer: true,
                },
                () => {
                    data.groupId = this.props.selectedGroup;
                    data.clinic = this.props.selectedClinic;
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/users/account/updateClinicData', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            // if (this.state.previewNeeded) {
                            //     this.setState({ previewNeeded: false });
                            //     window.open(window.location.origin + '/' + data.alias, '_blank')
                            // }
                            this.props.verifyUser(() => { }, () => {
                                this.props.changeGroup(data.groupId)
                            });
                        }

                    })
                }
            )


        }


    }
    setDefaultValues = (tab) => {
        let notificationsDoctorObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        let notificationsPatientObjDefault = {
            "set1": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set2": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set3": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set4": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set5": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set6": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set7": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set8": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set9": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set10": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set11": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set12": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set13": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set14": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            },
            "set15": {
                "email": true, "sms": true, "webpush": true, "notif": true, "default": true
            }
        }
        let notificationsGlobalObjDefault = {
            "set1": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set2": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set3": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
            "set4": {
                "email": false, "sms": false, "webpush": false, "notif": true, "default": true
            },
        }
        if (tab == 1) {
            this.setState({
                notificationsDoctor: notificationsDoctorObjDefault,
            })
        } else if (tab == 2) {
            this.setState({
                notificationsPatient: notificationsPatientObjDefault
            })
        } else if (tab == 3) {
            this.setState({
                notificationsGlobal: notificationsGlobalObjDefault
            })
        }

    }

    onDismiss = () => { this.setState({ message: null, error: null }) };

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }


        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        let mailEnabled = false;
        let smsEnabled = false;
        let pushEnabled = false;
        let notifEnabled = false;
        let listingNotificationsEnabled = false;
        if (this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx]) {
            mailEnabled = this.props.uData.clinicGroups[selectedGroupIdx].mailNotifications;
            smsEnabled = this.props.uData.clinicGroups[selectedGroupIdx].smsNotifications;
            pushEnabled = this.props.uData.clinicGroups[selectedGroupIdx].pushNotifications;
            notifEnabled = this.props.uData.clinicGroups[selectedGroupIdx].systemNotifications;
            listingNotificationsEnabled = this.props.uData.clinicGroups[selectedGroupIdx].listing
        }

        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise
        const semble = this.props.hasSemble


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">


                            {/* {this.state.error ?
                                <Alert toggle={this.onDismiss} isOpen={this.state.error} color="success">
                                    {this.state.error.translate(this.props.lang)}
                                </Alert>
                                :
                                null
                            } */}


                            <div className="panel">

                                <Row>
                                    <Col lg="12">
                                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>


                                    </Col>
                                    <Col lg="12">
                                        <div className="flex-space-between tablet-none notifications-tabs-wrap" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf" }}>
                                            <div className="notifications-tabs tabs-buttons-wrap" style={{ marginBottom: 0, borderBottom: 0 }}>
                                                <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 1 })}>{"My notifications".translate(this.props.lang)}</Button>
                                                <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 2 })}>{"Patient notifications".translate(this.props.lang)}</Button>
                                                {
                                                    this.props.uData?.groupPermissionsUser &&
                                                        this.props.uData?.groupPermissionsUser[this.props.selectedGroup] &&
                                                        this.props.uData?.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] &&
                                                        this.props.uData?.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.props.uData?.permissions?.clinicAdmin) !== -1 && enterprise ?
                                                        <Button className={this.state.tabIndex == 3 ? "tab-button acitve-tab-button" : "tab-button"} onClick={() => this.setState({ tabIndex: 3 })}>{"Global settings".translate(this.props.lang)}</Button>
                                                        :
                                                        null
                                                }

                                            </div>

                                            <div>
                                                <Button style={{ whiteSpace: 'nowrap' }} className="tab-button acitve-tab-button reset-default-btn" onClick={() => this.setDefaultValues(this.state.tabIndex)}>{"Reset default".translate(this.props.lang)}</Button>
                                            </div>
                                        </div>

                                    </Col>


                                    <Col lg="12" className={this.state.tabIndex == 1 ? 'visibility mt-20' : 'none-visibility'}>
                                        <FormGroup style={{ width: '100%', overflowX: 'auto' }}>
                                            <div className="table-container-scroll">
                                                <div className="table-container-scroll-reverse">
                                                    <table className="clinic-notification-table">
                                                        <tr>
                                                            <th>{"My notifications".translate(this.props.lang)}</th>
                                                            {
                                                                mailEnabled ?
                                                                    <th>{"EMAIL".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <th>{"SMS".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <th>{"PUSH".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                notifEnabled ?
                                                                    <th>
                                                                        {"SYSTEM".translate(this.props.lang)}
                                                                        {/* <span className='info-wrap'>
                                                                    <Isvg src={infoIcon} />
                                                                    <div className='info-tooltip'>{'This setting cannot be changed.'.translate(this.props.lang)}</div>
                                                                </span> */}
                                                                    </th>
                                                                    :
                                                                    null
                                                            }

                                                            {/* <th>{"PUSH".translate(this.props.lang)}</th> */}
                                                            <th>{"DEFAULT".translate(this.props.lang)}</th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p>{"New event".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td >
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set1) {
                                                                                        notificationsDoctor.set1.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }


                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.sms : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set1) {
                                                                                        notificationsDoctor.set1.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set1) {
                                                                                        notificationsDoctor.set1.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.webpush : null}
                                                            onChange={(e) => {
                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set1) {
                                                                        notificationsDoctor.set1.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }

                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && !this.state.notificationsDoctor.set1.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set1) {
                                                                                        notificationsDoctor.set1.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 && this.state.notificationsDoctor.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set1 ? this.state.notificationsDoctor.set1.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;
                                                                        if (notificationsDoctor.set1 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set1) {
                                                                            notificationsDoctor.set1.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set1 = this.state.notificationsDoctorDefault.set1;
                                                                            }
                                                                        }

                                                                        this.setState({ notificationsDoctor })
                                                                    }}

                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Notification of booked appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {

                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set2) {
                                                                                        notificationsDoctor.set2.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {

                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set2) {
                                                                                        notificationsDoctor.set2.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }


                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set2) {
                                                                                        notificationsDoctor.set2.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set2) {
                                                                        notificationsDoctor.set2.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && !this.state.notificationsDoctor.set2.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set2) {
                                                                                        notificationsDoctor.set2.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 && this.state.notificationsDoctor.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set2 ? this.state.notificationsDoctor.set2.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;

                                                                        if (notificationsDoctor.set2 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set2) {
                                                                            notificationsDoctor.set2.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set2 = this.state.notificationsDoctorDefault.set2;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Patient rescheduled/cancelled a visit".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set3) {
                                                                                        notificationsDoctor.set3.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set3) {
                                                                                        notificationsDoctor.set3.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set3) {
                                                                                        notificationsDoctor.set3.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set3) {
                                                                                        notificationsDoctor.set3.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && !this.state.notificationsDoctor.set3.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set3) {
                                                                        notificationsDoctor.set3.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 && this.state.notificationsDoctor.set3.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set3 ? this.state.notificationsDoctor.set3.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;

                                                                        if (notificationsDoctor.set3 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set3) {
                                                                            notificationsDoctor.set3.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set3 = this.state.notificationsDoctorDefault.set3;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"New appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set4) {
                                                                                        notificationsDoctor.set4.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set4) {
                                                                                        notificationsDoctor.set4.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set4) {
                                                                                        notificationsDoctor.set4.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set4) {
                                                                        notificationsDoctor.set4.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && !this.state.notificationsDoctor.set4.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set4) {
                                                                                        notificationsDoctor.set4.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 && this.state.notificationsDoctor.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set4 ? this.state.notificationsDoctor.set4.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;

                                                                        if (notificationsDoctor.set4 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set4) {
                                                                            notificationsDoctor.set4.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set4 = this.state.notificationsDoctorDefault.set4;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"New message".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set5) {
                                                                                        notificationsDoctor.set5.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set5) {
                                                                                        notificationsDoctor.set5.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set5) {
                                                                                        notificationsDoctor.set5.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set5) {
                                                                        notificationsDoctor.set5.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && !this.state.notificationsDoctor.set5.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set5) {
                                                                                        notificationsDoctor.set5.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 && this.state.notificationsDoctor.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set5 ? this.state.notificationsDoctor.set5.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;


                                                                        if (notificationsDoctor.set5 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set5) {
                                                                            notificationsDoctor.set5.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set5 = this.state.notificationsDoctorDefault.set5;
                                                                            }
                                                                        }

                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"New time for appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set6) {
                                                                                        notificationsDoctor.set6.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set6) {
                                                                                        notificationsDoctor.set6.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set6) {
                                                                                        notificationsDoctor.set6.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <td>
                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.webpush : null}
                                                            onChange={(e) => {

                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                    if (notificationsDoctor.set6) {
                                                                        notificationsDoctor.set6.webpush = e.target.checked;
                                                                    }
                                                                    this.setState({ notificationsDoctor })
                                                                }
                                                            }}
                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                        /></div>
                                                    </td> */}
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && !this.state.notificationsDoctor.set6.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set6) {
                                                                                        notificationsDoctor.set6.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 && this.state.notificationsDoctor.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set6 ? this.state.notificationsDoctor.set6.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;
                                                                        if (notificationsDoctor.set6 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set6) {
                                                                            notificationsDoctor.set6.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set6 = this.state.notificationsDoctorDefault.set6;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p>{"Reminder that a meeting starts in 1 hour".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set7) {
                                                                                        notificationsDoctor.set7.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set7) {
                                                                                        notificationsDoctor.set7.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set7) {
                                                                                        notificationsDoctor.set7.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && !this.state.notificationsDoctor.set7.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set7) {
                                                                                        notificationsDoctor.set7.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 && this.state.notificationsDoctor.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set7 ? this.state.notificationsDoctor.set7.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;
                                                                        if (notificationsDoctor.set7 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set7) {
                                                                            notificationsDoctor.set7.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set7 = this.state.notificationsDoctorDefault.set7;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Payment information".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set8) {
                                                                                        notificationsDoctor.set8.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set8) {
                                                                                        notificationsDoctor.set8.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>

                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set8) {
                                                                                        notificationsDoctor.set8.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                notifEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.notif : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && !this.state.notificationsDoctor.set8.default) {
                                                                                    let notificationsDoctor = this.state.notificationsDoctor;
                                                                                    if (notificationsDoctor.set8) {
                                                                                        notificationsDoctor.set8.notif = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsDoctor })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 && this.state.notificationsDoctor.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsDoctor && this.state.notificationsDoctor.set8 ? this.state.notificationsDoctor.set8.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsDoctor = this.state.notificationsDoctor;
                                                                        if (notificationsDoctor.set8 && this.state.notificationsDoctorDefault && this.state.notificationsDoctorDefault.set8) {
                                                                            notificationsDoctor.set8.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsDoctor.set8 = this.state.notificationsDoctorDefault.set8;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsDoctor })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </FormGroup>




                                    </Col>


                                    <Col lg="12" className={this.state.tabIndex == 2 ? 'visibility mt-20' : 'none-visibility'}>

                                        <FormGroup style={{ width: '100%', overflowX: 'auto' }}>
                                            <div className="table-container-scroll">
                                                <div className="table-container-scroll-reverse">
                                                    <table className="clinic-notification-table">
                                                        <tr>
                                                            <th>{"Patient notifications".translate(this.props.lang)}</th>
                                                            {
                                                                mailEnabled ?
                                                                    <th>{"EMAIL".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <th>{"SMS".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <th>{"PUSH".translate(this.props.lang)}</th>
                                                                    :
                                                                    null
                                                            }
                                                            <th>
                                                                {"SYSTEM / APP".translate(this.props.lang)}
                                                                <span className='info-wrap'>
                                                                    <Isvg src={infoIcon} />
                                                                    <div className='info-tooltip'>{'This setting cannot be changed.'.translate(this.props.lang)}</div>
                                                                </span>
                                                            </th>

                                                            <th>{"DEFAULT".translate(this.props.lang)}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Reminder that a appointment starts in 24 hours".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.email : null}
                                                                            onChange={(e) => {
                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set1) {
                                                                                        notificationsPatient.set1.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}

                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set1) {
                                                                                        notificationsPatient.set1.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set1 && !this.state.notificationsPatient.set1.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set1) {
                                                                                        notificationsPatient.set1.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set1 && this.state.notificationsPatient.set1.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set1 ? this.state.notificationsPatient.set1.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set1 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set1) {
                                                                            notificationsPatient.set1.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set1 = this.state.notificationsPatientDefault.set1;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })


                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Reminder that a appointment starts in 1 hour".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set10) {
                                                                                        notificationsPatient.set10.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set10) {
                                                                                        notificationsPatient.set10.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set10 && !this.state.notificationsPatient.set10.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set10) {
                                                                                        notificationsPatient.set10.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set10 && this.state.notificationsPatient.set10.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set10 ? this.state.notificationsPatient.set10.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set10 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set10) {
                                                                            notificationsPatient.set10.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set10 = this.state.notificationsPatientDefault.set10;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Reminder that a appointment starts in 10 minutes".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set11) {
                                                                                        notificationsPatient.set11.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set11) {
                                                                                        notificationsPatient.set11.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set11 && !this.state.notificationsPatient.set11.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set11) {
                                                                                        notificationsPatient.set11.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set11 && this.state.notificationsPatient.set11.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set11 ? this.state.notificationsPatient.set11.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set11 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set11) {
                                                                            notificationsPatient.set11.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set11 = this.state.notificationsPatientDefault.set11;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Notification of received request".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set2) {
                                                                                        notificationsPatient.set2.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set2) {
                                                                                        notificationsPatient.set2.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set2 && !this.state.notificationsPatient.set2.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set2) {
                                                                                        notificationsPatient.set2.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set2 && this.state.notificationsPatient.set2.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set2 ? this.state.notificationsPatient.set2.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set2 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set2) {
                                                                            notificationsPatient.set2.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set2 = this.state.notificationsPatientDefault.set2;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Approved request".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set3) {
                                                                                        notificationsPatient.set3.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set3) {
                                                                                        notificationsPatient.set3.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set3 && !this.state.notificationsPatient.set3.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set3) {
                                                                                        notificationsPatient.set3.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set3 && this.state.notificationsPatient.set3.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set3 ? this.state.notificationsPatient.set3.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set3 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set3) {
                                                                            notificationsPatient.set3.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set3 = this.state.notificationsPatientDefault.set3;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}

                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"A meeting has been booked".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set4) {
                                                                                        notificationsPatient.set4.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set4) {
                                                                                        notificationsPatient.set4.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set4 && !this.state.notificationsPatient.set4.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set4) {
                                                                                        notificationsPatient.set4.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set4 && this.state.notificationsPatient.set4.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set4 ? this.state.notificationsPatient.set4.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set4 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set4) {
                                                                            notificationsPatient.set4.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set4 = this.state.notificationsPatientDefault.set4;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Denied patient request".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set5) {
                                                                                        notificationsPatient.set5.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set5) {
                                                                                        notificationsPatient.set5.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set5 && !this.state.notificationsPatient.set5.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set5) {
                                                                                        notificationsPatient.set5.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set5 && this.state.notificationsPatient.set5.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set5 ? this.state.notificationsPatient.set5.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set5 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set5) {
                                                                            notificationsPatient.set5.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set5 = this.state.notificationsPatientDefault.set5;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Rebooked appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set6) {
                                                                                        notificationsPatient.set6.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set6) {
                                                                                        notificationsPatient.set6.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set6 && !this.state.notificationsPatient.set6.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set6) {
                                                                                        notificationsPatient.set6.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set6 && this.state.notificationsPatient.set6.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set6 ? this.state.notificationsPatient.set6.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set6 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set6) {
                                                                            notificationsPatient.set6.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set6 = this.state.notificationsPatientDefault.set6;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"New message".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set7) {
                                                                                        notificationsPatient.set7.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set7) {
                                                                                        notificationsPatient.set7.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set7 && !this.state.notificationsPatient.set7.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set7) {
                                                                                        notificationsPatient.set7.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set7 && this.state.notificationsPatient.set7.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set7 ? this.state.notificationsPatient.set7.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set7 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set7) {
                                                                            notificationsPatient.set7.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set7 = this.state.notificationsPatientDefault.set7;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Change time for appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set8) {
                                                                                        notificationsPatient.set8.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set8) {
                                                                                        notificationsPatient.set8.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set8 && !this.state.notificationsPatient.set8.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set8) {
                                                                                        notificationsPatient.set8.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set8 && this.state.notificationsPatient.set8.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set8 ? this.state.notificationsPatient.set8.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set8 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set8) {
                                                                            notificationsPatient.set8.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set8 = this.state.notificationsPatientDefault.set8;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })

                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Deleted scheduled appointment".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set9) {
                                                                                        notificationsPatient.set9.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set9) {
                                                                                        notificationsPatient.set9.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set9 && !this.state.notificationsPatient.set9.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set9) {
                                                                                        notificationsPatient.set9.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set9 && this.state.notificationsPatient.set9.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set9 ? this.state.notificationsPatient.set9.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set9 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set9) {
                                                                            notificationsPatient.set9.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set9 = this.state.notificationsPatientDefault.set9;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        {
                                                            listingNotificationsEnabled ?
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Listing request approved".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set12) {
                                                                                                notificationsPatient.set12.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set12) {
                                                                                                notificationsPatient.set12.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set12 && !this.state.notificationsPatient.set12.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set12) {
                                                                                                notificationsPatient.set12.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set12 && this.state.notificationsPatient.set12.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set12 ? this.state.notificationsPatient.set12.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set12 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set12) {
                                                                                    notificationsPatient.set12.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsPatient.set12 = this.state.notificationsPatientDefault.set12;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                null
                                                        }

                                                        <tr>
                                                            <td>
                                                                <p>{"Video call starting".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set13) {
                                                                                        notificationsPatient.set13.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set13) {
                                                                                        notificationsPatient.set13.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set13 && !this.state.notificationsPatient.set13.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set13) {
                                                                                        notificationsPatient.set13.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set13 && this.state.notificationsPatient.set13.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set13 ? this.state.notificationsPatient.set13.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set13 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set13) {
                                                                            notificationsPatient.set13.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set13 = this.state.notificationsPatientDefault.set13;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>{"Payment reminders".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set14) {
                                                                                        notificationsPatient.set14.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set14) {
                                                                                        notificationsPatient.set14.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set14 && !this.state.notificationsPatient.set14.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set14) {
                                                                                        notificationsPatient.set14.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set14 && this.state.notificationsPatient.set14.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set14 ? this.state.notificationsPatient.set14.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set14 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set14) {
                                                                            notificationsPatient.set14.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set14 = this.state.notificationsPatientDefault.set14;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr>
                                                        {
                                                            semble ?
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Semble notifications".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set15) {
                                                                                                notificationsPatient.set15.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set15) {
                                                                                                notificationsPatient.set15.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                            let notificationsPatient = this.state.notificationsPatient;
                                                                                            if (notificationsPatient.set15) {
                                                                                                notificationsPatient.set15.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsPatient })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsPatient = this.state.notificationsPatient;
                                                                                if (notificationsPatient.set15 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set15) {
                                                                                    notificationsPatient.set15.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsPatient.set15 = this.state.notificationsPatientDefault.set15;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsPatient })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                null
                                                        }
                                                        {/* <tr>
                                                            <td>
                                                                <p>{"Diary, Test results and Medication list".translate(this.props.lang)}</p>
                                                            </td>
                                                            {
                                                                mailEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.email : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set15) {
                                                                                        notificationsPatient.set15.email = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                smsEnabled ?
                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.sms : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set15) {
                                                                                        notificationsPatient.set15.sms = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }
                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                pushEnabled ?
                                                                    <td >
                                                                        <div ><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.webpush : null}
                                                                            onChange={(e) => {

                                                                                if (this.state.notificationsPatient && this.state.notificationsPatient.set15 && !this.state.notificationsPatient.set15.default) {
                                                                                    let notificationsPatient = this.state.notificationsPatient;
                                                                                    if (notificationsPatient.set15) {
                                                                                        notificationsPatient.set15.webpush = e.target.checked;
                                                                                    }
                                                                                    this.setState({ notificationsPatient })
                                                                                }

                                                                            }}
                                                                            disabled={this.state.notificationsPatient && this.state.notificationsPatient.set15 && this.state.notificationsPatient.set15.default ? true : false}
                                                                        /></div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                            <td>
                                                                <div><Checkbox disabled checked={true}
                                                                    onChange={(e) => {


                                                                    }}
                                                                /></div>
                                                            </td>

                                                            <td>
                                                                <div><Checkbox checked={this.state.notificationsPatient && this.state.notificationsPatient.set15 ? this.state.notificationsPatient.set15.default : null}
                                                                    onChange={(e) => {
                                                                        let notificationsPatient = this.state.notificationsPatient;
                                                                        if (notificationsPatient.set15 && this.state.notificationsPatientDefault && this.state.notificationsPatientDefault.set15) {
                                                                            notificationsPatient.set15.default = e.target.checked;
                                                                            if (e.target.checked) {
                                                                                notificationsPatient.set15 = this.state.notificationsPatientDefault.set15;
                                                                            }
                                                                        }
                                                                        this.setState({ notificationsPatient })
                                                                    }}
                                                                /></div>
                                                            </td>
                                                        </tr> */}

                                                    </table>
                                                </div>
                                            </div>
                                        </FormGroup>


                                    </Col>
                                    {
                                        enterprise ?
                                            <Col lg="12" className={this.state.tabIndex == 3 ? 'visibility mt-20' : 'none-visibility'}>

                                                <FormGroup style={{ width: '100%', overflowX: 'auto' }}>
                                                    <div className="table-container-scroll">
                                                        <div className="table-container-scroll-reverse">
                                                            <table className="clinic-notification-table">
                                                                <tr>
                                                                    <th>{"Patient notifications".translate(this.props.lang)}</th>
                                                                    {
                                                                        mailEnabled ?
                                                                            <th>{"EMAIL".translate(this.props.lang)}</th>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <th>{"SMS".translate(this.props.lang)}</th>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <th>{"PUSH".translate(this.props.lang)}</th>
                                                                            :
                                                                            null
                                                                    }
                                                                    <th>
                                                                        {"SYSTEM / APP".translate(this.props.lang)}
                                                                        <span className='info-wrap'>
                                                                            <Isvg src={infoIcon} />
                                                                            <div className='info-tooltip'>{'This setting cannot be changed.'.translate(this.props.lang)}</div>
                                                                        </span>
                                                                    </th>

                                                                    <th>{"DEFAULT".translate(this.props.lang)}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Diary".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 ? this.state.notificationsGlobal.set1.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && !this.state.notificationsGlobal.set1.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set1) {
                                                                                                notificationsGlobal.set1.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && this.state.notificationsGlobal.set1.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 ? this.state.notificationsGlobal.set1.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && !this.state.notificationsGlobal.set1.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set1) {
                                                                                                notificationsGlobal.set1.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && this.state.notificationsGlobal.set1.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 ? this.state.notificationsGlobal.set1.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && !this.state.notificationsGlobal.set1.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set1) {
                                                                                                notificationsGlobal.set1.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 && this.state.notificationsGlobal.set1.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set1 ? this.state.notificationsGlobal.set1.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsGlobal = this.state.notificationsGlobal;
                                                                                if (notificationsGlobal.set1 && this.state.notificationsGlobalDefault && this.state.notificationsGlobalDefault.set1) {
                                                                                    notificationsGlobal.set1.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsGlobal.set1 = this.state.notificationsGlobalDefault.set1;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsGlobal })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Test results".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 ? this.state.notificationsGlobal.set2.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && !this.state.notificationsGlobal.set2.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set2) {
                                                                                                notificationsGlobal.set2.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && this.state.notificationsGlobal.set2.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 ? this.state.notificationsGlobal.set2.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && !this.state.notificationsGlobal.set2.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set2) {
                                                                                                notificationsGlobal.set2.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && this.state.notificationsGlobal.set2.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 ? this.state.notificationsGlobal.set2.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && !this.state.notificationsGlobal.set2.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set2) {
                                                                                                notificationsGlobal.set2.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 && this.state.notificationsGlobal.set2.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set2 ? this.state.notificationsGlobal.set2.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsGlobal = this.state.notificationsGlobal;
                                                                                if (notificationsGlobal.set2 && this.state.notificationsGlobalDefault && this.state.notificationsGlobalDefault.set2) {
                                                                                    notificationsGlobal.set2.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsGlobal.set2 = this.state.notificationsGlobalDefault.set2;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsGlobal })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Medication list".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 ? this.state.notificationsGlobal.set3.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && !this.state.notificationsGlobal.set3.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set3) {
                                                                                                notificationsGlobal.set3.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && this.state.notificationsGlobal.set3.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 ? this.state.notificationsGlobal.set3.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && !this.state.notificationsGlobal.set3.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set3) {
                                                                                                notificationsGlobal.set3.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && this.state.notificationsGlobal.set3.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 ? this.state.notificationsGlobal.set3.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && !this.state.notificationsGlobal.set3.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set3) {
                                                                                                notificationsGlobal.set3.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 && this.state.notificationsGlobal.set3.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set3 ? this.state.notificationsGlobal.set3.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsGlobal = this.state.notificationsGlobal;
                                                                                if (notificationsGlobal.set3 && this.state.notificationsGlobalDefault && this.state.notificationsGlobalDefault.set3) {
                                                                                    notificationsGlobal.set3.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsGlobal.set3 = this.state.notificationsGlobalDefault.set3;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsGlobal })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>{"Patient documents".translate(this.props.lang)}</p>
                                                                    </td>
                                                                    {
                                                                        mailEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 ? this.state.notificationsGlobal.set4.email : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && !this.state.notificationsGlobal.set4.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set4) {
                                                                                                notificationsGlobal.set4.email = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && this.state.notificationsGlobal.set4.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        smsEnabled ?
                                                                            <td>
                                                                                <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 ? this.state.notificationsGlobal.set4.sms : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && !this.state.notificationsGlobal.set4.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set4) {
                                                                                                notificationsGlobal.set4.sms = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && this.state.notificationsGlobal.set4.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        pushEnabled ?
                                                                            <td >
                                                                                <div ><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 ? this.state.notificationsGlobal.set4.webpush : null}
                                                                                    onChange={(e) => {

                                                                                        if (this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && !this.state.notificationsGlobal.set4.default) {
                                                                                            let notificationsGlobal = this.state.notificationsGlobal;
                                                                                            if (notificationsGlobal.set4) {
                                                                                                notificationsGlobal.set4.webpush = e.target.checked;
                                                                                            }
                                                                                            this.setState({ notificationsGlobal })
                                                                                        }

                                                                                    }}
                                                                                    disabled={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 && this.state.notificationsGlobal.set4.default ? true : false}
                                                                                /></div>
                                                                            </td>
                                                                            :
                                                                            null
                                                                    }
                                                                    <td>
                                                                        <div><Checkbox disabled checked={true}
                                                                            onChange={(e) => {


                                                                            }}
                                                                        /></div>
                                                                    </td>

                                                                    <td>
                                                                        <div><Checkbox checked={this.state.notificationsGlobal && this.state.notificationsGlobal.set4 ? this.state.notificationsGlobal.set4.default : null}
                                                                            onChange={(e) => {
                                                                                let notificationsGlobal = this.state.notificationsGlobal;
                                                                                if (notificationsGlobal.set4 && this.state.notificationsGlobalDefault && this.state.notificationsGlobalDefault.set4) {
                                                                                    notificationsGlobal.set4.default = e.target.checked;
                                                                                    if (e.target.checked) {
                                                                                        notificationsGlobal.set4 = this.state.notificationsGlobalDefault.set4;
                                                                                    }
                                                                                }
                                                                                this.setState({ notificationsGlobal })
                                                                            }}
                                                                        /></div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </FormGroup>


                                            </Col>
                                            :
                                            null
                                    }

                                    <Col lg='12' className="notifications-btns-wrap">
                                        <Button color="primary" onClick={() => this.update()}>
                                            {/* {"Save".translate(this.props.lang)} */}

                                            {
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }

                                        </Button>
                                        <Button color="primary" className="reset-default-mobile" onClick={() => this.setDefaultValues(this.state.tabIndex)}>{"Reset default".translate(this.props.lang)}</Button>
                                    </Col>



                                </Row>


                            </div>

                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        check: selector(state, 'landingConfig.informationLink')

    }

})(Page(ClinicData));


