import React, { Component } from 'react';
import Page from '../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';
import Checkbox from './forms/fields/checkbox';
import File from './forms/fields/multipleFiles';


import { API_ENDPOINT } from '../constants';
import HTML from './forms/fields/html';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



/**
* Modal for creating referral
* @author   Milan Stanojevic
*/
class CreateReferralModal extends Component {
    constructor(props) {
        super(props);
        this.searchResultsWraps = {};

        this.state = {
            ...props.initialData,
            answers: {},
            items: [],
            nextItems: [],
            audioStarted: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        let arr = []
        for (let i = 0; i <= 100; i++) {
            let obj = {}
            obj.value = i;
            obj.name = i;

            arr.push(obj)
        }

        this.setState({
            items: arr
        })
    }
    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        for (var key in this.searchResultsWraps) {
            if (this.searchResultsWraps[key]) {
                if (this.searchResultsWraps[key] && this.searchResultsWraps[key] && !this.searchResultsWraps[key].contains(event.target)) {
                    let stateObj = {};
                    stateObj[`showIcd10Suggestions-${key}`] = false;
                    this.setState(stateObj)
                }

            }
        }
    }




    getQuestionAnswers = (id) => {
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].id == id) {
                        return this.props.sections[i].questions[j].answers ? this.props.sections[i].questions[j].answers : [];
                    }
                }
            }
        }


        return [];
    }
    getQuestion = (id) => {
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].id == id) {
                        return this.props.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.props.sections[i].questions[j].answers.length; k++) {
                            if (this.props.sections[i].questions[j].answers[k].id == id) {
                                return this.props.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }





    answerQuestion(questionId, answer) {
        this.setState({ nextItems: [] }, () => {
            let question = this.getQuestion(questionId);
            if (!question) {
                return;
            }

            let answers = this.state.answers;

            if (question.type == 1) {
                if (!answers[question.id]) {
                    answers[question.id] = null;
                }

                answers[question.id] = answer;
            } else if (question.type == 2) {
                if (!answers[question.id]) {
                    answers[question.id] = [];
                }

                if (answers[question.id].indexOf(answer) !== -1) {
                    answers[question.id].splice(answers[question.id].indexOf(answer), 1);
                } else {
                    answers[question.id].push(answer);
                }

            } else if (question.type == 3 || question.type == 5 || question.type == 6 || question.type == 9 || question.type == 12 || question.type == 13 || question.type == 14) {
                answers[question.id] = answer;
            }
            // clear not vissible fields

            for (let i = 0; i < this.props.sections.length; i++) {
                if (this.checkSectionCondition(i)) {
                    for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                        if (!this.checkQuestionCondition(this.props.sections[i].questions[j].id)) {
                            delete answers[this.props.sections[i].questions[j].id];
                            if (this.props.sections[i].questions[j].type == 5) {
                                this.setState({
                                    icd10Search: ''
                                })
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                        delete answers[this.props.sections[i].questions[j].id];
                        if (this.props.sections[i].questions[j].type == 5) {
                            this.setState({
                                icd10Search: ''
                            })
                        }
                    }
                }
            }

            //console.log(answers)
            this.setState({
                answers
            })
        })

    }

    checkQuestionAnswer = (questionId) => {
        let question = this.getQuestion(questionId);
        if (!question) {
            return false;
        }

        if (question.type == 1) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 2) {
            if (this.state.answers[question.id] && this.state.answers[question.id].length) {
                return true;
            }

        } else if (question.type == 3) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 5) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 6) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 7) {
            if (this.state.answers[question.id] && this.state.answers[question.id][0] && this.state.answers[question.id][1] && this.state.answers[question.id][2] && this.state.answers[question.id][3]) {
                return true;
            }
        } else if (question.type == 8) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 9) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 10) {
            return true;
        } else if (question.type == 15) {
            return true;

        } else if (question.type == 12) {
            if (this.state.answers[question.id]) {
                return true;
            }

        } else if (question.type == 13) {
            if (this.state.answers[question.id]) {
                return true;
            }
        } else if (question.type == 14) {
            if (this.state.answers[question.id]) {
                return true;
            }

        }
        return false;
    }
    addToNextItems = (questionId, qidx) => {
        let nextItems = this.state.nextItems;
        nextItems = nextItems.sort((a, b) => a.qidx - b.qidx)
        if (nextItems.filter(item => item.questionId == questionId).length == 0) {
            for (let i = 0; i < nextItems.length; i++) {
                let question = this.getQuestion(nextItems[i]);
                if (question.type == 4) {
                    return false;
                }
            }
            nextItems.push({
                questionId,
                qidx
            })
            this.setState({ nextItems })
        }

        return true;

    }
    getPreviousVisibleChatQuestion = (sectionIdx, questionIdx) => {
        if (questionIdx == -1 && sectionIdx > 0 && this.props.sections[sectionIdx - 1] && this.props.sections[sectionIdx - 1].questions && this.props.sections[sectionIdx - 1].questions.length) {
            for (let i = this.props.sections[sectionIdx - 1].questions.length - 1; i >= 0; i--) {
                if (this.props.sections[sectionIdx - 1].questions[i].type == 10) {
                    continue;
                }

                if (this.checkQuestionCondition(this.props.sections[sectionIdx - 1].questions[i].id)) {
                    return this.props.sections[sectionIdx - 1].questions[i];
                }
            }

        } else {
            for (let i = questionIdx; i >= 0; i--) {
                if (this.props.sections[sectionIdx].questions[i].type == 10) {
                    continue;
                }


                if (this.checkQuestionCondition(this.props.sections[sectionIdx].questions[i].id)) {
                    return this.props.sections[sectionIdx].questions[i];
                }
            }
        }
        if (sectionIdx > 0) {
            let questions = this.state.sections[sectionIdx - 1].questions;
            for (let i = questions.length - 1; i >= 0; i--) {
                if (this.state.answers[questions[i].id]) {
                    return questions[i];
                }
            }

        }

        return null;
    }

    checkChatQuestionCondition = (questionId, qidx) => {
        if (!this.checkQuestionCondition(questionId, qidx)) {
            return false;
        }


        let check = this.getQuestion(questionId);
        if (this.state.nextItems[check.type] == 4) {
            if (this.state.nextButtonClicked) {
                // this.setState({
                //     nextButtonClicked: false
                // })
                return true
            } else {
                return true
            }
        }
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.props.sections[i] && this.props.sections[i].questions) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.props.sections[i].questions[j].id == questionId) {
                        if (j == 0) {


                            return true;
                        } else {
                            let previousQuestion = this.getPreviousVisibleChatQuestion(i, j - 1, qidx);
                            let prevPrevQuestion = this.getPreviousVisibleChatQuestion(i, j - 2, qidx);

                            //console.log('previousQuestion', previousQuestion)
                            if (!previousQuestion) {
                                return false;
                            }


                            if (previousQuestion.type == 4) {
                                return false;
                            } else if (previousQuestion.type == 1 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 2 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 3 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 5 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id].length) {
                                return true;
                            } else if (previousQuestion.type == 6 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1]) {
                                return true;
                            } else if (previousQuestion.type == 7 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1] && this.state.answers[previousQuestion.id][2] && this.state.answers[previousQuestion.id][3]) {
                                return true;
                            } else if (previousQuestion.type == 8 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 9 && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 10 && this.state.answers[prevPrevQuestion.id]) {
                                return true;
                            } else if (previousQuestion.type == 15) {
                                return true;
                            } else if (previousQuestion.type == 12 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0] && this.state.answers[previousQuestion.id][1] && this.state.answers[previousQuestion.id][2]) {
                                return true;
                            } else if (previousQuestion.type == 13 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id][0]) {
                                return true;
                            } else if (previousQuestion.type == 14 && this.state.answers[previousQuestion.id] && this.state.answers[previousQuestion.id]) {
                                return true;
                            } else {
                                return false;
                            }


                        }
                    }
                }
            }
        }

        return true;
    }

    checkQuestionCondition(questionId, qidx) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {

            return this.addToNextItems(questionId, qidx);
        }

        if (!question.conditions.length) {
            return this.addToNextItems(questionId, qidx);
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }




            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return this.addToNextItems(questionId, qidx);
                }
            } else if (conditionQuestion.type == 2) {
                if (question.conditions[i].conditionAnswer == '__user-input:' && this.state.answers[conditionQuestion.id]) {
                    let index = this.indexOfIndexOf(this.state.answers[conditionQuestion.id], '__user-input:');
                    if (index != -1 && this.state.answers[conditionQuestion.id][index] && this.state.answers[conditionQuestion.id][index].replace('__user-input:', '')) {
                        return this.addToNextItems(questionId, qidx);
                    }
                } else {

                    if (this.state.answers[conditionQuestion.id] && this.state.answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                        return this.addToNextItems(questionId, qidx);
                    }
                }

            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14) {
                if (this.state.answers[conditionQuestion.id]) {
                    return this.addToNextItems(questionId, qidx);
                }

            } else if (conditionQuestion.type == 10) {
                return this.addToNextItems(questionId, qidx);

            } else if (conditionQuestion.type == 15) {
                return this.addToNextItems(questionId, qidx);
            }
        }
        return false;
    }
    checkSectionCondition(sectionIdx) {
        let section = this.props.sections[sectionIdx];
        if (!section) {
            return;
        }
        if (sectionIdx > 0) {
            let prevSection = this.props.sections[sectionIdx - 1]
            let questions = prevSection.questions;
            if (questions && questions.length) {
                let notAllAnswered = false;
                for (let i = 0; i < questions.length; i++) {
                    let check = false;
                    if (questions[i] && questions[i].conditions.length == 0) {
                        check = true;
                    } else if (questions[i] && questions[i].conditions.length > 0 && this.checkQuestionCondition(questions[i].id)) {
                        let check2 = true;
                        for (let j = 0; j < questions[i].conditions.length; j++) {
                            if (this.checkQuestionAnswer(questions[i].conditions[j].conditionQuestion) /*&& this.getAnswer(questions[i].id) == questions[i].conditions[j].conditionAnswer*/ && this.checkQuestionCondition(questions[i].id)) {
                                check2 = false;
                            }
                        }
                        if (check2) {
                            check = true;
                        }
                    }

                    if (!this.checkQuestionAnswer(questions[i].id) && check) {
                        notAllAnswered = true;
                    }

                }
                if (notAllAnswered) {
                    return false;
                }
            }
        }




        if (!section.conditions) {
            return true;
        }
        if (!section.conditions.length) {
            return true;
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.answers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.answers[question.id] && this.state.answers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 12) {
                if (this.state.answers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 14) {
                if (this.state.answers[question.id]) {
                    return true;
                }
            } else if (question.type == 10) {
                return true;
            } else if (question.type == 15) {
                return true;
            }
        }

        return false;
    }

    checkReferralAnswers = () => {
        let lastQuestionType = -1;
        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && (this.props.sections[i].questions[j].type == 1 || this.props.sections[i].questions[j].type == 2 || this.props.sections[i].questions[j] == 3 || this.props.sections[i].questions[j] == 5 || this.props.sections[i].questions[j] == 6 || this.props.section[i].question[j] == 10 || this.props.sections[i].questions[j] == 12 || this.props.sections[i].questions[j] == 13)) {
                        lastQuestionType = this.props.sections[i].questions[j].type;
                        if (!this.state.answers[this.props.sections[i].questions[j].id] && this.props.sections[i].questions[j].type != 10) {
                            // console.log(this.getQuestion(this.props.sections[i].questions[j].id))
                            return false;
                        }
                    }

                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && this.props.sections[i].questions[j].type == 4) {
                        lastQuestionType = this.props.sections[i].questions[j].type;
                    }
                }
            }
        }

        return lastQuestionType != 4 ? true : false;
    }
    translateReferralContent = (referralContent) => {
        let lang = this.props.lang;
        console.log('lang', lang)
        let returnValue = referralContent;
        returnValue = referralContent
            .replace(/Sökdatum/g, 'Sökdatum'.translate(lang))
            .replace(/Ärende-ID/g, 'Ärende-ID'.translate(lang))
            .replace('Personuppgifter', 'Personuppgifter'.translate(lang))
            .replace('Patientens längd', 'Patientens längd'.translate(lang))
            .replace('vikt', 'vikt'.translate(lang))
            .replace('BMI', 'BMI'.translate(lang))
            .replace('Frikortsnummer', 'Frikortsnummer'.translate(lang))
            .replace('Giltig från', 'Giltig från'.translate(lang))
            .replace('Giltig till', 'Giltig till'.translate(lang))
            .replace('Utfärdad av', 'Utfärdad av'.translate(lang))
            .replace(/File\/photo/g, 'File/photo'.translate(lang))
            .replace('Patientens blodtryck uppmätt till', 'Patientens blodtryck uppmätt till'.translate(lang))
            .replace(/Patientens VAS-skalvärde är/g, 'Patientens VAS-skalvärde är'.translate(lang))
            .replace('Aktuell andningsfrekvens/minut', 'Aktuell andningsfrekvens/minut'.translate(lang))
            .replace('Vårdnadshavare', 'Vårdnadshavare'.translate(lang))
            .replace('Samtycke', 'Samtycke'.translate(lang))
            .replace('Free kort number', 'Free kort number'.translate(lang))
            .replace('Valid from', 'Valid from'.translate(lang))
            .replace('Valid to', 'Valid to'.translate(lang))
            .replace('Issued at', 'Issued at'.translate(lang))
            .replace('Paid on', 'Paid on'.translate(lang))
            .replace('Visit date', 'Visit date'.translate(lang))
            .replace('Kvitto nr', 'Kvitto nr'.translate(lang))
            .replace('Referens', 'Referens'.translate(lang))
            .replace('Betalningssätt', 'Betalningssätt'.translate(lang))
            .replace('Specifikation', 'Specifikation'.translate(lang))
            .replace('Anledning till besök', 'Anledning till besök'.translate(lang))
            .replace('Patient svar', 'Patient svar'.translate(lang))
        return returnValue;
    }

    generateReferral = () => {

        let referralData = '';
        referralData += `<p><strong>Patient svar</strong></p>\n\n`
        // console.log(this.state.answers);

        for (let i = 0; i < this.props.sections.length; i++) {
            if (this.checkSectionCondition(i)) {
                if (this.props.sections[i].displayOnReferral) {
                    if (i > 0) {
                        referralData += '<br/><br/>'
                    }
                    referralData += `<p><strong>${this.props.sections[i].name}</strong></p>\n`;
                }
                referralData += '<p>\n';
                for (let j = 0; j < this.props.sections[i].questions.length; j++) {
                    if (this.checkQuestionCondition(this.props.sections[i].questions[j].id) && (this.props.sections[i].questions[j].type == 10 || this.state.answers[this.props.sections[i].questions[j].id])) {
                        if (this.props.sections[i].questions[j].displayOnReferral) {
                            if (this.props.sections[i].questions[j].type == 1) {
                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id]).text)
                                    referralData += this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id]).text + ' ';
                            } else if (this.props.sections[i].questions[j].type == 2) {
                                if (this.state.answers[this.props.sections[i].questions[j].id]) {

                                    let arr = [];

                                    //for (let k = 0; k < this.state.answers[this.props.sections[i].questions[j].id].length; k++) {
                                    for (let k = 0; k < this.props.sections[i].questions[j].answers.length; k++) {
                                        let check = -1;
                                        for (let l = 0; l < this.state.answers[this.props.sections[i].questions[j].id].length; l++) {
                                            // console.log(this.state.answers[this.props.sections[i].questions[j].id][l], this.props.sections[i].questions[j].answers[k].id)
                                            if (this.props.sections[i].questions[j].answers[k] && this.state.answers[this.props.sections[i].questions[j].id][l] == this.props.sections[i].questions[j].answers[k].id) {
                                                check = l;
                                                break;
                                            }
                                        }
                                        // console.log(check)
                                        if (check != -1) {
                                            if (this.props.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.answers[this.props.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.answers[this.props.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text)
                                                    arr.push(this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text);
                                                // }
                                            } else {
                                                if (this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text)
                                                    referralData += this.getAnswer(this.state.answers[this.props.sections[i].questions[j].id][check]).text + ' ';
                                            }
                                        }
                                    }

                                    if (this.props.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.answers[this.props.sections[i].questions[j].id], '__user-input:') !== -1) {
                                        arr.push(this.state.answers[this.props.sections[i].questions[j].id][this.indexOfIndexOf(this.state.answers[this.props.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                    }

                                    // console.log(arr);

                                    if (arr.length && this.props.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.props.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                        } else {
                                            referralData += this.props.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                        }
                                    }
                                }
                            } else if (this.props.sections[i].questions[j].type == 3) {
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] + '. ';
                            } else if (this.props.sections[i].questions[j].type == 5) {
                                //referralData += this.state.answers[this.props.sections[i].questions[j].id] + '. ';
                                let newArr = JSON.parse(JSON.stringify(this.state.answers[this.props.sections[i].questions[j].id]));
                                let arr = [];
                                for (let k = 0; k < newArr.length; k++) {
                                    if (newArr[k]) {
                                        arr.push(newArr[k]);
                                    }
                                }

                                if (arr.length > 1) {
                                    let last = arr.pop();
                                    referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                } else {
                                    referralData += arr.join(', ') + '. ';
                                }

                            } else if (this.props.sections[i].questions[j].type == 6) {
                                if (this.state.answers[this.props.sections[i].questions[j].id])
                                    referralData += 'Patientens längd: ' + this.state.answers[this.props.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.answers[this.props.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length ? (this.state.answers[this.props.sections[i].questions[j].id][0] / ((this.state.answers[this.props.sections[i].questions[j].id][1] / 100) * (this.state.answers[this.props.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                            } else if (this.props.sections[i].questions[j].type == 9) {
                                if (this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id].length) {
                                    for (let f = 0; f < this.state.answers[this.props.sections[i].questions[j].id].length; f++) {
                                        if (this.state.answers[this.props.sections[i].questions[j].id][f] && this.state.answers[this.props.sections[i].questions[j].id][f].file)
                                            referralData += `<a href='${API_ENDPOINT + this.state.answers[this.props.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                    }
                                }
                                // referralData += `<a href='${this.state.answers[this.props.sections[i].questions[j].id] ? API_ENDPOINT + this.state.answers[this.props.sections[i].questions[j].id].file : ''}' target="_blank">File/photo</a><br/>` + ' ';
                            } else if (this.props.sections[i].questions[j].type == 10) {
                                if (this.props.sections[i].questions[j].userInput) {
                                    referralData += "<br/><br/><br/><br/>"
                                } else {
                                    referralData += "<br/><br/>"
                                }

                            } else if (this.props.sections[i].questions[j].type == 12) {
                                referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][0] ? this.state.answers[this.props.sections[i].questions[j].id][0] + '/' : ''
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][1] ? this.state.answers[this.props.sections[i].questions[j].id][1] + '<br/>' : ''
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.answers[this.props.sections[i].questions[j].id][2] + '<br/>' : ''
                            } else if (this.props.sections[i].questions[j].type == 13) {
                                referralData += 'Patientens VAS-skalvärde är:';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] && this.state.answers[this.props.sections[i].questions[j].id][0] ? this.state.answers[this.props.sections[i].questions[j].id][0] + '<br/>' : ''
                            } else if (this.props.sections[i].questions[j].type == 14) {
                                referralData += 'Aktuell andningsfrekvens/minut:';
                                referralData += this.state.answers[this.props.sections[i].questions[j].id] ? this.state.answers[this.props.sections[i].questions[j].id] + '<br/>' : ''
                            }
                        }
                    }
                }

                referralData += '</p>\n';
            }
        }


        let html = `       
            ${referralData}
        `
        return html;

    }





    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }

    // audioFunction = () => {
    //     this.props.ring112Audio(true)
    // }

    render() {
        // console.log(this.props)
        const bmiIndex = ['#9ABBDA', '#93CCA9', '#FFE02B', '#EAA353', '#D8414A'];
        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.props.sections.length ?
                            <Col lg="12">
                                <div className="panel referral-section">
                                    <Row>
                                        <Col lg="6" className="questionary-section-scroll scroll-questions">
                                            {
                                                this.props.sections.map((section, sidx) => {
                                                    let cssStyle = {};
                                                    if (section.fontSize) {
                                                        cssStyle.fontSize = section.fontSize;
                                                    }
                                                    if (section.fontWeight) {
                                                        cssStyle.fontWeight = section.fontWeight;
                                                    }
                                                    if (section.fontStyle) {
                                                        cssStyle.fontStyle = section.fontStyle;
                                                    }

                                                    if (this.checkSectionCondition(sidx))
                                                        return (
                                                            <div className="referral-section-item">
                                                                <div className="head">
                                                                    <h4 style={cssStyle}> {section.name} </h4>


                                                                </div>



                                                                {
                                                                    section.questions.map((question, qidx) => {
                                                                        let name = question.name;
                                                                        if (question.wikiLinks && question.wikiLinks) {
                                                                            for (let i = 0; i < question.wikiLinks.length; i++) {
                                                                                name = name.replace(question.wikiLinks[i].term, `<a href="#" widx="${i}">${question.wikiLinks[i].term}</a>`)
                                                                            }
                                                                        }

                                                                        let questionCssStyle = {};
                                                                        if (question.fontSize) {
                                                                            questionCssStyle.fontSize = question.fontSize;
                                                                        }
                                                                        if (question.fontWeight) {
                                                                            questionCssStyle.fontWeight = question.fontWeight;
                                                                        }
                                                                        if (question.fontStyle) {
                                                                            questionCssStyle.fontStyle = question.fontStyle;
                                                                        }
                                                                        // if(question.type == 4){
                                                                        //     this.audioFunction();
                                                                        // }

                                                                        if (this.checkChatQuestionCondition(question.id, qidx))
                                                                            return (

                                                                                <div>
                                                                                    <FormGroup tag="fieldset">
                                                                                        {question.wikiLinks && question.wikiLinks.length ?
                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3) ? 'required-question' : ''}>{<div onClick={(e) => {
                                                                                                if (e.target && e.target.getAttribute('widx') !== null) {
                                                                                                    this.setState({
                                                                                                        wikiModal: question.wikiLinks[parseInt(e.target.getAttribute('widx'))].wiki
                                                                                                    })
                                                                                                }
                                                                                            }} dangerouslySetInnerHTML={{ __html: name }}></div>}</Label>

                                                                                            :
                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id] && (question.type == 1 || question.type == 2 || question.type == 3) ? 'required-question' : ''}>{question.name}                                                                                            </Label>

                                                                                        }

                                                                                        {question.type == 1 ?
                                                                                            question.answers.map((answer) => {
                                                                                                return (
                                                                                                    <FormGroup className="referal-checkbox" check>
                                                                                                        <Label size="sm" check>
                                                                                                            <Input size="sm" name={question.id} type={'radio'} checked={this.state.answers[question.id] == answer.id ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />{' '}{answer.name} {answer.wikiHint ? <button onClick={() => this.setState({ wikiModal: answer.wikiHint })} className="wiki-hint"><i className="fa fa-info-circle" /> </button> : null}
                                                                                                        </Label>
                                                                                                    </FormGroup>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        {question.type == 2 ?
                                                                                            <>
                                                                                                {
                                                                                                    question.answers.map((answer) => {
                                                                                                        return (
                                                                                                            <FormGroup className="referal-checkbox" check>
                                                                                                                <Label size="sm" check>
                                                                                                                    <Input size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.state.answers[question.id].indexOf(answer.id) !== -1 ? true : false} onChange={() => this.answerQuestion(question.id, answer.id)} />{' '}{answer.name}
                                                                                                                </Label>
                                                                                                            </FormGroup>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                                {
                                                                                                    question.answerPrefix && question.userInput ?
                                                                                                        <FormGroup className="referal-checkbox" check>
                                                                                                            <Label size="sm" check>
                                                                                                                <Input size="sm" name={question.id} type={'checkbox'} checked={this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') !== -1 ? true : false} onChange={() => {
                                                                                                                    let answers = this.state.answers;
                                                                                                                    if (!answers[question.id]) {
                                                                                                                        answers[question.id] = [];
                                                                                                                    }

                                                                                                                    let index = this.indexOfIndexOf(answers[question.id], '__user-input:');
                                                                                                                    // console.log(index, 'index');
                                                                                                                    if (index != -1) {
                                                                                                                        answers[question.id].splice(index, 1)
                                                                                                                    } else {
                                                                                                                        answers[question.id].push('__user-input:');
                                                                                                                    }
                                                                                                                    this.setState({
                                                                                                                        answers
                                                                                                                    }, this.forceUpdate)
                                                                                                                }} />{' '}{'Ytterligare'}
                                                                                                            </Label>
                                                                                                        </FormGroup>

                                                                                                        :

                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    this.state.answers[question.id] && this.indexOfIndexOf(this.state.answers[question.id], '__user-input:') != -1 ?
                                                                                                        <>
                                                                                                            <Label style={questionCssStyle} size="sm" className={!this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '') ? 'required-question' : ''}>{question.answerPrefix}</Label>

                                                                                                            <Input size="sm" type="text" value={this.state.answers[question.id][this.indexOfIndexOf(this.state.answers[question.id], '__user-input:')].replace('__user-input:', '')} onChange={(e) => {
                                                                                                                let index = this.indexOfIndexOf(this.state.answers[question.id], '__user-input:');
                                                                                                                let answers = this.state.answers;
                                                                                                                answers[question.id][index] = '__user-input:' + e.target.value;
                                                                                                                this.setState({
                                                                                                                    answers
                                                                                                                })
                                                                                                            }}></Input>

                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            null

                                                                                        }


                                                                                        {
                                                                                            question.type == 3 ?
                                                                                                <Input style={questionCssStyle} size="sm" type="textarea" value={this.state.answers[question.id]} onChange={(e) => {
                                                                                                    this.answerQuestion(question.id, e.target.value)
                                                                                                }}></Input>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 4 ?
                                                                                                <div
                                                                                                // onClick={() =>{
                                                                                                //     this.setState({
                                                                                                //         audioStarted: !this.state.audioStarted
                                                                                                //     }, () => {
                                                                                                //         if(question.userInput){
                                                                                                //             this.props.ring112Audio(this.state.audioStarted)
                                                                                                //         }
                                                                                                //     })

                                                                                                // }}
                                                                                                >
                                                                                                    <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>

                                                                                                    {/* <Checkbox checked={question.userInput ? true : false} label={'Alert the patient'.translate(this.props.lang)} /> */}

                                                                                                </div>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 15 ?
                                                                                                <div

                                                                                                >
                                                                                                    <div style={questionCssStyle} className="referral-treatment-instructions" dangerouslySetInnerHTML={{ __html: question.text }}></div>


                                                                                                </div>
                                                                                                :

                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            question.type == 6 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Weight [kg]'.translate(this.props.lang)}</Label>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                val[0] = e.target.value;
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Height [cm]'.translate(this.props.lang)}</Label>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', ''];
                                                                                                                val[1] = e.target.value;
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4" style={{ paddingTop: 35 }}>
                                                                                                        <div style={{
                                                                                                            width: 24,
                                                                                                            height: 24,
                                                                                                            backgroundColor: parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                                                                                :
                                                                                                                parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                                                                                    :
                                                                                                                    parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                                                                                        :
                                                                                                                        parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                                                                                            :
                                                                                                                            parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                                                                                :
                                                                                                                                bmiIndex[5]

                                                                                                        }}></div>
                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 9 ?
                                                                                                <FormGroup tag="fieldset">


                                                                                                    <File onChange={(img) => {
                                                                                                        this.answerQuestion(question.id, img)

                                                                                                    }} value={this.state.answers[question.id]} />
                                                                                                    :


                                                                                                </FormGroup>

                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 14 ?
                                                                                                <Row>

                                                                                                    <Col>
                                                                                                        <FormGroup>
                                                                                                            <Input type='select' value={this.state.answers[question.id] ? this.state.answers[question.id] : ''} min={0} max={100} onChange={(e) => {

                                                                                                                // let val  = e.target.value.replace(/[^\d]/g, '')


                                                                                                                // if (Number(val) > 100) {
                                                                                                                //     val = 100;
                                                                                                                // } else if (Number(val) >= 0 && Number(val) <= 100) {
                                                                                                                //     val = e.target.value.replace(/[^\d]/g, '');
                                                                                                                // } else if (Number(val) < 0) {
                                                                                                                //     val = 0;
                                                                                                                // }
                                                                                                                let val = e.target.value

                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}>
                                                                                                                {this.state.items.map(item => {
                                                                                                                    return (
                                                                                                                        <option value={item.value}>
                                                                                                                            {item.name}
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Input>
                                                                                                        </FormGroup>

                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 12 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Systolic'.translate(this.props.lang)}</Label>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[0] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[0] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[0] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Diastolic'.translate(this.props.lang)}</Label>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][1] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[1] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[1] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[1] = 0;
                                                                                                                }

                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Label>{'Pulse'.translate(this.props.lang)}</Label>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='300' value={this.state.answers[question.id] ? this.state.answers[question.id][2] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : ['', '', ''];
                                                                                                                if (Number(e.target.value) > 300) {
                                                                                                                    val[2] = 300;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 300) {
                                                                                                                    val[2] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[2] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    {/* <Col lg="4" style={{ paddingTop: 35 }}>
                                                                                                        <div style={{
                                                                                                            width: 24,
                                                                                                            height: 24,
                                                                                                            backgroundColor: parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 18.5 ? bmiIndex[0]
                                                                                                                :
                                                                                                                parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 18.5 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 25 ? bmiIndex[1]
                                                                                                                    :
                                                                                                                    parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                        (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 25 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 30 ? bmiIndex[2]
                                                                                                                        :
                                                                                                                        parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                            (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 30 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 35 ? bmiIndex[3]
                                                                                                                            :
                                                                                                                            parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) >= 35 && parseFloat(this.state.answers[question.id] && this.state.answers[question.id].length ?
                                                                                                                                    (this.state.answers[question.id][0] / ((this.state.answers[question.id][1] / 100) * (this.state.answers[question.id][1] / 100))) : 0) < 40 ? bmiIndex[4]
                                                                                                                                :
                                                                                                                                bmiIndex[5]

                                                                                                        }}></div>
                                                                                                    </Col> */}

                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            question.type == 13 ?
                                                                                                <Row>
                                                                                                    <Col lg="4">
                                                                                                        <FormGroup>
                                                                                                            <Input style={questionCssStyle} size="sm" type="number" min='0' max='10' value={this.state.answers[question.id] ? this.state.answers[question.id][0] : ''} onChange={(e) => {
                                                                                                                let val = this.state.answers[question.id] ? this.state.answers[question.id] : [''];
                                                                                                                if (Number(e.target.value) > 10) {
                                                                                                                    val[0] = 10;
                                                                                                                } else if (Number(e.target.value) >= 0 && Number(e.target.value) <= 10) {
                                                                                                                    val[0] = e.target.value;
                                                                                                                } else if (Number(e.target.value) < 0) {
                                                                                                                    val[0] = 0;
                                                                                                                }
                                                                                                                this.answerQuestion(question.id, val)
                                                                                                            }}></Input>

                                                                                                        </FormGroup>
                                                                                                    </Col>



                                                                                                </Row>
                                                                                                :

                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            question.type == 5 ?
                                                                                                <>
                                                                                                    {
                                                                                                        // console.log(this.state.answers[question.id])
                                                                                                    }
                                                                                                    {

                                                                                                        (this.state.answers[question.id] || [""]).map((diagnose, didx) => {
                                                                                                            return (
                                                                                                                <FormGroup>
                                                                                                                    <Input onKeyUp={(e) => {
                                                                                                                        if (e.keyCode == 13) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: null,
                                                                                                                            })

                                                                                                                            if (this.state.suggestionIndex != null) {
                                                                                                                                let suggestion = JSON.parse(JSON.stringify(this.state[`icd10Suggestions-${question.id}-${didx}`][this.state.suggestionIndex]));

                                                                                                                                let arr = this.state.answers;
                                                                                                                                if (!arr[question.id]) {
                                                                                                                                    arr[question.id] = [];
                                                                                                                                }

                                                                                                                                arr[question.id][didx] = suggestion.code + ' - ' + suggestion.name;


                                                                                                                                this.answerQuestion(question.id, arr[question.id])

                                                                                                                                //this.answerQuestion(question.id, suggestion.code + ' - ' + suggestion.name);
                                                                                                                                let stateObj = {};
                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                this.setState(stateObj);
                                                                                                                                return;
                                                                                                                            }

                                                                                                                        }
                                                                                                                        if (e.keyCode == 38) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: this.state.suggestionIndex > 0 ? this.state.suggestionIndex - 1 : 0
                                                                                                                            })
                                                                                                                        }
                                                                                                                        if (e.keyCode == 40) {
                                                                                                                            this.setState({
                                                                                                                                suggestionIndex: this.state.suggestionIndex + 1 >= this.state[`icd10Suggestions-${question.id}-${didx}`].length ? 0 : this.state.suggestionIndex == null ? 0 : this.state.suggestionIndex + 1
                                                                                                                            })

                                                                                                                        }
                                                                                                                    }} onFocus={() => {
                                                                                                                        let stateObj = { suggestionIndex: null };
                                                                                                                        stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                        this.setState(stateObj);
                                                                                                                    }} style={questionCssStyle} size="sm" type="text" value={this.state.answers[question.id] && this.state.answers[question.id][didx] ? this.state.answers[question.id][didx] : ''} onChange={(e) => {
                                                                                                                        //this.answerQuestion(question.id, e.target.value)
                                                                                                                        let arr = this.state.answers;
                                                                                                                        if (!arr[question.id]) {
                                                                                                                            arr[question.id] = [];
                                                                                                                        }

                                                                                                                        arr[question.id][didx] = e.target.value;


                                                                                                                        this.answerQuestion(question.id, arr[question.id])
                                                                                                                        fetch(API_ENDPOINT + '/data/icd10/suggest', {
                                                                                                                            method: 'POST',
                                                                                                                            headers: {
                                                                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                                                'content-type': 'application/json'
                                                                                                                            },
                                                                                                                            body: JSON.stringify({ search: e.target.value })
                                                                                                                        }).then(parseJSON).then(({ result, status }) => {
                                                                                                                            if (status >= 200 && status < 300) {
                                                                                                                                let stateObj = {};
                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = result;
                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = true;
                                                                                                                                this.setState(stateObj);
                                                                                                                            }
                                                                                                                        })


                                                                                                                    }}></Input>
                                                                                                                    {this.state[`showIcd10Suggestions-${question.id}-${didx}`] ?
                                                                                                                        <div className="search-results" ref={(node) => { this.searchResultsWraps[question.id + '-' + didx] = node; }}>
                                                                                                                            <ul >
                                                                                                                                {
                                                                                                                                    this.state[`icd10Suggestions-${question.id}-${didx}`] && this.state[`icd10Suggestions-${question.id}-${didx}`].map((icd10Item, idx) => {
                                                                                                                                        return (
                                                                                                                                            <li className={this.state.suggestionIndex === idx ? 'active' : null} onClick={() => {

                                                                                                                                                let arr = this.state.answers;
                                                                                                                                                if (!arr[question.id]) {
                                                                                                                                                    arr[question.id] = [];
                                                                                                                                                }

                                                                                                                                                arr[question.id][didx] = icd10Item.code + ' - ' + icd10Item.name;


                                                                                                                                                this.answerQuestion(question.id, arr[question.id])


                                                                                                                                                let stateObj = {};
                                                                                                                                                stateObj[`icd10Suggestions-${question.id}-${didx}`] = [];
                                                                                                                                                stateObj[`showIcd10Suggestions-${question.id}-${didx}`] = false;
                                                                                                                                                this.setState(stateObj);
                                                                                                                                            }}>{icd10Item.code} - {icd10Item.name}</li>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }

                                                                                                                </FormGroup>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                    <button className="add-diagnose-button" onClick={() => {
                                                                                                        let answers = this.state.answers;
                                                                                                        if (!answers[question.id]) {
                                                                                                            answers[question.id] = [""];
                                                                                                        }

                                                                                                        answers[question.id].push("");
                                                                                                        this.setState({
                                                                                                            answers
                                                                                                        })

                                                                                                    }}>{'Add diagnos'.translate(this.props.lang)}</button>

                                                                                                </>
                                                                                                :

                                                                                                null
                                                                                        }

                                                                                    </FormGroup>

                                                                                </div>
                                                                            )
                                                                    }
                                                                    )

                                                                }

                                                            </div>
                                                        )
                                                })
                                            }





                                        </Col>
                                        <Col lg="6">
                                            <HTML value={this.translateReferralContent(this.generateReferral())} onChange={() => { }}></HTML>

                                        </Col>
                                    </Row>
                                </div>


                            </Col>
                            :
                            null
                        }

                    </Row>

                </Container>



                {this.state.wikiModal ?
                    <Modal isOpen={this.state.wikiModal} centered toggle={() => this.setState({ wikiModal: !this.state.wikiModal })} wrapClassName="wiki-hint-modal">
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ wikiModal: !this.state.wikiModal })}>&times;</button>}>{this.state.wikiModal.name}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.wikiModal.content }}></div>
                            <img src={API_ENDPOINT + this.state.wikiModal.image} />
                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div>
        );
    }
}

export default CreateReferralModal;