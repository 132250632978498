import React, { Component } from 'react';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';


import ErrorModal from '../../../components/errorModal';
import { API_ENDPOINT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';

import Isvg from 'react-inlinesvg'
import PatientFilter from '../../../components/patientFilter';

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";

import PaysonSvg from '../../../assets/svg/payson.svg';
import SwishSvg from '../../../assets/svg/swish.svg';
import Card from '../../../assets/svg/card.svg';

import checkIcon from '../../../assets/svg/check-icon.svg';
import calendar from '../../../assets/svg/calendar.svg';


import BankIdModal from '../../../components/bankIdModal';

import backIcon from '../../../assets/svg/back-icon.svg'
import clockIcon from '../../../assets/svg/clock-icon.svg'




import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Payson from '../../../components/payson';
import Swish from '../../swish';


import moment from 'moment';

moment.locale('en')

import ModalFooter from 'reactstrap/lib/ModalFooter';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
function dateDifference(date1, date2) {
    // Parse the dates
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(d2 - d1);

    // Convert time difference from milliseconds to days
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
}
// function getMonday(d) {
//     d = new Date(d);
//     var day = d.getDay(),
//         diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
//     return new Date(d.setDate(diff));
// }

function getStartTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(0, 0, 0, 0)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}
function getEndTS(date) {
    let start = null;
    let checkDate = new Date(date);
    checkDate.setHours(23, 59, 59, 59)

    let timestamp = Math.floor(new Date(checkDate).getTime() / 1000)
    if (timestamp) {
        start = timestamp;
    }

    return start;
}

/**
* React Big Calendar for patient schedule
* @author   Aleksandar Dabic
*/
class RescheduleWebBookingCalendar extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            referrals: [],
            // view: 'week',
            view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'week',
            doctors: [],
            doctor: null,
            clinics: [],
            wayOfPaying: false,
            age: 25,
            patientData: {},
            referralType: 1,
            referralSections: { header: '', footer: '', body: '' },
            version: 0,
            sections: [],
            calendarMonth: [],
            monday: null,
            weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            // weekDaysMob: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            weekDaysMob: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            weekViewDate: new Date(),
            groupedDoctors: false,
            selectedDay: 0

        };
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)
    }




    get = () => {


        let professions = [];

        let clinic;
        if (this.props.referral && this.props.referral.recipientClinic) {
            clinic = this.props.referral.recipientClinic;
        } else if (this.props.selectedReferral && this.props.selectedReferral.clinic && this.props.selectedReferral.clinic._id) {
            clinic = this.props.selectedReferral.clinic._id;
        }

        if (this.props.availableProfessions && this.props.personalQuestionary && this.props.personalQuestionary.professionList) {
            for (let i = 0; i < this.props.availableProfessions.length; i++) {
                if (this.props.personalQuestionary.professionList.filter(item => item == this.props.availableProfessions[i].value).length) {
                    professions.push(this.props.availableProfessions[i])
                }
            }
        }
        let alias;
        if (this.props.referral && this.props.referral.groupAlias) {
            alias = this.props.referral.groupAlias;
        } else if (typeof window != 'undefined' && localStorage.groupAlias) {
            alias = localStorage.groupAlias;
        }
        fetch(API_ENDPOINT + '/data/clinic-groups/without-login/patient-data', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                alias: alias
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    clinics: result.clinics,
                    groupId: result.groupId,
                    agentId: result.agentId,
                    apiKey: result.apiKey,
                    bankAccount: result.bankAccount
                })


            }
        }).then(() => fetch(API_ENDPOINT + '/users/subaccounts/doctors/personal-questionary', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: null,
                entries: null,
                groupId: this.state.groupId ? this.state.groupId : null,
                clinic: this.state.clinics ? this.state.clinics?.[0]?._id : null,
                recipientClinic: clinic ? clinic : null,
                query: {},
                personalQuestionary: this.props.personalQuestionary?._id,
                availableProfessions: professions,
                uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    doctors: result.doctors,
                    firstDoctor: result.firstDoctor
                })

            }
        }).then(() => {
            this.fetchFreeEventsCalendar(() => { this.checkFirstFreeEvent() }, true)

        }))



    }
    checkFirstFreeEvent = () => {
        let firstFreeEvent = this.state.firstFreeEvent;

        if (firstFreeEvent) {
            this.setState({ monday: this.getMonday(new Date(firstFreeEvent * 1000)) })
        }

    }

    getMonday = (d) => {
        if (d) {
            d = new Date(d);
        } else {
            d = new Date();
        }


        d = new Date(d);

        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

        let monday = new Date(d.setDate(diff));
        monday = new Date(this.props.getStringDateTs(Math.floor(monday.getTime() / 1000), this.props.dateFormat))
        return monday;
    }

    checkSwitchView = (clinicId) => {

        if (clinicId) {
            fetch(API_ENDPOINT + '/clinic-groups/switch-view/check', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    clinicId
                })
            }).then(res => res.json()).then((result) => {
                let webbookingSwitchViews = result?.webbookingSwitchViews ? true : false;
                this.setState({ allowedSwitchViews: webbookingSwitchViews })

            })
        }

    }



    componentDidMount() {
        this.get();
        this.setState({ monday: this.getMonday() })

        if (this.props.referral?.recipientClinic) {
            this.checkSwitchView(this.props.referral.recipientClinic)
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupedDoctors !== this.state.groupedDoctors && this.state.groupedDoctors && this.state.selectedDay !== 0) {
            this.setState({ selectedDay: 0 })
        }

        if (this.state.referrals && this.state.referrals.length && !this.state.referralId && !this.state.doNotUpdate) {
            this.setState({
                referralId: this.state.referrals[0]._id,
                referralNumber: this.state.referrals[0].patient.referralNumber,
                doNotUpdate: true
            })
            this.props.refId(this.state.referrals[0]._id)

            if (this.state.referrals[0]._id) {
                for (let k = 0; k < this.state.referrals.length; k++) {
                    if (this.state.referrals[0]._id == this.state.referrals[k]._id) {
                        this.setState({
                            clinic: this.state.referrals[k].recipientClinic
                        }, () => this.get())
                    }
                }
            } else {
                this.setState({
                    doctors: []
                })
            }
        }

        if (this.state.doctors && this.state.doctors.length && !this.state.doctor) {
            if (!this.state.firstDoctor) {
                this.setState({
                    noAvailableDoctors: 'None of the doctors has free time for schedule in this clinic.',
                    doctor: this.state.doctors[0]._id,
                }, () => {
                    this.getWorkingTime()
                })
            } else {
                this.setState({
                    doctor: this.state.firstDoctor,
                }, () => {
                    this.getWorkingTime()
                })
            }



        }



        if (this.state.monday !== prevState.monday || this.state.monthView !== prevState.monthView || this.state.monthView && this.state.monthViewDate !== prevState.monthViewDate) {
            let firstWeek = this.state.monthView !== prevState.monthView && this.state.monthView && this.state.monthViewDate !== prevState.monthViewDate ? true : false;
            this.fetchFreeEventsCalendar(null, firstWeek)
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    getWorkingTime = () => {
        // {
        //     this.state.doctor ?
        //         this.state.doctors.filter(item => item._id.toString() == this.state.doctor).map(item => {
        //             if (item.workingTimeConfig) {
        //                 if (item.workingTimeConfig[this.state.groupId] && item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id]) {
        //                     this.setState({
        //                         min: new Date(item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id].min),
        //                         max: new Date(item.workingTimeConfig[this.state.groupId][this.state.clinics[0]._id].max)
        //                     })
        //                 }
        //             }
        //         })
        //         : this.setState({
        //             min: this.state.min,
        //             max: this.state.max
        //         })
        // }
        let obj = {

        };
        obj.min = new Date();
        obj.min.setHours(8, 30, 0)
        obj.max = new Date();
        obj.max.setHours(20, 30, 0)

        obj.min = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        obj.max = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

        if (this.state.listDoctor) {
            if (this.state.subAccounts && this.state.subAccounts.length && this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor).length) {
                let doctors = this.state.subAccounts.filter(item => item._id.toString() == this.state.listDoctor);
                for (let i = 0; i < doctors.length; i++) {
                    if (doctors[i].workingTimeConfig) {
                        if (doctors[i].workingTimeConfig[this.props.selectedGroup] && doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
                            obj.min = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min)
                            obj.max = new Date(doctors[i].workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max)
                        }
                    }
                }
            }
        }
        let scheduleListReferral = this.state.scheduleListReferral;
        let min = this.props.getStringDateTs(Math.floor(obj.min.getTime() / 1000), 'HH:mm');
        let max = this.props.getStringDateTs(Math.floor(obj.max.getTime() / 1000), 'HH:mm');
        if (scheduleListReferral?.startTime && scheduleListReferral?.endTime && min) {
            let minSeconds = Number(min?.split(':')?.[0]) * 60 + Number(min?.split(':')?.[1])
            let maxSeconds = Number(max?.split(':')?.[0]) * 60 + Number(max?.split(':')?.[1])
            let startTimeSeconds = Number(scheduleListReferral.startTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.startTime?.split(':')?.[1])
            let endTimeSeconds = Number(scheduleListReferral.endTime?.split(':')?.[0]) * 60 + Number(scheduleListReferral.endTime?.split(':')?.[1])
            if (minSeconds > startTimeSeconds) {
                scheduleListReferral.startTime = min;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (minSeconds > endTimeSeconds) {
                scheduleListReferral.endTime = this.props.getStringDateTs(Math.floor((obj.min.getTime() / 1000) + 60 * 5), 'HH:mm');
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < startTimeSeconds) {
                scheduleListReferral.startTime = this.props.getStringDateTs(Math.floor((obj.max.getTime() / 1000) - 60 * 5), 'HH:mm');;
                obj.scheduleListReferral = scheduleListReferral;
            }
            if (maxSeconds < endTimeSeconds) {
                scheduleListReferral.endTime = max;
                obj.scheduleListReferral = scheduleListReferral;
            }
        }
        this.setState(obj)

    }




    selectedEvent = (event, selectedDoctor = null) => {
        console.log('event 1', event)
        if (this.state.groupedDoctors) {
            if (event?.events?.length === 1) {
                event = event.events[0];
            } else if (event?.events?.length > 1) {
                if (selectedDoctor && event.events.filter(el => el.doctor === selectedDoctor).length) {
                    event = event.events.filter(el => el.doctor === selectedDoctor)[0];
                    console.log('event 2', event)
                } else {
                    this.setState({ chooseDoctor: event })
                    return;
                }
            }
        }

        let doctor = this.state.groupedDoctors ? event.doctor : this.state.doctor;

        var startDate = this.props.getStringDateTs(event.start, 'YYYY-MM-DD');
        let start = this.props.getStringDateTs(event.start, 'HH:mm')
        var end = this.props.getStringDateTs(event.end, 'HH:mm');



        let startTs = event.start;
        let endTs = event.end;

        let obj = {
            title: event.title,
            id: event.id,
            date: startDate,
            startTime: start,
            endTime: end,
            type: event.type,
            duration: (endTs - startTs) / 60,
            startTimeHours: event?.startTimeHours,
            endTimeHours: event?.endTimeHours,
            doctor: doctor
        }
        // console.log(obj)
        if (event.type == 'free') {
            let startTimeHours = obj?.startTimeHours ?? obj.startTime;
            let endTimeHours = obj?.endTimeHours ?? obj.endTime;
            fetch(API_ENDPOINT + '/referrals/check-selected-time', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    doctor: doctor,
                    startEventReservedDateOriginal: obj.date + ' ' + obj.startTime,
                    endEventReservedDateOriginal: obj.date + ' ' + obj.endTime,
                    // startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(obj.date + ' ' + obj.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    // endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(obj.date + ' ' + obj.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`)
                    startEventReservedDate: obj.date + ' ' + startTimeHours,
                    endEventReservedDate: obj.date + ' ' + endTimeHours,
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    }, () => {
                        this.fetchFreeEventsCalendar(() => { this.checkFirstFreeEvent() }, true)
                    })
                } else {
                    this.setState({
                        // scheduleReferral: obj,
                        selectEvent: obj
                    })
                }

            })

        }

    }




    /**
    * Change referral status
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    chageStatus = (info) => {

        if (this.state.referralId) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.referralId == this.state.referrals[i]._id) {
                    this.setState({
                        referral: this.state.referrals[i]
                    })
                }
            }
        }


        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient-failure/status/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                if (info) {
                    this.props.closeCalendarModal(false)
                }
            })
        })

    }





    reserveEvent = () => {
        if (this.props.changeSelectedTime) {
            let startTimeHours = this.state.selectEvent?.startTimeHours ?? this.state.selectEvent.startTime;
            let endTimeHours = this.state.selectEvent?.endTimeHours ?? this.state.selectEvent.endTime;
            fetch(API_ENDPOINT + '/referrals/check-selected-time', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    doctor: this.state.doctor,
                    startEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime,
                    endEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime,
                    // startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    // endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`)
                    startEventReservedDate: this.state.selectEvent.date + ' ' + startTimeHours,
                    endEventReservedDate: this.state.selectEvent.date + ' ' + endTimeHours,
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    }, () => {
                        this.fetchFreeEventsCalendar()
                    })
                } else {
                    this.setState(
                        {
                            showPlayer: true,
                        }, () => {
                            this.player.current.play();
                            fetch(API_ENDPOINT + '/referrals/patient-schedule/' + this.props.changeSelectedTime, {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({ startEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime, endEventReservedDateOriginal: this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime, startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(this.state.selectEvent.date + ' ' + this.state.selectEvent.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), doctor: this.state.doctor, patient: this.props.uData._id, referral: this.props.changeSelectedTime, scheduledBy: 'patient', rescheduleWebBooking: true })
                            }).then(res => res.json()).then((result) => {
                                if (result.error) {
                                    this.setState({ error: result.error.translate(this.props.lang) })
                                } else {
                                    let selectedTime = {
                                        date: this.state.selectEvent?.date,
                                        startTime: this.state.selectEvent?.startTime,
                                        endTime: this.state.selectEvent?.endTime
                                    }
                                    if (this.props.setSelectedTime) {
                                        this.props.setSelectedTime(selectedTime)
                                    }
                                    this.setState({
                                        scheduleReferral: null,
                                        referralId: null,
                                        cardModal: null,
                                        calendarModal: null,
                                        selectEvent: null,
                                        _loader: false,


                                    }, () => this.props.createdReferral(this.props.changeSelectedTime))
                                    this.setState({
                                        showPlayer: false,
                                        showPlayer2: true,
                                    },
                                        () => {
                                            setTimeout(() => {
                                                this.setState({ showPlayer2: false });
                                            }, 1000);
                                        })

                                }

                            })
                        })
                }

            })

        }

    }

    fetchBankId = () => {
        fetch(API_ENDPOINT + '/users/bankid/v2/start', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ group: this.state.groupData._id })
        }).then(res => res.json()).then((result) => {
            if (result.redirect_url) {
                this.setState({

                    bankIdModal: result.redirect_url

                }, () => {
                    setTimeout(() => {
                        if (!this.props.uData) {
                            this.fetchBankId()
                        }
                    }, 1000 * 60 * 4);
                })
            }
        })

    }



    /**
    * Check if selected doctor have any free events
    * @author   Stefan Pantic
    */
    doctorWithoutFreeEvents = () => {

        let noFreeEvents = true
        // for (let i = 0; i < this.state.items.length; i++) {
        //     if (this.state.items[i].type == 'free') {
        //         noFreeEvents = false
        //     }
        // }


        let items = []
        let ref = this.state.referrals.filter((item) => item._id == this.state.referralId);

        let duration = 0;
        let clinicId;

        if (this.props.personalQuestionary) {
            duration = Number(this.props.personalQuestionary.duration)
        }

        if (this.props.personalQuestionary && this.props.personalQuestionary.clinic) {
            clinicId = this.props.personalQuestionary.clinic._id
        }

        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i];


            if (item.type == 'free' && duration && item.clinic.toString() == clinicId.toString()) {
                let segments = Math.floor(((Number(item.endTime) - Number(item.startTime)) / 60) / duration)

                for (let j = 0; j < segments; j++) {
                    let time = item.startTime + j * duration * 60;
                    let endTime = item.startTime + j * duration * 60 + duration * 60;
                    if (time > Math.floor(new Date().getTime() / 1000)) {
                        items.push({
                            id: item._id,
                            title: item.title.translate(this.props.lang),
                            tooltip: item.title.translate(this.props.lang),
                            start: new Date(time * 1000),
                            end: new Date(endTime * 1000),
                            type: item.type,
                            tooltipDisabled: false
                        })
                    }


                }
            }
        }
        let firstDayTs;
        for (let i = 0; i < items.length; i++) {
            if (!firstDayTs) {
                firstDayTs = Math.floor(new Date(items[i].start).getTime() / 1000);
            } else if (firstDayTs > Math.floor(new Date(items[i].start).getTime() / 1000)) {
                firstDayTs = Math.floor(new Date(items[i].start).getTime() / 1000);
            }

        }
        let monday = this.state.monday;
        // if (firstDayTs && moment.unix(Math.floor(new Date(monday).getTime() / 1000)).format('MM-DD-YYYY') != moment.unix(firstDayTs).format('MM-DD-YYYY')) {
        if (firstDayTs && this.props.getStringDateTs(Math.floor(new Date(monday).getTime() / 1000), 'MM-DD-YYYY') != this.props.getStringDateTs(firstDayTs, 'MM-DD-YYYY')) {
            let firstDay = new Date(firstDayTs * 1000)
            this.setState({ monday: this.getMonday(new Date(firstDay)) })
        }



        if (items.length >= 1) {
            noFreeEvents = false
            let min = items[0];
            for (let i = 1; i < items.length; i++) {
                if (Math.floor(new Date(items[i].start).getTime() / 1000) < Math.floor(new Date(min.start).getTime() / 1000)) {
                    min = items[i];
                }
            }
            this.setState({ day: new Date(Math.floor(new Date(min.start * 1000).getTime() / 1000)), noAvailableDoctors: false })
        } else {
            this.setState({ day: new Date() })
        }

        if (noFreeEvents/* && this.state.doctorChanged*/) {
            this.setState({
                error: "No available time slots.",
                // doctorChanged: false
            })
        }
    }
    goToLandingPage = () => {
        let url = '/';
        let alias;
        if (this.props.referral && this.props.referral.groupAlias) {
            alias = this.props.referral.groupAlias;
        } else if (typeof window != 'undefined' && localStorage.groupAlias) {
            alias = localStorage.groupAlias;
        }

        if (alias) {
            url += alias
        }
        this.props[0].history.push(url)
    }



    fetchFreeEventsByDoctors = (callback, firstWeek = false) => {
        let obj = {
            duration: this.props.personalQuestionary?.duration ? Number(this.props.personalQuestionary.duration) : 0,
            clinic: this.props.referral?.recipientClinic,
            doctor: this.state.doctor,
            date: this.state.monday,
            dateTs: this.state.firstFreeEvent ? this.state.firstFreeEvent : null,
            dateOffset: this.state.monday ? new Date(this.state.monday).getTimezoneOffset() : 0,
            month: this.state.monthView ? true : false,
            monthViewDate: this.state.monthViewDate && this.state.monthView ? this.state.monthViewDate : null,
            weekViewDate: this.state.weekViewDate ? this.state.weekViewDate : null,
            firstWeek: firstWeek,
            personalQuestionary: this.props.personalQuestionary?._id
        }

        if (obj.clinic && obj.duration) {
            fetch(API_ENDPOINT + '/calendar/fetch/free-events/by-doctors', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result && result.days) {

                    this.setState({
                        ...result,
                        // firstFreeEvent: result.firstFreeEvent ? result.firstFreeEvent : null,
                        // loadingDoctor: false
                    }, () => {
                        if (callback) {
                            callback()
                        }
                    })


                }
            })
        }
    }

    fetchFreeEventsCalendar = (callback, firstWeek = false) => {
        if (this.state.groupedDoctors /*&& !this.state.monthView*/) {
            this.fetchFreeEventsByDoctors(callback, firstWeek)
            return;
        }
        if (this.state.groupedDoctors) {
            this.setState({ groupedDoctors: false })
        }
        let obj = {
            duration: this.props.personalQuestionary?.duration ? Number(this.props.personalQuestionary.duration) : 0,
            clinic: this.props.referral?.recipientClinic,
            doctor: this.state.doctor,
            date: this.state.monday,
            dateTs: this.state.firstFreeEvent ? this.state.firstFreeEvent : null,
            dateOffset: this.state.monday ? new Date(this.state.monday).getTimezoneOffset() : 0,
            month: this.state.monthView ? true : false,
            monthViewDate: this.state.monthViewDate && this.state.monthView ? this.state.monthViewDate : null,
            weekViewDate: this.state.weekViewDate ? this.state.weekViewDate : null,
            firstWeek: firstWeek,
            personalQuestionary: this.props.personalQuestionary?._id,
        }
        // personalQuestionary: this.props.personalQuestionary._id,

        if (obj.doctor && obj.clinic && obj.duration) {
            fetch(API_ENDPOINT + '/calendar/fetch/free-events', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result && result.days) {

                    this.setState({
                        ...result,
                        firstFreeEvent: result.firstFreeEvent ? result.firstFreeEvent : null,
                        loadingDoctor: false
                    }, () => {
                        if (callback) {
                            callback()
                        }
                    })


                }
            })
        }

    }
    chooseDoctor = (event, value) => {
        if (value?._id) {
            this.setState({ chooseDoctor: null }, () => {
                this.selectedEvent(event, value._id)
            })
        }
    }

    render() {
        const days = this.state.days ? this.state.days : [];


        let selectedDoctor = null;
        for (let i = 0; i < this.state.doctors.length; i++) {
            if (this.state.doctors[i] && this.state.doctors[i]._id == this.state.doctor) {
                selectedDoctor = this.state.doctors[i];
                break;
            }
        }


        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';


        let doctorId = this.state.doctor;
        if (this.state.selectEvent?.doctor) {
            if (this.state.selectEvent.doctor !== this.state.doctor) {
                doctorId = this.state.selectEvent.doctor;
            }
        }
        const smallView = typeof window != 'undefined' && window.innerWidth < 1200;

        const allowedSwitchViews = this.state.allowedSwitchViews;

        const dayView = allowedSwitchViews && this.state.groupedDoctors && smallView;
        let selectedDay;
        if (this.state.monday && this.state.groupedDoctors && smallView) {
            selectedDay = new Date(this.state.monday);
            selectedDay.setDate(this.state.monday.getDate() + this.state.selectedDay);
        }
        return (

            <div>
                <Container fluid>
                    <Row>
                        <Col lg='12'>
                            <div className='panel mob-div-padding-none'>

                                <div className="webbooking-calendar-wrap">
                                    <div className="mob-flex-col" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        {
                                            !this.state.groupedDoctors ?
                                                this.state.doctors && this.state.doctors.length > 1 ?
                                                    <div className="mob-wid-100 patient-calendar-select-wrap"> {'Doctor:'.translate(this.props.lang)}
                                                        <Input type='select' className="patient-calendar-select" style={{ width: '100%' }}
                                                            onChange={e => {

                                                                this.setState({ doctor: e.target.value == -1 ? null : e.target.value, loadingDoctor: true }, () => {
                                                                    this.get(); this.getWorkingTime();

                                                                })
                                                            }}
                                                            value={this.state.doctor}
                                                        >
                                                            {this.state.doctors.map(item => {
                                                                return <option value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                            })}

                                                        </Input>
                                                    </div>
                                                    :
                                                    <div className="select-one-value patient-calendar-select-wrap">
                                                        {'Doctor:'.translate(this.props.lang)}
                                                        <Input type='select' className="patient-calendar-select" style={{ width: '100%' }}
                                                            value={this.state.doctor}
                                                            disabled
                                                        >

                                                            {this.state.doctors.map(item => {
                                                                return <option value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                            })}

                                                        </Input>
                                                    </div>
                                                :
                                                null
                                        }
                                        {
                                            this.props.selectedReferral ?
                                                <div className='web-booking-calendar-info' style={this.state.groupedDoctors ? { paddingLeft: 0 } : {}}>
                                                    <h6>{'Visit reason'.translate(this.props.lang)}:</h6>
                                                    <div>
                                                        <span> {this.props.lang == 'en' ? this.props.selectedReferral.name.replace(' (onlinebesök)', ' (online visit)') : this.props.selectedReferral.name.replace(' (online visit)', ' (onlinebesök)')}</span>
                                                    </div>
                                                    <div>
                                                        <h6>{'Duration'.translate(this.props.lang)}: <span>{this.props.selectedReferral.duration} min</span></h6>
                                                        <h6>
                                                            {
                                                                !this.props.referral.referralIsPaid ?
                                                                    <>{'Price'.translate(this.props.lang)}: <span>{this.props.selectedReferral.price} {currency}</span></>
                                                                    :
                                                                    null
                                                            }
                                                        </h6>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="web-booking-calendar-header-wrap">
                                        <div className='web-booking-calendar-back-wrap'>
                                            <Button color='primary' onClick={() => this.props.backFromCalendar()}>
                                                <Isvg src={backIcon} />
                                            </Button>
                                        </div>

                                        <div className='options-btns-wrap'>
                                            {
                                                allowedSwitchViews && !smallView ?
                                                    <div className='switch-view-button-wrap'>
                                                        <Button color='primary' onClick={() => {
                                                            this.setState({ groupedDoctors: !this.state.groupedDoctors }, () => {
                                                                this.fetchFreeEventsCalendar(() => { this.checkFirstFreeEvent() }, true)
                                                            })
                                                        }}>{'Change view'.translate(this.props.lang)}</Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                !this.state.monthView ?
                                                    <Button color='primary' className='button-green' onClick={() => {
                                                        this.setState({ monthView: true, monthViewDate: new Date() })
                                                    }}>
                                                        {'Month view'.translate(this.props.lang)}
                                                    </Button>
                                                    :
                                                    <Button color='primary' className='button-green' onClick={() => { this.setState({ monthView: false, monday: this.getMonday(new Date()) }) }}>
                                                        {dayView ? 'Day view'.translate(this.props.lang) : 'Week view'.translate(this.props.lang)}
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                    {
                                        allowedSwitchViews && smallView ?
                                            <div className='switch-view-button-wrap'>
                                                <Button color='primary' onClick={() => {
                                                    this.setState({ groupedDoctors: !this.state.groupedDoctors }, () => {
                                                        this.fetchFreeEventsCalendar(() => { this.checkFirstFreeEvent() }, true)
                                                    })
                                                }}>{'Change view'.translate(this.props.lang)}</Button>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.loadingDoctor ?
                                            <div className="loader-wrap" >
                                                <Player
                                                    autoplay={true}
                                                    loop={true}
                                                    src="/lf30_editor_l5cxzdyf.json"
                                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                                ></Player>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        !this.state.monthView ?
                                            <div
                                                ref={node => {
                                                    if (!this.state.calendarRef) {
                                                        this.setState({
                                                            calendarRef: node
                                                        })
                                                    }
                                                }}
                                                className='web-booking-calendar-custom-wrap'
                                            >
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        if (smallView && this.state.groupedDoctors && this.state.selectedDay > 0) {
                                                            this.setState({ selectedDay: this.state.selectedDay - 1 })
                                                        } else {
                                                            let prevWeekMonday = new Date(this.state.monday);
                                                            prevWeekMonday.setDate(this.state.monday.getDate() - 7);
                                                            this.setState({ monday: prevWeekMonday, selectedDay: 6 })
                                                        }
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {
                                                            smallView && this.state.groupedDoctors && selectedDay ?
                                                                <>{this.props.getStringDateTs(Math.floor(new Date(selectedDay).getTime() / 1000), 'DD', true)} {(this.props.getStringDateTs(Math.floor(new Date(selectedDay).getTime() / 1000), 'MMM', true)).translate(this.props.lang)}</>
                                                                :
                                                                <>{this.props.getStringDateTs(days && days[0]?.timestamp, 'DD', true)} {(this.props.getStringDateTs(days && days[0]?.timestamp, 'MMM', true)).translate(this.props.lang)} - {this.props.getStringDateTs(days && days[6]?.timestamp, 'DD', true)} {(this.props.getStringDateTs(days && days[6]?.timestamp, 'MMM', true)).translate(this.props.lang)}</>
                                                        }

                                                    </p>
                                                    <div onClick={() => {
                                                        if (smallView && this.state.groupedDoctors && this.state.selectedDay < 6) {
                                                            this.setState({ selectedDay: this.state.selectedDay + 1 })
                                                        } else {
                                                            let prevWeekMonday = new Date(this.state.monday);
                                                            prevWeekMonday.setDate(this.state.monday.getDate() + 7);
                                                            this.setState({ monday: prevWeekMonday, selectedDay: 0 })
                                                        }
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className={this.state.groupedDoctors && smallView ? 'week-calendar-wrap day-calendar-wrap' : 'week-calendar-wrap'}>
                                                    {/* <div className='days-wrap'>
                                                        {
                                                            days?.map((date, didx) => {
                                                                
                                                                return (
                                                                    <div key={didx} style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7, alignItems: 'center' }}>
                                                                        <p style={{ textAlign: 'center', color: date?.items?.length ? '#000' : '#cacaca' }}>
                                                                            {this.props.getStringDateTs(date?.timestamp, 'DD', true)}
                                                                        </p>
                                                                        {
                                                                            // new Date().getDate() == new Date(date.date).getDate() && new Date().getMonth() == new Date(date.date).getMonth() ?
                                                                            date.today ?
                                                                                <div className='date-now-marker-wrap'>
                                                                                    <div className='date-now-marker'></div>
                                                                                </div>
                                                                                :

                                                                                null
                                                                        }
                                                                    </div>
                                                                )

                                                            })
                                                        }

                                                    </div> */}
                                                    <div className='days-wrap'>
                                                        {
                                                            days?.map((date, didx) => {
                                                                if (!this.state.groupedDoctors || !smallView)

                                                                    return (
                                                                        <div key={didx} style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7, alignItems: 'center' }}>
                                                                            <p style={{ textAlign: 'center', color: date?.items?.length ? '#000' : '#cacaca' }}>
                                                                                {
                                                                                    typeof window != 'undefined' && window.innerWidth > 768 ?
                                                                                        this.state.weekDays && this.state.weekDays[didx] && this.state.weekDays[didx].translate(this.props.lang)
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    typeof window != 'undefined' && window.innerWidth <= 768 ?
                                                                                        this.state.weekDaysMob && this.state.weekDaysMob[didx] && this.state.weekDaysMob[didx].translate(this.props.lang)
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </p>
                                                                            <p style={{ textAlign: 'center', color: date?.items?.length ? '#000' : '#cacaca' }}>
                                                                                {this.props.getStringDateTs(date?.timestamp, 'DD', true)}
                                                                            </p>
                                                                            {
                                                                                // new Date().getDate() == new Date(date.date).getDate() && new Date().getMonth() == new Date(date.date).getMonth() ?
                                                                                date.today ?
                                                                                    <div className='date-now-marker-wrap'>
                                                                                        <div className='date-now-marker'></div>
                                                                                    </div>
                                                                                    :

                                                                                    null
                                                                            }
                                                                        </div>
                                                                    )

                                                            })
                                                        }

                                                    </div>
                                                    <div>
                                                        <div className='items-wrap'>

                                                            {
                                                                days.map((date, didx) => {
                                                                    let condition = null;
                                                                    if (smallView && this.state.groupedDoctors) {
                                                                        let stringSelectedDate = moment.unix(Math.floor(new Date(selectedDay).getTime() / 1000)).format('MM-DD-YYYY');
                                                                        let stringDate = moment.unix(date.timestamp).format('MM-DD-YYYY');
                                                                        condition = stringDate === stringSelectedDate ? true : false;
                                                                    }
                                                                    if (!this.state.groupedDoctors || !smallView || condition)
                                                                        return (
                                                                            <div key={didx} className='one-day-wrap' style={{ width: ((this.state.calendarRef && this.state.calendarRef.getBoundingClientRect() ? this.state.calendarRef.getBoundingClientRect().width : 360) - 30) / 7 }}>
                                                                                {
                                                                                    date.items.map((item, idx) => {
                                                                                        const showDoctorList = item?.doctors?.length && this.state.groupedDoctors ? true : false;

                                                                                        return (
                                                                                            <div onClick={() => {
                                                                                                this.selectedEvent(item)
                                                                                            }} className={showDoctorList ? 'item-wrap-with-doctor-list' : ''}>
                                                                                                <div style={{ marginBottom: 5 }}>
                                                                                                    <div className='item-wrap'>
                                                                                                        <p>
                                                                                                            {this.props.getStringDateTs(item.start, 'HH:mm')} {showDoctorList ? ` - ${this.props.getStringDateTs(item.end, 'HH:mm')}` : null}
                                                                                                        </p>
                                                                                                        {
                                                                                                            showDoctorList ?
                                                                                                                <div className='doctor-names-wrap'>
                                                                                                                    {
                                                                                                                        item?.doctors.map((doctor, dIdx) => {
                                                                                                                            return (
                                                                                                                                <div className='doctor-name-wrap'>
                                                                                                                                    {doctor?.name}
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                                :
                                                                                                                null

                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>

                                                                        )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            :
                                            <div className='web-booking-calendar-custom-wrap'>
                                                <div className='navigation-wrap'>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let prevMonth = monthViewDate.getMonth();
                                                        let prevYear = monthViewDate.getFullYear()
                                                        prevMonth--;
                                                        if (prevMonth == -1) {
                                                            prevMonth = 11;
                                                            prevYear--;
                                                        }
                                                        monthViewDate.setMonth(prevMonth)
                                                        monthViewDate.setFullYear(prevYear)

                                                        this.setState({ monthViewDate })
                                                    }}
                                                        className='next-prev-btn'
                                                    >
                                                        <p >
                                                            {'Previous'.translate(this.props.lang)}
                                                        </p>
                                                    </div>
                                                    <p className='date-range'>
                                                        {this.state.monthViewDate ? ((this.props.getStringDateTs(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000), 'MMMM').translate(this.props.lang)) + ' ' + this.props.getStringDateTs(Math.floor(new Date(this.state.monthViewDate).getTime() / 1000), 'YYYY')) : ''}

                                                    </p>
                                                    <div onClick={() => {
                                                        let monthViewDate = new Date(this.state.monthViewDate);
                                                        monthViewDate.setDate(1);
                                                        let nextMonth = monthViewDate.getMonth();
                                                        let nextYear = monthViewDate.getFullYear()
                                                        nextMonth++;
                                                        if (nextMonth == 12) {
                                                            nextMonth = 0;
                                                            nextYear++;
                                                        }
                                                        monthViewDate.setMonth(nextMonth)
                                                        monthViewDate.setFullYear(nextYear)

                                                        this.setState({ monthViewDate })
                                                    }} className='next-prev-btn'>
                                                        <p >
                                                            {'Next'.translate(this.props.lang)}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className='web-booking-calendar-month-wrap'>
                                                    {
                                                        typeof window != 'undefined' && window.innerWidth > 768 ?
                                                            this.state.weekDays && this.state.weekDays.map((item, idx) => {
                                                                return (
                                                                    <div className='web-booking-calendar-month-week-days'>
                                                                        {item.translate(this.props.lang)}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                    {
                                                        typeof window != 'undefined' && window.innerWidth <= 768 ?
                                                            this.state.weekDaysMob && this.state.weekDaysMob.map((item, idx) => {
                                                                return (
                                                                    <div className='web-booking-calendar-month-week-days'>
                                                                        {item.translate(this.props.lang)}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this.state.calendarMonth && this.state.calendarMonth.map((item, idx) => {
                                                            return (
                                                                <div className={item.notThisMonth && !item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month' : item.notThisMonth && item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box-not-this-month-have-free' : item.haveFreeTime ? 'web-booking-calendar-month-item free-time-box' : 'web-booking-calendar-month-item'}
                                                                    onClick={() => {
                                                                        if (item.haveFreeTime) {
                                                                            this.setState({ monthView: false, monday: this.getMonday(new Date(item.date)) }, () => {
                                                                                if (this.state.groupedDoctors && smallView) {
                                                                                    let newValSelectedDay = dateDifference(new Date(item.date), this.getMonday(new Date(item.date))) - 1 ?? 0;
                                                                                    this.setState({ selectedDay: newValSelectedDay })
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    <h6 className={item.today ? 'today-date' : ''}>{item.day}</h6>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>




                                            </div>
                                    }




                                </div>

                            </div>

                        </Col>


                    </Row>
                </Container>
                {
                    this.state.chooseDoctor ?
                        <Modal isOpen={this.state.chooseDoctor} toggle={() => this.setState({ chooseDoctor: !this.state.chooseDoctor })} centered >
                            <ModalHeader style={{ margin: 'auto' }}>
                                {'Choose doctor'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='choose-doctor-wrap'>
                                    {
                                        this.state.chooseDoctor?.doctors?.map((item, idx) => {
                                            return (
                                                <div className='choose-doctor-item' onClick={() => {
                                                    this.chooseDoctor(this.state.chooseDoctor, item)
                                                }}>
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ chooseDoctor: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.selectEvent ?
                        <Modal isOpen={this.state.selectEvent} toggle={() => this.setState({ selectEvent: !this.state.selectEvent })} centered >
                            <ModalHeader style={{ margin: 'auto' }}>
                                {'Confirm new appointment'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='schedule-web-booking-info' >

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div className="date-time">
                                            <Isvg src={calendar} />
                                            <p>
                                                {/* {this.state.selectEvent.date ? moment.unix(new Date(this.state.selectEvent.date).getTime() / 1000).format(this.props.dateFormat) : null}{this.props.lang == 'se' ? ' kl. ' : ' '}{this.state.selectEvent.startTime} */}
                                                {this.state.selectEvent.date ? this.props.getStringDateTs(Math.floor(new Date(this.state.selectEvent.date).getTime() / 1000), this.props.dateFormat) : null}{this.props.lang == 'se' ? ' kl. ' : ' '}{this.state.selectEvent.startTime}
                                            </p>
                                        </div>
                                        <div className='duration-wrap'>
                                            <Isvg src={clockIcon} />
                                            <p>{this.state.selectEvent.duration} min</p>
                                        </div>
                                    </div>
                                    <div className="doctor-name">
                                        {
                                            doctorId && this.state.doctors && this.state.doctors.filter(doc => doc._id == doctorId).length ?
                                                this.state.doctors.filter(doc => doc._id == doctorId)[0].userData ?
                                                    <>
                                                        {this.state.doctors.filter(doc => doc._id == doctorId)[0].userData.name}
                                                        <br />
                                                        {this.state.doctors.filter(doc => doc._id == doctorId)[0].userData.title}
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="questionary-name">
                                        <Isvg src={checkIcon} />
                                        {this.props.personalQuestionary && this.props.personalQuestionary.name}
                                    </div>
                                    {
                                        !this.props.referral.referralIsPaid ?
                                            <>
                                                <div className="price-wrap">
                                                    <span>{'Total'.translate(this.props.lang)}:</span>
                                                    <span>{this.props.personalQuestionary && this.props.personalQuestionary.price} {currency}</span>
                                                </div>
                                                {
                                                    this.props.displayPaymentInfoText ?
                                                        <div className="info-message">
                                                            {'For visits with a fee the following applies: The cost can vary depending on your age or if you have free-card. Confirm the booking and then choose payment method.'.translate(this.props.lang)}
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </>
                                            :
                                            null
                                    }




                                </div>

                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                {/* <Button color="primary" style={{ margin: 10 }} disabled={this.state._loader} onClick={() => this.reserveEvent()}> */}
                                <Button

                                    color='primary' onClick={() => {
                                        let setObj = {}
                                        if (this.state.selectEvent?.doctor && this.state.selectEvent.doctor !== this.state.doctor) {
                                            setObj.doctor = this.state.selectEvent.doctor;
                                        }
                                        this.setState(setObj, () => {
                                            this.reserveEvent()
                                        })
                                    }}>
                                    {
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Confirmed new booking'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            'Confirm new booking'.translate(this.props.lang)
                                        )
                                    }
                                </Button>
                                {/* {'Confirm booking'.translate(this.props.lang)}</Button> */}
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ selectEvent: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }




                {
                    this.state.bankIdModal ?
                        <BankIdModal
                            {...this.props}
                            checkLastLogin={true}
                            isOpen={this.state.bankIdModal}
                            toggle={() => {

                                if (this.props.uData) {
                                    localStorage.setItem('groupAlias', this.props[0].match.params.alias);
                                    this.reserveEvent()
                                }
                                this.setState({ bankIdModal: null })

                            }}
                            redirect_url={this.state.bankIdModal}></BankIdModal>
                        :

                        null
                }



                {
                    this.state.cardModal && this.state.age < 85 && this.props.openPayWithCard ?
                        <Modal isOpen={this.state.cardModal} centered>
                            <ModalHeader style={{ margin: 'auto' }}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>
                            <ModalBody className="delete-modal">
                                <Isvg src={Card} />
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <div className="payment-container">
                                    {this.state.agentId && this.state.apiKey ?
                                        <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                        : null}
                                    {this.state.bankAccount ?
                                        <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={SwishSvg} /></Button>
                                        : null}
                                </div>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.cancelCostModal ?
                        <Modal isOpen={this.state.cancelCostModal} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{`If you cancel, your case will be deleted. Are you sure you want to continue?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.chageStatus(true))}>{'Ok'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.chageStatus(false))}>{'Cancel'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {
                    this.state.paysonModal ?

                        <Payson
                            lang={this.props.lang}
                            openModal={(item) => this.setState({
                                paysonModal: item
                            }, () => {
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                                this.setState({
                                    successModal: true,
                                })
                            }

                            )}
                            data={this.state.paysonModal}
                            doctor={this.state.doctor}
                            patient={this.props.uData._id}
                            referral={this.state.referralId}
                            price={this.props.price}
                        />

                        : null
                }
                {
                    this.state.swishModal ?

                        <Swish
                            getStringDateInverseTs={this.props.getStringDateInverseTs}
                            clearStatusBefore={() => this.props.clearStatusBefore()}
                            verifyUser={() => this.props.verifyUser()}
                            lang={this.props.lang}
                            history={this.props[0]}
                            doctor={this.state.doctor}
                            data={this.state.swishModal}
                            patient={this.props.uData._id}
                            open={this.state.swishModal}
                            phone={this.props.uData.phone}
                            price={this.props.price}
                            referralNumber={this.state.referralNumber}
                            referral={this.state.referralId}
                            openModal={(item) => this.setState({
                                swishModal: item
                            }, () => {
                                // this.props[0].history.push('/my-data/personal-referrals/referrals')
                                this.setState({
                                    successModal: true,
                                })
                            }
                            )}
                        />


                        : null
                }




                {
                    this.state.wrongDate ?
                        <Modal isOpen={this.state.wrongDate} toggle={() => this.setState({ wrongDate: !this.state.wrongDate })} centered>
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='button-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongDate: !this.state.wrongDate, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }
                {
                    this.state.wrongInput ?
                        <Modal isOpen={this.state.wrongInput} toggle={() => this.setState({ wrongInput: !this.state.wrongInput })} centered>
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p>{'Wrong input'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongInput: !this.state.wrongInput, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }
                {

                    this.state.error && this.state.error != "No available time slots." || (this.state.error == "No available time slots." && !this.state.noAvailableDoctors) ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.errorReserve ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorReserve}
                            toggle={() => this.setState({ errorReserve: null, selectEvent: null }, () => {
                                this.get()
                            })}
                            closeButtonText={'Cancel'.translate(this.props.lang)}
                        >
                            {this.state.errorReserve}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.noAvailableDoctors ?


                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.noAvailableDoctors}
                            toggle={() => this.goToLandingPage()}
                            closeButtonText={'Go back'.translate(this.props.lang)}

                        >
                            {this.state.noAvailableDoctors.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.successModal ?
                        <Modal isOpen={this.state.successModal} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                {/* <Isvg src={info} /> */}
                                <p className='message'>{'You have successfully reserved an examination. Please fill out the form.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right', minWidth: 100 }} onClick={() => {
                                    this.setState({ successModal: !this.state.successModal })
                                    this.props.closeCalendarModal(false)
                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }


            </div >
        );
    }
}

export default RescheduleWebBookingCalendar;

