
import React, { Component } from 'react';
import { formatCSS } from '../cssHelper';

import mapPng from '../../assets/images/swedenMap.PNG';

import { API_ENDPOINT } from '../../constants';

import searchIcon from "../../assets/svg/search.svg";


import Isvg from "react-inlinesvg";

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* Component for html field
* @author   Aleksandar Dabic
*/
class MainInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clinicGroups: []
        };
    }

    componentDidMount() {
        this.getGroupsData()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.mainData?.clinicGroups !== prevProps.mainData?.clinicGroups) {
            this.getGroupsData()
        }
    }

    getGroupsData = () => {
        let groupsIds = this.props.mainData?.clinicGroups;
        if (groupsIds) {

            fetch(API_ENDPOINT + '/data/clinic-groups/data/ids', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ groupsIds })
            }).then(parseJSON).then(({ result, status }) => {
                if (result?.length) {
                    this.setState({
                        clinicGroups: result
                    })
                }


            })
        } else if (this.state.clinicGroups?.length) {
            this.setState({ clinicGroups: [] })
        }
    }

    render() {
        const buttonsMainLayout = this.state.clinicGroups?.length < 4 ? true : false;
        let logo;
        let name;
        let loginTxt = 'Login text'.translate(this.props.lang);
        if (this.props.mainData) {
            if (this.props.mainData.logo) {
                logo = API_ENDPOINT + this.props.mainData.logo;
            }
            if (this.props.mainData.name) {
                name = this.props.mainData.name;
            }
            if (this.props.mainData.loginTxt) {
                loginTxt = this.props.mainData.loginTxt;
            }
        }

        let groupNameStyle = {};
        let searchLabelStyle = {};
        let showAllLinStyle = {};
        let loginTextStyle = { whiteSpace: 'pre-line' };
        let loginButtonStyle = {}
        let clinicButtonStyle = {}
        let clinicButtonActiveStyle = {};

        if (this.props.style) {
            if (this.props.style.groupNameColor) {
                groupNameStyle.color = this.props.style.groupNameColor;
            }
            if (this.props.style.groupNameFontWeight) {
                groupNameStyle.fontWeight = this.props.style.groupNameFontWeight;
            }
            if (this.props.style.groupNameFontSize) {
                groupNameStyle.fontSize = this.props.style.groupNameFontSize;
            }
            if (this.props.style.customGroupNameEn) {
                groupNameStyle.customGroupNameEn = this.props.style.customGroupNameEn;
            }
            if (this.props.style.customGroupNameSv) {
                groupNameStyle.customGroupNameSv = this.props.style.customGroupNameSv;
            }
            if (this.props.style.visibilityGroupName) {
                groupNameStyle.visibilityGroupName = this.props.style.visibilityGroupName;
            }

            if (this.props.style.searchLabelColor) {
                searchLabelStyle.color = this.props.style.searchLabelColor;
            }
            if (this.props.style.searchLabelFontWeight) {
                searchLabelStyle.fontWeight = this.props.style.searchLabelFontWeight;
            }
            if (this.props.style.searchLabelFontSize) {
                searchLabelStyle.fontSize = this.props.style.searchLabelFontSize;
            }


            if (this.props.style.showAllLinkColor) {
                showAllLinStyle.color = this.props.style.showAllLinkColor;
            }
            if (this.props.style.showAllLinkFontWeight) {
                showAllLinStyle.fontWeight = this.props.style.showAllLinkFontWeight;
            }
            if (this.props.style.showAllLinkFontSize) {
                showAllLinStyle.fontSize = this.props.style.showAllLinkFontSize;
            }

            if (this.props.style.loginTextColor) {
                loginTextStyle.color = this.props.style.loginTextColor;
            }
            if (this.props.style.loginTextFontWeight) {
                loginTextStyle.fontWeight = this.props.style.loginTextFontWeight;
            }
            if (this.props.style.loginTextFontSize) {
                loginTextStyle.fontSize = this.props.style.loginTextFontSize;
            }


            if (this.props.style.loginButtonBackgroundColor) {
                loginButtonStyle.backgroundColor = this.props.style.loginButtonBackgroundColor;
            }
            if (this.props.style.loginButtonColor) {
                loginButtonStyle.color = this.props.style.loginButtonColor;
            }
            if (this.state.activeElement === 'login-btn' && this.props.activeStyle) {
                if (this.props.activeStyle.loginButtonBackgroundColor) {
                    loginButtonStyle.backgroundColor = this.props.activeStyle.loginButtonBackgroundColor;
                }
                if (this.props.activeStyle.loginButtonColor) {
                    loginButtonStyle.color = this.props.activeStyle.loginButtonColor;
                }
            }

            if (this.props.style.clinicButtonBackgroundColor) {
                clinicButtonStyle.backgroundColor = this.props.style.clinicButtonBackgroundColor;
            }
            if (this.props.style.clinicButtonColor) {
                clinicButtonStyle.color = this.props.style.clinicButtonColor;
            }
            if (this.props.style.clinicButtonFontSize) {
                clinicButtonStyle.fontSize = this.props.style.clinicButtonFontSize;
            }
            if (this.props.style.clinicButtonFontWeight) {
                clinicButtonStyle.fontWeight = this.props.style.clinicButtonFontWeight;
            }
            clinicButtonActiveStyle = { ...clinicButtonStyle }
            if (this.props.activeStyle) {
                if (this.props.activeStyle.clinicButtonBackgroundColor) {
                    clinicButtonActiveStyle.backgroundColor = this.props.activeStyle.clinicButtonBackgroundColor;
                }
                if (this.props.activeStyle.clinicButtonColor) {
                    clinicButtonActiveStyle.color = this.props.activeStyle.clinicButtonColor;
                }
                if (this.props.activeStyle.clinicButtonFontSize) {
                    clinicButtonActiveStyle.fontSize = this.props.activeStyle.clinicButtonFontSize;
                }
                if (this.props.activeStyle.clinicButtonFontWeight) {
                    clinicButtonActiveStyle.fontWeight = this.props.activeStyle.clinicButtonFontWeight;
                }
            }


        }
        let showAllText = 'Show all'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.showAllLinkTextEn) {
            showAllText = this.props.style.showAllLinkTextEn;
        } else if (this.props.style.showAllLinkTextSv) {
            showAllText = this.props.style.showAllLinkTextSv;
        }
        let searchLabelText = 'Search'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.searchLabelTextEn) {
            searchLabelText = this.props.style.searchLabelTextEn;
        } else if (this.props.style.searchLabelTextSv) {
            searchLabelText = this.props.style.searchLabelTextSv;
        }

        let loginButtonText = 'Login'.translate(this.props.lang);
        if (this.props.lang == 'en' && this.props.style.loginButtonTextEn) {
            loginButtonText = this.props.style.loginButtonTextEn;
        } else if (this.props.style.loginButtonTextSv) {
            loginButtonText = this.props.style.loginButtonTextSv;
        }

        let searchLabelVisibility = true;
        if (this.props.style.searchLabelVisibility == 'invisible') {
            searchLabelVisibility = false;
        }


        if (this.props.lang == 'en' && this.props.style.customGroupNameEn) {
            name = this.props.style.customGroupNameEn;
        } else if (this.props.lang == 'se' && this.props.style.customGroupNameSv) {
            name = this.props.style.customGroupNameSv;
        }
        let visibilityGroupName = this.props.style.visibilityGroupName === 'invisible' ? false : true;
        return (
            <div style={{ ...formatCSS(this.props.style) }} className='main-clinic-data-wrap'>
                {
                    this.props.style.borderTopLeftRadius || this.props.style.borderTopRightRadius ||
                        this.props.style.borderBottomLeftRadius || this.props.style.borderBottomRightRadius ?
                        <InverseRadius style={this.props.style} />
                        :
                        null
                }
                <div >
                    <div className='main-data-wrap'>
                        {
                            logo ?
                                <div className='main-data-image-wrap'>
                                    <img src={logo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                                :
                                null

                        }
                        {
                            name && visibilityGroupName ?
                                <p style={{ ...groupNameStyle }}>{name}</p>
                                :
                                null

                        }
                        {
                            buttonsMainLayout ?
                                <div className='clinic-buttons-wrap'>
                                    {
                                        this.state.clinicGroups?.map((item, idx) => {
                                            const styleElement = this.state.activeElement === item?._id ? { ...clinicButtonActiveStyle } : { ...clinicButtonStyle }
                                            return (
                                                <div
                                                    onMouseEnter={() => this.setState({ activeElement: item?._id })}
                                                    onMouseLeave={() => this.setState({ activeElement: null })}
                                                    className='clinic-btn'
                                                    style={styleElement}
                                                >
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            !buttonsMainLayout ?
                                <div className='search-field-wrap'>
                                    {
                                        searchLabelVisibility ?
                                            <h5 style={{ ...searchLabelStyle }}>{searchLabelText}</h5>
                                            :
                                            null
                                    }

                                    <div className='input-wrap'>
                                        <input type='text' disabled />
                                        <div className='search-icon'>
                                            <Isvg src={searchIcon} />
                                        </div>

                                    </div>

                                </div>
                                :
                                null
                        }


                        {
                            !buttonsMainLayout ?
                                <div className='show-all-link'>
                                    <h6 style={{ ...showAllLinStyle }}>{showAllText}</h6>
                                </div>
                                :
                                null
                        }



                        <div className='login-info'>
                            {
                                loginTxt ?
                                    <p style={{ ...loginTextStyle }}>{loginTxt}</p>
                                    :
                                    null

                            }
                            <div
                                onMouseEnter={() => this.setState({ activeElement: 'login-btn' })}
                                onMouseLeave={() => this.setState({ activeElement: null })}
                                className='login-btn'
                                style={{ ...loginButtonStyle }}
                            >
                                {/* {'Login'.translate(this.props.lang)} */}
                                {loginButtonText}
                            </div>
                        </div>

                    </div>

                </div>

            </div>




        );
    }
}

export default MainInfo;