import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Button, UncontrolledAlert, Alert, Modal, ModalBody, ModalFooter, ModalHeader

} from 'reactstrap';

import { Rnd } from 'react-rnd';

import Isvg from "react-inlinesvg";
import { formValueSelector, change, isDirty, submit } from 'redux-form';
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/formBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import { Link } from 'react-router-dom';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import warrning from '../../assets/svg/warrning.svg';
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import link from "../../assets/svg/link-new-tab.svg";

import Iframe from 'react-iframe';
import Checkbox from '../../components/forms/fields/checkbox';


import defaultJson from '../landingBuilder/defaultMainClinicGroup.json'
import defaultInformationJson from '../landingBuilder/defaultInformation.json'
import defaultLoginJson from '../landingBuilder/defaultLogin.json'

import LandingBuilder from './mainLandingBuilder'

import { fileUploading } from '../../components/forms/validation';
import { fileUploadingRequired } from '../../components/forms/validation';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicData extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'query-group': null
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            regions: [],
            showForm: false,
            tabIndex: 1,
            template: -1,
            windowWidth: typeof window != 'undefined' && window.innerWidth ? window.innerWidth : 0,
            type: 'bankid',
            bankIdRedirectUrl: null,
            templates: [],
            updateLanding: false,



        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })

        }





    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        fetch(API_ENDPOINT + '/data-managment/landing-builder-styles/get/all', {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (result) {
                this.setState({
                    templates: result
                })
            }


        })
        this.setLandingStyle()

        window.addEventListener('resize', this.resizeWindow);

        // let selectedGroupIdx = -1;
        // if (this.props.uData) {
        //     if (this.props.selectedGroup && this.props.uData.clinicGroups) {
        //         for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        //             if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
        //                 selectedGroupIdx = i;
        //                 break;
        //             }
        //         }
        //     }
        // }



        this.setState({ showForm: true })

    }
    componentWillUnmount() {

        window.removeEventListener('resize', this.resizeWindow);
    }
    resizeWindow = () => {
        let windowWidth = this.state.windowWidth;
        if (typeof window != 'undefined' && window.innerWidth && Math.abs(windowWidth - window.innerWidth) > 50) {
            this.setState({ windowWidth: window.innerWidth })
        }

    }
    setLandingStyle = (reset = false) => {
        let landingStyle = {
            landingJSON: this.props.uData.mainClinicGroup ? this.props.uData.mainClinicGroup.landingJSON_v2 : defaultJson,

        }

        this.setState({
            landingStyle
        }, () => {
            if (reset) {
                if (this.state.pageBuilderChanged) {
                    this.setState({ pageBuilderChanged: 'done' })
                }
            }
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps.uData.adminMainClinicGroup != this.props.uData.adminMainClinicGroup && !this.props.uData.adminMainClinicGroup) {
            this.props[0].history.push('/')
        }
        if (prevProps.uData.mainClinicGroup != this.props.uData.mainClinicGroup) {
            this.setLandingStyle(true)
        }
        if (!this.state.pageBuilderChanged) {
            this.setLandingStyle()
            this.setState({
                // landingStyle,
                pageBuilderChanged: 'done'
            })
        }
        if ((this.props.isDirty || this.state.pageBuilderChanged == 'open') && !this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }
        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.setState({
                showForm: false
            }, () => {
                setTimeout(() => {
                    this.setState({ showForm: true })
                }, 100)
            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };


    /*
       update = (data) => {
         data.groupId = this.props.selectedGroup;
 
         fetch(API_ENDPOINT + '/users/account/updateClinicData', {
             method: 'POST',
             headers: {
                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                 'content-type': 'application/json'
             },
             body: JSON.stringify(data)
         }).then(parseJSON).then(({ result, status }) => {
             if (result.error) {
                 this.setState({ error: result.error })
             } else {
                 this.setState({
                     message: result.message
                 })
 
                 this.props.verifyUser(() => {}, () => {
                     this.props.changeGroup(data.groupId)
                 });
             }
 
         })
 
 
     }
     */



    copyFunction = () => {
        const copyText = this.input
        copyText.select();
        document.execCommand('copy');
    }
    copyFunction1 = () => {
        const copyText = this.input1
        copyText.select();
        document.execCommand('copy');
    }

    onDismiss = () => { this.setState({ message: null, error: null }) };

    updateFooterSettings = (data) => {
        console.log(data)
        let obj = {

        }
        if (data) {
            obj.footerTextColor = data.footerTextColor;
            obj.footerBackground = data.footerBackground;
            if (data.alias) {
                obj.alias = data.alias;
            }
            if (data.showHeader) {
                obj.showHeader = true;
            } else {
                obj.showHeader = false;
            }
            obj.logo = data.logo;
            obj.loginTxt = data.loginTxt;
            obj.backToMainEn = data.backToMainEn;
            obj.backToMainSv = data.backToMainSv;
            obj.statusBarColor = data.statusBarColor;
            this.setState(
                {
                    showPlayer: true,
                },
                () => {



                    data.landingJSON_v2 = this.state.landingJSON;
                    let groupId = null;
                    if (this.props.uData.mainClinicGroup && this.props.uData.mainClinicGroup._id)
                        groupId = this.props.uData.mainClinicGroup._id;
                    if (groupId) {
                        this.player.current.play();
                        fetch(API_ENDPOINT + '/data/main-clinic-groups/' + groupId, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(obj)
                        }).then(parseJSON).then(({ result, status }) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.setState({
                                    showPlayer: false,
                                    showPlayer2: true,
                                },
                                    () => {
                                        setTimeout(() => {
                                            this.setState({ showPlayer2: false });
                                        }, 1000);
                                    })
                                this.props.verifyUser(() => {
                                    if (this.state.changeTabIndex) {
                                        this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, changeTabIndex: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                                    } else {
                                        this.setState({ showForm: null }, () => this.setState({ showForm: true }, () => this.props.abortAction()))
                                    }
                                }, () => {
                                    this.props.changeGroup(data.groupId)

                                });
                                if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('group|') != -1) {
                                    this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                    this.props.abortAction()
                                } else if (this.props.changeLinkModal && this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                    this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                    this.props.abortAction()
                                } else if (this.props.changeLinkModal) {
                                    this.props[0].history.push(this.props.changeLinkModal)
                                    this.props.abortAction()
                                }
                            }

                        })
                    }


                }
            )
        }

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let selectedGroupIdx = 0;
        if (this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>

                    <Row>
                        <Col lg="12">


                            {this.state.error ?
                                <Alert toggle={this.onDismiss} isOpen={this.state.error} color="success" className='landing-alert-success'>
                                    {this.state.error.translate(this.props.lang)}
                                </Alert>
                                :
                                null
                            }

                            <div className="panel">

                                <Row>
                                    <Col lg="12">
                                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>

                                    </Col>
                                    <Col lg="12">
                                        <div className="tabs-buttons-wrap" style={{ zIndex: 950 }} >

                                            <Button className={this.state.tabIndex == 1 ? "tab-button acitve-tab-button" : "tab-button"}
                                                // onClick={() => this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged ? this.setState({ tabIndexCheck: 1 }) : this.setState({ tabIndex: 1 })}
                                                onClick={() => { this.props.isDirty ? this.setState({ tabIndexCheck: 1 }) : this.setState({ tabIndex: 1 }) }}
                                            >{"Landing Builder".translate(this.props.lang)}</Button>


                                            <Button className={this.state.tabIndex == 2 ? "tab-button acitve-tab-button" : "tab-button"}
                                                // onClick={() =>
                                                //     this.props.isDirty || this.props.isDirtySecond || this.state.colorSchemeChanged || this.state.pageBuilderChanged == 'open' ?
                                                //         this.setState({ tabIndexCheck: 2 })
                                                //         :
                                                //         this.setState({ tabIndex: 2 })
                                                // }
                                                onClick={() => { this.state.pageBuilderChanged == 'open' ? this.setState({ tabIndexCheck: 2 }) : this.setState({ tabIndex: 2 }) }}
                                            >{"Settings".translate(this.props.lang)}</Button>


                                        </div>
                                    </Col>

                                    <Col lg='12'>
                                        {
                                            typeof window != 'undefined' && window.innerWidth > 1023 ?
                                                <div className={this.state.tabIndex == 1 ? 'visibility mobile-mt-20' : 'none-visibility'}>
                                                    <LandingBuilder {...this.props} landingStyle={this.state.landingStyle}
                                                        mainData={this.props.uData && this.props.uData.mainClinicGroup ? this.props.uData.mainClinicGroup : {}}
                                                        templates={this.state.templates} uData={this.props.uData}

                                                        onChange={(item) => {
                                                            this.setState({
                                                                pageBuilderChanged: item
                                                            })

                                                        }} updateLanding={this.state.updateLanding} changeUpdateLanding={(item) => {
                                                            this.setState({ updateLanding: item }, () => {
                                                                this.props.verifyUser(() => { })
                                                                if (this.state.waitResponseFromLandingBulder) {
                                                                    this.setState({ waitResponseFromLandingBulder: false }, () => {
                                                                        if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                                                            this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                                                            this.props.abortAction()
                                                                        } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                                                            this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                                                            this.props.abortAction()
                                                                        } else
                                                                            this.props.verifyUser(() => {
                                                                                this.props[0].history.push(this.props.changeLinkModal)
                                                                                this.props.abortAction()
                                                                            })
                                                                    })
                                                                }
                                                            })
                                                        }} />
                                                </div>
                                                :
                                                null
                                        }

                                        <div className={this.state.tabIndex == 2 ? 'visibility mt-20 mobile-mt-20' : 'none-visibility'}>

                                            {this.state.showForm ?
                                                <>
                                                    <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                                        getStringDateTs={this.props.getStringDateTs}
                                                        lang={this.props.lang}
                                                        onSubmit={(data) => this.updateFooterSettings(data)}
                                                        initialValues={this.props.uData && this.props.uData.mainClinicGroup ? this.props.uData.mainClinicGroup : {}}
                                                        buttonText={
                                                            this.state.showPlayer ? (
                                                                <Player
                                                                    onEvent={(event) => {
                                                                        if (event === "load") this.stopAnimation();
                                                                    }}
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>

                                                            ) : this.state.showPlayer2 ? (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player2} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                    style={{ height: "30px", width: "30px" }}
                                                                ></Player>
                                                                    {'Saved'.translate(this.props.lang)}
                                                                </div>
                                                            ) : (
                                                                "Save".translate(this.props.lang)
                                                            )
                                                        }


                                                        fields={[

                                                            {
                                                                type: 'row',
                                                                children: [

                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 4,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },

                                                                        children: [
                                                                            {
                                                                                type: 'image',
                                                                                name: 'logo',
                                                                                label: 'Logo'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                group: this.props.selectedGroup,
                                                                                validate: [fileUploading("Upload file".translate(this.props.lang))]
                                                                            },
                                                                            // {
                                                                            //     type: 'checkbox',
                                                                            //     name: 'showHeader',
                                                                            //     label: 'Show landing header'.translate(this.props.lang),
                                                                            // },



                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 8,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },

                                                                        children: [
                                                                            {
                                                                                type: 'textarea',
                                                                                name: 'loginTxt',
                                                                                label: 'Login text'.translate(this.props.lang),
                                                                                labelInfo: 'Line breaks appear as entered. Be mindful of how they affect text on different screen sizes.'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                height: 100
                                                                            },




                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },

                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'backToMainEn',
                                                                                label: 'Back to main group text (en)'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                validate: []
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },

                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'backToMainSv',
                                                                                label: 'Back to main group text (sv)'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                validate: []
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: {
                                                                            lg: 12,
                                                                            sm: 12,
                                                                            xs: 12
                                                                        },

                                                                        children: [
                                                                            {
                                                                                type: 'color-picker',
                                                                                name: 'statusBarColor',
                                                                                label: 'Status bar color'.translate(this.props.lang),
                                                                                lang: this.props.lang,
                                                                                className: 'clinic-data-color-picker'
                                                                            },

                                                                        ]
                                                                    },






                                                                ]
                                                            },
                                                            // {
                                                            //     type: 'row',
                                                            //     children: [

                                                            //         {
                                                            //             type: 'col',
                                                            //             width: {
                                                            //                 lg: 8,
                                                            //                 sm: 12,
                                                            //                 xs: 12
                                                            //             },

                                                            //             children: [


                                                            //                 {
                                                            //                     type: 'color-picker',
                                                            //                     name: 'footerTextColor',
                                                            //                     label: 'Footer text color'.translate(this.props.lang),
                                                            //                     className: 'clinic-data-color-picker'
                                                            //                 },
                                                            //                 {
                                                            //                     type: 'color-picker',
                                                            //                     name: 'footerBackground',
                                                            //                     label: 'Footer background color'.translate(this.props.lang),
                                                            //                     className: 'clinic-data-color-picker'
                                                            //                 },



                                                            //             ]
                                                            //         },


                                                            //     ]
                                                            // }
                                                        ]}
                                                    ></FormBuilder>
                                                </>
                                                :
                                                null
                                            }
                                        </div>


                                    </Col>
                                </Row>
                                {
                                    this.state.tabIndexCheck ?
                                        <Modal isOpen={this.state.tabIndexCheck} centered>
                                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                                            <ModalBody className="delete-modal">
                                                {/* <img src={warrning} /> */}
                                                <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                                                <p>{this.props.isDirtySecond}</p>
                                            </ModalBody>
                                            <ModalFooter className='buttons-right-blue'>
                                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {

                                                    if (this.state.pageBuilderChanged == 'open') {
                                                        // this.props.verifyUser(() => {

                                                        // })
                                                        this.setState({
                                                            updateLanding: true,
                                                            tabIndex: this.state.tabIndexCheck,
                                                            tabIndexCheck: false,
                                                            showForm: null,
                                                            pageBuilderChanged: 'done'
                                                        }, () => {
                                                            this.setState({
                                                                showForm: true
                                                            })
                                                            this.props.verifyUser(() => { })
                                                        })

                                                    } else {
                                                        this.setState({
                                                            changeTabIndex: true
                                                        }, () => {
                                                            this.props.dispatch(submit('form'))

                                                        })
                                                    }


                                                }}>{'Save and exit'.translate(this.props.lang)}</Button>


                                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndex: this.state.tabIndexCheck, tabIndexCheck: false, pageBuilderChanged: false }, () => {
                                                    this.setState({ showForm: true })
                                                    if (!this.state.pageBuilderChanged) {
                                                        this.props.abortAction()
                                                        this.props.verifyUser(() => { })
                                                    }
                                                })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }
                                {
                                    this.props.changeLinkModal ?
                                        <Modal isOpen={this.props.changeLinkModal} centered>
                                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                                            <ModalBody className="delete-modal">
                                                {/* <img src={warrning} /> */}
                                                <p className="message">{'Data is not saved, if you click Yes it will be deleted'.translate(this.props.lang)}</p>
                                            </ModalBody>
                                            <ModalFooter className='buttons-right-blue'>
                                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                                    if (this.state.pageBuilderChanged == 'open') {

                                                        this.setState({
                                                            updateLanding: true,

                                                            pageBuilderChanged: 'done',
                                                            waitResponseFromLandingBulder: true
                                                        })

                                                    } else {
                                                        this.props.dispatch(submit('form'))

                                                    }


                                                }}>{'Save and exit'.translate(this.props.lang)}</Button>


                                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ showForm: null, tabIndexCheck: false, pageBuilderChanged: false }, () => {
                                                    this.setState({
                                                        showForm: true
                                                    }, () => {
                                                        //code below was in this callback but pageBuilderChanged was updated in componentDidUpdate and it didn't work
                                                    })
                                                    if (this.props.changeLinkModal.indexOf('group|') != -1) {
                                                        this.props.changeGroup(this.props.changeLinkModal.split('|')[1])
                                                        this.props.abortAction()
                                                    } else if (this.props.changeLinkModal.indexOf('clinic|') != -1) {
                                                        this.props.changeClinic(this.props.changeLinkModal.split('|')[1]);
                                                        this.props.abortAction()
                                                    } else if (!this.state.pageBuilderChanged) {
                                                        this.props.verifyUser(() => {
                                                            this.props[0].history.push(this.props.changeLinkModal)
                                                            this.props.abortAction()
                                                        })
                                                    }
                                                })}>{'Exit without saving'.translate(this.props.lang)}</Button>

                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }
                            </div>

                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        check: selector(state, 'landingConfig.informationLink'),
        isDirty: isDirty('form')(state),
    }

})(Page(ClinicData));


